import { IStateAttachments } from "../states/state-attachments";
import {
  ATTACHMENT_TYPES,
  LOAD_FILE,
  LOADING_FILES,
  UPLOAD,
} from "../actions/attachmentsActions";

const INITIAL_STATE: IStateAttachments = {
  files: new Map<string, File>(),
  loading: false,
  attachmentTypes: [],
};

const attachmentsReducer = (
  state = INITIAL_STATE,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case UPLOAD:
      return {
        ...state,
      };
    case LOADING_FILES:
      return {
        ...state,
        loading: action.payload,
      };
    case ATTACHMENT_TYPES:
      return {
        ...state,
        attachmentTypes: action.payload,
      };
    case LOAD_FILE:
      const fileMap = state.files;
      fileMap.set(action.payload.id, action.payload.file);
      return {
        ...state,
        files: fileMap,
      };
    default:
      return state;
  }
};

export default attachmentsReducer;
