import React from "react";
import "./ReferenceView.scss";
import { IViewField } from "../../../../models/view-field";
import {
  DictionaryObject,
  Dictionary,
} from "../../../../models/types/dictionary";
import { useSelector } from "react-redux";
import { IGeneralField } from "../../../../models/fields/general-field";
import { FieldFactory } from "../../../../models/fields/field-factory";
import { useTranslation } from "react-i18next";

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const ReferenceView = (props: {
  field: IViewField;
  item: DictionaryObject;
  viewArray: string[];
  showFields?: boolean;
  showEmpty?: boolean;
  delimiter?: string;
}) => {
  const { field, item, viewArray, showFields, showEmpty, delimiter } = props;
  const { t } = useTranslation();

  const referenceModel = useSelector((state: any) =>
    state.modelsReducer.models.get(field.model)
  );

  const filterEmpty = (viewFields: IViewField[]): IGeneralField[] => {
    return viewFields
      .map((vf: IViewField) => {
        const fieldFactory = new FieldFactory(vf);
        const newField: IGeneralField = fieldFactory.createField();
        const isEmpty = newField.isEmpty((item as DictionaryObject)[vf.name]);
        return isEmpty && !showEmpty ? null : newField;
      })
      .filter(notEmpty);
  };

  const referenceListView = (field: IViewField, item: Dictionary) => {
    const referenceView: string[] | undefined = viewArray;

    if (typeof item === "object" && referenceView && referenceModel) {
      const viewFields: IViewField[] = referenceModel.fields.filter(
        (f: IViewField) => {
          return !!referenceView.find((c: string) => c && c.split(".")[0] === f.name)
        }
      )
      return item ? (
        <span key={(item as DictionaryObject)["id"] as string}>
          {filterEmpty(viewFields).map((f: IGeneralField, indexF: number) => {
            const _delimiter =
              indexF > 0 ? delimiter || field.viewDelimiter || " / " : " ";
            let view = referenceView.find((c: string) => c && c.split(".").length > 1 && c.split(".")[0] === f.viewField.name);
            view = view ? view.substring(view.indexOf(".") + 1) : "";
            return (
              <span key={f.viewField.name}>
                <span>{_delimiter}</span>
                {showFields && <>{indexF > 0 && <br />}<i>{t(f.viewField.name) + ': '}</i></>}
                {f.viewRender((item as DictionaryObject)[f.viewField.name], [view ? view : ""])}
              </span>
            );
          })}
        </span>
      ) : null;
    } else if (typeof item === "string" || typeof item === "number") {
      return (
        <span>
          {field.needTranslate ? t(item?.toString()) : item?.toString()}
        </span>
      );
    }
    return null;
  };

  return referenceListView(field, item);
};

export default ReferenceView;
