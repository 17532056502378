import { Button } from "@blueprintjs/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { reduxForm } from "redux-form";
import { dynamicConstraints } from "../../../../helpers/dynamic-constraints";
import { DictionaryObject, Selectable } from "../../../../models/types/dictionary";
import { IViewModel } from "../../../../models/view-model";
import { saveDefaultsConditions } from "../../../../redux/actions/entitiesActions";
import { IAppState } from "../../../../redux/states/state";
import SimpleSelect from "../../forms/controls/simple-select/SimpleSelect";
import "./AnalysisDone.scss";

const subreports = [
    {
        name: "No selection",
        defaultConditions: null
    },
    {
        name: "PeculiarProperty",
        defaultConditions: {
            rule: "And",
            nodes: [
                {
                    rule: "And",
                    constraints: [
                        {
                            type: "NotNull",
                            field: "PeculiarProperty.id"
                        }
                    ]
                }
            ]
        }
    },
    {
        name: "AnalysisExist",
        defaultConditions: {
            rule: "And",
            nodes: [
                {
                    rule: "And",
                    constraints: [
                        {
                            type: "NotNull",
                            field: "AnalyticsResult.id"
                        },
                        {
                            type: "NotNull",
                            field: "AnalyticsResult.AnalysisDate"
                        }
                    ]
                }
            ]
        }
    },
    {
        name: "AnalysisNotExist",
        defaultConditions: {
            rule: "And",
            nodes: [
                {
                    rule: "Or",
                    constraints: [
                        {
                            type: "Null",
                            field: "AnalyticsResult.id"
                        },
                        {
                            type: "Null",
                            field: "AnalyticsResult.AnalysisDate"
                        }
                    ]
                }
            ]
        }
    }
] as {name: string, defaultConditions: DictionaryObject}[];

interface Props {
    model: IViewModel;
}

function AnalysisDone(props: Props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { model } = props;
    const [selectedReport, selectReport] = useState<any>(null);
    const currentEmployee = useSelector((state: IAppState) => state.authorizationReducer.currentEmployee);

    if (!model) {
        return <span>Loading...</span>;
    }

    const renderSubreports = () => {
        return (<SimpleSelect
            items={subreports}
            renderView={(value: Selectable) =>
                value ? t((value as DictionaryObject)["name"] as string) : "No selection"
            } 
            component={
                <Button
                    rightIcon="caret-down"
                    text={selectedReport ? t(selectedReport.name as string) : "No selection"}
                />
            }
            onChange={(e) => {
                selectReport(e);
                dispatch(saveDefaultsConditions(
                    dynamicConstraints(currentEmployee, e.defaultConditions, model && model.yearFilter ? new Date().getFullYear() : undefined)
                ));
            }}
        />);
    }
   
    return (
        <div className="analysis-done">
            {renderSubreports()}
        </div>
    )
}

export default AnalysisDone;
