import {
  Boundary,
  Button,
  Icon,
  Menu,
  MenuItem,
  OverflowList,
  Popover,
  Position
} from "@blueprintjs/core";
import { TFunction } from "i18next";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { project } from "../../../configs/index";
import { IDefaultSorting } from "../../../models/default-sorting";
import { IMenuItem } from "../../../models/menu-item";
import { IPermit } from "../../../models/permit";
import { userHasPermit } from "../../../redux/actions/authorizationActions";
import { updateSorting } from "../../../redux/actions/newEntitiesActions";
import { IAppState } from "../../../redux/states/state";
import "./TopMenu.scss";

interface Props extends RouteComponentProps, WithTranslation {
  permits: IPermit[];
  updateSorting: CallableFunction;
}

class Sections extends React.Component<Props, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      menu: [],
      selectedSection: {
        group: "",
        section: null,
      },
    };
  }

  componentDidMount(): void {
    this.setState({ menu: project.menu.items });
    const currentLocation = this.props.location.pathname;
    this.findSelectedGroup(currentLocation, (project.menu as any).items);
  }

  findSelectedGroup(currentLocation: string, menu: IMenuItem[]) {
    menu.forEach((menuItem: IMenuItem) => {
      if (menuItem.url === currentLocation) {
        this.setState({
          selectedSection: {
            group: menuItem.title,
            section: menuItem.title,
          },
        });
        return;
      }
      if (menuItem.menuItems && menuItem.menuItems.length) {
        this.findSelectedMenuItem(
          currentLocation,
          menuItem.menuItems,
          menuItem.title
        );
      } else {
        return;
      }
    });
  }

  findSelectedMenuItem(
    currentLocation: string,
    menu: IMenuItem[],
    group: string
  ) {
    menu.forEach((menuItem: IMenuItem) => {
      if (menuItem.url === currentLocation) {
        this.setState({
          selectedSection: {
            group: group,
            section: menuItem.title,
          },
        });
        return;
      }
      if (menuItem.menuItems && menuItem.menuItems.length) {
        this.findSelectedMenuItem(currentLocation, menuItem.menuItems, group);
      } else {
        return;
      }
    });
  }

  selectSection(section: IMenuItem, group: IMenuItem, selectable: boolean, newWindow: boolean) {
    if(newWindow) {
      window.open(section.url, "_blank");
      return;
    }
    const { history } = this.props;
    if (selectable) {
      this.setState({
        selectedSection: {
          section: section.title,
          group: group.title,
        },
      });
      this.props.updateSorting(null);
      history.push(section.url.toLowerCase());
    }
  }

  renderMenuItems = (
    g: IMenuItem,
    menuItems: IMenuItem[],
    t: TFunction,
    permits: IPermit[]
  ) => {
    return menuItems && menuItems.length
      ? menuItems.map((m: any) => {
        if (!m.permit || (m.permit && userHasPermit(permits, m.permit))) {
          return (
            <MenuItem
              popoverProps={{ openOnTargetFocus: false }}
              key={m.title}
              onClick={() =>
                this.selectSection(m, g, !m.menuItems || !m.menuItems.length, false)
              }
              onContextMenu={() => this.selectSection(m, g, !m.menuItems || !m.menuItems.length, true)}
              text={t(`Menu.${m.title}`)}
            >
              {this.renderMenuItems(g, m.menuItems, t, permits)}
            </MenuItem>
          );
        } else {
          return <span key={m.title} />;
        }
      })
      : undefined;
  };

  renderOverFlowListItems = (g: any, isOverflowed = false) => {
    const { t, permits } = this.props;

    const { selectedSection } = this.state;
    let className =
      selectedSection.section && selectedSection.group === g.title
        ? "menu-selected"
        : "";
    className += isOverflowed ? "button-for-overflowed-menu" : "";

    return (
      <div key={g.title}>
        {g.menuItems && g.menuItems.length ? (
          <Popover position={Position.BOTTOM_LEFT} minimal>
            <Button
              large
              className={className}
              minimal
              text={
                selectedSection.section && selectedSection.group === g.title
                  ? t(`Menu.${selectedSection.section}`)
                  : t(`Menu.${g.title}`)
              }
            />
            <Menu>{this.renderMenuItems(g, g.menuItems, t, permits)}</Menu>
          </Popover>
        ) : (
            <Button
              large
              onClick={() => this.selectSection(g, g, true, false)}
              onContextMenu={() => this.selectSection(g, g, true, true)}
              className={className}
              minimal
              text={t(`Menu.${g.title}`)}
            />
          )}
      </div>
    );
  };
  renderOverflowItem = (items: any[]) => {
    return (
      <Popover position={Position.LEFT} className={"popover"}>
        <Icon icon={"more"} className={"icon-more"} />
        <Menu>
          {items.map((item) => this.renderOverFlowListItems(item, true))}
        </Menu>
      </Popover>
    );
  };
  render() {
    const { permits } = this.props;
    const { menu } = this.state;
    const items: any = [];
    menu.forEach((g: IMenuItem) => {
      if (!g.permit || (g.permit && userHasPermit(permits, g.permit))) {
        items.push(g);
      }
    });
    return (
      <div className="display-row">
        <OverflowList
          items={items}
          observeParents={true}
          collapseFrom={Boundary.END}
          minVisibleItems={1}
          overflowRenderer={(items: any) => this.renderOverflowItem(items)}
          visibleItemRenderer={(item: any) =>
            this.renderOverFlowListItems(item)
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  permits: state.authorizationReducer.permits,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    updateSorting: (sortingOptions: IDefaultSorting) =>
      updateSorting(sortingOptions),
  }, dispatch);

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Sections))
);
