export const removeEmptyObject = (value: any) => {
  const noEmpty = { ...value };
  if (Object.keys(noEmpty).length > 0) {
    Object.keys(noEmpty).forEach((key: any) => {
      if (noEmpty[key]) {
        if (Array.isArray(noEmpty[key]) && noEmpty[key].length) {
          noEmpty[key] = noEmpty[key].filter(
            (v: any) =>
              v &&
              (typeof v !== "object" ||
                (Object.keys(v).length > 0 && !v.default))
          );
        }
      }
    });
    return noEmpty;
  }
};
