import { IPagingOptions } from "./../../models/paging-options";
import { DictionaryObject } from "./../../models/types/dictionary";
import { IConstraintTree } from "./../../models/constraint";
import axios from "axios";
import { ENTITIES_BACKEND } from "../../backend-connection";
import { IConstraint } from "../../models/constraint";

const findAll = (
  modelName: string,
  constraints: IConstraint[]
): Promise<any> => {
  return axios(`${ENTITIES_BACKEND}/findAll?modelName=` + modelName, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: constraints,
  });
};

const findAllV2 = (
  modelName: string,
  constraintTree: IConstraintTree,
  sortingQuery: string,
  pagingOptions?: IPagingOptions
): Promise<any> => {
  const pagingString = pagingOptions ? `&page.offset=${pagingOptions.page * pagingOptions.pageSize}&page.limit=${
    pagingOptions.pageSize
  }&page.showDeleted=${pagingOptions.showDeleted}`: ""
  return axios(
    `${ENTITIES_BACKEND}/findAllV2?modelName=` +
      modelName + pagingString +
      sortingQuery,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: constraintTree,
    }
  );
};

const updateEntity = (
  modelName: string,
  entity: DictionaryObject
): Promise<any> => {
  return axios(`${ENTITIES_BACKEND}/update?modelName=` + modelName, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: entity,
  });
};

const createEntity = (
  modelName: string,
  entity: DictionaryObject
): Promise<any> => {
  return axios(`${ENTITIES_BACKEND}/create?modelName=` + modelName, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: entity,
  });
};

export { findAll, findAllV2, updateEntity, createEntity };
