import React from "react";
import "./ResultViewField.scss";
import { useSelector } from "react-redux";
import { formValueSelector } from "redux-form";
import { IViewField } from "../../../../../models/view-field";
import { useTranslation } from "react-i18next";

export const ResultViewField = (props: {
  selectedItem: any;
  field: IViewField;
  formName: string;
  value?: any | undefined;
}) => {
  const { t } = useTranslation();

  const state = useSelector((state: any) => state);
  let selector: any = null;
  const data: any = {};

  if (props.formName) {
    selector = formValueSelector(props.formName);
  }

  const connectedTo = props.field.connectedTo;

  if (selector && connectedTo && connectedTo.length) {
    connectedTo.forEach((f) => {
      data[f.field] = selector(state, f.field);
    });
    data[props.field.name] = selector(state, props.field.name);
  }

  return (
    <div className="result-view">
      {props.field.resultFunction && data
        ? t("Cost") + " " + props.value
        : ""}
    </div>
  );
};

export default ResultViewField;
