import React, { useEffect, useState, ReactElement } from "react";
import "./ChangesHistory.scss";
import { useTranslation } from "react-i18next";
import { Pagination, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  clearChanges,
  getChangesHistory,
  loadChangesDetail,
} from "../../redux/actions/changesActions";
import { IAppState } from "../../redux/states/state";
import Moment from "moment";
import { ChangesTypes } from "../../models/enums/changes-types";
import { Alert, Button, ButtonGroup } from "@blueprintjs/core";
import { IChangeRequest } from "../../models/changes/change-request";
import ChangesDetails from "./changes-details/ChangesDetails";
import { IChange } from "../../models/changes/changes-history";
import { Dictionary, DictionaryObject } from "../../models/types/dictionary";
import ListCount from "../core/lists/list-count/ListCount";
import { defaultPagingOptions } from "./default-paging";

interface Props {
  selectedItem: Dictionary;
  model?: string;
  isNew?: boolean;
}

export const ChangesHistory: React.FC<Props> = (props: Props): ReactElement => {
  const { t } = useTranslation();
  const { model, selectedItem, isNew } = props;

  const dispatch = useDispatch();
  const [selected, select] = useState(0);

  useEffect(() => {
    const request: IChangeRequest = {};
    if (model) {
      request.entity = model;
    }
    if (selectedItem) {
      request.entityId = selectedItem
        ? ((selectedItem as DictionaryObject)["id"] as number).toString()
        : "0";
    }
    // dispatch(setChangeFilter(request));
    if (!isNew) {
      dispatch(getChangesHistory(request, 0, defaultPagingOptions.pageSize));
    }
  }, [dispatch, model, selectedItem]);

  useEffect(() => {
    return () => {
      dispatch(clearChanges());
    };
  }, [dispatch]);

  const { changes, loading, totalChangesCount, filter } = useSelector(
    (state: IAppState) => state.changesReducer
  );
  const { usersSimple } = useSelector((state: IAppState) => state.usersReducer);

  const openDetailForm = (value: number) => {
    select(value);
  };

  const closeDetailForm = () => {
    dispatch(loadChangesDetail(null));
    select(0);
  };

  const getRenderFields = () => {
    const columns = [
      {
        title: t("ChangesHistory.Operation"),
        dataIndex: "type",
        key: "operation",
        render: (value: ChangesTypes) => (
          <span>{t("ChangesTypes." + ChangesTypes[value])}</span>
        ),
      },
      {
        title: t("ChangesHistory.Entity"),
        dataIndex: "entity",
        key: "entity",
        render: (value: string) => <span>{t(value)}</span>,
      },
      {
        title: t("ChangesHistory.Changes"),
        dataIndex: "entity",
        key: "entity",
        hidden: !model,
        render: (date: string, record: IChange) => {
          return <ChangesDetails id={record.id} />;
        },
      },
      {
        title: t("ChangesHistory.User"),
        dataIndex: "userId",
        key: "user",
        render: (value: string) => {
          if (usersSimple && usersSimple.size) {
            const user = usersSimple.get(value ? value.toString() : "0");
            return <span>{user ? user.username : " - "}</span>;
          }
          return <span></span>;
        },
      },
      {
        title: t("ChangesHistory.Date"),
        dataIndex: "dateTime",
        key: "date",
        render: (date: string) => (
          <span>{Moment(date).format("DD.MM.YYYY HH:mm")}</span>
        ),
      },
      {
        title: t("ChangesHistory.Changes"),
        dataIndex: "id",
        key: "id",
        hidden: model,
        render: (value: number) => {
          return (
            <div>
              <Button
                icon="applications"
                minimal
                text={t("UI.ViewDetails")}
                onClick={() => openDetailForm(value)}
              />
            </div>
          );
        },
      },
    ];
    return columns.filter((c) => !c.hidden);
  };

  const renderTemplatesFooter = () => {
    return (
      <div className={`footer`}>
        <ButtonGroup className={`footer_group`}>
          <Pagination
            className="footer-pagination"
            total={totalChangesCount}
            defaultPageSize={defaultPagingOptions.pageSize}
            showLessItems={true}
            showSizeChanger={false}
            onChange={(e: number) => {
              dispatch(getChangesHistory(filter, e - 1, defaultPagingOptions.pageSize));
            }}
          />
          <ListCount totalCount={totalChangesCount} />
        </ButtonGroup>
      </div>
    );
  };

  return (
    <div className="changes-history">
      <div className="changes-history__body">
        <Table
          loading={loading}
          columns={getRenderFields()}
          dataSource={Array.from(changes.values())}
          scroll={{ y: 500 }}
          footer={() => renderTemplatesFooter()}
          pagination={false}
          rowKey="id"
          bordered
        />
      </div>
      <Alert
        confirmButtonText={t("UI.Close")}
        isOpen={!!selected}
        onClose={() => closeDetailForm()}
      >
        <ChangesDetails id={selected} general />
      </Alert>
    </div>
  );
};

export default ChangesHistory;
