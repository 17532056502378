import React from "react";
import "./MultipleDateField.scss";
import { IViewField } from "../../../../../models/view-field";
import { Views } from "../../../../../models/enums/views";
import { autofill, FieldArray, WrappedFieldArrayProps } from "redux-form";
import MultipleDateForm from "../../controls/multiple-date-form/MultipleDateForm";
import { useDispatch } from "react-redux";
import { checkShowConstraints } from "../../../../../helpers/check-show-constraints";
import DateField from "../date-field/DateField";
import { IFormField } from "../../../../../models/fields/form-field";
import { DictionaryObject } from "../../../../../models/types/dictionary";

const renderMultipleDateField = ({
  fields,
  meta: { error, submitFailed },
  field,
  selectedItem,
  formName,
}: WrappedFieldArrayProps & {
  field: IViewField;
  selectedItem: any;
  formName: string;
}) => {
  return (
    <div className="row-input">
      <MultipleDateForm
        formName={formName}
        fields={fields}
        f={field}
        selectedItem={selectedItem}
      />
      {/*{touched && ((error && <div>{error}</div>))}*/}
    </div>
  );
};

export const MultipleDateField = (props: IFormField) => {
  const { f, fieldName, view, formName } = props;
  const selectedItem: DictionaryObject | null = props.selectedItem as DictionaryObject;
  const dispatch = useDispatch();

  const ArrayFilterView = () => {
    return <DateField {...props} />;
  };

  const ArrayFormView = () => {
    const { listeners } = props;
    const show = checkShowConstraints(f, listeners);
    if (show) {
      return (
        <FieldArray
          key={f.name}
          name={fieldName}
          field={f}
          selectedItem={selectedItem}
          formName={formName}
          component={renderMultipleDateField}
        />
      );
    } else {
      dispatch(
        autofill(
          "OutgoingDocument__FORM",
          f.name,
          selectedItem ? selectedItem[f.name] : []
        )
      );
      dispatch(
        autofill(
          "IncomingDocument__FORM",
          f.name,
          selectedItem ? selectedItem[f.name] : []
        )
      );
      return <span />;
    }
  };

  switch (view) {
    case Views.FILTER:
      return ArrayFilterView();
    case Views.FORM:
      return ArrayFormView();
    case Views.REFERENCE:
      return <span>Text</span>;
    default:
      return <span>Text</span>;
  }
};

export default MultipleDateField;
