import { Button } from "@blueprintjs/core";
import { Pagination } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { IPagingOptions } from "../../../../models/paging-options";
import "./GeneralFooter.scss";

export const GeneralFooter = (props: {
  allowCreate: boolean;
  newTitle: string;
  totalCount: number;
  hasPagination: boolean;
  pagingOptions: IPagingOptions;
  createNew: () => void;
  refreshItems: (pagingOptions: IPagingOptions) => void;
  selectPrev?: () => void;
  selectNext?: () => void;
}) => {
  const {
    newTitle,
    totalCount,
    hasPagination,
    pagingOptions,
    createNew,
    refreshItems,
    selectNext,
    selectPrev,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="general-footer">
      <div className="general-footer__new">
        <Button onClick={createNew} text={t("UI." + newTitle)} />
      </div>
      <div className="general-footer__selection">
        {selectPrev && <Button icon="chevron-up" onClick={selectPrev} />}
        {selectNext && <Button icon="chevron-down" onClick={selectNext} />}
      </div>
      {hasPagination && (
        <div className="general-footer__pagination">
          <Pagination
            total={totalCount}
            defaultPageSize={pagingOptions.pageSize}
            showLessItems={true}
            showSizeChanger={false}
            pageSize={pagingOptions.pageSize}
            current={pagingOptions.page + 1}
            onChange={(e: any) => {
              refreshItems({ ...pagingOptions, page: e - 1 });
            }}
          />
          <div className="general-footer__amount">
            <span>
              {t("UI.ListAmount")}: {totalCount}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default GeneralFooter;
