import { Button, Dialog } from "@blueprintjs/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { getFormValues } from "redux-form";
import { transformCalculation } from "../../../../../helpers/transform-calculations";
import { ModelType } from "../../../../../models/enums/model-type";
import { ISequenceState } from "../../../../../models/sequence-state";
import { ITransformation } from "../../../../../models/transformation";
import { Dictionary, DictionaryObject } from "../../../../../models/types/dictionary";
import { IViewField } from "../../../../../models/view-field";
import { ITab, IViewModel } from "../../../../../models/view-model";
import { fillCardField, relateCard } from "../../../../../redux/actions/cardActions";
import { IAppState } from "../../../../../redux/states/state";
import FormInitializer from "../../form-modal/form-initializer/FormInitializer";
import { ITransformParams } from "../transform-params";
import "./CreateCard.scss";

const tranformItem = (
    modelFrom: IViewModel,
    modelTo: IViewModel,
    transformation: ITransformation,
    fromItem: Dictionary,
    toItem: Dictionary
): Dictionary => {
    let transfromedItem = transformCalculation(transformation, modelFrom, fromItem, toItem);
    const reflectField = modelTo.fields.find((vf: IViewField) => vf.model === modelFrom.dbname);
    if(!reflectField) return transfromedItem;
    fillCardField(transfromedItem as DictionaryObject, reflectField, fromItem as DictionaryObject );
    return transfromedItem;
};

interface Props extends RouteComponentProps, WithTranslation {
    selectedTransformation: ITransformation;
    selectedModel: IViewModel;
    currentModel: IViewModel;
    formName: string;
    tryValue: Map<string, ISequenceState>;
    parentFormValues: any;
    updateCallback?: () => void;
}

interface State {
    isOpenNew: boolean;
}

type OwnProps = {
    selectedTransformation: ITransformation;
    selectedModel: IViewModel;
    currentModel: IViewModel;
    formName: string;
};

class CreateCard extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isOpenNew: false
        };
    }

    getRelatedCard = (result: Dictionary, existing: boolean): Dictionary => {
        const { history, selectedModel } = this.props;
        history.push(selectedModel.mainRoute);
        return null;
    }

    currentTab = (currentModel: IViewModel | undefined) => {
        return currentModel && currentModel.tabs
            ? currentModel.tabs.find((tab: ITab) => tab.title === "TransformationTab")
            : null;
    };

    createNew = () => {
        this.setState({ isOpenNew: true });
    };

    render() { 
        const { t, selectedTransformation, selectedModel, currentModel, parentFormValues } = this.props;
        const { isOpenNew } = this.state;
        const tab = this.currentTab(selectedModel);
        
        if (!selectedTransformation || !selectedModel || !tab) {
            return <div></div>;
        }

        return (
            <>
                <Button
                    text={t("UI.Create")}
                    onClick={() => this.createNew()}
                />
                <Dialog
                    onClose={() => this.setState({ isOpenNew: false })}
                    title={t(selectedTransformation?.toName || "")}
                    className="dialog"
                    isOpen={isOpenNew}
                >
                    {isOpenNew && selectedTransformation ? (
                        <FormInitializer
                            tab={tab}
                            model={selectedModel}
                            selectedItem={null}
                            mainEntity={false}
                            hideId={false}
                            type={ModelType.Entity}
                            form={
                                "Transformation" + selectedTransformation?.toName + "__FORM"
                            }
                            transformInitialItem={(initialItem: DictionaryObject) => tranformItem(currentModel, selectedModel, selectedTransformation, parentFormValues, initialItem)}
                            callback={(result: Dictionary) =>
                                this.getRelatedCard(result, false)
                            }
                            updateCallback={() => { return; }}
                            onClose={() => this.setState({ isOpenNew: false })}
                        />
                    ) : null}
                </Dialog>
            </>
        );
    }
}

const mapStateToProps = (state: IAppState, initialProps: OwnProps) => ({
    models: state.modelsReducer.models,
    tryValue: state.sequencesReducer.tryValue,
    parentFormValues: getFormValues(initialProps.formName)(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            relateCard: (tranformParams: ITransformParams) => relateCard(tranformParams),
        },
        dispatch
    );

export default withTranslation()(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateCard))
);
