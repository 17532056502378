import React, { MouseEventHandler, ReactNode, useState } from "react";
import "./SimpleMultiselect.scss";
import { MenuItem } from "@blueprintjs/core";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import { MultiSelect } from "@blueprintjs/select";
import {
  IItemModifiers,
  IItemRendererProps,
} from "@blueprintjs/select/src/common/itemRenderer";
import { WrappedFieldInputProps } from "redux-form/lib/Field";
import {
  DictionaryArray,
  Dictionary,
  Selectable,
  DictionaryObject,
} from "../../../../../models/types/dictionary";
import SelectableMethods from "../selectable/Selectable";

const AnyMultiSelect = MultiSelect.ofType<any>();

export const SimpleMultiselect = (props: {
  input: WrappedFieldInputProps;
  disabled?: boolean;
  options: string[];
  selectedItems: DictionaryArray;
  renderView: (item: Selectable) => string;
}) => {
  const { t } = useTranslation();
  const { options, selectedItems, input, renderView } = props;
  const [items, setItems] = useState(input.value as DictionaryArray);

  const renderTag = (item: string) => {
    return renderView(item);
  };

  const renderItem = (
    item: any,
    {
      handleClick,
      modifiers,
      query,
    }: {
      handleClick: MouseEventHandler;
      modifiers: IItemModifiers;
      query: string;
    },
    renderView: (item: Selectable) => string
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const key = (item: Selectable): string => {
      if (typeof item === "string") {
        return item;
      } else if (typeof item === "number") {
        return (item as number)?.toString();
      } else if (typeof item === "object") {
        return item["id"] as string;
      } else {
        return "";
      }
    };

    const text = renderView(item);
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        icon={isItemSelected(item) ? "tick" : "blank"}
        key={key(item)}
        onClick={handleClick}
        text={text}
        shouldDismissPopover={false}
      />
    );
  };

  const handleItemSelect = (item: Dictionary) => {
    if (!isItemSelected(item)) {
      selectItem(item);
    } else {
      deselectItem(getSelectedItemIndex(item));
    }
  };

  const handleTagRemove = (element: ReactNode, index: number) => {
    deselectItem(index);
  };

  const getSelectedItemIndex = (item: Dictionary) => {
    if (typeof item === "string") {
      return items.indexOf(item);
    } else if (typeof item === "object" && !item?.length) {
      return items.findIndex(
        (c) =>
          (c as DictionaryObject)["id"] === (item as DictionaryObject)["id"]
      );
    } else return -1;
  };

  const isItemSelected = (item: Dictionary) => {
    return getSelectedItemIndex(item) !== -1;
  };

  const selectItem = (item: Dictionary) => {
    const selectedItems = [...items];
    selectedItems.push(item);
    selectItems(selectedItems);
  };

  const selectItems = (selectedItems: DictionaryArray) => {
    setItems(selectedItems);
    input.onChange(selectedItems);
  };

  const deselectItem = (index: number) => {
    setItems(items.filter((_item, i) => i !== index));
    input.onChange(selectedItems);
  };
  return (
    <Row gutter={16}>
      <Col>
        <AnyMultiSelect
          className="multiselect"
          itemRenderer={(item: any, itemProps: IItemRendererProps) =>
            renderItem(item, itemProps, renderView)
          }
          items={options ? options : []}
          fill
          noResults={<MenuItem disabled={true} text="No results." />}
          onItemSelect={handleItemSelect}
          tagRenderer={renderTag}
          selectedItems={items}
          tagInputProps={{
            onRemove: handleTagRemove,
            tagProps: {
              minimal: true,
            },
            disabled: props.disabled
          }}

          placeholder={t("UI.SelectMultiple")}
          popoverProps={{ minimal: true }}
          itemsEqual={(a: any, b: any) =>
            SelectableMethods.compareItems(a, b, renderView)
          }
          itemPredicate={(
            query: any,
            film: any,
            _index: any,
            exactMatch: any
          ) =>
            SelectableMethods.filterItems(
              query,
              film,
              _index,
              exactMatch,
              renderView
            )
          }
        />
      </Col>
      {/*{touched && ((error && <div>{error}</div>))}*/}
    </Row>
  );
};

export default SimpleMultiselect;
