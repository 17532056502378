import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Route, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { project } from "../../../configs";
import { dynamicConstraints } from "../../../helpers/dynamic-constraints";
import { IConstraintTree } from "../../../models/constraint";
import { IEmployee } from "../../../models/employee";
import { IPagingOptions } from "../../../models/paging-options";
import { IPermit } from "../../../models/permit";
import { IViewModel } from "../../../models/view-model";
import { userHasPermit } from "../../../redux/actions/authorizationActions";
import { clearFilters, setDefaultConditions } from "../../../redux/actions/entitiesActions";
import { updatePaging } from "../../../redux/actions/newEntitiesActions";
import { IAppState } from "../../../redux/states/state";
import { defaultPagingOptions } from "../../core/lists/default-paging";
import "./DynamicModelView.scss";
import InnerDynamicModel from "./InnerDynamicModel";

interface State {
  modelName: string;
}

interface Props extends RouteComponentProps, WithTranslation {
  activeModel: IViewModel | undefined;
  activeRoute: string;
  permits: IPermit[];
  setDefaultConditions: (defaultConditions: IConstraintTree | null) => void;
  clearFilters: () => void;
  updatePaging: (pagingOptions: IPagingOptions) => void;
  currentEmployee: IEmployee | null;
}

class DynamicModelView extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      modelName: "",
    };
  }

  componentDidMount(): void {
    this.props.clearFilters();
    this.getDefault();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.activeModel !== this.props.activeModel ||
      prevProps.activeRoute !== this.props.activeRoute) {
      this.props.clearFilters();
      this.getDefault();
    }
  }

  getDefault() {
    const { activeModel, currentEmployee } = this.props;
    if (!activeModel) {
      return;
    }
    const defaultConditions = activeModel.defaultConditions;
    this.props.updatePaging({
      ...defaultPagingOptions,
      noPagination: activeModel.noPagination
    });
    this.props.setDefaultConditions(
      dynamicConstraints(currentEmployee, defaultConditions, activeModel && activeModel.yearFilter ? new Date().getFullYear() : undefined)
    );
  }

  componentWillUnmount() {
    this.props.clearFilters();
  }

  render() {
    const { permits } = this.props;
    const routings = project.routings as any;
    return (
      <>
        {Object.keys(project.routings).map((r: any) => {
          if(routings[r].permit && !userHasPermit(permits, routings[r].permit)) return <></>;
          return (<Route
            key={r}
            path={`/${r}`}
            exact
            component={InnerDynamicModel}
          />)
        })}
      </>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  userInfo: state.authorizationReducer.userInfo,
  currentEmployee: state.authorizationReducer.currentEmployee,
  activeModel: state.modelsReducer.models.get(state.modelsReducer.activeModel),
  activeRoute: state.modelsReducer.activeRoute,
  permits: state.authorizationReducer.permits
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setDefaultConditions: (defaultConditions) =>
        setDefaultConditions(defaultConditions),
      clearFilters: () => clearFilters(),
      updatePaging: (pagingOptions) => updatePaging(pagingOptions)
    },
    dispatch
  );

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DynamicModelView))
);
