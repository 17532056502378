import { CalculationTypes } from "../models/enums/calculation-types";
import {
  Dictionary,
  DictionaryObject,
  DictionaryArray,
} from "../models/types/dictionary";
import {
  FieldMap,
  ICalculation,
  ITransformation,
} from "./../models/transformation";
import { IViewField } from "./../models/view-field";
import { IViewModel } from "./../models/view-model";
import { getDeepValue } from "./get-deep-value";

export function transformCalculation(
  transformation: ITransformation,
  modelFrom: IViewModel | undefined,
  fromItem: Dictionary,
  toItem: Dictionary
): Dictionary {
  if (!modelFrom || !fromItem) {
    return toItem;
  }

  const newItem = { ...(toItem as DictionaryObject) };
  const fieldMap = transformation.fieldMap;
  if (!fieldMap) {
    return toItem;
  }
  const ArrayComponent = ["Multiple", "EditableTable", "TablePopup"];
  fieldMap.forEach((f: FieldMap) => {
    const fromField: IViewField | undefined = modelFrom.fields
    .find((field: IViewField) =>
      transformation.reverse
        ? field.name === f.toName
        : field.name === f.fromName
    );
    if (!f.calculation) {
      newItem[f.toName] = (fromItem as DictionaryObject)[f.fromName] || undefined;
      if (fromField && ArrayComponent.includes(fromField.component)) {
        newItem && ((newItem as DictionaryObject)[f.toName] as DictionaryArray).forEach((el, index) => {
          delete (el as DictionaryObject).id;
        });
      }
    } else {
      switch (f.calculation.type) {
        case CalculationTypes.Concatenation:
          newItem[f.toName] = concatenation(
            fromField,
            newItem[f.toName],
            (fromItem as DictionaryObject)[f.fromName],
            f.calculation
          );
          break;
        default:
          break;
      }
    }
  });
  return newItem;
}

function concatenation(
  fromField: IViewField | undefined,
  toName: Dictionary,
  fromName: Dictionary,
  calculation: ICalculation
): Dictionary {
  if (!fromField) {
    let result = "";
    result += toName;
    return result;
  }
  if (toName && Array.isArray(toName)) {
    let resultArray: DictionaryArray = [];

    if (fromName && Array.isArray(fromName)) {
      const resArray = calculation.arguments && calculation.arguments.length == 1 ? 
        fromName.map((f: Dictionary) => getDeepValue(f, calculation.arguments[0])): fromName;
      resultArray = [...resultArray, ...resArray];
    }

    resultArray = [...resultArray, ...toName];
    return resultArray;
  } else {
    let resultString = "";
    resultString += toName || "";

    if (fromName) {
      resultString += resultString.length > 0 ? calculation.delimiter : "";
      if (calculation.arguments && calculation.arguments.length) {
        if (Array.isArray(fromName)) {
          if (fromName.length) {
            resultString += fromName
              .map((f: Dictionary) => {
                return calculation.arguments
                  .map((arg: string) => {
                    return getDeepValue(f, arg);
                  })
                  .join(" ");
              })
              .join(" ");
          } else {
            resultString += "";
          }
        } else {
          resultString += calculation.arguments
            .map((arg: string) => {
              return getDeepValue(fromName, arg);
            })
            .join(" ");
        }
      } else {
        resultString += fromName;
      }
    }
    return resultString;
  }
}
