import React from "react";
import "./GroupDelimeter.scss";
import { useTranslation } from "react-i18next";
import { IViewField } from "../../../../../models/view-field";
import { Views } from "../../../../../models/enums/views";
import { Icon } from "@blueprintjs/core";

export const GroupDelimeter = (props: {
  f: IViewField;
  selectedItem: any;
  view: Views;
}) => {
  const { t } = useTranslation();
  const { f } = props;

  return <div className="group-delimeter">
    <span className="group-delimeter__title">{t(f.name)}</span>
    <Icon className="group-delimeter__icon" icon="chevron-down" />
  </div>;
};
export default GroupDelimeter;
