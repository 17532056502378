import { FormGroup, Icon, Label, NumericInput } from "@blueprintjs/core";
import { Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { WrappedFieldProps } from "redux-form/lib/Field";
import { Views } from "../../../../../models/enums/views";
import { IFormField } from "../../../../../models/fields/form-field";
import { IViewField } from "../../../../../models/view-field";
import { renderEmptyField } from "../../controls/empty-btn/EmptyBtn";
import { NumberField } from "../number-field/NumberField";
import ResultViewField from "../result-view-field/ResultViewField";
import "./NumberIntervalField.scss";

const renderField = ({
  input,
  meta: { touched, invalid, error },
  placeholder,
  disabled,
  field,
  selectedItem,
  formName,
}: WrappedFieldProps & {
  placeholder: string;
  disabled: boolean;
  field: IViewField;
  selectedItem: any;
  formName: string;
}) => {
  const clearText = () => {
    if (input.value) {
      input.onChange("");
    }
  };

  return (
    <div className="row-input">
      <NumericInput
        className={"int-range"}
        disabled={disabled}
        large={false}
        fill
        buttonPosition={"none"}
        allowNumericCharactersOnly
        value={input.value || ""}
        onValueChange={(e: any) => input.onChange(e)}
        rightElement={
          input.value && !disabled ? (
            <Icon
              className="clear-btn"
              icon="small-cross"
              onClick={() => clearText()}
            />
          ) : (
              <span></span>
            )
        }
      />
      {field.isResultView && (
        <ResultViewField
          selectedItem={selectedItem}
          field={field}
          formName={formName}
        />
      )}
      {/*{touched && ((error && <div>{error}</div>))}*/}
    </div>
  );
};

const parseStringToNumber = (value: string) => {
  return typeof value === "string" && value ? Number(value) : value;
};

export const NumberIntervalField = (props: IFormField) => {
  const { t } = useTranslation();
  const { f, fieldName, view, showEmpty } = props;
  const NumberIntervalFormView = () => {
    return <NumberField {...props} />;
  };

  const NumberIntervalFilterView = () => {
    return (
      <Row>
        <FormGroup className="form-group-text">
          <Label htmlFor={fieldName}>{t(f.name)}</Label>
          <div className="any-text__container">
            <Field
              name={fieldName + ".start"}
              type="text"
              disabled={f.readonly}
              parse={parseStringToNumber}
              component={renderField}
              classType={"date-range"}
              field={f}
              onChange={(value: any) =>
                props.onChange && props.onChange(value)
              }
            />
            <Field
              name={fieldName + ".end"}
              type="text"
              disabled={f.readonly}
              parse={parseStringToNumber}
              component={renderField}
              classType={"date-range"}
              field={f}
              onChange={(value: any) =>
                props.onChange && props.onChange(value)
              }
            />
            {!f.noSearchEmpty && showEmpty && (
              <Field
                name={fieldName + "__EMPTY"}
                type="checkbox"
                onClick={() => {
                }}
                onChange={(e: any) => { }}
                field={f}
                view={view}
                component={renderEmptyField}
              />
            )}
          </div>
        </FormGroup>
      </Row>
    );
  };

  switch (view) {
    case Views.FILTER:
      return NumberIntervalFilterView();
    case Views.FORM:
      return NumberIntervalFormView();
    case Views.REFERENCE:
      return <span>Text</span>;
    default:
      return <span>Text</span>;
  }
};
export default NumberIntervalField;
