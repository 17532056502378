import { Tag } from "@blueprintjs/core";
import React from "react";
import "./Symbols.scss";

interface Props {
  chars?: string[],
  onChange: (e: any) => void;
  value: string;
}

export const Sybmols = (props: Props) => {
  const { chars, value, onChange } = props;
  return <div className="special-symbols">{chars && chars.map((sc: string) =>
    <Tag minimal onClick={(e: any) => onChange(value + sc)}>{sc}</Tag>
  )}</div>
};

export default Sybmols;
