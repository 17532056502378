import React from "react";
import "./TableStateChange.scss";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "@blueprintjs/core";
import { DictionaryObject } from "../../../../../models/types/dictionary";
import { useDispatch } from "react-redux";
import { IViewModel } from "../../../../../models/view-model";
import { updateStateMultipleItems } from "../../../../../redux/actions/entitiesActions";
import { ToName } from "../../../../../models/state";

export const TableStateChange = (props: {
  selectedItems: DictionaryObject[];
  state: string;
  model: IViewModel;
}) => {
  const { selectedItems, model, state } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  if (!model || !model.states || !model.states.size) {
    return null;
  }

  if (!state) {
    return null;
  }

  const states = model.states.get(state);
  if (!states) {
    return null;
  }

  if (!states.toName || !states.toName.length) {
    return null;
  }

  return (
    <div>
      {states.toName
        .filter((tn: ToName) => !tn.reverse)
        .map((tN: ToName) => (
          <Button
            className={"change-state-button"}
            onClick={() => {
              if (selectedItems && selectedItems.length) {
                const data = selectedItems.map((el) => {
                  return { ...el, state: tN.name };
                });
                dispatch(updateStateMultipleItems(model.dbname, data));
              }
            }}
          >
            <span>{t("States." + tN.name)}</span>
            <Icon icon="chevron-right" />
          </Button>
        ))}
    </div>
  );
};

export default TableStateChange;
