export enum ChangesTypes {
  List = 0,
  Create = 1,
  Read = 2,
  Update = 3,
  Delete = 4,
  Print = 5,
  Export = 6,
  UpdateState = 100,
  MarkDeleted = 101,
  RestoreDeleted = 102,
}
