import React from "react";
import styled from "styled-components";
import LoginForm from "./LoginForm";

const DivStyle = styled.div`
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

class LoginPage extends React.Component<any, any> {
  render() {
    return (
      <DivStyle className="user-wrapper">
        <div className={`user-login`}>
          <LoginForm />
        </div>
      </DivStyle>
    );
  }
}

export default LoginPage;
