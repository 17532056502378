import { Button, Checkbox, TextArea } from "@blueprintjs/core";
import { Col } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateEntity } from "../../../../../redux/actions/entitiesActions";
import { refreshItem } from "../../../../../redux/actions/newEntitiesActions";
import { IAppState } from "../../../../../redux/states/state";
import moment from "moment";
import "./Custom.scss"


export const AnswerStatus = (props: {
    selectedItem: any;
}) => {
    const { selectedItem } = props;
    const [editable, setEditable] = useState(false);
    const activeModel = useSelector((state: IAppState) => state.modelsReducer.activeModel);
    const [answerText, setAnswerText] = useState(selectedItem.Answer);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const currentEmployee = useSelector((state: IAppState) => state.authorizationReducer.currentEmployee)
    return currentEmployee ? (<React.Fragment>
        <Col span={24}>
            <span>{t("Answer")}</span>
        </Col>
        <div className="Answer">
            <div className="Employee">
                <p>{selectedItem.LastModifier ? t("UI.Changed") + ": " + selectedItem.LastModifier.ShortName : ""}</p>
                <p>{selectedItem.LastModified && moment(selectedItem.LastModified as string).format(
                    "DD.MM.YYYY"
                )}</p>
                {currentEmployee.SignOrder &&
                    (!selectedItem.LastModifier || currentEmployee.SignOrder <= selectedItem.LastModifier.SignOrder) &&
                    <Checkbox checked={editable} onChange={() => setEditable(!editable)} label={t("UI.Edit")} />}
            </div>
            <TextArea
                disabled={!editable}
                value={answerText}
                onChange={(value) => setAnswerText(value.target.value)}
                growVertically
            />
            <div className="Employee">
                <p>{selectedItem.WriteEmployee ? selectedItem.WriteEmployee.ShortName : ""}</p>
                <p>{selectedItem.WriteDate && moment(selectedItem.WriteDate as string).format(
                    "DD.MM.YYYY"
                )}</p>
            </div>
            {editable && <Button className="Submit" onClick={() => {
                let newData = { ...selectedItem };
                if (!newData.WriteEmployee) {
                    newData.WriteEmployee = currentEmployee;
                    newData.WriteDate = new Date();
                }
                newData.Answer = answerText;
                newData.LastModifier = currentEmployee;
                newData.LastModified = new Date();
                dispatch(updateEntity(activeModel, newData, [], selectedItem, () => {
                    setEditable(false);
                    dispatch(
                        refreshItem(
                            activeModel,
                            selectedItem.id
                        )
                    )
                }));
            }} text={t("UI.Save")} />}
        </div>
    </React.Fragment>) :
        (<React.Fragment>
            <Col span={24}>
                <span>{t("Answer")}</span>
            </Col>
            <div className="Answer"><TextArea
                disabled={!editable}
                value={answerText}
                onChange={(value) => setAnswerText(value.target.value)}
                growVertically
            /></div>
        </React.Fragment>);
};
export default AnswerStatus;
