import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Button, Dialog, Intent } from "@blueprintjs/core";
import InnerTableDetails from "../inner-table-details/InnerTableDetails";
import { IViewModel } from "../../../../../../models/view-model";
import { Dictionary } from "../../../../../../models/types/dictionary";
import { ModelType } from "../../../../../../models/enums/model-type";
import { IViewField } from "../../../../../../models/view-field";

interface Props {
  selectedItem: any;
  model?: IViewModel;
  parentModel: IViewModel;
  item: Dictionary;
  type: ModelType;
  f?: IViewField;
  formName: string;
  listeners: Map<string, any>;
}
export const InnerTable = (props: Props) => {
  const { t } = useTranslation();
  const [isOpenTable, setIsOpenTable] = useState<boolean>(false);
  const closeWindow = () => {
    setIsOpenTable(false);
  };
  return (
    <>
      <Button intent={Intent.PRIMARY} onClick={() => setIsOpenTable(true)}>
        {t("UI.AnalysisDetails")}
      </Button>
      <Dialog
        onClose={() => setIsOpenTable(false)}
        title={t("AnalysisDetailsWindow")}
        className="card-search-dialog"
        isOpen={isOpenTable}
        canOutsideClickClose={false}
        canEscapeKeyClose={false}
      >
        {isOpenTable && props.model && props.f ? (
          <InnerTableDetails
            closeWindow={closeWindow}
            parentModel={props.parentModel}
            f={props.f}
            formName={props.formName}
            listeners={props.listeners}
            selectedItem={props.selectedItem}
            model={props.model}
            item={props.item}
            type={props.type}
          />
        ) : null}
      </Dialog>
    </>
  );
};
