import { Button, Card, Label, Popover, Position } from "@blueprintjs/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IEmployee } from "../../../models/employee";
import { IUser } from "../../../models/users/user";
import { signOut } from "../../../redux/actions/authorizationActions";
import { IAppState } from "../../../redux/states/state";
import ChangePassword from "../../user/change-password/ChangePassword";
import "./UserInfo.scss";

interface Props extends WithTranslation {
  userInfo: IUser | null;
  currentEmployee: IEmployee | null;
  signOut: () => void;
}

export interface IDialogExampleState {
  autoFocus: boolean;
  canEscapeKeyClose: boolean;
  canOutsideClickClose: boolean;
  enforceFocus: boolean;
  isOpen: boolean;
  usePortal: boolean;
}

class UserInfo extends React.Component<Props, IDialogExampleState> {
  public state: IDialogExampleState = {
    autoFocus: true,
    canEscapeKeyClose: true,
    canOutsideClickClose: true,
    enforceFocus: true,
    isOpen: false,
    usePortal: true,
  };

  componentDidMount(): void {}

  renderUserInfo() {
    const { t, userInfo } = this.props;
    if (!userInfo) {
      return <span>User not loaded...</span>;
    }
    return (
      <Card>
        {this.renderEmployeeInfo()}
        <div className="display-row">
          <Label>{t("UserRef.UserName")}:</Label>
          <p>{userInfo.username}</p>
        </div>
        <div className="display-row">
          <ChangePassword />

          <Button text={t("UI.SignOut")} onClick={() => this.props.signOut()} />
        </div>
      </Card>
    );
  }

  renderEmployeeInfo() {
    const { t, currentEmployee } = this.props;
    if (!currentEmployee) {
      return <div></div>;
    }
    return (
      <div>
        <div className="display-row">
          <Label>{t("UserRef.FullName")}:</Label>
          <p>{currentEmployee?.FirstName + " " + currentEmployee?.LastName}</p>
        </div>
        {currentEmployee.Department ? (
          <div className="display-row">
            <Label>{t("EmployeeRef.Department")}:</Label>
            <p>{currentEmployee?.Department.Name}</p>
          </div>
        ) : null}
        {currentEmployee.EmployeeSpeciality ? (
          <div className="display-row">
            <Label>{t("EmployeeRef.Speciality")}:</Label>
            <p>{currentEmployee?.EmployeeSpeciality.Name}</p>
          </div>
        ) : null}
      </div>
    );
  }

  render() {
    return (
      <Popover position={Position.BOTTOM_LEFT} minimal>
        <Button large minimal icon="user" text={"Istifadəçi"} />
        {this.renderUserInfo()}
      </Popover>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  userInfo: state.authorizationReducer.userInfo,
  currentEmployee: state.authorizationReducer.currentEmployee,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      signOut: () => signOut(),
    },
    dispatch
  );

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(UserInfo)
);
