import { constructInitialValues } from "../../../../../helpers/default-values";
import { ModelType } from "../../../../../models/enums/model-type";
import { Views } from "../../../../../models/enums/views";
import { ISequenceState } from "../../../../../models/sequence-state";
import { DictionaryObject } from "../../../../../models/types/dictionary";
import { IViewField } from "../../../../../models/view-field";
import { IViewModel, ITab } from "../../../../../models/view-model";
import ChangesFilter from "../../../../changes-history/changes-filter/ChangesFilter";
import ChangesHistory from "../../../../changes-history/ChangesHistory";
import AttachmentForm from "../../form-modal/attachments-form/AttachmentForm";
import ReduxForm from "../../form-modal/redux-form/ReduxForm";
import React, { useEffect, useState } from "react";
import "./TabPanel.scss";
import Letters from "../letters/Letters";
import { getSequences } from "../../../../../helpers/sequences";
import { useDispatch, useSelector } from "react-redux";
import {
  clearTryValues,
  getTryValues,
} from "../../../../../redux/actions/newSequencesActions";
import { clearRelatedCards } from "../../../../../redux/actions/newEntitiesActions";
import { IAppState } from "../../../../../redux/states/state";
import ExpredAccordion from "../../controls/expred-accordion/ExpredAccordion";
import DVChanges from "../dv-changes/DVChanges";
import { getFieldsByState } from "../../../../../redux/actions/modelsActions";
import FormInitializer from "../../form-modal/form-initializer/FormInitializer";

const getRenderedFields = (model: IViewModel, tab: ITab) => {
  if(!model || !model.fields || !model.fields.length || !tab.fields) {
    return [];
  }
  return model.fields.filter((f: IViewField) => tab.fields.includes(f.name) || f.name === "Archived")
}

const TabPanel = (props: {
  tab: ITab;
  type: ModelType;
  item: DictionaryObject;
  modelName: string;
  dbname: string;
  model: IViewModel;
  tryValue: Map<string, ISequenceState>;
  onClose: () => void;
  updateCallback?: (close: boolean, item: DictionaryObject | null) => void;
  transformCallback?: (currentItem: DictionaryObject) => void;
  callback?: () => any;
}) => {
  const {
    item,
    type,
    modelName,
    dbname,
    model,
    tab,
    tryValue,
    updateCallback,
    transformCallback,
    callback,
    onClose,
  } = props;

  const dispatch = useDispatch();
  const [initialItem, setInitialItem] = useState<DictionaryObject | undefined>(undefined);
  const loadingSeq = useSelector((state: IAppState) => state.sequencesReducer.loadingSeq);

  useEffect(() => {
    if (model) {
      const sequences = getSequences(false, tab, model, item || {});
      dispatch(getTryValues(sequences));
    }
  }, [dispatch, model, tab]);

  useEffect(() => {
    if (!loadingSeq) {
      const renderedFields = getRenderedFields(model, tab);
      setInitialItem(constructInitialValues(renderedFields, Views.FORM, tryValue, item));
    }
  }, [loadingSeq, item]);

  useEffect(() => {
    return () => {
      if (model) {
        const sequences = getSequences(true, tab, model, item || {});
        dispatch(clearTryValues(sequences));
        dispatch(clearRelatedCards());
      }
    };
  }, [dispatch, model, tab]);

  if (initialItem === undefined) {
    return <span />;
  }
  switch (tab.title) {
    case "General":
      return (
        <ReduxForm
          type={type}
          selectedItem={item}
          mainEntity={type === ModelType.Entity}
          hideId={false}
          initialValues={initialItem}
          model={model}
          form={modelName + tab.title + "__FORM"}
          tab={tab}
          updateCallback={updateCallback}
          transformCallback={transformCallback}
          callback={callback}
          onClose={onClose}
          onSubmit={(v: any) => { }}
        />
      );
    case "Preparats":
      return (
        <ReduxForm
          type={type}
          selectedItem={item}
          mainEntity={type === ModelType.Entity}
          hideId={true}
          initialValues={initialItem}
          model={model}
          form={modelName + tab.title + "__FORM"}
          tab={tab}
          updateCallback={updateCallback}
          callback={callback}
          onClose={onClose}
          onSubmit={(v: any) => { }}
        />
      );
    case "Expertise":
      return (
        <FormInitializer
          tab={tab}
          type={type}
          selectedItem={item}
          mainEntity={type === ModelType.Entity}
          hideId={true}
          model={model}
          form={modelName + tab.title + "__FORM"}
          updateCallback={updateCallback || (() => null)}
          callback={callback || ((dict: any) => {})}
          onClose={onClose}
         />
      );
    case "Samples":
      return (
        <ExpredAccordion
          modelName={modelName}
          selectedItem={item}
        />
      );
    case "Answers":
      return (
        <ReduxForm
          type={type}
          selectedItem={item}
          mainEntity={type === ModelType.Entity}
          hideId={true}
          initialValues={initialItem}
          model={model}
          form={modelName + tab.title + "__FORM"}
          tab={tab}
          updateCallback={updateCallback}
          callback={callback}
          onClose={onClose}
          onSubmit={(v: any) => { }}
        />
      );
    case "Letters":
      return (
        <ReduxForm
          component={<Letters item={item} model={model} />}
          hideId={true}
          type={type}
          selectedItem={item}
          mainEntity={type === ModelType.Entity}
          initialValues={initialItem}
          model={model}
          form={modelName + tab.title + "__FORM"}
          tab={tab}
          updateCallback={updateCallback}
          callback={callback}
          onClose={onClose}
          onSubmit={(v: any) => { }}
        />
      );
    case "DVChanges":
        return (
          <DVChanges
            model={model}
            item={item}
          />
        );
    case "FarmControl":
      return (
        <ReduxForm
          type={type}
          selectedItem={item}
          mainEntity={type === ModelType.Entity}
          hideId={true}
          initialValues={initialItem}
          model={model}
          form={modelName + tab.title + "__FORM"}
          tab={tab}
          updateCallback={updateCallback}
          callback={callback}
          onClose={onClose}
          onSubmit={(v: any) => { }}
        />
      );
    case "Attachments":
      const fields = getFieldsByState(model.defaultState, model, item);
      const scanDocuments = fields.find(
        (vf: IViewField) => vf.name === "ScanDocuments"
      );
      return (
        <AttachmentForm
          field={scanDocuments}
          sequence={scanDocuments?.sequence || ""}
          selectedItem={item}
          dbname={dbname}
        />
      );
    case "ChangesHistory":
      return (
        <React.Fragment>
          <ChangesFilter selectedItem={item} model={modelName} />
          <ChangesHistory selectedItem={item} model={modelName} isNew={!item} />
        </React.Fragment>
      );
    default:
      return <div></div>;
  }
};

export default TabPanel;
