import { loadRelatedCard } from "./newEntitiesActions";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { ITransformParams } from "../../components/core/forms/transformations/transform-params";
import { DictionaryObject } from "../../models/types/dictionary";
import { IAppState } from "../states/state";

export const relateProcessCard = (params: ITransformParams): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  const { result, selectedItem, selectedModel } = params;
  if (!selectedItem || !result) {
    return;
  }
  dispatch(loadRelatedCard(selectedModel.dbname, result as DictionaryObject));
};
