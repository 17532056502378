import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IDefaultSorting } from "../../../../models/default-sorting";
import { ModelType } from "../../../../models/enums/model-type";
import { IPagingOptions } from "../../../../models/paging-options";
import { IViewModel } from "../../../../models/view-model";
import { getEntities2 } from "../../../../redux/actions/entitiesActions";
import { IAppState } from "../../../../redux/states/state";
import BaseTable from "../base-table/BaseTable";
import "./MainListAnt.scss";

interface Props {
  model: string;
  openForm: (open: boolean) => void;
  selectItem: (item: any) => void;
  selectedItem: any;
  items: any[];
  loading: boolean;
  footer: React.ReactNode;
  pagingOptions: IPagingOptions;
  getEntities2: CallableFunction;
}

class VirtualTable extends React.Component<Props, any> {
  static FillNode({
    height,
    node,
    marginTop,
    marginBottom,
  }: {
    height?: number;
    node?: any;
    marginTop?: number;
    marginBottom?: number;
  }) {
    if (node) {
      marginTop = marginTop || 0;
      marginBottom = marginBottom || 0;
      height = height || 0;
      return ReactDOM.createPortal(
        <div
          style={{
            height: `${height}px`,
            marginTop: `${marginTop}px`,
            marginBottom: `${marginBottom}px`,
          }}
        />,
        node
      );
    }
    return null;
  }

  constructor(props: Props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.state = {
      model: {} as IViewModel,
      topBlankHeight: 0,
      bottomBlankHeight: 0,
      maxTotalHeight: 15000000,
      bodyHeight: 500,
      rowHeight: 50,
      startIndex: 0,
      visibleRowCount: 0,
      thresholdCount: 40,
      width: 400,
      height: 300,
      scrollY: 0,
    };
  }

  refScroll: any;
  placeholder: any;
  lastSlideUpHeight: any;
  sameSlideHeightCount: any;
  listenEvent: any;
  refTopNode: any;
  refBottomNode: any;

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  tableHeightAdjust(className: string) {
    const node: any = document.getElementsByClassName(className);
    this.refScroll = !!node ? node[0] : null;

    this.updateWindowDimensions();
    this.createTopFillNode();
    this.createBottomFillNode();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    if (prevProps.loading && !this.props.loading) {
      this.tableHeightAdjust("ant-table-body");
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  handleBlankHeight(
    length: number,
    rowHeight: number,
    maxTotalHeight: number,
    visibleHeight: number,
    scrollTop: number,
    bodyHeight: number
  ) {
    const oriRowHeight = rowHeight;
    let totalHeight = length * rowHeight;
    let isBigData = false;
    if (totalHeight > maxTotalHeight) {
      isBigData = true;
      totalHeight = maxTotalHeight;
      rowHeight = totalHeight / length;
      scrollTop = scrollTop > maxTotalHeight ? maxTotalHeight : scrollTop;
    }
    if (length >= 10000) {
      isBigData = true;
    }
    let topBlankHeight, bottomBlankHeight, startIndex, visibleRowCount;
    startIndex = this.getIndexByScrollTop(rowHeight, scrollTop);
    visibleRowCount = Math.ceil(visibleHeight / oriRowHeight);
    topBlankHeight = rowHeight * startIndex;
    topBlankHeight = this.getValidValue(topBlankHeight, 0, totalHeight);
    if (totalHeight < bodyHeight) {
      bottomBlankHeight = bodyHeight - totalHeight + 100;
    } else {
      bottomBlankHeight = 0;
    }
    const slideUpHeight = Math.abs(topBlankHeight - this.state.topBlankHeight);
    const slideDownHeight = Math.abs(
      bottomBlankHeight - this.state.bottomBlankHeight
    );

    if (!this.lastSlideUpHeight) {
      this.sameSlideHeightCount = 0;
      this.lastSlideUpHeight = slideUpHeight;
    } else if (this.lastSlideUpHeight === slideUpHeight) {
      this.sameSlideHeightCount++;
    } else {
      this.lastSlideUpHeight = slideUpHeight;
      this.sameSlideHeightCount = 0;
    }

    let isValid = slideUpHeight >= rowHeight;
    isValid = isValid || slideDownHeight >= rowHeight;
    isValid = isValid || startIndex === 0;
    if (isValid) {
      startIndex = startIndex - 5;
      visibleRowCount = visibleRowCount + 5;
      this.setState({
        startIndex,
        visibleRowCount,
        topBlankHeight,
        bottomBlankHeight,
      });

      if (isBigData && this.sameSlideHeightCount >= 1) {
        this.refScroll.scrollTop = scrollTop;
        this.sameSlideHeightCount = 0;
      }
    }
  }

  getValidValue(val: any, min = 0, max = 40) {
    if (val < min) {
      return min;
    } else if (val > max) {
      return max;
    }
    return val;
  }

  getIndexByScrollTop(rowHeight: number, scrollTop: number) {
    return (scrollTop - (scrollTop % rowHeight)) / rowHeight;
  }

  updateWindowDimensions() {
    // this.setState({
    //     width: window.innerWidth,
    //     height: window.innerHeight
    // });
    const refScrollHeight = this.refScroll ? this.refScroll.clientHeight : 0;
    this.setState({
      scrollY: window.innerHeight - 300,
      bottomBlankHeight: window.innerHeight - refScrollHeight - 300,
    });
  }

  createTopFillNode() {
    if (this.refScroll) {
      const ele = document.createElement("div");
      this.refScroll.insertBefore(ele, this.refScroll.firstChild);
      this.refTopNode = ele;
    }
  }

  createBottomFillNode() {
    if (this.refScroll) {
      const ele = document.createElement("div");
      this.refScroll.appendChild(ele);
      this.refBottomNode = ele;
    }
  }

  render() {
    const { items, model, loading, footer, pagingOptions } = this.props;
    const { topBlankHeight, bottomBlankHeight, scrollY } = this.state;
    return (
      <Fragment>
        <VirtualTable.FillNode height={topBlankHeight} node={this.refTopNode} />
        <BaseTable
          viewPath={'list'}
          getEntities={(modelName: string,
            pagingOptions: IPagingOptions, sortingOptions?: IDefaultSorting) => {
            this.props.getEntities2(modelName, pagingOptions, sortingOptions)
          }}
          allowSelect={true}
          model={model}
          loading={loading}
          scrollY={scrollY}
          type={ModelType.Entity}
          footer={footer}
          openForm={(isOpen: boolean) => {
            // logGet(model, this.props.selectedItem?.id);
            this.props.openForm(isOpen);
          }}
          selectedItem={this.props.selectedItem}
          selectItem={(item: any) => this.props.selectItem(item)}
          pagingOptions={pagingOptions}
          data={items}
        />
        <VirtualTable.FillNode
          height={bottomBlankHeight}
          node={this.refBottomNode}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  items: state.entitiesReducer.items,
  loading: state.entitiesReducer.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getEntities2: getEntities2 }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VirtualTable);
