import React, { ReactElement } from "react";
import { ChangeAction } from "../changes/field-change";
import { IConstraint } from "../constraint";
import { Dictionary, DictionaryObject } from "../types/dictionary";
import { GeneralField } from "./general-field";

export class StateField extends GeneralField {
  compareFunction(before: Dictionary, after: Dictionary): ChangeAction {
    return (before as string) === (after as string)
      ? ChangeAction.Unchanged
      : ChangeAction.Updated;
  }

  viewRender(value: Dictionary): ReactElement {
    const prefix = this.viewField.prefix ? this.viewField.prefix : "";
    const postfix = this.viewField.postfix ? " " + this.viewField.postfix : "";
    return value ? (
      <span>{prefix + (value as string) + postfix}</span>
    ) : (
      <span></span>
    );
  }

  stringRender(value: Dictionary): string {
    const prefix = this.viewField.prefix ? this.viewField.prefix : "";
    const postfix = this.viewField.postfix ? " " + this.viewField.postfix : "";
    return value ? prefix + (value as string) + postfix : "";
  }

  tagRender(value: Dictionary): ReactElement {
    return this.viewRender(value);
  }

  emptyConstraint(fieldName: string): IConstraint {
    return {
      type: "NullOrEmpty",
      field: fieldName,
    } as IConstraint;
  }

  valueConstraint(fieldName: string, value: Dictionary) {
    return [
      {
        type: "Eq",
        field: fieldName,
        value: (value as DictionaryObject)["value"],
      } as IConstraint,
    ];
  }

  isEmpty(value: Dictionary) {
    return !value;
  }
}
