import { FormGroup, Icon, Label } from "@blueprintjs/core";
import { Position } from "@blueprintjs/core/lib/esm/common/position";
import { DateInput } from "@blueprintjs/datetime";
import { Col, Row } from "antd";
import Moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Field, WrappedFieldInputProps } from "redux-form";
import { WrappedFieldProps } from "redux-form/lib/Field";
import { Views } from "../../../../../models/enums/views";
import { IFormField } from "../../../../../models/fields/form-field";
import { IViewField } from "../../../../../models/view-field";
import "./DateStateHistoryField.scss";
/* eslint-disable no-eval */

export const FORMATS = [
  {
    formatDate: (date: Date) => {
      return date == null ? "" : date.toLocaleDateString("ru-RU");
    },
    parseDate: (str: string) => {
      return str ? new Date(Date.parse(str)) : null;
    },
  },
];

export const renderDateField = ({
  input,
  meta: { touched, invalid, error },
  field,
  placeholder,
  classType,
  disabled,
  selectedItem,
}: WrappedFieldProps & {
  input: WrappedFieldInputProps;
  meta: any;
  field: IViewField;
  placeholder: string;
  startDate: string;
  endDate: string;
  disabled: boolean;
  classType: string;
  selectedItem: any;
}) => {
  const value = field.condition ? eval(field.condition)(selectedItem) : null;

  if (disabled && value && input.value !== value) {
    input.value = new Date(value);
  }

  return (
    <div className="row-input">
      <label>
        <DateInput
          className={classType ? classType : ""}
          canClearSelection={true}
          highlightCurrentDay={true}
          showActionsBar={true}
          closeOnSelection={true}
          fill
          disabled={!!disabled}
          {...FORMATS[0]}
          onChange={input.onChange}
          placeholder={placeholder}
          value={input.value}
          inputProps={{
            rightElement: <Icon icon="calendar" className="right-btn" />,
          }}
          popoverProps={{ position: Position.BOTTOM }}
          dayPickerProps={{ firstDayOfWeek: 1 }}
        />
        {/*{touched && ((error && <div>{error}</div>))}*/}
      </label>
    </div>
  );
};

export const parseDateToString = (date: Date, end: boolean) => {
  if (date) {
    const moment = Moment(date);
    if (end) {
      return moment.endOf("day").format();
    } else {
      return moment.format();
    }
  }
  return null;
  // return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString() : null;
  // return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
};

export const formatStringToDate = (value: string | Date) => {
  return typeof value === "string" ? new Date(value) : value;
};

export const DateStateHistoryField = (props: IFormField) => {
  const { t } = useTranslation();
  const { f, fieldName, view, showLabel } = props;

  const DateFilterView = () => {
    return (
      <Row className="date-field" key={f.name}>
        <FormGroup>
          <Label htmlFor={fieldName}>{t(f.name)}</Label>
          <div className="row">
            <Field
              name={fieldName + ".startDate"}
              type="text"
              format={formatStringToDate}
              parse={(date: Date) => parseDateToString(date, false)}
              field={f}
              classType={"date-range"}
              placeholder={t("UI.SelectDate")}
              component={renderDateField}
            />
            <Field
              name={fieldName + ".endDate"}
              type="text"
              format={formatStringToDate}
              parse={(date: Date) => parseDateToString(date, true)}
              classType={"date-range"}
              field={f}
              placeholder={t("UI.SelectDate")}
              component={renderDateField}
            />
          </div>
        </FormGroup>
      </Row>
    );
  };
  const DateFormView = () => {
    return (
      <Row className="date-field date-field_simple" key={f.name}>
        {showLabel && (
          <Col span={8}>
            <Label htmlFor={fieldName}>
              <span>{t(f.name)}</span>
              <span className="required">{f.required ? "*" : ""}</span>
            </Label>
          </Col>
        )}
        <Col span={16}>
          <Field
            name={fieldName}
            type="text"
            disabled={f.readonly || (f.writable === false)}
            selectedItem={props.selectedItem}
            format={formatStringToDate}
            parse={(date: Date) => parseDateToString(date, false)}
            onChange={(e: any) => {
              if (props.onChange) {
                props.onChange(e);
              }
            }}
            field={f}
            placeholder={t("UI.SelectDate")}
            component={renderDateField}
          />
        </Col>
      </Row>
    );
  };

  switch (view) {
    case Views.FILTER:
      return DateFilterView();
    case Views.FORM:
      return DateFormView();
    case Views.REFERENCE:
      return <span>Text</span>;
    default:
      return <span>Text</span>;
  }
};

export default DateStateHistoryField;
