import React, { useEffect, ReactElement } from "react";
import "./ChangeDetails.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { H4 } from "@blueprintjs/core";
import { getChangesDetail } from "../../../redux/actions/changesActions";
import { IAppState } from "../../../redux/states/state";
import { IChange } from "../../../models/changes/changes-history";
import { IViewModel } from "../../../models/view-model";
import { ChangesFactory } from "../../../models/changes/changes-factory";

interface Props {
  id: number;
  general?: boolean;
}

export const ChangesDetails: React.FC<Props> = (props: Props): ReactElement => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { changes } = useSelector((state: IAppState) => state.changesReducer);
  const { models } = useSelector((state: IAppState) => state.modelsReducer);
  const { id, general } = props;

  useEffect(() => {
    if (id) {
      dispatch(getChangesDetail(id));
    }
  }, [id, dispatch]);

  const renderInfo = (change?: IChange) => {
    if (!change) {
      return null;
    }
    const model: IViewModel | undefined = models.get(change.entity || "");
    const changesFactory = new ChangesFactory(change, model);
    const parsedChange = changesFactory.parseChange();
    return (
      <React.Fragment>
        {general ? (
          <H4 className="changes-details__header">
            {parsedChange.getShortDescription(t)}
          </H4>
        ) : null}
        <div className={general ? "changes-details__body" : ""}>
          {general ? (
            <div className="changes-details__info">
              {changesFactory.getGeneralInfo(t)}
              {parsedChange.renderInfo(t)}
            </div>
          ) : null}
          <div className="changes-details__changes">
            {parsedChange.renderChanges(t)}
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="changes-details_container">
      <div className="changes-details">
        {changes && changes.get(id) ? renderInfo(changes.get(id)) : null}
      </div>
    </div>
  );
};

export default ChangesDetails;
