import { Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IColumn } from "../../../../../models/column";
import { DictionaryObject } from "../../../../../models/types/dictionary";
import { IViewField } from "../../../../../models/view-field";
import { IViewModel } from "../../../../../models/view-model";
import "./Letters.scss";

const Letters = (props: { item: DictionaryObject; model: IViewModel }) => {
  const { item, model } = props;
  const { t } = useTranslation();
  const [incColumns, setIncColumns] = useState<IColumn[]>([]);
  const [outColumns, setOutColumns] = useState<IColumn[]>([]);

  const incField = model.fields.find(
    (f: IViewField) => f.model === "IncomingDocument"
  );
  const outField = model.fields.find(
    (f: IViewField) => f.model === "OutgoingDocument"
  );

  useEffect(() => {
    setIncColumns(getIncColumns());
    setOutColumns(getOutColumns());
  }, []);

  const getIncColumns = () => {
    const columns: IColumn[] = [];
    columns.push({
      title: t("IncomingDocumentNumber"),
      dataIndex: "IncomingDocumentNumber",
      render: (element: number) => {
        return element;
      },
    });
    columns.push({
      title: t("IncomingDocumentDate"),
      dataIndex: "IncomingDocumentDate",
      render: (element: Date) => {
        return element ? moment(element).format("DD.MM.YYYY") : "";
      },
    });
    return columns;
  };

  const getOutColumns = () => {
    const columns: IColumn[] = [];
    columns.push({
      title: t("OutgoingDocumentNumber"),
      dataIndex: "OutgoingDocumentNumber",
      render: (element: number) => {
        return element;
      },
    });
    columns.push({
      title: t("OutgoingDocumentDate"),
      dataIndex: "OutgoingDocumentDate",
      render: (element: Date) => {
        return element ? moment(element).format("DD.MM.YYYY") : "";
      },
    });
    return columns;
  };

  return (
    <div className="letters">
      {incField && item && item[incField.name] && (
        <div className="letters__incoming">
          <label>{t("IncomingDocument")}</label>
          <Table
            columns={incColumns}
            size={"small"}
            dataSource={item[incField.name] as object[]}
            pagination={false}
            bordered
          />
        </div>
      )}
      {outField && item && item[outField.name] && (
        <div className="letters__outgoing">
          <label>{t("OutgoingDocument")}</label>
          <Table
            columns={outColumns}
            size={"small"}
            dataSource={item[outField.name] as object[]}
            pagination={false}
            bordered
          />
        </div>
      )}

    </div>
  );
};

export default Letters;
