import { IChange } from "./changes-history";
import { IViewModel } from "../view-model";
import { TFunction } from "i18next";
import React from "react";
import {
  IChangesDetails,
  readInfoConstraints,
  renderConstraints,
} from "./changes-factory";
import { IFieldRead } from "./field-change";
import {
  IConstraintChanges,
  IConstraintTreeChanges,
} from "./constraint-changes";

export class ChangesList implements IChangesDetails {
  details = "";
  info: IFieldRead[] = [];

  constructor(change: IChange, model?: IViewModel) {
    this.getDetails();
    this.info = readInfoConstraints(
      change.after as IConstraintChanges[] | IConstraintTreeChanges,
      model
    );
  }

  getDetails(): void {
    this.details = this.readDetails();
  }

  readDetails(): string {
    return "";
  }

  renderInfo(t: TFunction): React.ReactNode {
    return renderConstraints(this.info, t);
  }

  renderChanges(): React.ReactNode {
    return <span>{this.details}</span>;
  }

  getShortDescription(t: TFunction): React.ReactNode {
    return <span>{t("ChangesShortDesc.List")}</span>;
  }
}
