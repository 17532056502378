export function BfmqaDelayed() {
  return [
    {
      field: "BF_AnalysisSend",
      type: "Eq",
      value: true,
    },
  ];
}

export function PrelimLettersDelayed() {
  const today = new Date();
  today.setDate(today.getDate() - 90);
  return [
    {
      field: "PrelimLetters.id",
      type: "NotNull",
    },
    {
      field: "PrelimLetters.SendDate",
      type: "Lt",
      value: today,
    },
    {
      field: "PrelimLetters.AnswerDate",
      type: "Null",
    },
  ];
}

export function ExpertLettersDelayed() {
  const today = new Date();
  today.setDate(today.getDate() - 90);
  return [
    {
      field: "ExpertLetters.id",
      type: "NotNull",
    },
    {
      field: "ExpertLetters.SendDate",
      type: "Lt",
      value: today,
    },
    {
      field: "ExpertLetters.AnswerDate",
      type: "Null",
    },
  ];
}

export function IsExpertResult(item) {
  return [
    {
      field: "BF_ExpertResult",
      type: "NotNull",
    },
  ];
}

export function HasPrinted(item) {
  switch (item) {
    case "Bəli":
      return [
        {
          field: "PrintCount",
          type: "Gt",
          value: 0,
        },
      ];
    case "Yox":
      return [
        {
          field: "PrintCount",
          type: "Eq",
          value: 0,
        },
      ];
    default:
      return [];
  }
}

export function HasProblem(item) {
  return [
    {
      field: "RegProblem.id",
      type: "NotNull",
    },
  ];
}

export function HasProblems(item) {
  return [
    {
      field: "Problems.id",
      type: "NotNull",
    },
  ];
}

export function HasSertExist(item) {
  switch (item) {
    case "Bəli":
      return [
        {
          field: "SertificateExist",
          type: "Eq",
          value: true,
        },
      ];
    case "Yox":
      return [
        {
          field: "SertificateExist",
          type: "Eq",
          value: false,
        },
      ];
    default:
      return [];
  }
}

export function HasSert(item) {
  switch (item) {
    case "Bəli":
      return [
        {
          field: "Sertificate",
          type: "Eq",
          value: true,
        },
      ];
    case "Yox":
      return [
        {
          field: "Sertificate",
          type: "Eq",
          value: false,
        },
      ];
    default:
      return [];
  }
}

export function IsExtra(item) {
  return [
    {
      field: "AdditionalNumber",
      type: "Gt",
      value: 0,
    },
    {
      field: "AdditionalNumber",
      type: "NotNull",
    },
  ];
}

export function IsRepeat(item) {
  return [
    {
      field: "RepeatNumber",
      type: "Eq",
      value: "Bəli",
    },
  ];
}

export function BpaFilter(item) {
  return [
    {
      field: "BPA.BPA.NameAZE",
      type: "Like",
      value: "%" + item + "%",
    },
  ];
}

export function ActiveRegCard(item, filterObject) {
  const hiddenStatuses = [
    "auto-closed-spec",
    "auto-closed",
    "reestr-archive",
    "registration-archive",
    "specexp-cancel",
    "preexp-cancel",
  ];
  let newStateFilter = [];
  if (item === true && filterObject != null) {
    const state = filterObject["state"];
    if (state && state.length) {
      newStateFilter = [
        {
          field: "state",
          type: "In",
          values: state.filter(
            (st) => hiddenStatuses.findIndex((hs) => hs === st) === -1
          ),
        },
      ];
    }
  }
  return newStateFilter;
}

export function ActiveRegCardGeneral(item, filterObject) {
  const showStatuses = [
    "accepted",
    "preexp-contract-prepare",
    "preexp-payment",
    "first-expertise",
    "preexp-waiting",
    "specexp-contract-prepare",
    "specexp-payment",
    "specexp-expert-selection",
    "specexp-process",
    "specexp-lab-analysis",
    "specexp-waiting",
    "specexp-positive-result-confirm",
    "changes-confirmed",
    "registered",
  ];
  return [
    {
      field: "state",
      type: "In",
      values: showStatuses,
    },
  ];
}

export function ManufacturerCompany(item) {
  return [
    {
      field: "ManufactureDetails.IstComp.Country.id",
      type: "Eq",
      value: item.id,
    },
  ];
}

export function ManufacturerRegion(item) {
  return [
    {
      field: "ManufactureRegions.id",
      type: "Eq",
      value: item.id,
    },
  ];
}

export default {
  BfmqaDelayed,
  IsExpertResult,
  HasPrinted,
  HasProblem,
  HasProblems,
  HasSertExist,
  HasSert,
  IsExtra,
  IsRepeat,
  ExpertLettersDelayed,
  PrelimLettersDelayed,
  BpaFilter,
  ActiveRegCard,
  ActiveRegCardGeneral,
  ManufacturerCompany,
  ManufacturerRegion,
};
