import { Button, InputGroup } from "@blueprintjs/core";
import { Row, Spin } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { IDefaultSorting } from "../../../models/default-sorting";
import { IEmployee } from "../../../models/employee";
import { IPagingOptions } from "../../../models/paging-options";
import { Dictionary } from "../../../models/types/dictionary";
import { IUser } from "../../../models/users/user";
import {
    clearFilters,
    clearItems,
    getEntitiesForControlMarks
} from "../../../redux/actions/entitiesActions";
import { IStateAuthorization } from "../../../redux/states/state-authorization";
import { IStateEntities } from "../../../redux/states/state-entities";
import { IStateModels } from "../../../redux/states/state-models";
import { IStateNavigation } from "../../../redux/states/state-navigation";
import ListFooter from "../../core/lists/list-footer/ListFooter";
import MainListAnt from "../../core/lists/main-list-ant/MainListAnt";
import "./ControlMarks.scss";

interface State {
    modelName: string;
    searchText: string;
}

interface Props extends RouteComponentProps, WithTranslation {
    routing: string;
    setActiveModel: (modelName: string) => void;
    activeModel: string | undefined;
    userInfo: IUser | null;
    loading: boolean;
    clearFilters: () => void;
    clearItems: () => void;
    getEntitiesForControlMarks: (value: string, pagingOptions: IPagingOptions, sortingOptions?: IDefaultSorting) => void;
    currentEmployee: IEmployee | null;
    filter: any;
}

class ControlMarks extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            searchText: "",
            modelName: "",
        };
    }

    componentDidMount() {
        this.props.clearItems();
    }
    render() {
        const { activeModel, loading, t, } = this.props;

        if (loading) {
            return (
                <Spin
                    tip="Loading models..."
                    className={"large-spinner"}
                    size={"large"}
                />
            );
        }
        if (!activeModel) {
            return <span></span>;
        }

        return (
            <div className="main-layout">
                <div id="main">
                    <Row className="display-row list-header">
                        <InputGroup
                            className={"any-text"}
                            value={this.state.searchText}
                            onChange={(e: any) => this.setState({ searchText: e.target.value })}
                        />
                        <Button onClick={() => { this.props.getEntitiesForControlMarks(this.state.searchText, { page: 0, pageSize: 15, showDeleted: false }); }} text={"Ok"} />
                    </Row>
                    <div className="content">
                        <MainListAnt
                            selectItem={(item: Dictionary) => null}
                            selectedItem={null}
                            openForm={() => null}
                            pagingOptions={{
                                page: 0,
                                pageSize: 15,
                                showDeleted: false
                            }}
                            footer={(selectPrev: () => void, selectNext: () => void) => (
                                <ListFooter
                                    canExport={true}
                                    openForm={() => null}
                                    updatePagingOps={(pagingOptions: IPagingOptions) => {

                                    }}
                                    createNew={false}
                                    selectPrev={selectPrev}
                                    selectNext={selectNext}
                                />
                            )}
                            model={activeModel}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: {
    navigationReducer: IStateNavigation;
    modelsReducer: IStateModels;
    authorizationReducer: IStateAuthorization;
    entitiesReducer: IStateEntities;
}) => ({
    userInfo: state.authorizationReducer.userInfo,
    currentEmployee: state.authorizationReducer.currentEmployee,
    loading: state.modelsReducer.loading,
    activeModel: state.modelsReducer.activeModel,
    filter: state.entitiesReducer.filter,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            clearFilters: () => clearFilters(),
            clearItems: () => clearItems(),
            getEntitiesForControlMarks: (value, pagingOptions, sortingOptions) => getEntitiesForControlMarks(value, pagingOptions, sortingOptions)
        },
        dispatch
    );

export default withTranslation()(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(ControlMarks))
);
