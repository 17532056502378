import { Button, MenuItem, Popover, Position } from "@blueprintjs/core";
import { Menu } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isDirty } from "redux-form";
import { ModelType } from "../../../../../models/enums/model-type";
import { DictionaryObject } from "../../../../../models/types/dictionary";
import { IViewModel } from "../../../../../models/view-model";
import { setConfirm } from "../../../../../redux/actions/alertActions";
import { updateState } from "../../../../../redux/actions/entitiesActions";
import { IAppState } from "../../../../../redux/states/state";
import "./StateChange.scss";

export const StateChange = (props: {
  selectedItem: DictionaryObject;
  type: ModelType;
  model: IViewModel;
  onClose: CallableFunction;
}) => {
  const { selectedItem, model } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reduxState = useSelector((state: IAppState) => state);
  if (!model || !model.states || !model.states.size) {
    return null;
  }

  if (!selectedItem || !selectedItem["state"]) {
    return null;
  }

  const state = model.states.get(selectedItem["state"].toString());

  if (!state) {
    return null;
  }

  if (!state.toName || !state.toName.length) {
    return null;
  }
  const states = [...state.toName];
  return (
    <div>
      <Popover

        position={Position.BOTTOM}
        content={<Menu>{states.filter(s => !s.name.includes('auto-closed')).map((item, index) => <MenuItem key={index} onClick={() => {
          const updateStateFunc = () => {
            dispatch(
              updateState(model.dbname, { ...selectedItem, state: item.name }, () => props.onClose(true, null))
            );
          }

          if (isDirty(reduxState.modelsReducer.activeModel + reduxState.modelsReducer.activeForm + "__FORM")(reduxState)) {
            dispatch(setConfirm({
              message: t("UI.CloseMessage"),
              isOpen: true,
              onConfirm: () => {
                updateStateFunc();
              }
            }))
          } else {
            updateStateFunc();
          }
        }}
          icon={item.reverse ? "caret-left" : "caret-right"} text={t("States." + item.name)} />)}</Menu>} >
        <Button rightIcon="caret-down" text={"Status"} />
      </Popover>

    </div>
  );
};

export default StateChange;
