import { DictionaryObject } from "../models/types/dictionary";
import { IViewModel } from "../models/view-model";
import { ITransformation } from "../models/transformation";

export const getState = (item: DictionaryObject): string | null => {
  return item && item["state"] ? (item["state"] as string) : null;
};

export function getTransformationsByState(
  state: string | null,
  model: IViewModel
): ITransformation[] {
  if (!state) {
    return [];
  }
  const foundState = model.states.get(state);
  if (!foundState) {
    return [];
  }
  return foundState.transformations;
}
