import { Alignment, Checkbox } from "@blueprintjs/core";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { WrappedFieldInputProps, WrappedFieldProps } from "redux-form";
import { IViewField } from "../../../../../models/view-field";
import ValidMessages from "../../valid-messages/ValidMessages";
import "./EmptyBtn.scss";

export const renderEmptyField = ({
  input,
  meta: { touched, invalid, error },
  field,
  placeholder,
  label,
  disabled
}: WrappedFieldProps & {
  field: IViewField;
  placeholder: string;
  label: string;
  disabled?: boolean;
}) => (
    <Fragment>
      <EmptyField
        input={input}
        field={field}
        placeholder={placeholder}
        label={label}
        disabled={disabled}
      />
      {touched && error && <ValidMessages text={error} />}
    </Fragment>
  );

export const EmptyField = ({
  input,
  field,
  placeholder,
  label,
  disabled
}: {
  input: WrappedFieldInputProps;
  field: IViewField;
  placeholder: string;
  label: string;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Checkbox
      className="empty"
      alignIndicator={Alignment.RIGHT}
      value={input.value}
      onChange={input.onChange}
      placeholder={placeholder}
      disabled={disabled}
      defaultChecked={input.value !== "" ? input.value : false}
    >
      <div
        className={input.value ? "empty__btn checked" : "empty__btn unchecked"}
      >
        {t("UI.Empty")}
      </div>
    </Checkbox>
  );
};

export default EmptyField;
