import axios from "axios";
import { project } from "./../../configs/index";

const checkPrintApi = (): Promise<any> => {
    return axios(`${(project.config as any).printUrl}/api/document/check`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    });
  };

export { checkPrintApi };