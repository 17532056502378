import { ISequenceState } from "../../models/sequence-state";
import { IStateSequences } from "../states/state-sequences";
import {
  CLEAR_TRY_VALUES,
  LOADING_TRY_VALUES,
  LOAD_TRY_VALUES,
} from "./../constants/sequences";

const INITIAL_STATE: IStateSequences = {
  tryValue: new Map<string, ISequenceState>(),
  loadingSeq: false,
  loadingSeqProcess: false
};

const sequencesReducer = (
  state = INITIAL_STATE,
  action: { type: string; payload: any }
) => {
  const prevState = state;
  switch (action.type) {
    case LOAD_TRY_VALUES:
      const tvMap: Map<string, ISequenceState> = new Map<
        string,
        ISequenceState
      >(prevState.tryValue);
      const sequences = action.payload.sequences;
      const states = action.payload.states;

      if (states && states.length) {
        sequences.forEach((seq: string, index: number) => {
          tvMap.set(seq, states[index].data);
        });
      }
      return {
        ...prevState,
        tryValue: tvMap
      };
    case LOADING_TRY_VALUES:
      return {
        ...prevState,
        loadingSeq: !action.payload.process && action.payload.loading,
        loadingSeqProcess: action.payload.process && action.payload.loading
      };
    case CLEAR_TRY_VALUES:
      const tMap: Map<string, ISequenceState> = new Map<string, ISequenceState>(
        prevState.tryValue
      );
      action.payload.sequences.forEach((seq: string) => {
        tMap.delete(seq);
      });
      return {
        ...prevState,
        tryValue: tMap,
      };
    default:
      return prevState;
  }
};

export default sequencesReducer;
