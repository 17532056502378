import React from "react";
import { FieldArray, WrappedFieldArrayProps } from "redux-form";
import { checkShowConstraints } from "../../../../../helpers/check-show-constraints";
import { Views } from "../../../../../models/enums/views";
import { IFormField } from "../../../../../models/fields/form-field";
import { IViewField } from "../../../../../models/view-field";
import EntityFieldFilter from "../../../filters/fields/entity-field/EntityFieldFilter";
import ManyFillForm from "../../controls/many-fill-form/ManyFillForm";
import ReferenceField from "../reference-field/ReferenceField";
import "./ManyField.scss";

const renderManyField = ({
  fields,
  meta: { error, submitFailed },
  field,
  selectedItem,
  formName
}: WrappedFieldArrayProps & {
  field: IViewField;
  selectedItem: any;
  formName: string;
}) => {
  return (
    <div className="row-input">
      <ManyFillForm
        formName={formName}
        fields={fields}
        f={field}
        selectedItem={selectedItem}
      />
      {/*{touched && ((error && <div>{error}</div>))}*/}
    </div>
  );
};

export const ManyField = (props: IFormField) => {
  const { f, selectedItem, fieldName, view, formName } = props;

  const ManyFilterView = () => {
    return <ReferenceField {...props} />;
  };

  const ManyFormView = () => {
    const { listeners } = props;
    const show = checkShowConstraints(f, listeners);
    if (show) {
      return (
        <FieldArray
          key={f.name}
          name={fieldName}
          field={f}
          selectedItem={selectedItem}
          formName={formName}
          component={renderManyField}
        />
      );
    } else {
      return <span />;
    }
  };

  switch (view) {
    case Views.FILTER:
      return ManyFilterView();
    case Views.FORM:
      return ManyFormView();
    case Views.REFERENCE:
      return <span>Text</span>;
    default:
      return <span>Text</span>;
  }
};

export default ManyField;
