import { ThunkAction } from "redux-thunk";
import { Action } from "typesafe-actions";
import { IConstraintTree } from "../../models/constraint";
import { DictionaryObject } from "../../models/types/dictionary";
import { findAllV2, updateEntity } from "../api/entities-api";
import { LOADING_SAMPLES, LOAD_DV_CHANGES, LOAD_SAMPLES } from "../constants/aem";
import { IAppState } from "../states/state";
import { showError } from "./errorsActions";

export function loadSamples(samples: DictionaryObject[]) {
    return {
      type: LOAD_SAMPLES,
      payload: samples,
    };
}

export function loadingSamples(loading: boolean) {
    return {
      type: LOADING_SAMPLES,
      payload: loading,
    };
}

export const getSamples = (selectedItem: DictionaryObject):ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
    if(!selectedItem || !selectedItem['id']) return;
    dispatch(loadingSamples(true));
    
    const constraintTree = {
        constraints: [
          {
            field: "Registration.id",
            type: "Eq",
            value: selectedItem['id']
          }
        ],
        nodes: [],
        rule: "And"
    } as IConstraintTree;

    let sortingQuery = `&page.sortColumn=id&page.sortDirection=Asc`;

    findAllV2(
        "Ekspred",
        constraintTree,
        sortingQuery
    )
      .then((response) => {
        dispatch(loadSamples(response.data));
        dispatch(loadingSamples(false));
      })
      .catch((err) => {
        dispatch(showError("ERROR.GetSamples"));
        dispatch(loadingSamples(false));
      });
};

export const updateSamples = (samples: DictionaryObject[], updateCallback: () => void):ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  Promise.all(
    samples.map((sample: DictionaryObject) => {
      return updateEntity("Ekspred", sample);
    })
  )
    .then((response) => {
      updateCallback();
    })
    .catch((err) => {
      dispatch(showError("ERROR.UpdateSamples"));
    });
};

export function loadDvChanges(items: DictionaryObject[], loading: boolean) {
  return {
    type: LOAD_DV_CHANGES,
    payload: {
      items: items,
      loading: loading
    },
  };
}

export const getDVChanges = (selectedItem: DictionaryObject):ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  if(!selectedItem || !selectedItem['id']) return;
  dispatch(loadDvChanges([], true));
  
  const constraintTree = {
      constraints: [
        {
          field: "RegisterCardN",
          type: "Eq",
          value: selectedItem['CardNumber']
        },
        {
          field: "ExpertiseType.Name",
          type: "Eq",
          value: "Dəyişiklik"
        }
      ],
      nodes: [],
      rule: "And"
  } as IConstraintTree;

  let sortingQuery = `&page.sortColumn=id&page.sortDirection=Asc`;

  findAllV2(
      "DVRegistration",
      constraintTree,
      sortingQuery
  )
    .then((response) => {
      dispatch(loadDvChanges(response.data, false));
    })
    .catch((err) => {
      dispatch(showError("ERROR.GetDVChanges"));
      dispatch(loadDvChanges([], false));
    });
};

export const clearDVChanges = () => {
  return loadDvChanges([], false);
}