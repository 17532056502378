import { Icon } from "@blueprintjs/core";
import { Button, ButtonGroup, Dialog, FileInput } from "@blueprintjs/core";
import { Pagination, Table } from "antd";
import { saveAs } from "file-saver";
import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { TemplateType } from "../../../models/enums/template-type";
import { ITemplate } from "../../../models/template";
import { setConfirm } from "../../../redux/actions/alertActions";
import { getFile } from "../../../redux/actions/attachmentsActions";
import {
  deleteTemplate,
  getTemplates,
  updateTemplate
} from "../../../redux/actions/templatesActions";
import { IAppState } from "../../../redux/states/state";
import { IStateAlerts } from "../../../redux/states/state-alerts";
import ListCount from "../../core/lists/list-count/ListCount";
import CreateTemplate from "../create-template/CreateTemplate";
import MetadataField from "../metadata-field/MetadataField";
import "./Templates.scss";

interface Props extends WithTranslation {
  deleteTemplate: (name: string, fileId: string) => void;
  getTemplates: (page: number, pageSize: number) => void;
  updateTemplate: (template: ITemplate, formData: FormData) => void;
  setConfirm: (props: IStateAlerts) => void;
  templates: ITemplate[] | null;
  files: Map<string, any>;
  loading: boolean;
  totalCount: number;
  isOpen: boolean;
}

interface IColumn {
  title: string;
  key?: string;
  width?: number;
  value: string;
  dataIndex: string;
  render?: any;
}

class Templates extends React.PureComponent<Props, any> {

  constructor(props: Props) {
    super(props);
    this.state = {
      columns: [],
      selectedRowKeys: [],
      isOpen: false,
      inputKey: 0
    };

  }

  handleInputChange = (e: any, template: ITemplate) => {
    if (e.target.files.length) {
      const arrFiles: File[] = Array.from(e.target.files);
      const formData = new FormData();
      formData.append("file", arrFiles[0], arrFiles[0].name);
      this.props.updateTemplate(template, formData);
      this.setState({ inputKey: new Date().getTime() });
      e.target.value = "";
    }
  };

  tableColumns = () => {
    const { t } = this.props;
    const { inputKey } = this.state;
    this.setState({
      columns: [
        {
          title: t("TemplateRef.TemplateNameAZ"),
          value: "TemplateNameAZ",
          dataIndex: "name",
          key: "Name",
          render: (value: any, record: any) => {
            return t("PrintForms." + value);
          }
        },
        {
          title: t("TemplateRef.TemplateName"),
          value: "TemplateName",
          dataIndex: "name",
          key: "Name",
        },
        {
          title: t("TemplateRef.Template"),
          dataIndex: "attachmentId",
          key: "Template",
          render: (value: any, record: any) => {
            return (
              <div>
                <Button
                  key={record["id"]}
                  minimal
                  onClick={() => {
                    getFile(value).then((res) => {
                      if (!res) {
                        return;
                      }
                      const fileType = res.headers["content-type"];
                      const blob = new File([res.data], record["name"], {
                        type: fileType,
                      });
                      saveAs(blob);
                    });
                  }}
                >
                  {t("UI.Download")}
                </Button>
                <FileInput
                  className="attach-btn"
                  text="Choose file..."
                  buttonText={t("UI.TemplateChange")}
                  fill={false}
                  key={inputKey}
                  inputProps={{ accept: ".doc, .docx, .xlsx, .xls" }}
                  onInputChange={(e: any) => { this.handleInputChange(e, record) }}
                />
              </div>
            );
          },
        },
        {
          title: t("TemplateRef.TemplateType"),
          value: "TemplateType",
          dataIndex: "type",
          key: "type",
          render: (value: any) => {
            return <span>{t(TemplateType[value])}</span>;
          },
        },
        {
          title: t("TemplateRef.Metadata"),
          value: "Metadata",
          dataIndex: "metadata",
          key: "metadata",
          render: (value: any, record: ITemplate) => {
            if (record.type === TemplateType.Export) {
              return <MetadataField template={record} />;
            } else {
              return null;
            }
          },
        },
        {
          title: t("TemplateRef.Restricted"),
          value: "Restricted",
          dataIndex: "restricted",
          key: "restricted",
          render: (value: any, record: ITemplate) => {
            return <div className="restricted"> {value ? <Icon icon="tick" />: null}</div>
          },
        },
        {
          title: this.props.t("Actions.Actions"),
          value: this.props.t("Actions.Actions"),
          key: "delete",
          render: (value: any, record: any) => {
            return (
              <Button
                key={record["id"]}
                minimal
                icon="remove"
                onClick={() => {
                  this.props.setConfirm({
                    message: t("UI.Sure"),
                    isOpen: true,
                    onConfirm: () => {
                      this.props.deleteTemplate(
                        record["name"],
                        record["attachmentId"]
                      )}
                  });
                 
                }}
              />
            );
          },
        },
      ] as IColumn[],
    });
  }

  onSelectChange = (selectedRowKeys: any[]) => {
    this.setState({ selectedRowKeys });
  };

  onDoubleChange = (selectedRowKeys: any[]) => {
    this.handleOpen();
  };

  componentDidMount(): void {
    this.props.getTemplates(0, 15);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    if (prevProps.loading !== this.props.loading) {
      this.tableColumns();
    }
  }

  keyPressEvent(e: any) {
    if (e.keyCode === 13) {
    } else {
    }
  }

  renderTemplatesFooter() {
    const { totalCount } = this.props;
    return (
      <div className={`footer`}>
        <ButtonGroup className={`footer_group`}>
          <Pagination
            className="footer-pagination"
            total={totalCount}
            defaultPageSize={15}
            showLessItems={true}
            showSizeChanger={false}
            onChange={(e: any) => {
              this.props.getTemplates(e - 1, 15);
            }}
          />
          <ListCount totalCount={totalCount} />
        </ButtonGroup>
      </div>
    );
  }

  handleOpen = () => this.setState({ isOpen: true });
  handleClose = () => this.setState({ isOpen: false });

  render() {
    const { templates, loading } = this.props;
    const { columns, selectedRowKeys } = this.state;
    return (
      <div className="template-page">
        <CreateTemplate />
        <div
          tabIndex={0}
          onKeyUp={(e) => {
            this.keyPressEvent(e);
          }}
        >
          <Dialog
            className="dialog"
            onClose={this.handleClose}
            title="Templates modal"
            canOutsideClickClose={false}
            {...this.state}
          ></Dialog>
          <Table
            loading={loading}
            tableLayout={"auto"}
            columns={columns}
            dataSource={templates || []}
            size="small"
            footer={() => this.renderTemplatesFooter()}
            pagination={false}
            rowKey="id"
            scroll={{ x: 10 * 120, y: 400 }}
            bordered
            rowClassName={(record: any, index: number) => {
              let rowClass = "all-row ";
              if (index === 0) {
                rowClass += "first-row ";
              }
              if (selectedRowKeys.find((r: ITemplate) => r === record["id"])) {
                rowClass += "scroll-row ";
              }
              return rowClass;
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  this.onSelectChange([record["id"]]);
                },
                onDoubleClick: () => {
                  this.onDoubleChange([record["id"]]);
                },
              };
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  templates: state.templatesReducer.templates,
  loading: state.templatesReducer.loading,
  totalCount: state.templatesReducer.totalCount,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      deleteTemplate: (name, fileId) => deleteTemplate(name, fileId),
      getTemplates: (page, pageSize) => getTemplates(page, pageSize),
      updateTemplate: (template, formData) =>
        updateTemplate(template, formData),
        setConfirm: (props) => setConfirm(props),
    },
    dispatch
  );

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Templates)
);
