export const SELECT_ROLE = "SELECT_ROLE";
export const GET_PERMISSIONS_BY_ROLE = "GET_PERMISSIONS_BY_ROLE";
export const LOAD_ROLE_PERMISSIONS = "LOAD_ROLE_PERMISSIONS";
export const LOADING_ROLE_PERMISSIONS = "LOADING_ROLE_PERMISSIONS";
export const LOAD_PERMITS = "LOAD_PERMITS";
export const CLEAR_PERMITS = "CLEAR_PERMITS";
export const CREATE_ROLE = "CREATE_ROLE";
export const MODIFY_ROLE = "MODIFY_ROLE";
export const REMOVE_ROLE = "REMOVE_ROLE";
export const GET_ROLES = "GET_ROLES";
export const LOAD_ROLES = "LOAD_ROLES";
export const LOADING_ROLES = "LOADING_ROLES";
export const IS_DUBLICATE_CREATION = "IS_DUBLICATE_CREATION";
export const GET_PERMISSIONS = "GET_PERMISSIONS";
