import { Icon, Tab, Tabs } from "@blueprintjs/core";
import { TFunction } from "i18next";
import React, { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { ModelType } from "../../../../../models/enums/model-type";
import { ISequenceState } from "../../../../../models/sequence-state";
import {
  Dictionary,
  DictionaryObject,
} from "../../../../../models/types/dictionary";
import { ITab, IViewModel } from "../../../../../models/view-model";
import { setActiveTab } from "./../../../../../redux/actions/modelsActions"
import TabPanel from "../../custom-grids/tab-panel/TabPanel";
import "./FormTabs.scss";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { isDirty } from "redux-form";
import { setConfirm } from "../../../../../redux/actions/alertActions";
import { showWarning } from "../../../../../redux/actions/errorsActions";
import { IAppState } from "../../../../../redux/states/state";

const allowTab = (tab: ITab, item: Dictionary): boolean => {
  return !!tab.condition;
};

const filterTabs = (tabs: ITab[], item: Dictionary): ITab[] => {
  if (!tabs) {
    return [];
  }
  return [...tabs].filter((tab: ITab) => allowTab(tab, item)).reverse();
};

const getTabName = (tab: ITab, t: TFunction): React.ReactNode => {
  const tabName = t("Tabs." + tab.title);
  const icon = tab.icon ? (
    <span>
      <Icon iconSize={14} icon={tab.icon} />{" "}
    </span>
  ) : (
      ""
    );
  return (
    <div>
      {icon}
      {tabName}
    </div>
  );
};

const FormTabs = (props: {
  type: ModelType;
  item: DictionaryObject;
  modelName: string;
  dbname: string;
  model: IViewModel;
  tryValue: Map<string, ISequenceState>;
  onClose: () => void;
  updateCallback?: (close: boolean, item: DictionaryObject | null) => void;
  transformCallback?: (currentItem: DictionaryObject) => void;
  callback?: () => void;
  setActiveTab: CallableFunction;
  isDirty: boolean,
  selectedTabId?: string
}) => {
  const { t } = useTranslation();
  const [tabs, setTabs] = useState<ITab[]>([]);
  const { model, item } = props;

  useEffect(() => {
    setTabs(filterTabs(model.tabs, item));
  }, [model, item]);
  useEffect(() => {
    if (tabs.length) {
      props.setActiveTab(tabs[0].title);
    } else {
      props.setActiveTab("General");
    }
  }, []);
  const dispatch = useDispatch();
  if (!tabs || !tabs.length) {
    return <div></div>;
  }

  return (
    <Tabs
      id="TabsGeneral"
      defaultSelectedTabId={tabs.length ? tabs[tabs.length - 1].title : ""}
      renderActiveTabPanelOnly={true}
      className="form-tabs"
      selectedTabId={props.selectedTabId}
    >
      {tabs.map((tab: ITab) => {
        return (
          <Tab
            key={tab.title}
            id={tab.title}

            onClickCapture={() => {
              if (props.isDirty) {
                dispatch(setConfirm({
                  message: t("UI.CloseMessage"),
                  isOpen: true,
                  onConfirm: () => {
                    props.setActiveTab(tab.title)
                  }
                }))
              } else if(tab.title !== "General" && (!item || !item['id'])) {
                dispatch(showWarning("UI.NeedCreate"));
              } else {
                props.setActiveTab(tab.title)
              }
            }}
            title={getTabName(tab, t)}
            panel={<TabPanel {...props} tab={tab} />}
            style={{ width: 100 / tabs.length + "%" }}
          />
        );
      })}
      <Tabs.Expander />
    </Tabs>
  );
};
const mapStateToProps = (state: IAppState) => {
  return {
    isDirty: isDirty(state.modelsReducer.activeModel + state.modelsReducer.activeForm + "__FORM")(state),
    selectedTabId: state.modelsReducer.activeForm
  }
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  ({
    setActiveTab: bindActionCreators(setActiveTab, dispatch),
  })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(FormTabs));
