import React, { useEffect, useState } from "react";
import "./CommonFilter.scss";
import { useDispatch, useSelector } from "react-redux";
import { autofill, reduxForm } from "redux-form";
import { IConstraint } from "../../../../models/constraint";
import { ConstraintType } from "../../../../models/enums/constraint-type";
import SimpleSelect from "../../forms/controls/simple-select/SimpleSelect";
import { Button } from "@blueprintjs/core";
import { useTranslation } from "react-i18next";
import { IViewField } from "../../../../models/view-field";
import CustomFormField from "../../forms/fields/form-types/FormTypes";
import { FormField } from "../../../../models/fields/form-field";
import { Views } from "../../../../models/enums/views";
import { commonFilterToConstraint } from "../../../../helpers/common-filter-to-constraint";

interface Props {
  modelName: string;
  onSearch: (constraints: IConstraint[]) => void;
  onClear: () => void;
}

const CommonFilter = (props: {
  modelName: string;
  form: string;
  handleSubmit: any;
  onSearch: (constraints: IConstraint[]) => void;
  onClear: () => void;
}) => {
  const { modelName, onSearch, onClear } = props;
  const model = useSelector((state: any) =>
    state.modelsReducer.models.get(modelName)
  );
  const fields = model.fields.filter(
    (f: IViewField) =>
      (f.visible.filter || f.name !== "BpaFilter") &&
      f.component !== "Multiple" &&
      f.component !== "OneToOne" &&
      f.component !== "ManyToMany" &&
      f.component !== "SimpleMultiSelect"
  );
  let defaultFilters = fields
    .filter((field: any) => field.visible.defaultSearch)
    .map((el: any) => ({
      value: "",
      type: ConstraintType.Like,
      field: el.name,
      fieldData: el,
      customFilter: el.customFilter
    }));
  if (!defaultFilters.length)
    defaultFilters =
      fields && fields.length
        ? [
          {
            value: "",
            type: ConstraintType.Like,
            field: fields[0].name,
            fieldData: fields[0],
            customFilter: fields[0].customFilter
          },
        ]
        : [{ value: "", type: ConstraintType.Like, field: "Name" }];

  const [selectedFilterList, setSelectedFilterList] = useState<IConstraint[]>(
    defaultFilters
  );
  const [selectedField, setSelectedField] = useState<any>(null);
  const { t } = useTranslation();
  const [, forceUpdate] = useState(false);

  const [selectableFields, setSelectableFields] = useState<any>(
    fields.filter(
      (field: IViewField) =>
        selectedFilterList.findIndex(
          (el: IConstraint) => field.name === el.field
        ) === -1
    )
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (model) {
      const modelFields = fields && fields.length ? fields : [];

      const filter =
        modelFields && modelFields.length
          ? modelFields.find(
            (field: IViewField) =>
              selectedFilterList.findIndex(
                (el: IConstraint) => field.name === el.field
              ) === -1
          )
          : null;

      setSelectedField(filter);
    }
  }, [model]);

  if (!model) {
    return <span>Not a model...</span>;
  }

  const renderView = (item: any): string => {
    if (!item) {
      return "";
    }
    return t(item.name);
  };

  const arrayOfValuesForSearch = (array: IConstraint[]) => {
    const arrayOfConstraits: any = [];

    array.forEach((el: IConstraint) => {
      const arr = !el.customFilter ? commonFilterToConstraint(el): el.customFilter.constraints(el.value);
      if (arr && arr.length) {
        arr.forEach((element: IConstraint) => arrayOfConstraits.push(element));
      }
    });

    return arrayOfConstraits;
  };

  const onChange = (value: any, filter: IConstraint) => {
    let val = value ? (value.target ? value.target.value : value) : value;
    if (value && value.target && value.target.type === "checkbox") {
      val = (+value.target.checked).toString();
    }
    const filteredList = selectedFilterList.map((el) => {
      return el.field === filter.field ? { ...el, value: val } : el;
    });
    setSelectedFilterList(filteredList);
  };

  const renderSingleFilter = (filter: IConstraint, index: number) => {

    return (
      <div className="dictionary-filter">
        <div className="dictionary-filter__value">
          {filter.fieldData && (
            <CustomFormField
              {...new FormField(
                {...filter.fieldData, readonly: false},
                filter.fieldData.name,
                null,
                Views.FILTER,
                true,
                false,
                props.form,
                false,
                false,
                new Map(),
                (value) => {
                  onChange(value, filter);
                },
                false
              )}
              key={index}
            />
          )}
        </div>
        <div className="dictionary-filter__delete">
          {defaultFilters.findIndex((el: any) => el.field === filter.field) ===
            -1 && (
              <Button
                small={true}
                icon="small-minus"
                className={"selected-filter__remove-button"}
                onClick={() => {
                  const filteredList = selectedFilterList.filter(
                    (el) => el.field !== filter.field
                  );
                  dispatch(autofill(props.form, filter.fieldData.name, ""));
                  setSelectedFilterList(filteredList);
                  setSelectedField(filter.fieldData);
                  setSelectableFields(
                    fields.filter(
                      (field: IViewField) =>
                        filteredList.findIndex(
                          (el: IConstraint) => field.name === el.field
                        ) === -1
                    )
                  );
                }}
              />
            )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="dictionary-filter">
        <div className={"dictionary-filter-list"}>
          {selectedFilterList.map(renderSingleFilter)}
        </div>
        <div className="dictionary-filter-toolbar">
          <div className="dictionary-filter__criteria">
            {selectableFields.length > 0 && (
              <SimpleSelect
                renderView={renderView}
                items={selectableFields}
                onChange={(value) => {
                  setSelectedField(value);
                }}
                component={
                  <Button
                    small
                    rightIcon="caret-down"
                    text={renderView(selectedField)}
                  />
                }
              />
            )}
          </div>

          <div className="dictionary-filter__buttons">
            <Button
              small={true}
              icon="plus"
              disabled={selectableFields.length === 0}
              className="dictionary-filter__add-button"
              onClick={() => {
                const found = selectedFilterList.find(
                  (el) => el.field === selectedField.name
                );
                if (!found) {
                  setSelectedFilterList([
                    ...selectedFilterList,
                    {
                      value: "",
                      field: selectedField.name,
                      fieldData: selectedField,
                      type: ConstraintType.Like,
                    },
                  ]);
                  const filtredSelectableFields = selectableFields.filter(
                    (field: IViewField) => field.name !== selectedField.name
                  );

                  setSelectableFields(filtredSelectableFields);

                  setSelectedField(
                    filtredSelectableFields ? filtredSelectableFields[0] : null
                  );
                  forceUpdate((s: boolean) => !s);
                }
              }}
            />
            <Button
              small={true}
              icon="small-tick"
              onClick={() => {
                if (selectedFilterList && selectedFilterList.length) {
                  const arrayOfSearchVal = arrayOfValuesForSearch(
                    selectedFilterList.filter((filter) => filter.value !== "")
                  );
                  onSearch(arrayOfSearchVal);
                } else {
                  onClear();
                }
              }}
            />
            <Button
              small={true}
              icon="small-cross"
              onClick={() => {
                onClear();
                setSelectedFilterList(defaultFilters);
                const filtredList = fields.filter(
                  (field: IViewField) =>
                    defaultFilters.findIndex(
                      (el: IConstraint) => field.name === el.field
                    ) === -1
                );
                setSelectableFields(filtredList);
                setSelectedField(filtredList && filtredList[0]);
                forceUpdate((s: boolean) => !s);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const CommonFilterForm = reduxForm<{}, Props>({})(CommonFilter);

export default CommonFilterForm;
