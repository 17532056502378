import { Button, Checkbox, Label, TextArea } from "@blueprintjs/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateEntity } from "../../../../../redux/actions/entitiesActions";
import { refreshItem } from "../../../../../redux/actions/newEntitiesActions";
import { IAppState } from "../../../../../redux/states/state";
import "./Custom.scss"


export const Comments = (props: {
    selectedItem: any;
    fieldName: string;
}) => {
    const { selectedItem } = props;
    const Comment = selectedItem[props.fieldName];
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const activeModel = useSelector((state: IAppState) => state.modelsReducer.activeModel);
    const currentEmployee = useSelector((state: IAppState) => state.authorizationReducer.currentEmployee);
    const [editable, setEditable] = useState(false);
    const [newComment, setNewComment] = useState(true);
    const [commentText, setCommentText] = useState("");
    const [commentsCount, setCommentsCount] = useState(0);
    useEffect(() => {
        setCommentsCount(Comment?.filter((element: any) => {
            if (element.Employee.id === currentEmployee?.id) {
                setNewComment(false);
                setCommentText(element.TextComment);
            }
            return !(element.Employee.id === currentEmployee?.id)
        }).length);
    }, [Comment]);
    return currentEmployee && <React.Fragment >
        <Label>{t('Comments')}</Label>
        {commentsCount ? <div className="Comments">
            {Comment?.map((el: any, index: number) => {
                if (el.Employee.id !== currentEmployee?.id) {
                    return <React.Fragment key={index}>
                        <div className="Employee">
                            <p>{el.Employee ? el.Employee.ShortName : ""}</p>
                            <p>{el.CommentDate && moment(el.CommentDate as string).format(
                                "DD.MM.YYYY"
                            )}</p>
                        </div>
                        <TextArea
                            disabled
                            value={el.TextComment}
                            growVertically
                        />
                    </React.Fragment>
                } else {
                    return "";
                }
            })}
        </div> : null}
        {!newComment ? <div className="Message">
            <div className="Employee">
                <p>{currentEmployee ? currentEmployee.ShortName : ""}</p>
                <Checkbox checked={editable} onChange={() => setEditable(!editable)} label={t("UI.Edit")} />
            </div>
            <TextArea
                disabled={!editable}
                value={commentText}
                onChange={(value) => setCommentText(value.target.value)}
                growVertically
            />
            <Button className="Submit" onClick={() => {
                let newData = { ...selectedItem };
                let temp = { Employee: currentEmployee, TextComment: commentText, CommentDate: new Date() }
                let index = newData.Comment.findIndex((p: any) => p.Employee.id === currentEmployee?.id);
                if (index > -1) {
                    newData.Comment[index] = temp;
                } else {
                    newData.Comment.push(temp);
                }

                dispatch(updateEntity(activeModel, newData, [], selectedItem, () => {
                    setEditable(false);
                    dispatch(
                        refreshItem(
                            activeModel,
                            selectedItem.id
                        )
                    )
                }));
            }} text={t("UI.Save")} />
        </div> :
            <div className="newMessage"> <Button onClick={() => setNewComment(false)} text={t("newComment")} /></div>
        }
    </React.Fragment >;
};
export default Comments;
