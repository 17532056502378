import React from "react";
import "./DynamicModels.scss";
import { withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { getModels } from "../../../redux/actions/modelsActions";
import { IAppState } from "../../../redux/states/state";

interface Props extends RouteComponentProps {
  getModels: () => void;
}

class DynamicModels extends React.Component<Props> {
  componentDidMount(): void {
    this.props.getModels();
  }

  render() {
    return <div></div>;
  }
}

const mapStateToProps = (state: IAppState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getModels: () => getModels(),
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DynamicModels)
);
