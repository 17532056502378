import React, { ReactElement } from "react";
import { CalculationView } from "../../components/core/lists/calculation-view/CalculationView";
import { ChangeAction } from "../changes/field-change";
import { IConstraint } from "../constraint";
import { Dictionary } from "../types/dictionary";
import { IViewField } from "../view-field";
import { GeneralField } from "./general-field";

export class CalculationField extends GeneralField {
  compareFunction(before: Dictionary, after: Dictionary): ChangeAction {
    return ChangeAction.Unchanged;
  }

  viewRender(value: Dictionary): ReactElement {
    return <CalculationView item={value} field={this.viewField} />;
  }

  stringRender(value: Dictionary, viewFields?: IViewField[]): string {
    return "";
  }

  tagRender(value: Dictionary, onRemove: (id: number) => void): ReactElement {
    return this.viewRender(value);
  }

  emptyConstraint(fieldName: string): IConstraint {
    return {
      type: "Null",
      field: fieldName,
    } as IConstraint;
  }

  valueConstraint(fieldName: string, value: Dictionary) {
    return [
      {
        type: "Eq",
        field: fieldName,
        value: value,
      } as IConstraint,
    ];
  }

  isEmpty(value: Dictionary) {
    return !value;
  }
}
