import {
  LOAD_REFERENCE,
  LOAD_REFERENCES,
  OPEN_REFERENCE_FORM,
  SELECT_REFERENCE_ITEM,
  SET_FILTER_REFERENCE,
  SET_REFERENCE_TOTAL_COUNT,
  LOADING_SEARCH,
} from "../actions/referencesActions";
import { IStateReferences } from "../states/state-references";

const INITIAL_STATE: IStateReferences = {
  references: new Map<string, any[]>(),
  totalCount: 0,
  formIsOpen: false,
  selectedItem: null,
  filterReference: [],
  currentReference: null,
  loadingSearch: false,
  updateFieldCall: () => null
};

const referencesReducer = (
  state = INITIAL_STATE,
  action: { type: string; payload: any }
) => {
  const prevState = state;
  switch (action.type) {
    case OPEN_REFERENCE_FORM:
      return {
        ...prevState,
        currentReference: action.payload.refName,
        formIsOpen: action.payload.isOpen,
        selectedItem: action.payload.editItem,
        updateFieldCall: action.payload.updateFieldCall
      };
    case SELECT_REFERENCE_ITEM:
      return {
        ...prevState,
        selectedItem: action.payload,
      };
    case LOAD_REFERENCE:
      prevState.references.set(action.payload.name, action.payload.items);
      return {
        ...prevState,
        references: prevState.references,
      };
    case LOAD_REFERENCES:
      action.payload.forEach((item: string) => {
        prevState.references.set(item, []);
      });
      return {
        ...prevState,
        references: new Map(prevState.references),
      };
    case SET_REFERENCE_TOTAL_COUNT:
      return {
        ...prevState,
        totalCount: action.payload,
      };
    case SET_FILTER_REFERENCE:
      return {
        ...prevState,
        filterReference: action.payload,
      };
    case LOADING_SEARCH:
      return {
        ...prevState,
        loadingSearch: action.payload,
      };
    default:
      return prevState;
  }
};

export default referencesReducer;
