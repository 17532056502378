import { Button, Popover } from "@blueprintjs/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { checkFilterConstraintsItem } from "../../../../../helpers/check-filter-constraints";
import { IConstraint } from "../../../../../models/constraint";
import { IFormField } from "../../../../../models/fields/form-field";
import { DictionaryArray, DictionaryObject } from "../../../../../models/types/dictionary";
import { findAll } from "../../../../../redux/api/entities-api";
import ReferenceView from "../../../lists/reference-view/ReferenceView";
import "./HelpInfo.scss";

export const HelpInfo = (props: IFormField) => {
  const { f, selectedItem } = props;
  const [info, setInfo] = useState<DictionaryArray>([]);
  const { t } = useTranslation();

  const search = () => {
    setInfo([]);
    const filters = checkFilterConstraintsItem(f, selectedItem as DictionaryObject);
    const constraints: IConstraint[] = filters || [];
    findAll(f.model, constraints).then((res) => {
      setInfo(res.data || []);
    });
  };

  return (
    <Popover minimal>
      <Button icon="info-sign" text={t(f.label || "")} onClick={search} />
      <ul className="help-info">
        {info.map(i => <li key={(i as DictionaryObject)['id'] as string}>
          <ReferenceView field={f} item={i as DictionaryObject} viewArray={f.referenceView || []} />
        </li>)}
      </ul>
    </Popover>)
};

export default HelpInfo;
