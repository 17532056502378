import axios from "axios";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { ENTITIES_BACKEND } from "../../backend-connection";
import { ISequenceState } from "../../models/sequence-state";
import { LOAD_TRY_VALUES } from "../constants/sequences";
import { IAppState } from "../states/state";
import { showError } from "./errorsActions";

export const LOADING_TRY_VALUE = "LOADING_TRY_VALUE";

export function setTryValue(seqName: string, tryValue?: ISequenceState) {
  return {
    type: LOAD_TRY_VALUES,
    payload: {
      name: seqName,
      value: tryValue,
    },
  };
}

export function nextValue(sequence: string) {
  return axios(`${ENTITIES_BACKEND}/nextValue?sequence=${sequence}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    return response;
  });
}

export const tryValue = (sequence: string): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
    return axios(`${ENTITIES_BACKEND}/tryValue?sequence=${sequence}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch(setTryValue(sequence, response.data));
      })
      .catch((err) => dispatch(showError("ERROR.AutoNumber")));
  };
