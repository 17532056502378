import React from "react";
import { useTranslation } from "react-i18next";
import "./ListCount.scss";

export const ListCount = (props: { totalCount: number }) => {
  const { t } = useTranslation();
  const { totalCount } = props;

  return (
    <div className="list-amount">
      <span>
        {t("UI.ListAmount")}: {totalCount}
      </span>
    </div>
  );
};

export default ListCount;
