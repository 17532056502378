import { Button } from "@blueprintjs/core";
import { Row } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { getFormValues } from "redux-form";
import { ISequenceState } from "../../../../../models/sequence-state";
import { ITransformation } from "../../../../../models/transformation";
import {
  DictionaryObject,
  Selectable
} from "../../../../../models/types/dictionary";
import { IViewModel } from "../../../../../models/view-model";
import { relateCard } from "../../../../../redux/actions/cardActions";
import { IAppState } from "../../../../../redux/states/state";
import SimpleSelect from "../../controls/simple-select/SimpleSelect";
import CreateCard from "../create-card/CreateCard";
import SearchItem from "../search-item/SearchItem";
import { ITransformParams } from "../transform-params";
import "./Transformation.scss";

const filterTransformations = (
  transformations: ITransformation[],
  cards: Map<string, DictionaryObject>
) => {
  return transformations.filter(
    (tr: ITransformation) => (tr.card === "SimpleCard" || !cards.get(tr.card)) && tr.embedded === false
  );
};

interface Props extends WithTranslation {
  transformations: ITransformation[];
  model: IViewModel;
  formName: string;
  models: Map<string, IViewModel>;
  cards: Map<string, DictionaryObject>;
  tryValue: Map<string, ISequenceState>;
  parentFormValues: any;
  relateCard: (params: ITransformParams) => void;
  getTryValues: (sequences: string[]) => void;
  updateCallback?: () => void;
}

interface State {
  filterTransformations: ITransformation[];
  selectedTransformation: ITransformation | null;
  selectedModel: IViewModel | undefined;
  currentModel: IViewModel | undefined;
}

class Transformation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filterTransformations: [],
      selectedTransformation: null,
      selectedModel: undefined,
      currentModel: undefined
    };
  }

  componentDidMount() {
    this.initializeTransformations();
  }

  initializeTransformations() {
    const { transformations, models, cards } = this.props;
    const filteredTransformations = filterTransformations(
      transformations,
      cards
    );
    const selectedTransformation =
      filteredTransformations && filteredTransformations.length > 0
        ? filteredTransformations[0]
        : null;
    this.setState({
      filterTransformations: filteredTransformations,
      selectedTransformation: selectedTransformation,
      selectedModel: selectedTransformation
        ? models.get(selectedTransformation?.toName)
        : undefined,
    });
  }

  onSelectTransformation = (transformation: ITransformation) => {
    this.setState({
      selectedTransformation: transformation,
      selectedModel: this.props.models.get(transformation?.toName)
    });
  };

  renderView = (item: Selectable) => {
    if (!item) {
      return "";
    }
    return this.props.t("Transformations." + (item as ITransformation).name);
  };

  render() {
    const { t, model, formName, updateCallback } = this.props;
    const {
      filterTransformations,
      selectedTransformation,
      selectedModel
    } = this.state;

    if (!selectedTransformation || !selectedModel) {
      return <div></div>;
    }

    return (
      <div className="transformation">
        <Row>
          <div className="transformation-label">{t("UI.ProcessRelate")}</div>
          {filterTransformations.length > 1 && <SimpleSelect
            items={filterTransformations}
            renderView={this.renderView}
            onChange={this.onSelectTransformation}
            component={
              <Button
                rightIcon="caret-down"
                text={
                  selectedTransformation
                    ? t("Transformations." + selectedTransformation.name)
                    : "(" + t("UI.NoSelection") + ")"
                }
              />
            }
          />}
          {selectedTransformation && (
            <div className="buttons-actions">
              {!selectedTransformation.reverse && (
                <CreateCard
                  selectedTransformation={selectedTransformation}
                  selectedModel={selectedModel}
                  currentModel={model}
                  formName={formName}
                  updateCallback={updateCallback}
                />
              )}
              <SearchItem
                selectedTransformation={selectedTransformation}
                selectedModel={selectedModel}
                currentModel={model}
                formName={formName}
                updateCallback={updateCallback}
              />
            </div>
          )}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState, initialProps: any) => ({
  models: state.modelsReducer.models,
  tryValue: state.sequencesReducer.tryValue,
  cards: state.entitiesReducer.cards,
  parentFormValues: getFormValues(initialProps.formName)(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      relateCard: (tranformParams: ITransformParams) =>
        relateCard(tranformParams),
    },
    dispatch
  );

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Transformation)
);
