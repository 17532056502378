import { IChange } from "./changes-history";
import { IViewModel } from "../view-model";
import { TFunction } from "i18next";
import React from "react";
import {
  getFormName,
  IChangesDetails,
  readInfoPrint,
  renderPrintDetails,
} from "./changes-factory";
import { IFieldRead } from "./field-change";
import { IApiPrintInfo } from "./api-print-info";

export class ChangesPrint implements IChangesDetails {
  info: IFieldRead[] = [];
  formName = "";

  constructor(change: IChange, model?: IViewModel) {
    this.info = readInfoPrint(change.after as IApiPrintInfo, model);
    this.formName = getFormName(change.after as IApiPrintInfo);
  }

  renderInfo(t: TFunction): React.ReactNode {
    return null;
  }

  renderChanges(t: TFunction): React.ReactNode {
    return renderPrintDetails(this.formName, this.info, t);
  }

  getShortDescription(t: TFunction): React.ReactNode {
    return <span>{t("ChangesShortDesc.Print")}</span>;
  }
}
