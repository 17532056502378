import { IConstraint } from "./../constraint";
import { Dictionary } from "./../types/dictionary";
import { IViewField } from "../view-field";
import { Views } from "../enums/views";

export class FormField implements IFormField {
  public f: IViewField;
  public fieldName: string;
  public selectedItem: Dictionary;
  public view: Views;
  public showLabel: boolean;
  public hidden: boolean;
  public formName: string;
  public isDraft: boolean;
  public listeners?: Map<string, IConstraint>;
  public onChange?: (e: Dictionary) => void;
  public key: string;
  public showEmpty?: boolean;
  public autoChangable?: boolean;

  constructor(
    f: IViewField,
    fieldName: string,
    selectedItem: Dictionary,
    view: Views,
    showLabel: boolean,
    hidden: boolean,
    formName: string,
    isDraft: boolean,
    autoChangable?: boolean,
    listeners?: Map<string, IConstraint>,
    onChange?: (e: Dictionary) => void,
    showEmpty?: boolean
  ) {
    this.f = f;
    this.fieldName = fieldName;
    this.selectedItem = selectedItem;
    this.view = view;
    this.showLabel = showLabel;
    this.hidden = hidden;
    this.formName = formName;
    this.autoChangable = autoChangable;
    this.listeners = listeners;
    this.onChange = onChange;
    this.key = f.name;
    this.showEmpty = showEmpty;
    this.isDraft = isDraft;
  }
}

export interface IFormField {
  f: IViewField;
  fieldName: string;
  selectedItem: Dictionary;
  view: Views;
  showLabel: boolean;
  hidden: boolean;
  formName: string;
  isDraft: boolean;
  autoChangable?: boolean;
  listeners?: Map<string, IConstraint>;
  onChange?: (e: Dictionary) => void;
  showEmpty?: boolean;
}
