import React, { useState } from "react";
import "./PrintButtons.scss";
import { useTranslation } from "react-i18next";
import { Button } from "@blueprintjs/core";
import { DictionaryObject } from "../../../../models/types/dictionary";
import { useDispatch } from "react-redux";
import { ModelType } from "../../../../models/enums/model-type";
import { IPrintForm, ITab, IViewModel } from "../../../../models/view-model";
import { getState } from "../../../../helpers/entities";
import { extendedPrintApi } from "../../../../redux/actions/printActions";
import { hasUserPermit } from "../../../../redux/actions/authorizationActions";
import { wordIcon } from "../../../../custom-icons/custom-icon";
import { getDeepValue } from "../../../../helpers/get-deep-value";
import PrintCount from "../print-count/PrintCount";

export const PrintButtons = (props: {
  selectedItem: DictionaryObject;
  type: ModelType;
  model: IViewModel;
  tab: ITab;
  analysis?: boolean;
}) => {
  const { t } = useTranslation();
  const { selectedItem, model, tab, type, analysis } = props;
  const dispatch = useDispatch();
  const [controlPf, setControlPf] = useState<IPrintForm | null>(null);

  if (
    type !== ModelType.Entity ||
    !model ||
    !tab.printForms ||
    !tab.printForms.length
  ) {
    return null;
  }


  const getPrintFormTitle = (pf: IPrintForm) => {
    const identifier = pf.identifier
      ? " " + getDeepValue(selectedItem, pf.identifier)
      : "";
    return t(model.name) + " " + t("PrintForms." + pf.code) + identifier;
  };

  const printFunc = (pf: IPrintForm | null, printReason?: string) => {
    if(!pf) return null;
    dispatch(
      extendedPrintApi(
        pf.code,
        model.dbname,
        {
          rule: "And",
          constraints: [
            {
              field: "id",
              type: 6,
              value: selectedItem["id"]
                ? selectedItem["id"].toString()
                : "0",
            },
          ],
          nodes: [],
        },
        getPrintFormTitle(pf),
        pf.printAction,
        selectedItem["id"]
                ? selectedItem["id"].toString()
                : "0",
        printReason,
        selectedItem["PrintCount"]
                ? selectedItem["PrintCount"].toString()
                : "0",
      )
    );
  }

  if (selectedItem) {
    const state = getState(selectedItem);
    const statePF = state
      ? tab.printForms.find((pf) => pf.state === state)
      : tab.printForms[0];
    if (statePF && statePF.forms && statePF.forms.length) {
      const allowedForms = statePF.forms.filter(
        (sf: IPrintForm) => ((!analysis && !sf.analysis) || (analysis && sf.analysis)) && (!sf.condition || !!eval(sf.condition)(selectedItem)) && (!sf.permit || !!dispatch(hasUserPermit(sf.permit)))
      );
      return (
        <span>
          {allowedForms.map((pf: IPrintForm) => {
            return (
              <span key={pf.code}>
                <Button
                  icon={wordIcon}
                  onClick={() => {
                    if(pf.countControl) {
                      setControlPf(pf);
                    } else {
                      printFunc(pf);
                    }
                  }}
                >
                  {t("PrintForms." + pf.code)}
                </Button>
              </span>
            );
          })}
          <PrintCount 
            isOpen={!!controlPf} 
            close={() => setControlPf(null)} 
            printFunc={(printReason: string) => printFunc(controlPf, printReason)}
          ></PrintCount>
        </span>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export default PrintButtons;
