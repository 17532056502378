import React from "react";
import "./TextEditor.scss";

interface Props {
  value: any;
  onChange: (value: any) => void;
  onKeyDown: (value: any) => void;
}

interface State {}

class TextEditor extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  _input: any;

  componentDidMount() {
    this._input.focus();
  }

  handleChange(e: any) {
    this.props.onChange(e.target.value);
  }

  render() {
    const { value, onKeyDown } = this.props;
    return (
      <input
        ref={(input) => {
          this._input = input;
        }}
        type="text"
        className="text-editor"
        value={value}
        onChange={this.handleChange}
        onKeyDown={onKeyDown}
      />
    );
  }
}

export default TextEditor;
