import { ContextMenu, Menu, MenuItem } from "@blueprintjs/core";
import React from "react";
import ReactDataSheet from "react-datasheet";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IEmployee } from "../../../../../../models/employee";
import { FieldFactory } from "../../../../../../models/fields/field-factory";
import { DictionaryObject } from "../../../../../../models/types/dictionary";
import { IViewField } from "../../../../../../models/view-field";
import { IViewModel } from "../../../../../../models/view-model";
import { IAppState } from "../../../../../../redux/states/state";
import "./DataSheet.scss";

interface Props extends WithTranslation{
  model: string;
  models: Map<string, IViewModel>;
  reference: DictionaryObject[];
  currentEmployee: IEmployee | null;
  columns: IViewField[];
  data: any[][];
  onChange: CallableFunction;
  onSelected: CallableFunction;
  selections: boolean[];
}

interface RowProps extends ReactDataSheet.RowRendererProps<any> {
  selected: boolean;
  onSelectChanged: CallableFunction;
}

const RowRenderer = (props: RowProps) => {
  const { children, row, selected, onSelectChanged } = props;
  return (
    <tr className="inner-datasheet__row">
      <td style={{ paddingLeft: "4px" }}>
        <input
          type="checkbox"
          checked={selected}
          onChange={(e) => onSelectChanged(row, e.target.checked)}
        />
      </td>
      {children}
    </tr>
  );
};

class DataSheet extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.valueViewer = this.valueViewer.bind(this);
  }

  handleSelectChanged = (index: number, selected: boolean) => {
    const selections = [...this.props.selections];
    selections[index] = selected;
    this.props.onSelected(selections);
  };
  handleSelectAllChanged = (selected: boolean) => {
    const selections = this.props.selections.map((s) => selected);
    this.props.onSelected(selections)
  };
  renderRow(props: ReactDataSheet.RowRendererProps<any>) {
    const { row, cells, ...rest } = props;
    return (
      <RowRenderer
        selected={this.props.selections[props.row]}
        onSelectChanged={this.handleSelectChanged}
        row={row}
        cells={cells}
        {...rest}
      />
    );
  }

  valueViewer(props: ReactDataSheet.ValueViewerProps<any, any>) {
    const { value, row, col } = props;
    if (!value) {
      return <span />;
    }
    const field = this.props.columns[col];
    if (!field) {
      return <span />;
    }
    React.useEffect(() => {
      if (field.textInputs) {
        const rightClickMe = document.querySelector(
          `#right-click-me-${row}-${col}`
        ) as HTMLElement;
        rightClickMe.oncontextmenu = (e: MouseEvent) => {
          e.preventDefault();
          const menuElement = field.textInputs
            ? field.textInputs.map((el) =>
              React.createElement(MenuItem, {
                onClick: (
                  c:
                    | React.MouseEvent<HTMLElement>
                    | React.MouseEvent<HTMLAnchorElement>
                ) => {
                  let data = [...this.props.data];
                  data[row][col].value = value + el;
                  this.props.onChange(data);
                },
                text: el,
              })
            )
            : [];

          const menu = React.createElement(Menu, {}, ...menuElement);

          ContextMenu.show(menu, { left: e.clientX, top: e.clientY }, () => {
            // menu was closed; callback optional
          });
        };
      }
    });

    const generalField = new FieldFactory(field).createField();
    return (
      <div style={{ width: "100%" }} id={`right-click-me-${row}-${col}`}>
        {generalField.viewRender(value)}
      </div>
    );
  }

  onCellsChanged = (changes: ReactDataSheet.CellsChangedArgs<any, any>) => {
    let data = [...this.props.data];
    changes.forEach(({ cell, row, col, value }) => {
      data[row][col].value = value;
      data[row][3].value = this.props.currentEmployee;
    });
    this.props.onChange(data);
  };

  public render() {
    const { columns, selections } = this.props;
    const data = this.props.data.map(row => row.map(cell => ({ ...cell, valueViewer: this.valueViewer })))
    const { t } = this.props;
    return (
      <ReactDataSheet
        data={data}
        valueRenderer={(cell: any) => {
          return cell.value;
        }}
        rowRenderer={(props) => this.renderRow(props)}
        sheetRenderer={(props) => {
          return (
            <table className={props.className + " inner-datasheet"}>
              <thead>
                <tr>
                  <th
                    key="select"
                    className="inner-datasheet__header"
                    style={{ width: "20px" }}
                  >
                    <input
                      type="checkbox"
                      checked={selections.every((s) => s)}
                      onChange={(e) =>
                        this.handleSelectAllChanged(e.target.checked)
                      }
                    />
                  </th>
                  {columns.map((col: IViewField) => (
                    <th
                      key={col.name}
                      className="inner-datasheet__header"
                      style={{ width: "25%" }}
                    >
                      {t(col.name).toUpperCase()}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>{props.children}</tbody>
            </table>
          );
        }}
        onCellsChanged={this.onCellsChanged}
      />
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  models: state.modelsReducer.models,
  currentEmployee: state.authorizationReducer.currentEmployee,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(DataSheet)
);
