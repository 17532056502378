import React from "react";
import "./ValidMessages.scss";
import { useTranslation } from "react-i18next";

export const ValidMessages = (props: { text: string }) => {
  const { t } = useTranslation();
  const { text } = props;

  return <div className="valid-messages">{t("ERROR." + text)}</div>;
};
export default ValidMessages;
