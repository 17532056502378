import React from "react";
import "./StateView.scss";
import { useTranslation } from "react-i18next";
import { Icon } from "@blueprintjs/core";

export const StateView = (props: { state: string }) => {
  const { t } = useTranslation();
  const { state } = props;

  return (
    <span className="state-view">
      <Icon icon="dot" />
      <span className="state">{t("States." + state)}</span>
    </span>
  );
};
export default StateView;
