import axios from "axios";
import { CHANGES_BACKEND } from "../../backend-connection";
import { Action } from "redux";
import { IChange } from "../../models/changes/changes-history";
import { IChangeRequest } from "../../models/changes/change-request";
import { ThunkAction } from "redux-thunk";
import { IAppState } from "../states/state";

export const LOAD_CHANGES_HISTORY = "LOAD_CHANGES_HISTORY";
export const LOADING_CHANGES_HISTORY = "LOADING_CHANGES_HISTORY";
export const SET_CHANGE_FILTER = "SET_CHANGE_FILTER";
export const SET_CHANGE_TOTAL_COUNT = "SET_CHANGE_TOTAL_COUNT";
export const LOAD_CHANGES_DETAIL = "LOAD_CHANGES_DETAIL";
export const CLEAR_CHANGES = "CLEAR_CHANGES";

export function loadingChangesHistory(loading: boolean) {
  return {
    type: LOADING_CHANGES_HISTORY,
    payload: loading,
  };
}

export function loadChangesHistory(changes: IChange[]) {
  return {
    type: LOAD_CHANGES_HISTORY,
    payload: changes,
  };
}

export function setChangeTotalCount(totalCount: number) {
  return {
    type: SET_CHANGE_TOTAL_COUNT,
    payload: totalCount,
  };
}

export function setChangeFilter(changeFilter: IChange) {
  return {
    type: SET_CHANGE_FILTER,
    payload: changeFilter,
  };
}

export function loadChangesDetail(change: IChange | null) {
  return {
    type: LOAD_CHANGES_DETAIL,
    payload: change,
  };
}

export function clearChanges() {
  return {
    type: CLEAR_CHANGES,
  };
}

export const getChangesHistory = (
  filter: IChangeRequest,
  page: number,
  pageSize: number
): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  dispatch(clearChanges());
  dispatch(loadingChangesHistory(true));
  dispatch(loadChangesHistory([]));
  return axios(
    `${CHANGES_BACKEND}/filter?limit=` + pageSize + "&offset=" + page * pageSize,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(filter),
    }
  )
    .then((response) => {
      dispatch(loadChangesHistory(response.data));
      dispatch(getChangesHistoryCount(filter));
      dispatch(loadingChangesHistory(false));
    })
    .catch((err) => {
      dispatch(loadingChangesHistory(false));
    });
};

export const getChangesHistoryCount = (filter: IChangeRequest): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  return axios(`${CHANGES_BACKEND}/count`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(filter),
  })
    .then((response) => {
      dispatch(setChangeTotalCount(response.data));
    })
    .catch((err) => {
      dispatch(setChangeTotalCount(0));
    });
};

export function getChangesHistoryById(id: number) {
  return axios(`${CHANGES_BACKEND}/?id=` + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).catch(() => {
    return null;
  });
}

export const getChangesDetail = (id: number): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  getChangesHistoryById(id).then((result) => {
    if (result && result.data) {
      const change = result.data;
      change.before = result.data.previous
        ? JSON.parse(result.data.previous)
        : null;

      if (result.data.output) {
        change.after = JSON.parse(result.data.output);
      } else if (result.data.input) {
        change.after = JSON.parse(result.data.input);
      }

      dispatch(loadChangesDetail(result.data));
    }
  });
};