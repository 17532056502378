import { IViewModel } from "./../../models/view-model";
import { IViewField } from "./../../models/view-field";
import { Action } from "redux";
import { ITransformParams } from "../../components/core/forms/transformations/transform-params";
import { DictionaryObject } from "../../models/types/dictionary";
import { showError } from "./errorsActions";
import { relateProcessCard } from "./processCardActions";
import { relateSimpleCard } from "./simpleCardActions";
import { createEntity, selectItem, updateEntity } from "./entitiesActions";
import { refreshItem } from "./newEntitiesActions";
import { ThunkAction } from "redux-thunk";
import { IAppState } from "../states/state";

export const relateCard = (params: ITransformParams):ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
    const {
      existing,
      result,
      selectedItem,
      currentModel,
      selectedModel,
      transformation,
      cardModel,
      cards,
      showSuccessMessage,
      updateCallback,
    } = params;
    if (!selectedItem) {
      dispatch(showError("ERROR.Relating"));
      return;
    }

    if (!currentModel || !selectedModel) {
      dispatch(showError("ERRORS.ModelLoading"));
      return;
    }

    if (transformation.card === selectedModel.dbname) {
      dispatch(
        relateProcessCard({
          existing,
          result,
          selectedItem,
          currentModel,
          selectedModel,
          transformation,
          cardModel,
          cards,
          showSuccessMessage,
          updateCallback,
        })
      );
    } else {
      dispatch(
        relateSimpleCard({
          existing,
          result,
          selectedItem,
          currentModel,
          selectedModel,
          transformation,
          cardModel,
          cards,
          showSuccessMessage,
          updateCallback,
        })
      );
    }
    dispatch(selectItem({ ...(fillCurrentItem(result as DictionaryObject, currentModel, selectedModel, selectedItem as DictionaryObject)) }));
  };

export const saveCards = (
  selectedItem: DictionaryObject,
  currentModel: IViewModel,
  models: Map<string, IViewModel>,
  cards: Map<string, DictionaryObject>
):ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => { 
    const updateFunc = () => {
      dispatch(
        refreshItem(
          currentModel.dbname,
          (selectedItem as DictionaryObject)["id"]?.toString() || "0"
        )
      );
    };

    if (!currentModel || !selectedItem) {
      return;
    }

    currentModel.fields.forEach((vf: IViewField) => {
      if (vf.isCard) {
        const cardData = cards.get(vf.model);
        const cardModel = models.get(vf.model);

        if (!cardData || !cardModel) {
          return;
        }

        const reflectField = cardModel.fields.find(
          (vf: IViewField) => vf.model === currentModel.dbname
        );
        if (!selectedItem || !reflectField || !cardData) {
          return;
        }
         
        fillCardField(cardData, reflectField, selectedItem);

        if (cardData["id"] === 0) {
          dispatch(createEntity(vf.model, cardData, [], cardData, updateFunc));
        } else {
          dispatch(updateEntity(vf.model, cardData, [], cardData, updateFunc));
        }
      }
    });
  };
  
export const fillCardField = (
  cardData: DictionaryObject,
  reflectField: IViewField,
  selectedItem: DictionaryObject
  ) => {
  if (reflectField.many) {
    (cardData as DictionaryObject)[reflectField.name] =
      (cardData as DictionaryObject)[reflectField.name] || [];
    ((cardData as DictionaryObject)[
      reflectField.name
    ] as DictionaryObject[]).push({
      ...(selectedItem as DictionaryObject)
    });
  } else {
    ((cardData as DictionaryObject)[
      reflectField.name
    ] as DictionaryObject) = {
      ...(selectedItem as DictionaryObject)
    };
  }
};

const fillCurrentItem = (
  cardData: DictionaryObject,
  currentModel: IViewModel,
  cardModel: IViewModel,
  selectedItem: DictionaryObject
  ) => {

  const currentField = currentModel.fields.find(
    (vf: IViewField) => vf.model === cardModel.dbname
  );

  if(!currentField) {
    return selectedItem;
  }

  if (currentField.many) {
    (selectedItem as DictionaryObject)[currentField.name] =
      (selectedItem as DictionaryObject)[currentField.name] || [];
    ((selectedItem as DictionaryObject)[
      currentField.name
    ] as DictionaryObject[]).push({
      ...(cardData as DictionaryObject)
    });
  } else {
    ((selectedItem as DictionaryObject)[
      currentField.name
    ] as DictionaryObject) = {
      ...(cardData as DictionaryObject)
    };
  }
  return selectedItem;
};
