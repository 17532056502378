import {
  IConstraintTreeChanges,
  IConstraintChanges,
} from "./../models/changes/constraint-changes";

export const getFieldsFromTree = (
  constraintTree: IConstraintTreeChanges,
  fields: string[]
) => {
  if (
    !constraintTree ||
    (!constraintTree.Constraints && !constraintTree.Nodes)
  ) {
    return;
  }

  if (constraintTree.Constraints) {
    constraintTree.Constraints.forEach((c: IConstraintChanges) => {
      fields.push(c.Field);
    });
  }

  if (constraintTree.Nodes) {
    constraintTree.Nodes.forEach((node: IConstraintTreeChanges) => {
      getFieldsFromTree(node, fields);
    });
  }
};
