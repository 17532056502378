import { Icon } from "@blueprintjs/core";
import React, { ReactElement } from "react";
import { ChangeAction } from "../changes/field-change";
import { IConstraint } from "../constraint";
import { Dictionary } from "../types/dictionary";
import { GeneralField } from "./general-field";

export class BooleanField extends GeneralField {
  compareFunction(before: Dictionary, after: Dictionary): ChangeAction {
    return (before as boolean) === (after as boolean)
      ? ChangeAction.Unchanged
      : ChangeAction.Updated;
  }

  viewRender(value: Dictionary): ReactElement {
    return value ? <Icon icon="tick" /> : <span />;
  }

  stringRender(value: Dictionary): string {
    return value ? "true" : "false";
  }

  tagRender(value: Dictionary, onRemove: (id: number) => void): ReactElement {
    return this.viewRender(value);
  }

  emptyConstraint(fieldName: string): IConstraint {
    return {
      type: "Null",
      field: fieldName,
    } as IConstraint;
  }

  valueConstraint(fieldName: string, value: Dictionary) {
    return [
      {
        type: "Eq",
        field: fieldName,
        value: value,
      } as IConstraint,
    ];
  }

  isEmpty(value: Dictionary) {
    return !value;
  }
}
