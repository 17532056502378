import { createAction } from "typesafe-actions";
import { IPermit } from "../../models/permit";
import { IRole, IRoleWithPermits } from "../../models/role";
import { roles } from "../constants";

export const selectRole = createAction(
  roles.SELECT_ROLE,
  (resolve) => (role: IRoleWithPermits | null) => resolve({ role })
);

export const getPermissions = createAction(roles.GET_PERMISSIONS);

export const getPermissionsByRole = createAction(
  roles.GET_PERMISSIONS_BY_ROLE,
  (resolve) => (roleId: number) => resolve({ roleId })
);

export const loadRolePermissions = createAction(
  roles.LOAD_ROLE_PERMISSIONS,
  (resolve) => (permissions: IPermit[]) => resolve({ permissions })
);

export const loadingRolePermissions = createAction(
  roles.LOADING_ROLE_PERMISSIONS,
  (resolve) => (loading: boolean) => resolve({ loading })
);

export const loadPermits = createAction(
  roles.LOAD_PERMITS,
  (resolve) => (permits: IPermit[]) => resolve({ permits })
);

export const createRole = createAction(
  roles.CREATE_ROLE,
  (resolve) => (role: IRoleWithPermits) => resolve({ role })
);

export const modifyRole = createAction(
  roles.MODIFY_ROLE,
  (resolve) => (role: IRoleWithPermits) => resolve({ role })
);

export const removeRole = createAction(
  roles.REMOVE_ROLE,
  (resolve) => (roleId: number) => resolve({ roleId })
);

export const getRoles = createAction(roles.GET_ROLES);

export const loadRoles = createAction(
  roles.LOAD_ROLES,
  (resolve) => (roles: IRole[]) => resolve({ roles })
);

export const loadingRoles = createAction(
  roles.LOADING_ROLES,
  (resolve) => (loading: boolean) => resolve({ loading })
);

export const createDublicate = createAction(
  roles.IS_DUBLICATE_CREATION,
  (resolve) => (duplicate: boolean) => resolve({ duplicate })
);

export const clearPermits = createAction(roles.CLEAR_PERMITS);
