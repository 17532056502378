import { IPagingOptions } from "./../../models/paging-options";
import { Epic } from "redux-observable";
import { IAppState } from "../states/state";
import { ActionsType, actions } from "../store";
import * as API from "../api";
import { isActionOf } from "typesafe-actions";
import { from, of } from "rxjs";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { IConstraint } from "../../models/constraint";
import {
  filterContraintToTree,
  filterToConstraint,
} from "../../helpers/filter-to-constraint";
import { IDefaultSorting } from "../../models/default-sorting";
import { getJoinedConditions } from "../../helpers/dynamic-constraints";

export const updateAcceptEntityEpic: Epic<
  ActionsType,
  ActionsType,
  IAppState,
  typeof API
> = (action$, store, service) =>
  action$.pipe(
    filter(isActionOf(actions.entitiesActions.updateAcceptEntity)),
    mergeMap((action) =>
      from(
        service.entitiesApi.updateEntity(
          action.payload.modelName,
          action.payload.entity
        )
      ).pipe(
        catchError((error) =>
          of(actions.errorsActions.showError("ERROR.UpdateEntity"))
        ),
        map((response) =>
          actions.entitiesActions.getEntities(action.payload.refreshModel)
        )
      )
    )
  );

export const getEntitieEpic: Epic<
  ActionsType,
  ActionsType,
  IAppState,
  typeof API
> = (action$, state$, service) =>
  action$.pipe(
    filter(isActionOf(actions.entitiesActions.getEntities)),
    mergeMap((action) => {
      const { filter } = state$.value.entitiesReducer;
      const { models } = state$.value.modelsReducer;

      const statesFilter = state$.value.modelsReducer.statesFilter;

      filter["state"] = filter["state"] ? filter["state"] :
        (statesFilter && statesFilter.length ? statesFilter : undefined);
        
      const model = models.get(action.payload.modelName);

      const filterConstraint: IConstraint[] = filterToConstraint(
        filter,
        "",
        models,
        action.payload.modelName
      );
      const tree = filterContraintToTree(filterConstraint);

      let sortingQuery = "";

      if (model) {
        const sorting: IDefaultSorting[] = model.defaultSorting
          ? model.defaultSorting
          : [];
        sorting.forEach((sd: IDefaultSorting) => {
          sortingQuery += `&page.sortColumn=${sd.field}&page.sortDirection=${sd.direction}`;
        });
      }

      const joinedConstraints = getJoinedConditions(
        state$.value.entitiesReducer,
        tree
      );

      const pagingOptions: IPagingOptions = {
        page: 0,
        pageSize: 15,
        showDeleted: false,
      };

      return from(
        service.entitiesApi.findAllV2(
          action.payload.modelName,
          joinedConstraints,
          sortingQuery,
          pagingOptions
        )
      ).pipe(
        catchError((error) =>
          of(actions.errorsActions.showError("ERROR.GetEntities"))
        ),
        map((response) =>
          actions.entitiesActions.loadEntities(response.data || [])
        )
      );
    })
  );

export const getRelatedCardEpic: Epic<
  ActionsType,
  ActionsType,
  IAppState,
  typeof API
> = (action$, state$, service) =>
  action$.pipe(
    filter(isActionOf(actions.entitiesActions.getRelatedCard)),
    mergeMap((action) => {
      const idConstraints: IConstraint[] = [
        {
          field: "id",
          type: "Eq",
          value: action.payload.id,
        },
      ];

      return from(
        service.entitiesApi.findAll(action.payload.modelName, idConstraints)
      ).pipe(
        catchError((error) =>
          of(actions.errorsActions.showError("ERROR.GetRelatedCard"))
        ),
        map((response) =>
          actions.entitiesActions.loadRelatedCard(
            action.payload.modelName,
            response && response.data && response.data.length
              ? response.data[0]
              : null
          )
        )
      );
    })
  );

export const refreshItemEpic: Epic<
  ActionsType,
  ActionsType,
  IAppState,
  typeof API
> = (action$, state$, service) =>
  action$.pipe(
    filter(isActionOf(actions.entitiesActions.refreshItem)),
    mergeMap((action) => {
      const idConstraints: IConstraint[] = [
        {
          field: "id",
          type: "Eq",
          value: action.payload.id,
        },
      ];

      return from(
        service.entitiesApi.findAll(action.payload.modelName, idConstraints)
      ).pipe(
        catchError((error) =>
          of(actions.errorsActions.showError("ERROR.RefreshItem"))
        ),
        map((response) =>
          actions.entitiesActions.loadItem(
            response && response.data && response.data.length
              ? response.data[0]
              : null
          )
        )
      );
    })
  );

export default [
  updateAcceptEntityEpic,
  getEntitieEpic,
  getRelatedCardEpic,
  refreshItemEpic,
];
