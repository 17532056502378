import { createAction } from "typesafe-actions";
import { sequences } from "../constants";
import { ISequenceState } from "./../../models/sequence-state";

export const getNextValues = createAction(
  sequences.GET_NEXT_VALUE,
  (resolve) => (sequence: string) => resolve({ sequence })
);

export const getTryValue = createAction(
  sequences.GET_TRY_VALUE,
  (resolve) => (sequence: string) => resolve({ sequence })
);

export const getTryValues = createAction(
  sequences.GET_TRY_VALUES,
  (resolve) => (sequences: string[], process?: boolean) => resolve({ sequences, process })
);

export const loadingTryValues = createAction(
  sequences.LOADING_TRY_VALUES,
  (resolve) => (loading: boolean, process?: boolean) => resolve({ loading, process })
);

export const loadTryValues = createAction(
  sequences.LOAD_TRY_VALUES,
  (resolve) => (sequences: string[], states: ISequenceState[]) =>
    resolve({ sequences, states })
);

export const saveTryValue = createAction(
  sequences.SAVE_TRY_VALUE,
  (resolve) => (sequence: ISequenceState) => resolve({ sequence })
);

export const saveTryValues = createAction(
  sequences.SAVE_TRY_VALUES,
  (resolve) => (sequences: string[]) => resolve({ sequences })
);

export const clearTryValues = createAction(
  sequences.CLEAR_TRY_VALUES,
  (resolve) => (sequences: string[]) => resolve({ sequences })
);
