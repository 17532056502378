import moment from "moment";

export function GetToday(tryValue, entity, currentEmployee) {
    return moment().utcOffset(0, true).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
}

export function CurrentDepartment(tryValue, entity, currentEmployee) {
    return currentEmployee && currentEmployee.Department;
}

export function TryValue(sequence) {
    return (tryValue, entity, currentEmployee) => {
        const tryV = tryValue.get(sequence);
        if (tryV) { return tryV.value; }
        else return null;
    }
}

export function TryValueIncomingDocNumber(tryValue, entity, currentEmployee) {
    return TryValue("IncomingDocNumber")(tryValue, entity, currentEmployee);
}

export function TryValueInternalDocNumber(tryValue, entity, currentEmployee) {
    return TryValue("InternalDocNumber")(tryValue, entity, currentEmployee);
}

export function TryValueOutgoingDocNumber(tryValue, entity, currentEmployee) {
    return TryValue("OutgoingDocNumber")(tryValue, entity, currentEmployee);
}

export function TryValueInquiryFinanceNumber(tryValue, entity, currentEmployee) {
    return TryValue("InquiryFinanceNumber")(tryValue, entity, currentEmployee);
}

export function TryValueInquiryKadrNumber(tryValue, entity, currentEmployee) {
    return TryValue("InquiryKadrNumber")(tryValue, entity, currentEmployee);
}

export function TryValueClaimDocNumber(tryValue, entity, currentEmployee) {
    return TryValue("ClaimDocNumber")(tryValue, entity, currentEmployee);
}

export function TryValueInfoDocNumber(tryValue, entity, currentEmployee) {
    return TryValue("InfoDocNumber")(tryValue, entity, currentEmployee);
}

export function TryValueApplicationDocNumber(tryValue, entity, currentEmployee) {
    return TryValue("ApplicationDocNumber")(tryValue, entity, currentEmployee);
}

export function TryValueTripDocNumber(tryValue, entity, currentEmployee) {
    return TryValue("TripDocNumber")(tryValue, entity, currentEmployee);
}

export function TryValueKadrCommandNumber(tryValue, entity, currentEmployee) {
    const tryV = tryValue.get("KadrCommandNumber");
    if (tryV) { return 'K' + tryV.value; }
    else return null;
}

export function TryValueGenCommandNumber(tryValue, entity, currentEmployee) {
    const tryV = tryValue.get("GenCommandNumber");
    if (tryV) { return 'Ü' + tryV.value; }
    else return null;
}

export function CurrentEmployee(tryValue, entity, currentEmployee) {
    return currentEmployee;
}

export default {
    GetToday,
    CurrentDepartment,
    TryValue,
    TryValueIncomingDocNumber,
    TryValueInternalDocNumber,
    TryValueOutgoingDocNumber,
    TryValueInquiryFinanceNumber,
    TryValueInquiryKadrNumber,
    TryValueClaimDocNumber,
    TryValueInfoDocNumber,
    TryValueApplicationDocNumber,
    TryValueTripDocNumber,
    CurrentEmployee,
    TryValueKadrCommandNumber,
    TryValueGenCommandNumber
}