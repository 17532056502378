export const validateNote = (values: {
  EkspredNote: string
}) => {
  const errors = {} as {
    EkspredNote: string
  };
  if (!values.EkspredNote) {
    errors.EkspredNote = "Required";
  }
  return errors;
};
