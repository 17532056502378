import React from "react";
import { useDispatch } from "react-redux";
import { change } from "redux-form";
import { Views } from "../../../../../../models/enums/views";
import { IFormField } from "../../../../../../models/fields/form-field";
import ReferenceField from "../../reference-field/ReferenceField";
import { TextField } from "../../text-field/TextField";
import "./Representative.scss";

export const Representative = (props: IFormField) => {
    const { view, formName } = props;
    const dispatch = useDispatch();

    const FormView = () => {
        const changeForm = (e: any) => {
          if(e && typeof e === "object") {
            if(formName.includes('IncomingDocument')) {
              if(e["Company"]) {
                dispatch(change(formName, "Company", e["Company"]))
              }
              if(e["SpecialityAZE"]) {
                dispatch(change(formName, "LegalPersonSpeciality", e["SpecialityAZE"]))
              }
            } else if(formName.includes('OutgoingDocument')) {
              if(e["Company"]) {
                dispatch(change(formName, "SendCompany", e["Company"]))
              }
              if(e["SpecialityAZE"]) {
                dispatch(change(formName, "SendPersonSpeciality", e["SpecialityAZE"]))
              }
            } else if(formName.includes('Contract')) {
              if(e["Company"]) {
                dispatch(change(formName, "Company", e["Company"]))
              }
              if(e["SpecialityAZE"]) {
                dispatch(change(formName, "PersonSpeciality", e["SpecialityAZE"]))
              }
            }
          }
        }
        return <ReferenceField {...props} onChange={changeForm} />
    }

    switch (view) {
        case Views.FILTER:
          return <TextField {...props} />;
        case Views.FORM:
          return FormView();
        case Views.REFERENCE:
          return <span>Text</span>;
        default:
          return <span>Text</span>;
      }
};
export default Representative;
