import CompanyRepresentative from "./CompanyRepresentative.json";
import DepartmentalExecutor from "./DepartmentalExecutor.json";
import DocumentAttachment from "./DocumentAttachment.json";
import DocumentRecipient from "./DocumentRecipient.json";
import ResponsibleExecutor from "./ResponsibleExecutor.json";
import ScanDocument from "./ScanDocument.json";
import Comment from "./Comment.json";

export const properties = [
    CompanyRepresentative as any, 
    DepartmentalExecutor as any, 
    DocumentAttachment as any,
    DocumentRecipient as any,
    ResponsibleExecutor as any,
    ScanDocument as any,
    Comment as any
];
