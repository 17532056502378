import { Col, Row } from "antd";
import React from "react";
import { getState } from "../../../../../helpers/entities";
import { Views } from "../../../../../models/enums/views";
import { FormField } from "../../../../../models/fields/form-field";
import { DictionaryObject } from "../../../../../models/types/dictionary";
import { IViewField } from "../../../../../models/view-field";
import { IConnectedFields, IViewModel } from "../../../../../models/view-model";
import { getFieldsByState } from "../../../../../redux/actions/modelsActions";
import CustomFormField from "../../fields/form-types/FormTypes";
import "./AutoGrid.scss";
import { constructStageValues } from "../../../../../helpers/construct-stage-default-values";
import { requiredByState } from "../../../../../validation/required-state";
import { useSelector } from "react-redux";
import { getFormValues } from "redux-form";

export const renderGrid = (
  gridSize: number,
  fields: IViewField[],
  selectedItem: any,
  formName: string,
  listeners: Map<string, any>,
  model: IViewModel,
  hideId: boolean,
  isDraft: boolean
) => {
  const rows = [];
  let columns: any = [];
  let col = 0;

  for (let i = 0; i < fields.length; i++) {
    col += fields[i].col;

    while (col <= gridSize && i < fields.length) {
      if (model.connectedFields && model.connectedFields.length) {
        let connectedFields: IConnectedFields[] = [];
        model.connectedFields.forEach(f => {
          const connectedFieldsFound = f.filter((el) => el.name === fields[i].name);
          if (connectedFieldsFound && connectedFieldsFound.length) {
            connectedFields = f;
          }
        });

        if (connectedFields && connectedFields.length) {
          const fieldsForDisplay = fields.filter((f) => !!connectedFields.find(el => el.name === f.name));

          fieldsForDisplay.forEach((f) => {
            columns.push(
              <Col key={f.name} span={(24 * f.col) / gridSize}>
                <CustomFormField
                  {...new FormField(
                    f,
                    f.name,
                    selectedItem,
                    Views.FORM,
                    true,
                    hideId && f.name === "id",
                    formName,
                    isDraft,
                    true,
                    listeners
                  )}
                />
              </Col>
            );
          });

          if (fieldsForDisplay && fieldsForDisplay.length) {
            i = i + fieldsForDisplay.length - 1;
          }

          break;
        }
      }
      columns.push(
        <Col key={fields[i].name} span={(24 * fields[i].col) / gridSize}>
          <CustomFormField
            {...new FormField(
              fields[i],
              fields[i].name,
              selectedItem,
              Views.FORM,
              true,
              hideId && fields[i].name === "id",
              formName,
              isDraft,
              true,
              listeners
            )}
          />
        </Col>
      );
      if (i !== fields.length - 1) {
        if (col + fields[i + 1].col > gridSize) {
          break;
        } else {
          if (model.connectedFields && model.connectedFields.length) {
            const connectedFieldsFound = model.connectedFields.find((el) =>
              el.find((f) => f.name === fields[i].name)
            );
            if (connectedFieldsFound && connectedFieldsFound.length) {
              break;
            } else {
              i++;
            }
          } else {
            i++;
            col += fields[i].col;
          }
        }
      } else {
        break;
      }
    }
    rows.push(
      <Row key={fields[i]?.name} className="row-fields" gutter={16}>
        {columns}
      </Row>
    );
    columns = [];
    col = 0;
  }
  return rows;
};

const AutoGrid = (props: {
  selectedItem: DictionaryObject;
  model: any;
  fields: string[];
  formName: string;
  listeners: Map<string, any>;
  hideId: boolean;
  isDraft: boolean;
  pureFrontend?: boolean;
}) => {
  const { model, selectedItem, formName, listeners, fields, hideId, isDraft, pureFrontend } = props;
  const formValues = useSelector((state: any) => getFormValues(formName)(state));
  
  if (!model) return <div></div>;
  const state = getState(selectedItem);
  const fieldsByState = !pureFrontend ? getFieldsByState(
    state ? state : model.defaultState,
    model,
    formValues
  ) : model.fields;
  const renderFields: IViewField[] = [];
  const item = constructStageValues(model, Views.FORM, selectedItem);

  fields.forEach((v) => {
    const foundField = fieldsByState.find((f: IViewField) => f.name === v);
    if (foundField && foundField.readable) {
      foundField.required = requiredByState(
        foundField,
        state ? state : model.defaultState
      );
      renderFields.push(foundField);
    }
  });

  const gridSize = model.grid;

  return (
    <div>
      {renderGrid(
        gridSize,
        renderFields,
        item,
        formName,
        listeners,
        Object.assign({}, model),
        hideId,
        isDraft
      )}
    </div>
  );
};

export default AutoGrid;
