import { IConstraint } from "../models/constraint";
import { ConstraintType } from "../models/enums/constraint-type";
import Moment from "moment";

export const commonFilterToConstraint = (value: any) => {
  const constraintArray = [];
  if(!value || !value.value) return [];
  if (typeof value.value === "object") {
    if (value.value.hasOwnProperty("startDate") && value.value.startDate) {
      constraintArray.push({
        type: ConstraintType.Gte,
        field: value.field,
        value: Moment(value.value.startDate).utc(true).format(),
      } as IConstraint);
    }
    if (value.value.hasOwnProperty("endDate") && value.value.endDate) {
      constraintArray.push({
        type: ConstraintType.Lte,
        field: value.field,
        value: Moment(value.value.endDate).utc(true).format(),
      } as IConstraint);
    }
    if (value.value.hasOwnProperty("id")) {
      constraintArray.push({
        type: ConstraintType.Eq,
        field: value.field + ".id",
        value: value.value.id ? value.value.id.toString() : "",
      } as IConstraint);
    }
  } else if (typeof value.value === "string") {
    if (value.value === "NOT NULL") {
      constraintArray.push({
        type: ConstraintType.NotNull,
        field: value.field,
      } as IConstraint);
    } else {
      constraintArray.push({
        type: value.type || ConstraintType.Like,
        field: value.field,
        value: value.value,
      } as IConstraint);
    }
  } else if (typeof value.value === "number") {
    constraintArray.push({
      type: ConstraintType.Eq,
      field: value.field,
      value: value.value ? value.value.toString() : "",
    } as IConstraint);
  } else {
    constraintArray.push({
      type: ConstraintType.Eq,
      field: value.field,
      value: value.value,
    } as IConstraint);
  }
  return constraintArray;
};
