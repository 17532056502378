import { Button, Classes, Dialog, Drawer, InputGroup } from "@blueprintjs/core";
import React, { ChangeEvent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IConstraint } from "../../models/constraint";
import {
  getReferences,
  setFilterReference
} from "../../redux/actions/referencesActions";
import { IAppState } from "../../redux/states/state";
import ReferenceForm from "./reference-form/ReferenceForm";
import "./References.scss";
import SelectedReference from "./selected-reference/SelectedReference";

interface Props extends WithTranslation {
  references: Map<string, any[]>;
  getReferences: (userRole: string) => void;
  setFilterReference: (filter: IConstraint[]) => void;
}

export interface IDialogState {
  autoFocus: boolean;
  canEscapeKeyClose: boolean;
  canOutsideClickClose: boolean;
  enforceFocus: boolean;
  isOpen: boolean;
  usePortal: boolean;
  titleName: string;
}

class References extends React.Component<Props, any> {
  public state = {
    autoFocus: true,
    canEscapeKeyClose: false,
    canOutsideClickClose: false,
    enforceFocus: false,
    isOpen: false,
    usePortal: true,
    titleName: "",
    drawerIsOpen: false,
    search: "",
  };

  componentDidMount(): void {
    this.props.getReferences("menecer");
  }

  renderList(t: any) {
    return Array.from(this.props.references.keys())
      .filter((k) => t(k).toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1)
      .map((k) => (
        <div
          className="reference-link"
          onClick={() => this.handleOpen(k)}
          key={k}
        >
          {t(k)}
        </div>
      ));
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Button
          large
          minimal
          icon="cog"
          text={t("UI.Dictionaries")}
          onClick={() => this.handleOpenDrawer()}
        />
        {/*<Popover position={Position.BOTTOM_LEFT} minimal>*/}
        {/*    <Button large minimal icon="cog" text={t('UI.Dictionaries')}/>*/}
        {/*    <Menu>*/}
        {/*        {this.renderList(t)}*/}
        {/*    </Menu>*/}
        {/*</Popover>*/}
        {/*{this.renderDialog(t)}*/}
        <Drawer
          className="reference-drawer"
          icon="git-repo"
          onClose={this.handleCloseDrawer}
          title={t("UI.Dictionaries")}
          isOpen={this.state.drawerIsOpen}
        >
          <div className={Classes.DRAWER_BODY}>
            <div className={Classes.DIALOG_BODY}>
              <InputGroup
                value={this.state.search}
                leftIcon="search"
                rightElement={
                  <Button
                    minimal
                    icon="cross"
                    onClick={() => {
                      this.setState({ search: "" });
                    }}
                  />
                }
                placeholder={t("UI.Search")}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  this.setState({ search: event.target.value });
                }}
              />
              {this.renderList(t)}
            </div>
          </div>
          {/*<div className={Classes.DRAWER_FOOTER}>Footer</div>*/}
        </Drawer>
        <Dialog
          onClose={this.handleClose}
          title={t(this.state.titleName)}
          className="dialog"
          {...this.state}
        >
          <div className="dialog_body">
            <SelectedReference modelName={this.state.titleName} />
          </div>
        </Dialog>
        <ReferenceForm />
      </div>
    );
  }

  public handleOpen(name: string) {
    this.setState({ titleName: name, isOpen: true });
  }

  private handleClose = () => {
    this.props.setFilterReference([]);
    return this.setState({ isOpen: false });
  };

  private handleOpenDrawer = () => this.setState({ drawerIsOpen: true });
  private handleCloseDrawer = () => this.setState({ drawerIsOpen: false });
}

const mapStateToProps = (state: IAppState) => ({
  references: state.referencesReducer.references,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getReferences: bindActionCreators(getReferences, dispatch),
  setFilterReference: bindActionCreators(setFilterReference, dispatch),
});

const ConnectedForm = connect(mapStateToProps, mapDispatchToProps)(References);
export default withTranslation()(ConnectedForm);
