import DateField from "../date-field/DateField";
import TimeField from "../time-field/TimeField";
import ReferenceField from "../reference-field/ReferenceField";
import ArrayField from "../array-field/ArrayField";
import TextField from "../text-field/TextField";
import React from "react";
import TextareaField from "../textarea-field/TextareaField";
import BooleanField from "../boolean-field/BooleanField";
import MultiselectField from "../multiselect-field/MultiselectField";
import MultipleDateField from "../multiple-date/MultipleDateField";
import NumberField from "../number-field/NumberField";
import NumberIntervalField from "../number-interval-field/NumberIntervalField";
import GroupDelimeter from "../group-delimeter/GroupDelimeter";
import { IFormField } from "../../../../../models/fields/form-field";
import EntityField from "../entity-field/EntityField";
import ManyField from "../many-field/ManyField";
import EditableTable from "../editable-table/EditableTable";
import TablePopup from "../table-popup/TablePopup";
import DateStateHistoryField from "../date-state-history-field/DateStateHistoryField";
import TextInputField from "../text-input-field/TextInputField";
import ReferenceSearch from "../reference-search/ReferenceSearch";
import CardField from "../card-field/CardField";
import StateField from "../state-field/StateField";
import MultipleTextField from "../multiple-text/MultipleTextField";
import AnswerStatus from "../custom/AnswerStatus";
import Comments from "../custom/Comments";
import Representative from "../custom/representative/Representative";
import BarcodeField from "../barcode/BarcodeField";
import OneDateField from "../one-date-field/OneDateField";
import InnerTransformation from "../../transformations/inner-transformation/InnerTransformation";
import HelpInfo from "../../controls/help-info/HelpInfo";
import EnumField from "../enum-field/EnumField";

const CustomFormField = (props: IFormField) => {
  const field = props.f;
  if (field.model) {
    switch (props.f.component) {
      case "Single":
        return <ReferenceField {...props} />;
      case "Multiple":
        return <ArrayField {...props} />;
      case "OneToOne":
        return <EntityField {...props} />;
      case "ManyToMany":
        return <ManyField {...props} />;
      case "EditableTable":
        return <EditableTable {...props} />;
      case "TablePopup":
        return <TablePopup {...props} />;
      case "ReferenceSearch":
        return <ReferenceSearch {...props} />;
      case "RelatedCard":
        return <CardField {...props} />;
      case "MultiSelect":
        return <MultiselectField {...props} />;
      case "Comments":
        return <Comments {...props} />;
      case "Representative":
        return <Representative {...props} />;
      case "Transformation":
        return <InnerTransformation {...props} />;
      case "HelpInfo":
        return <HelpInfo {...props} />;
      default:
        return null;
    }
  } else {
    switch (field.component) {
      case "Number":
        return <NumberField {...props} />;
      case "NumberInterval":
        return <NumberIntervalField {...props} />;
      case "Date":
        return <DateField {...props} />;
      case "Time":
        return <TimeField {...props} />;
      case "DateValue":
        return <DateStateHistoryField {...props} />;
      case "DateMultiple":
        return <MultipleDateField {...props} />;
      case "TextMultiple":
        return <MultipleTextField {...props} />;
      case "Text":
        return <TextField {...props} />;
      case "TextInput":
        return <TextInputField {...props} />;
      case "Textarea":
        return <TextareaField {...props} />;
      case "Boolean":
        return <BooleanField {...props} />;
      case "SimpleMultiSelect":
        return <MultiselectField {...props} />;
      case "Group":
        return <GroupDelimeter {...props} />;
      case "State":
        return <StateField {...props} />;
      case "AnswerStatus":
        return <AnswerStatus {...props} />;
      case "Representative":
        return <Representative {...props} />;
      case "Barcode":
        return <BarcodeField {...props} />;
      case "OneDate":
        return <OneDateField {...props} />;
      case "EnumField":
        return <EnumField {...props} />;
      default:
        return null;
    }
  }
};

export default CustomFormField;
