import { Alignment, Checkbox } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IEmployee } from "../../../../../models/employee";
import { Views } from "../../../../../models/enums/views";
import { FieldFactory } from "../../../../../models/fields/field-factory";
import {
  Dictionary,
  DictionaryArray,
  DictionaryObject,
} from "../../../../../models/types/dictionary";
import { IViewField } from "../../../../../models/view-field";
import { IViewModel } from "../../../../../models/view-model";
import { updateAcceptEntity } from "../../../../../redux/actions/newEntitiesActions";
import { IAppState } from "../../../../../redux/states/state";
import Moment from "moment";
import "./Acceptance.scss";

const showAccepted = (acceptEmployee: DictionaryObject | null): boolean => {
  return !!acceptEmployee;
};

export const defaultCheck = (
  acceptEmployee: DictionaryObject | null,
  model: IViewModel
): boolean => {
  if (!acceptEmployee || !model.acceptance) {
    return false;
  }
  return !!acceptEmployee[model.acceptance?.accepted];
};

const calculateAcceptance = (
  acceptEmployee: DictionaryObject | null,
  model: IViewModel,
  checked: boolean
) => {
  if (acceptEmployee && model.acceptance) {
    const moment = Moment(new Date());
    acceptEmployee[model.acceptance?.accepted] = checked;
    acceptEmployee[model.acceptance?.date] = checked ? moment.format() : null;
  }
};

export const getAcceptEmployee = (
  selectedItem: DictionaryObject | null,
  currentEmployee: IEmployee | null,
  model: IViewModel
): DictionaryObject | null => {
  const modelAcceptance = model.acceptance;
  if (!selectedItem || !currentEmployee || !modelAcceptance) {
    return null;
  }
  const field = model.fields.find(
    (f: IViewField) =>
      f.name === model.acceptance?.field && selectedItem[f.name]
  );
  if (!field) {
    return null;
  }
  const gerenalField = new FieldFactory(field);
  if (gerenalField.isArray()) {
    const withEmployee = (selectedItem[field.name] as DictionaryArray).filter(
      (item: Dictionary) => {
        const employee = (item as DictionaryObject)[
          modelAcceptance.employee
        ] as DictionaryObject;
        return !!employee && employee.id === currentEmployee.id;
      }
    );
    if (withEmployee && withEmployee.length) {
      const acceptEmployee = withEmployee[0] as DictionaryObject;
      return acceptEmployee;
    }
  }
  return null;
};

export const Acceptance = (props: {
  views: Views;
  model: IViewModel;
  selectedItem: DictionaryObject;
}) => {
  const { model, selectedItem } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [acceptEmployee, setAcceptEmployee] = useState<DictionaryObject | null>(
    null
  );
  const currentEmployee = useSelector(
    (state: IAppState) => state.authorizationReducer.currentEmployee
  );

  useEffect(() => {
    const acceptedEmployee = getAcceptEmployee(
      selectedItem,
      currentEmployee,
      model
    );

    setAcceptEmployee(acceptedEmployee);
    setChecked(defaultCheck(acceptedEmployee, model));
  });

  const setAccepted = (
    acceptEmployee: DictionaryObject | null,
    checked: boolean
  ) => {
    setChecked(checked);
    if (model.acceptance && acceptEmployee) {
      calculateAcceptance(acceptEmployee, model, checked);
      dispatch(
        updateAcceptEntity(model.acceptance.model, acceptEmployee, model.name)
      );
    }
  };
  return (
    <span>
      {showAccepted(acceptEmployee) && (
        <Checkbox
          className={"acceptance__check-box"}
          alignIndicator={Alignment.RIGHT}
          label={t("MeAccept")}
          placeholder={t("MeAccept")}
          checked={checked}
          onChange={(e: any) => {
            setAccepted(acceptEmployee, e.target.checked);
          }}
        />
      )}
    </span>
  );
};

export default Acceptance;
