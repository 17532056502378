import React from "react";
import "./ReferenceForm.scss";
import { useSelector, useDispatch } from "react-redux";
import { ModelType } from "../../../models/enums/model-type";
import GenericForms from "../../core/forms/form-modal/generic-form/GenericForms";
import { referenceFormIsOpen } from "../../../redux/actions/referencesActions";
import { IAppState } from "../../../redux/states/state";

interface Props {}

const ReferenceForm = (props: Props) => {
  const isReferenceOpen = useSelector(
    (state: IAppState) => state.referencesReducer.formIsOpen
  );
  const currentReference = useSelector(
    (state: IAppState) => state.referencesReducer.currentReference
  );
  const selectedItem = useSelector(
    (state: IAppState) => state.referencesReducer.selectedItem
  );
  const updateFieldCall = useSelector(
    (state: IAppState) => state.referencesReducer.updateFieldCall
  );

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(referenceFormIsOpen(currentReference, false, null, () => null));
  };

  if(!isReferenceOpen) {
    return null;
  }

  return (
    <GenericForms
      isOpen={isReferenceOpen}
      selectedItem={selectedItem}
      type={ModelType.Dictionary}
      modelName={currentReference}
      dbname={currentReference}
      updateCallback={updateFieldCall}
      onClose={onClose}
    />
  );
};

export default ReferenceForm;
