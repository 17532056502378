import axios from "axios";
import { PERMITS_BACKEND, ROLES_BACKEND } from "../../backend-connection";
import { IRoleWithPermits } from "../../models/role";

const getRoles = (): Promise<any> => {
  return axios(`${ROLES_BACKEND}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const createRole = (role: IRoleWithPermits): Promise<any> => {
  return axios(`${ROLES_BACKEND}/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(role),
  });
};

const modifyRole = (role: IRoleWithPermits): Promise<any> => {
  return axios(`${ROLES_BACKEND}/modifyRole`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(role),
  });
};

const removeRole = (roleId: number): Promise<any> => {
  return axios(`${ROLES_BACKEND}/remove?roleId=` + roleId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getPermissions = (): Promise<any> => {
  return axios(`${PERMITS_BACKEND}/all`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getPermissiosByRole = (roleId: number): Promise<any> => {
  return axios(`${PERMITS_BACKEND}/byRole?roleId=` + roleId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export {
  getRoles,
  createRole,
  modifyRole,
  removeRole,
  getPermissions,
  getPermissiosByRole,
};
