import { alert } from "../constants";
import { IStateAlerts } from "../states/state-alerts";
export function setConfirm(props: IStateAlerts) {
    return {
        type: alert.SET_ALERT,
        payload: props,
    };
}

export function hideConfirm() {
    return {
        type: alert.CLOSE_ALERT,
        payload: false,
    };
}


export function showConfirm() {
    return {
        type: alert.OPEN_ALERT,
        payload: true,
    };
}
