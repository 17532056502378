import { defaultPagingOptions } from "../../components/user/users/default-paging";
import { IUser } from "../../models/users/user";
import {
  LOADING_USERS,
  LOADING_USERS_COUNT,
  LOAD_USERS,
  LOAD_USERS_SIMPLE,
  SELECT_USER,
  SET_USER_EMPLOYEE,
  UPDATE_PAGING,
} from "../constants/users";
import { IStateUsers } from "../states/state-users";

const INITIAL_STATE: IStateUsers = {
  users: new Map<string, IUser>(),
  usersSimple: new Map<string, IUser>(),
  selectedUser: {} as IUser,
  loading: false,
  totalCount: 0,
  prevEmployee: null,
  pagingOptions: defaultPagingOptions,
};

const usersReducer = (
  state = INITIAL_STATE,
  action: { type: string; payload: any }
) => {
  const prevState = state;
  switch (action.type) {
    case LOAD_USERS:
      const users = action.payload;
      const map: Map<string, IUser> = new Map<string, IUser>();
      users.forEach((user: IUser) => {
        map.set(user.id.toString(), user);
      });
      return {
        ...prevState,
        users: map,
      };
    case LOADING_USERS_COUNT:
      return {
        ...prevState,
        totalCount: action.payload,
      };
    case SELECT_USER:
      return {
        ...prevState,
        prevEmployee: null,
        selectedUser: action.payload,
      };
    case LOADING_USERS:
      return {
        ...prevState,
        loading: action.payload,
      };
    case LOAD_USERS_SIMPLE:
      const usersSimple = action.payload;
      const mapSimple: Map<string, IUser> = new Map<string, IUser>();
      usersSimple?.forEach((user: IUser) => {
        mapSimple.set(user.id.toString(), user);
      });
      return {
        ...prevState,
        usersSimple: mapSimple,
      };
    case SET_USER_EMPLOYEE:
      return {
        ...prevState,
        prevEmployee:
          action.payload && action.payload.length ? action.payload[0] : null,
      };
    case UPDATE_PAGING:
      return {
        ...prevState,
        pagingOptions: action.payload.pagingOptions,
      };
    default:
      return prevState;
  }
};

export default usersReducer;
