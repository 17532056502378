import { Button, Icon, Label } from "@blueprintjs/core";
import { Col, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { change, formValueSelector } from "redux-form";
import { FieldArrayFieldsProps } from "redux-form/lib/FieldArray";
import { getWritables } from "../../../../../helpers/get-writables";
import { sortById } from "../../../../../helpers/sort-by-id";
import { IConstraint } from "../../../../../models/constraint";
import { Views } from "../../../../../models/enums/views";
import { FieldFactory } from "../../../../../models/fields/field-factory";
import { FormField } from "../../../../../models/fields/form-field";
import { ISequenceState } from "../../../../../models/sequence-state";
import {
  DictionaryArray,
  DictionaryObject,
} from "../../../../../models/types/dictionary";
import { IViewField } from "../../../../../models/view-field";
import { IViewModel } from "../../../../../models/view-model";
import { updateEntity } from "../../../../../redux/actions/entitiesActions";
import { IAppState } from "../../../../../redux/states/state";
import { parseDateToString } from "../../fields/date-field/DateField";
import CustomFormField from "../../fields/form-types/FormTypes";
import "./EditableTableForm.scss";

interface Props {
  f: IViewField;
  selectedItem: any;
  fields: FieldArrayFieldsProps<any>;
  constraints?: IConstraint[];
  formName: string;
}
export const EditableTableForm = (props: Props) => {
  const { f, selectedItem, fields } = props;
  const [listeners, setListeners] = useState<any>(new Map<string, any>());
  const { t } = useTranslation();
  const [, forceUpdate] = useState(false);
  const [editingKey, setEditingKey] = useState("");

  const [oldRecord, setOldRecord] = useState(null);
  const dispatch = useDispatch();
  const referenceModel: IViewModel | undefined = useSelector((state: any) =>
    state.modelsReducer.models.get(f.model)
  );
  const writables: string[] = useSelector((state: any) => getWritables(state, f));

  const stateData = useSelector((state: any) => state);
  const currentEmployee = useSelector((state: IAppState) => state.authorizationReducer.currentEmployee);
  const models = useSelector((state: any) => state.modelsReducer.models);
  let selector: any = null;
  if (props.formName) {
    selector = formValueSelector(props.formName);
  }

  const updateEntityObject = (value: any) => {
    if (referenceModel) {
      dispatch(
        updateEntity(referenceModel.dbname, value, [], selectedItem, () => null)
      );
    }
  };

  const defaultObject = (f: IViewField) => {
    const field = new FieldFactory(f).createField();
    return field.defaultValue(
      selectedItem,
      new Map<string, ISequenceState>(),
      false
    );
  };

  // useEffect(() => {
  //   const defaultObj = defaultObject(f) as DictionaryArray;
  //   if (selectedItem) {
  //     fields.push({ ...(defaultObj[0] as DictionaryObject), default: true });
  //   }
  // }, [selectedItem]);

  const onAdd = () => {
    if (fields && fields.length > 0) {
      const prevField = fields.get(fields.length - 1);
      if (
        Object.keys(prevField).filter(
          (key) => key !== "default" && !!prevField[key]
        ).length > 0
      ) {
        const defaultObj = defaultObject(f) as DictionaryArray;
        referenceModel?.fields?.forEach((el) => {
          if (el && el.initialValue) {
            fields.getAll()?.forEach((f: any) => {
              if (!f[el.name]) {
                f[el.name] = selector
                  ? selector(stateData, el.initialValue.field)
                  : f[el.initialValue.field];
              }
            });
            if (!(defaultObj[0] as DictionaryObject)[el.name]) {
              (defaultObj[0] as DictionaryObject)[el.name] = selector
                ? selector(stateData, el.initialValue.field)
                : (defaultObj[0] as DictionaryObject)[el.initialValue.field];
            }
          }
        });
        fields.push({ ...(defaultObj[0] as DictionaryObject), default: true });
        prevField.default = undefined;
      }
    }
  };
  const onRemove = (index: number) => {
    fields.remove(index);
    if (fields.length > 0) {
      const prevField = fields.get(fields.length - 1);
      prevField.default = true;
    }
  };
  const onChange = (value: any, record: any, fieldName: string) => {

    if (referenceModel?.fields.find((el: IViewField) => el.name == fieldName)?.component == "Date") {

      record[fieldName] = parseDateToString(value, false);
    } else {

      record[fieldName] = value;
    }

    setEditingKey(createKey(record));
  };

  const renderEditing = (field: any, record: any, index: number) => {
    const onChangeFieldValue = (value: any) => {
      onChange(value, record, field.name);
    };
    return (
      <CustomFormField
        {...new FormField(
          { ...field, readonly: !(writables?.indexOf(field.name) > -1) },
          f.name + "[" + index + "]." + field.name,
          record,
          Views.FORM,
          false,
          false,
          props.formName,
          false,
          false,
          new Map(),
          onChangeFieldValue,
          false
        )}
      />
    );
  };

  const columns: any = referenceModel && f.tableView
  ? f.tableView
    .map((f1) => referenceModel.fields?.find(p => p.name === f1))
    .map((f1: IViewField | undefined, i: number) => {
      if (!f1) return null;
      return {
        title: t(f1.name),
        editable: !!f.editableView?.find(p => p === f1.name),
        dataIndex: f1.name,
        render: (elements: any, record: any, index: number) => {
          if (models && models.size) {
            const renderedField = new FieldFactory(f1).createField();
            return !f.editableView?.find(p => p == f1.name) || !isEditing(record)
              ? renderedField.viewRender(!f1.reflectField ? elements : record[f1.reflectField])
              : renderEditing(f1, record, index);
          } else {
            return "";
          }
        },
      }
    })
  : [];

  columns.push({
    title: t("UI.Actions"),
    editable: false,
    className: "text-align",
    render: (elements: any, record: any, index: number) => {

      return (
        <>
          {!isEditing(record) && (!f.stopEdit || (f.stopEdit && !f.stopEdit(record))) && (
            <>
              <a className={"editable-table-form__icon"}>
                <Icon
                  icon={"edit"}
                  htmlTitle={t("UI.Editing")}
                  onClick={() => {
                    fields.getAll().forEach((element, elIndex) => {
                      if (index == elIndex)
                        setOldRecord({ ...element });
                    });

                    setEditingKey(createKey(record));
                  }}
                />
              </a>
              <a>
                <Icon
                  icon={"ban-circle"}
                  htmlTitle={t("UI.ClearDelete")}
                  className={"editable-table-form__icon"}
                  onClick={() => onRemove(index)}
                />
              </a>
            </>
          )}
          {isEditing(record) && (
            <>
              <a className={"editable-table-form__icon"}>
                <Icon
                  icon={"tick"}
                  htmlTitle={t("UI.Save")}
                  onClick={() => {
                    let changeEl = f.triggerSave ? f.triggerSave({ ...record }, { form: props.formName, field: f.name }, { currentEmployee: currentEmployee }) : { ...record };
                    closeEditing(changeEl);
                  }}
                />
              </a>
              <a className={"editable-table-form__icon"}>
                <Icon
                  icon={"cross"}
                  htmlTitle={t("UI.Clear")}
                  onClick={() => {
                    dispatch(change(props.formName, f.name, fields.getAll().map((el: any) => oldRecord && el.id == record.id ? oldRecord : el)));
                    closeEditing(oldRecord);
                  }}
                />
              </a>
            </>
          )}
        </>
      );
    },
  });

  const createKey = (record: any) => {
    return JSON.stringify(record);
  };

  const isEditing = (record: any) => {

    return createKey(record) === editingKey;
  };

  const mergedColumns = columns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col
    };
  });

  const closeEditing = (record?: any) => {
    if (record && record.id) {
      updateEntityObject(record);
    }
    setEditingKey("");
  };

  return (
    <Row>
      <Row className="editable-table-form__header">
        <Col span={24}>
          <Label>
            <span>{t(f.name)}</span>
            <span className="required">{f.required ? "*" : ""}</span>
          </Label>
        </Col>
      </Row>
      <Row className="editable-table-form" gutter={16} key={f.name}>
        {fields
          ? fields.map((field1: string, index: number) => {
            return referenceModel
              ? referenceModel.fields
                .filter((field) => field.visible.form && f.createView?.find(p => p == field.name))
                .map((field: IViewField) => {
                  const onChange = (e: any) => {
                    if (field.listen) {
                      listeners.set(field.name, e);
                      setListeners(listeners);
                      forceUpdate((s: boolean) => !s);
                    }
                  };
                  return (
                    <Col
                      span={(24 * field.col) / referenceModel.grid}
                      key={field.name + index}
                      className={
                        index === fields.length - 1 ? "" : "display-none"
                      }
                    >
                      <CustomFormField
                        {...new FormField(
                          { ...field, readonly: !(writables?.indexOf(field.name) > -1) },
                          f.name + "[" + index + "]." + field.name,
                          selectedItem,
                          Views.FORM,
                          true,
                          false,
                          props.formName,
                          false,
                          false,
                          listeners,
                          onChange
                        )}
                      />
                    </Col>
                  );
                })
              : null;
          })
          : null}
        <Col span={1}>
          <div className="row button-row">
            <Button icon="plus" disabled={!props.f.writable} small onClick={() => onAdd()} />
          </div>
        </Col>
        <Col span={24}>
          <Table
            scroll={{
              x: (columns.length - 1) * 120,
            }}
            size="small"
            tableLayout={"auto"}
            bordered
            columns={mergedColumns}
            dataSource={fields.getAll().sort(sortById).filter((el) => el.default != true)}
            pagination={false}
          />
        </Col>
      </Row>
    </Row>
  );
};

export default EditableTableForm;
