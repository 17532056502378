import React, { ReactElement } from "react";
import { ChangeAction } from "../changes/field-change";
import { IConstraint } from "../constraint";
import { Dictionary } from "../types/dictionary";
import { GeneralField } from "./general-field";

export class NumberField extends GeneralField {
  compareFunction(before: Dictionary, after: Dictionary): ChangeAction {
    return (before as number) === (after as number)
      ? ChangeAction.Unchanged
      : ChangeAction.Updated;
  }

  viewRender(value: Dictionary): ReactElement {
    return <span> {value ? this.viewField.affix ? this.viewField.affix + value.toString() : value.toString() : ""}</span>;
  }

  stringRender(value: Dictionary): string {
    return value ? value.toString() : "";
  }

  tagRender(value: Dictionary, onRemove: (id: number) => void): ReactElement {
    return this.viewRender(value);
  }

  emptyConstraint(fieldName: string): IConstraint {
    return {
      type: "Null",
      field: fieldName,
    } as IConstraint;
  }

  valueConstraint(fieldName: string, value: Dictionary) {
    return [
      {
        type: "Eq",
        field: fieldName,
        value: value ? value.toString() : "",
      } as IConstraint,
    ];
  }

  isEmpty(value: Dictionary) {
    return !value;
  }
}
