import React, { ReactElement } from "react";
import "./ChangeGeneral.scss";
import ChangesFilter from "../changes-filter/ChangesFilter";
import ChangesHistory from "../ChangesHistory";

export const ChangesGeneral: React.FC = (): ReactElement => {
  return (
    <div>
      <ChangesFilter />
      <ChangesHistory selectedItem={null} model={undefined} />
    </div>
  );
};

export default ChangesGeneral;
