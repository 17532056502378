import { IViewField } from "../models/view-field";
import { formValueSelector, getFormMeta } from "redux-form";

export const formListeners = (
  form: string,
  state: any,
  fields: IViewField[]
) => {
  const selector = (form: string, state: any, ...other: any) =>
    formValueSelector(form)(state, ...other);

  const meta = getFormMeta(form)(state);

  const listeners: string[] = fields
    .filter((f: IViewField) => f.listen &&
      // эта строчка нужна, чтобы в листенеры запись попадала только вручную
      !!(meta as any)[f.name]
     )
    .map((f: IViewField) => f.name);

  const changeValue: Map<string, any> = new Map<string, any>();

  listeners.forEach((lis) => {
    changeValue.set(lis, selector(form, state, lis));
  });

  return changeValue;
};
