import { Action, Dispatch } from "redux";
import { IViewModel } from "../../models/view-model";
import axios from "axios";
import { MODELS_BACKEND } from "../../backend-connection";
import { showError } from "./errorsActions";
import { ThunkAction } from "redux-thunk";
import { ILogicModel } from "../../models/logic-model";
import { IViewField, IRelation } from "../../models/view-field";
import { ILogicField } from "../../models/logic-field";
import { IState } from "../../models/state";
import preprocessing from "../../helpers/preprocessing";
import { project } from "./../../configs/index";
import { IAppState } from "../states/state";
import { DictionaryObject } from "../../models/types/dictionary";


export const GET_MODEL = "GET_MODEL";
export const LOAD_MODEL = "LOAD_MODEL";
export const LOADING_MODELS = "LOADING_MODELS";
export const SET_ACTIVE_MODEL = "SET_ACTIVE_MODEL";
export const SET_ACTIVE_FORM = "SET_ACTIVE_FORM";

export function setActiveModel(
  modelName: string,
  dbname: string,
  allowCreate: boolean,
  archived: boolean,
  activeRoute: string,
  statesFilter?: string[]
) {
  return {
    type: SET_ACTIVE_MODEL,
    payload: {
      modelName: modelName,
      allowCreate: allowCreate,
      dbname: dbname,
      statesFilter: statesFilter,
      archived: archived,
      activeRoute: activeRoute
    },
  };
}

export function loadModel(name: string, model: IViewModel) {
  return {
    type: LOAD_MODEL,
    payload: {
      name: name,
      model: model,
    },
  };
}

export function loadingModels(loading: boolean) {
  return {
    type: LOADING_MODELS,
    payload: loading,
  };
}

export const getModels = (): ThunkAction<
  void,
  IAppState,
  unknown,
  Action<string>
> => (dispatch, getState) => {
  dispatch(loadingModels(true));
  return getModelsFromBackend()
    .then((response) => {
      const viewModels: IViewModel[] = [
        ...(preprocessing(project.properties) as Array<any>),
        ...(preprocessing(project.entities) as Array<any>),
        ...(preprocessing(project.dictionaries) as Array<any>),
        ...(preprocessing(project.reports) as Array<any>),
      ];
      joinModelsLogicAndView(dispatch, viewModels, response.data);
      dispatch(loadingModels(false));
    })
    .catch((err) => {
      dispatch(showError("Error loading models"));
      dispatch(loadingModels(false));
    });
};

export function getModelsFromBackend() {
  return axios(`${MODELS_BACKEND}/models`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// without states
export function joinModelsLogicAndView(
  dispatch: Dispatch,
  viewModels: IViewModel[],
  logicModels: { key: string; value: any }[]
) {
  if (!viewModels || !logicModels) {
    return null;
  }

  // Make a map from backend models key-value array
  const logicModelsMap: Map<string, ILogicModel[]> = new Map<
    string,
    ILogicModel[]
  >(logicModels.map((i) => [i.key, i.value]));

  // Iterate over frontend array and get corresponding logic model
  viewModels.forEach((viewModel, index) => {
    const foundLogicModel = logicModelsMap.get(viewModel.dbname);
    if (foundLogicModel) {
      const foundMainModel = foundLogicModel.find(
        (lm) => lm.name === viewModel.dbname
      );

      if (foundMainModel) {
        viewModel.type = foundMainModel.type;
        viewModel.states = new Map<string, IState>();
        if (foundMainModel.states && foundMainModel.states.length) {
          foundMainModel.states.forEach((state) => {
            viewModel.states.set(state.name || "stub", state);
          });
        }

        viewModel.submodelProps = {};
        setSubmodelProps(viewModel, foundLogicModel);
        viewModel.fields = viewInfoPermissions(viewModel);
        dispatch(loadModel(viewModel.name, viewModel));
      }
    } else {
      dispatch(loadModel(viewModel.name, viewModel));
    }
  });
}

export function setSubmodelProps(
  viewModel: IViewModel,
  foundLogicModel: ILogicModel[]
) {
  const filtred = foundLogicModel.filter((el) => el.name !== viewModel.dbname);

  viewModel.submodelProps.writable = filtred.map((el) => {
    const states = new Map<string, string[]>();
    el.states.forEach((state) =>
      states.set(
        state.name,
        state.fields
          .filter((field) => field.writable)
          .map((field) => field.name)
      )
    );
    return {
      submodel: el.name,
      states: states,
    };
  });
}

export function joinLogicAndViewFields(
  viewFields: IViewField[],
  logicFields: ILogicField[]
) {
  return viewFields.map((vf: IViewField) => {
    const _vf = { ...vf };
    let relation = _vf.relation as IRelation;
    switch (relation && relation.type) {
      case "OBJECT": {
        relation = {
          ...relation,
          func: (value: any, item: any, propsName: string[]) => {
            if (!propsName || propsName.length === 0) return value;
            else return item[propsName[0]];
          },
        };
        break; 
      }
      default: {
        relation && console.error("Can`t find type:" + relation.type);
      }
    }

    _vf.relation = relation;

    const logicField = logicFields.find((lf) => lf.name === vf.name);
    if (logicField) {
      joinConstraints(_vf, logicField);
      joinPermitions(_vf, logicField);
    } else {
      !exceptionalField(_vf) ? (_vf.readable = false) : (_vf.readable = true);
    }
    return _vf;
  });
}

export function joinPermitions(viewField: IViewField, logicField: ILogicField) {
  viewField.readable = true;
  viewField.writable = logicField.writable;
  viewField.deletable = logicField.deletable;
}

export function joinConstraints(
  viewField: IViewField,
  logicField: ILogicField
) {
  const constraints = logicField.constraints;
  if (constraints && constraints.length) {
    if (!viewField.constraints) {
      viewField.constraints = constraints;
    } else {
      viewField.constraints = [...viewField.constraints, ...constraints];
    }
  }
}

export function getFieldsByState(
  state: string,
  model: IViewModel,
  selectedItem: DictionaryObject
): IViewField[] {
  const foundState = model.states ? model.states.get(state) : null;
  if (!foundState || !state) {
    return model.fields;
  }
  const stateFields = foundState.fields;
  const stateVF = joinLogicAndViewFields(model.fields, stateFields);

  return stateVF.filter(
    (f: IViewField) =>
      (!f.uiStates || (f.uiStates && f.uiStates.indexOf(state) !== -1)) && (!f.condition || (f.condition && eval(f.condition)(selectedItem)))
  );
}

export const exceptionalField = (field: IViewField) => {
  const uiNames = ["id", "state", "created", "stateHistory"];
  const uiComponents = [
    "Group",
    "Placeholder",
    "Barcode",
    "Transformation",
    "HelpInfo",
  ];

  return uiNames.includes(field.name) || uiComponents.includes(field.component) || !!field.customFilter;
};

export const viewInfoPermissions = (model: IViewModel): IViewField[] => {
  return model.fields.filter((field: IViewField) => {
    return Array.from(model.states.values()).some((v) =>
      v.fields.some((f) => field.name === f.name || exceptionalField(field))
    );
  });
};

export function setActiveTab(activeForm: string) {
  return {
    type: SET_ACTIVE_FORM,
    payload: {
      activeForm,
    },
  };
}
