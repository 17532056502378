import axios from "axios";
import { ENTITIES_BACKEND } from "../../backend-connection";
import { ISequenceState } from "../../models/sequence-state";

const nextValue = (sequence: string): Promise<any> => {
  return axios(`${ENTITIES_BACKEND}/nextValue?sequence=${sequence}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getTryValues = (sequence: string): Promise<any> => {
  return axios(`${ENTITIES_BACKEND}/tryValue?sequence=${sequence}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const saveValue = (
  sequence: string,
  sequenceState: ISequenceState
): Promise<any> => {
  return axios(`${ENTITIES_BACKEND}/saveValue?sequence=${sequence}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(sequenceState),
  });
};

export { getTryValues, nextValue, saveValue };
