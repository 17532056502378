import React from "react";
import { Dictionary } from "../types/dictionary";
import { IViewField } from "../view-field";
import { IGeneralField } from "../fields/general-field";
import { FieldFactory } from "../fields/field-factory";

export enum ChangeAction {
  Added,
  Updated,
  Deleted,
  Unchanged,
}

export interface IFieldRead {
  fieldName: string;
  value: React.ReactNode;
}

export interface IFieldChange {
  fieldName: string;
  action: ChangeAction;
  before: React.ReactNode;
  after: React.ReactNode;
}

export class FieldChange implements IFieldChange {
  fieldName: string;
  action: ChangeAction = ChangeAction.Unchanged;
  before: React.ReactNode;
  after: React.ReactNode;

  constructor(
    fieldName: string,
    vBefore: Dictionary,
    vAfter: Dictionary,
    viewField: IViewField
  ) {
    this.fieldName = fieldName;
    this.compareValues(fieldName, vBefore, vAfter, viewField);
  }

  compareValues(
    fieldName: string,
    vBefore: Dictionary,
    vAfter: Dictionary,
    viewField: IViewField
  ): void {
    const fieldFactory = new FieldFactory(viewField);
    const field: IGeneralField = fieldFactory.createField();
    if (!vBefore && vAfter) {
      this.action = ChangeAction.Added;
    } else if (vBefore && !vAfter) {
      this.action = ChangeAction.Deleted;
    } else if (!vBefore && !vAfter) {
      this.action = ChangeAction.Unchanged;
    } else {
      this.action = field.compareFunction(vBefore, vAfter);
    }

    this.after = field.viewRender(vAfter);
    this.before = field.viewRender(vBefore);
  }
}
