import { IAlertProps } from "@blueprintjs/core";
import { alert } from "../constants";

const INITIAL_STATE: IAlertProps & { message: string } = {
    isOpen: false,
    message: "",
};

const errorsReducer = (
    state = INITIAL_STATE,
    action: { type: string; payload: any }
) => {
    const prevState = state;
    switch (action.type) {
        case alert.SET_ALERT:
            return { ...action.payload };
        case alert.CLOSE_ALERT || alert.OPEN_ALERT: {
            return {
                ...state,
                isOpen: action.payload
            };
        }
        default:
            return prevState;
    }
};

export default errorsReducer;
