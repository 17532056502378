import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";

import navigationReducer from "./navigationReducer";
import authorizationReducer from "./authorizationReducer";
import referencesReducer from "./referencesReducer";
import modelsReducer from "./modelReducer";
import entitiesReducer from "./entitiesReducer";
import errorsReducer from "./errorsReducer";
import alertsReducer from "./alertsReducer";
import permitionReducer from "./permitionReducer";
import { reducer as formReducer } from "redux-form";
import usersReducer from "./usersReducer";
import attachmentsReducer from "./attachmentsReducer";
import templatesReducer from "./templatesReducer";
import changesReducer from "./changesReducer";
import sequencesReducer from "./sequencesReducer";
import { newUserReducer } from "./newUsersReducer";
import printReducer from "./printReducer";
import aemReducer from "./aemReducer";

export default (history: History) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    navigationReducer,
    authorizationReducer,
    referencesReducer,
    modelsReducer,
    entitiesReducer,
    errorsReducer,
    alertsReducer,
    permitionReducer,
    usersReducer,
    attachmentsReducer,
    templatesReducer,
    changesReducer,
    newUserReducer,
    sequencesReducer,
    printReducer,
    aemReducer
  });
