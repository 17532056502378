export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const SET_ROLE = "SET_ROLE";
export const LOADING_USERS = "LOADING_USERS";
export const LOAD_USERS = "LOAD_USERS";
export const LOADING_USERS_COUNT = "LOADING_USERS_COUNT";
export const REFRESH_USERS = "REFRESH_USERS";
export const UPDATE_PAGING = "UPDATE_PAGING";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const GET_USERS_SIMPLE = "GET_USERS_SIMPLE";
export const LOAD_USERS_SIMPLE = "LOAD_USERS_SIMPLE";
export const GET_EMPLOYEE_BY_USERID = "GET_EMPLOYEE_BY_USERID";
export const SET_USER_EMPLOYEE = "SET_USER_EMPLOYEE";
export const SELECT_USER = "SELECT_USER";
