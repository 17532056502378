import {
  Button,
  Icon,
  Menu,
  MenuItem,
  PopoverPosition,
} from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import { IItemRendererProps } from "@blueprintjs/select/src/common/itemRenderer";
import React, { useState } from "react";
import { WrappedFieldInputProps } from "redux-form";
import { AUTOCOMPLETE_SIZE } from "../../../../../helpers/ui-constants";
import {
  DictionaryObject,
  Selectable,
} from "../../../../../models/types/dictionary";
import SelectableMethods from "../selectable/Selectable";
import "./Autocomplete.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../redux/states/state";
import copy from "copy-to-clipboard";
import { ContextMenu2 } from "@blueprintjs/popover2";

const AnySuggest = Suggest.ofType<Selectable>();

export const Autocomplete = (props: {
  items: Selectable[];
  size: AUTOCOMPLETE_SIZE;
  renderView: (item: Selectable) => string;
  position: PopoverPosition;
  resetOnClose: boolean;
  flatObject: boolean;
  placeholder?: string;
  input?: WrappedFieldInputProps;
  createNew?: (query: string) => void;
  editItem?: (item: DictionaryObject) => void;
  transformQuery?: (query: string) => any;
  onChangeQuery?: (
    query: string,
    event?: React.ChangeEvent<HTMLInputElement>
  ) => void;
  onChange?: (e: any) => void;
  selected?: Selectable;
  disabled?: boolean;
  rightBtnClick?: () => void;
  className?: string;
  filter?: any;
  autoCreateItem?: boolean;
}) => {
  const {
    items,
    size,
    renderView,
    position,
    placeholder,
    input,
    createNew,
    editItem,
    transformQuery,
    onChangeQuery,
    disabled,
    rightBtnClick,
    className,
    resetOnClose,
    flatObject,
    autoCreateItem,
  } = props;
  const [inputValue, setInputValue] = useState("");
  const classNameInternal =
    (size === "AUTOCOMPLETE_SIZE_SMALL" ? "small-select" : "") + " any-select";

  const { t } = useTranslation();
  const selectedItem = useSelector(
    (state: IAppState) => state.entitiesReducer.selectedItem
  );
  const onChange = (value: any) => {
    if (input) {
      input.onChange(value);
    }
    if (props.onChange) {
      props.onChange(value);
    }
  };

  const filterEmpty = (items: Selectable[]) => {
    let filtredEmpty = items
      .filter((item) => !!item)
      .filter((i) => !!renderView(i));
    return props.filter
      ? props.filter(filtredEmpty, selectedItem)
      : filtredEmpty;
  };

  const getSelected = () => {
    if (input) {
      return input.value;
    }
    if (props.selected) {
      return props.selected;
    }
    return null;
  };
  let selectableItems = items;
  if (autoCreateItem) {
    selectableItems = [{ Name: inputValue }, ...items];
  }

  return (
    <ContextMenu2
      content={
        <Menu>
          <MenuItem
            text="Copy"
            icon="duplicate"
            disabled={!input?.value}
            onClick={() => {
              copy(renderView(input?.value));
            }}
          />
        </Menu>
      }
    >
      <label className="autocomplete-label">
        <AnySuggest
          className={classNameInternal}
          itemRenderer={(item: any, itemProps: IItemRendererProps) => {
            if (item.id)
              return SelectableMethods.renderItem(item, itemProps, renderView);
            else
              return (
                <div key={itemProps.index} className="new-Item">
                  {SelectableMethods.renderItem(item, itemProps, renderView)}
                </div>
              );
          }}
          items={selectableItems ? filterEmpty(selectableItems) : []}
          query={inputValue}
          fill={true}
          disabled={disabled}
          onQueryChange={(
            query: string,
            event?: React.ChangeEvent<HTMLInputElement>
          ) => {
            setInputValue(query);
            if (onChangeQuery) {
              onChangeQuery(query, event);
            }
          }}
          createNewItemFromQuery={(query: string) => {
            if (transformQuery) {
              return transformQuery(query);
            } else return query;
          }}
          createNewItemRenderer={
            createNew
              ? () => {
                  return (
                    <MenuItem
                      icon="plus"
                      text={t("UI.CreateNew")}
                      onClick={() => {
                        createNew(inputValue || "");
                      }}
                    />
                  );
                }
              : undefined
          }
          noResults={
            <>
              {!!inputValue ? (
                <MenuItem disabled={true} text={t("UI.NothingFound")} />
              ) : (
                <MenuItem
                  disabled={true}
                  icon="search"
                  text={t("UI.TapToSearch")}
                />
              )}
              {createNew ? (
                <MenuItem
                  icon="plus"
                  text={t("UI.CreateNew")}
                  onClick={() => {
                    createNew(inputValue || "");
                  }}
                />
              ) : null}
            </>
          }
          onItemSelect={onChange}
          resetOnClose={resetOnClose}
          inputValueRenderer={(item: Selectable) =>
            SelectableMethods.renderInputValue(item, input?.value, renderView)
          }
          popoverProps={{
            minimal: true,
            position: position,
          }}
          itemsEqual={(a: Selectable, b: Selectable) =>
            SelectableMethods.compareItems(a, b, renderView)
          }
          itemPredicate={(
            query: any,
            item: Selectable,
            _index: any,
            exactMatch: any
          ) =>
            SelectableMethods.filterItems(
              query,
              item,
              _index,
              exactMatch,
              renderView
            )
          }
          defaultSelectedItem={getSelected()}
          selectedItem={getSelected()}
          activeItem={getSelected()}
          scrollToActiveItem={true}
          inputProps={{
            onBlur: () => {
              if (input && flatObject) {
                input.onChange(inputValue);
              }
            },
            onFocus: () => {
              if (input && onChangeQuery && flatObject) {
                onChangeQuery(input.value);
              }
            },
            className: className || "",
            placeholder: placeholder,
            disabled: disabled,
            rightElement: !disabled ? (
              <Icon
                className="right-element"
                icon={!getSelected() ? "chevron-down" : "small-cross"}
                onClick={() => {
                  if (rightBtnClick) {
                    rightBtnClick();
                  }
                }}
              />
            ) : (
              <div></div>
            ),
          }}
        />
        {input?.value && editItem ? (
          <div className="edit-button">
            <Button small icon="edit" onClick={() => editItem(input?.value)} />
          </div>
        ) : null}
      </label>
    </ContextMenu2>
  );
};

export default Autocomplete;
