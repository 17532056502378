import { FormGroup, Label } from "@blueprintjs/core";
import { Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { Views } from "../../../../../models/enums/views";
import { IFormField } from "../../../../../models/fields/form-field";
import { renderEmptyField } from "../../controls/empty-btn/EmptyBtn";
import DateField, { formatStringToDate, parseDateToString, renderDateField } from "../date-field/DateField";
import "./OneDateField.scss";

export const OneDateField = (props: IFormField) => {
  const { t } = useTranslation();
  const { f, fieldName, view, showEmpty } = props;

  const DateFilterView = () => {
    return (
      <Row className="date-field" key={f.name}>
        <FormGroup>
          <Label htmlFor={fieldName}>{t(f.name)}</Label>
          <div className="row">
            <Field
              name={fieldName + ".oneDate"}
              type="text"
              format={formatStringToDate}
              parse={(date: Date) => parseDateToString(date, false)}
              field={f}
              onChange={(value: any) => {
                props.onChange && props.onChange({ oneDate: value })
              }}
              placeholder={t("UI.SelectDate")}
              component={renderDateField}
            />
            {!f.noSearchEmpty && showEmpty && (
              <Field
                name={fieldName + "__EMPTY"}
                type="checkbox"
                onClick={() => { }}
                onChange={(e: any) => { }}
                field={f}
                view={view}
                component={renderEmptyField}
              />
            )}
          </div>
        </FormGroup>
      </Row>
    );
  };

  const DateFormView = () => {
    return <DateField {...props}/>
  };

  switch (view) {
    case Views.FILTER:
        return DateFilterView();
    case Views.FORM:
      return DateFormView();
    case Views.REFERENCE:
      return <span>Text</span>;
    default:
      return <span>Text</span>;
  }
};

export default OneDateField;
