import { Dictionary } from "../../../../models/types/dictionary";
import { IViewField } from "../../../../models/view-field";
import "./CalculationView.scss";

export const CalculationView = (props: {
  field: IViewField;
  item: Dictionary;
}) => {
  const { field, item } = props;

  const arrayListView = (field: IViewField, item: Dictionary) => {
    if (field.calculation) {
      return field.calculation(item);
    }
  };

  return arrayListView(field, item);
};

export default CalculationView;
