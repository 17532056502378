import { IChange } from "./changes-history";
import { IViewModel } from "../view-model";
import { TFunction } from "i18next";
import React from "react";
import {
  getFormName,
  IChangesDetails,
  readInfoPrint,
  renderPrintDetails,
} from "./changes-factory";
import { IFieldRead } from "./field-change";
import { IApiPrintInfo } from "./api-print-info";

export class ChangesExport implements IChangesDetails {
  details = "";
  info: IFieldRead[] = [];
  formName = "";

  constructor(change: IChange, model?: IViewModel) {
    this.getDetails();
    this.info = readInfoPrint(change.after as IApiPrintInfo, model);
    this.formName = getFormName(change.after as IApiPrintInfo);
  }

  getDetails(): void {
    this.details = this.readDetails();
  }

  //TODO: Отображение деталей по экспорту
  readDetails(): string {
    return "";
  }

  renderInfo(t: TFunction): React.ReactNode {
    return renderPrintDetails(this.formName, this.info, t);
  }

  renderChanges(): React.ReactNode {
    return <span>{this.details}</span>;
  }

  getShortDescription(t: TFunction): React.ReactNode {
    return <span>{t("ChangesShortDesc.Export")}</span>;
  }
}
