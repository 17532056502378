import { Button, Icon, Intent } from "@blueprintjs/core";
import { Col, Row, Spin } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { dynamicConstraints } from "../../../helpers/dynamic-constraints";
import { years } from "../../../helpers/year-reference";
import { IConstraintTree } from "../../../models/constraint";
import { IEmployee } from "../../../models/employee";
import { Selectable } from "../../../models/types/dictionary";
import { IUser } from "../../../models/users/user";
import { IViewModel } from "../../../models/view-model";
import {
  clearFilters,
  setArchiveYear
} from "../../../redux/actions/entitiesActions";
import { IAppState } from "../../../redux/states/state";
import SimpleSelect from "../../core/forms/controls/simple-select/SimpleSelect";
import ListLayout from "../../core/lists/list-layout/ListLayout";
import SideBar from "../side-bar/SideBar";
import "./ArchiveView.scss";

interface State {
  leftOpen: boolean;
  rightOpen: boolean;
  year: number;
  modelName: string;
}

interface Props extends RouteComponentProps, WithTranslation {
  routing: string;
  setActiveModel: (modelName: string) => void;
  activeModel: IViewModel | undefined;
  userInfo: IUser | null;
  loading: boolean;
  setArchiveYear: (archiveConditions: IConstraintTree | null) => void;
  clearFilters: () => void;
  currentEmployee: IEmployee | null;
  filter: any;
}

class ArchiveView extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      leftOpen: true,
      rightOpen: true,
      year: new Date().getFullYear(),
      modelName: "",
    };
  }

  componentDidMount(): void {
    this.props.clearFilters();
    this.getByYear(this.state.year);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.activeModel !== this.props.activeModel) {
      this.props.clearFilters();
      this.getByYear(this.state.year);
    }
  }

  componentWillUnmount() {
    this.props.clearFilters();
  }

  toggleSidebar = (event: any) => {
    this.setState({ leftOpen: !this.state.leftOpen });
  };

  getByYear(year: number) {
    const { activeModel } = this.props;
    if (!activeModel) {
      return;
    }
    const archiveConditions = activeModel
      ? activeModel.archiveConditions
      : undefined;

    this.props.setArchiveYear(
      dynamicConstraints(null, archiveConditions, year)
    );
  }

  selectYear = (event: number) => {
    this.setState({ year: event });
    this.getByYear(event);
  };

  render() {
    const leftOpen = !this.state.leftOpen ? "open" : "closed";
    const { activeModel, loading, t } = this.props;

    if (loading) {
      return (
        <Spin
          tip="Loading models..."
          className={"large-spinner"}
          size={"large"}
        />
      );
    }
    if (!activeModel) {
      return <span></span>;
    }

    return (
      <div className="main-layout">
        <div id="left" className={leftOpen}>
          <div className={`sidebar ${leftOpen}`}>
            <SideBar />
          </div>
        </div>
        <div id="main">
          <Row className="display-row list-header">
            <Col
              span={4}
              className={"filter-close-icon display-row"}
              onClick={this.toggleSidebar}
            >
              <Icon
                icon={"filter-list"}
                iconSize={Icon.SIZE_LARGE}
                intent={Intent.NONE}
              />
              <span>{t("UI.Search")}</span>
            </Col>
            <Col span={4} className={"filter-close-icon display-row"}>
              <SimpleSelect
                items={years(0, 5)}
                renderView={(value: Selectable) =>
                  value ? value.toString() : ""
                }
                component={
                  <Button
                    rightIcon="caret-down"
                    text={
                      this.state.year
                        ? this.state.year.toString()
                        : "(No Selection)"
                    }
                  />
                }
                onChange={this.selectYear}
              />
            </Col>
          </Row>
          <div className="content">
            <ListLayout createNew={false} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  userInfo: state.authorizationReducer.userInfo,
  currentEmployee: state.authorizationReducer.currentEmployee,
  loading: state.modelsReducer.loading,
  activeModel: state.modelsReducer.models.get(state.modelsReducer.activeModel),
  filter: state.entitiesReducer.filter,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setArchiveYear: (archiveConditions) => setArchiveYear(archiveConditions),
      clearFilters: () => clearFilters(),
    },
    dispatch
  );

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ArchiveView))
);
