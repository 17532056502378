import { Label } from "@blueprintjs/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import { WrappedFieldProps } from "redux-form/lib/Field";
import { checkDisabledConstraints } from "../../../../../helpers/check-disabled-constraints";
import { AUTOCOMPLETE_SIZE } from "../../../../../helpers/ui-constants";
import { Views } from "../../../../../models/enums/views";
import { IFormField } from "../../../../../models/fields/form-field";
import {
  Selectable
} from "../../../../../models/types/dictionary";
import { IViewField } from "../../../../../models/view-field";
import {
  referenceFormIsOpen
} from "../../../../../redux/actions/referencesActions";
import EntityFieldFilter from "../../../filters/fields/entity-field/EntityFieldFilter";
import SearchField from "../../controls/search-fields/SearchFields";
import "./ReferenceSearch.scss";

export const renderReferenceSearch = ({
  input,
  meta,
  field,
  placeholder,
  reference,
  view,
  size,
  disabled,
  valueInitial,
}: WrappedFieldProps & {
  field: IViewField;
  placeholder: string;
  reference: Selectable[];
  view: Views;
  size: AUTOCOMPLETE_SIZE;
  disabled?: boolean;
  valueInitial: any;
}) => {
  return <SearchField f={field} input={input} disabled={false} />;
};

export const ReferenceSearch = (props: IFormField) => {
  const { t } = useTranslation();
  const { f, fieldName, view, showLabel } = props;
  const [reference, setReference] = useState([]);
  const [initialValue, setInitialValue] = useState(null);
  const [notOnlyValues, setNotOnlyValues] = useState(true);
  const dispatch = useDispatch();

  const referenceModel = useSelector((state: any) =>
    state.modelsReducer.models.get(f.model)
  );

  const createNew = () => {
    dispatch(referenceFormIsOpen(referenceModel.dbname, true, null, () => null));
    setReference([]);
  };

  const ReferenceFilterView = () => {
    return <EntityFieldFilter {...props} />;
  };
  const stateData = useSelector((state: any) => state);

  const referenceValues = (reference: any[], connectedValues: any[]) => {
    const result = reference ? reference : [];
    if (!notOnlyValues) {
      return connectedValues;
    }
    if (connectedValues && connectedValues.length) {
      if (reference && reference.length) {
        connectedValues.forEach((el) => {
          const nonFound = reference.find((v) => v === el);
          if (!nonFound) {
            result.push(el);
          }
        });
      } else {
        result.push(...connectedValues);
      }
    }
    return result;
  };

  const ReferenceFormView = () => {
    const { listeners } = props;
    const disabled = checkDisabledConstraints(f, listeners) || f.readonly;
    const connectedToField = f.connectedTo;
    let selector: any = null;

    if (props.formName) {
      selector = formValueSelector(props.formName);
    }

    if (selector && f.initialValue) {
      const value = selector(stateData, f.initialValue.field);
      if (value && value !== initialValue) {
        setInitialValue(value);
      }
    }
    let valuesFromConnectedField: any = [];

    if (connectedToField && connectedToField.length && selector) {
      connectedToField.forEach((val) => {
        if (!val.notOnlyValues && notOnlyValues) {
          setNotOnlyValues(val.notOnlyValues);
        }
        const values = selector(stateData, val.field);

        if (values && values.length) {
          valuesFromConnectedField.push(...values);
        }
      });
    }

    if (valuesFromConnectedField && valuesFromConnectedField.length) {
      valuesFromConnectedField = valuesFromConnectedField.filter(
        (el: any) => !!Object.keys(el).length
      );
    }

    return (
      <div key={f.name}>
        {showLabel && (
            <Label htmlFor={fieldName}>
              <span>{t(f.name)}</span>
              <span className="required">{f.required ? "*" : ""}</span>
            </Label>
        )}
        <div>
          <Field
            name={fieldName}
            onClick={() => { }}
            disabled={disabled || f.readonly}
            type="text"
            onChange={(e: any) => {
              if (props.onChange) {
                props.onChange(e);
              }
            }}
            field={f}
            valueInitial={initialValue}
            view={view}
            size="AUTOCOMPLETE_SIZE_SMALL"
            reference={referenceValues(reference, valuesFromConnectedField)}
            placeholder={""}
            createNew={
              f.searchType && f.searchType === "Autocomplete" && f.allowCreate
                ? createNew
                : undefined
            }
            component={renderReferenceSearch}
          />
        </div>
      </div>
    );
  };

  switch (view) {
    case Views.FILTER:
      return ReferenceFilterView();
    case Views.FORM:
      return ReferenceFormView();
    case Views.REFERENCE:
      return <span>Text</span>;
    default:
      return <span>Text</span>;
  }
};
export default ReferenceSearch;
