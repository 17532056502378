import React from "react";
import { useSelector } from "react-redux";
import { IGeneralField } from "../../../../models/fields/general-field";
import {
  Dictionary,
  DictionaryArray,
  DictionaryObject,
} from "../../../../models/types/dictionary";
import { IViewField } from "../../../../models/view-field";
import "./ArrayView.scss";
import { Tag, Intent } from "@blueprintjs/core";
import { FieldFactory } from "../../../../models/fields/field-factory";
import { useTranslation } from "react-i18next";

export const ArrayView = (props: {
  field: IViewField;
  item: DictionaryArray;
  showLabels?: boolean;
  tagView?: boolean;
  onRemove?: (id: number) => void;
}) => {
  const { field, item, showLabels, tagView, onRemove } = props;
  const { t } = useTranslation();
  const referenceModel = useSelector((state: any) =>
    state.modelsReducer.models.get(field.model)
  );

  const arrayListView = (field: IViewField, item: DictionaryArray) => {
    const referenceView = field.referenceView;
    if (!referenceModel && !referenceView) {
      return (
        <span>
          {item
            ? (item as string[]).map((v: string, index: number) => {
              return (
                <span>
                  {field.needTranslate ? t(v) : v}{" "}
                  {index !== item.length - 1 ? ", " : ""}
                </span>
              );
            })
            : ""}
        </span>
      );
    } else {
      if (!referenceView) {
        return null;
      }
      const viewFields = referenceModel?.fields.filter(
        (f: IViewField) => !!referenceView.find((c: string) => c === f.name)
      );
      if (tagView) {
        return item && item.length ? (
          <span>
            {item.map((value: Dictionary, index) => {
              return (
                <Tag
                  intent={Intent.NONE}
                  multiline
                  key={(value as DictionaryObject)["id"] as string}
                  onRemove={() =>
                    onRemove
                      ? onRemove((value as DictionaryObject)["id"] as number)
                      : null
                  }
                >
                  {viewFields.map((f: IViewField, indexF: number) => {
                    const fieldFactory = new FieldFactory(f);
                    const newField: IGeneralField = fieldFactory.createField();
                    const delimiter = indexF > 0 ? " " : "";
                    return (
                      <span key={f.name}>
                        <span>{delimiter}</span>
                        {showLabels && <i>{f.name}</i>}
                        {newField.viewRender(
                          (value as DictionaryObject)[f.name]
                        )}
                      </span>
                    );
                  })}
                </Tag>
              );
            })}
          </span>
        ) : null;
      } else {
        return item && item.length ? (
          <span>
            {item.map((value: Dictionary, index) => {
              const delimiter = index > 0 ? "," : "";
              return (
                <span key={(value as DictionaryObject)["id"] as string}>
                  <span>{delimiter}</span>
                  {viewFields.map((f: IViewField, indexF: number) => {
                    const fieldFactory = new FieldFactory(f);
                    const newField: IGeneralField = fieldFactory.createField();
                    const delimiter = indexF > 0 ? " " : "";
                    return (
                      <span key={f.name}>
                        {showLabels && <i>{f.name}</i>}
                        <span>{delimiter}</span>
                        {newField.viewRender(
                          (value as DictionaryObject)[f.name]
                        )}
                      </span>
                    );
                  })}
                </span>
              );
            })}
          </span>
        ) : null;
      }
    }
  };

  return arrayListView(field, item);
};

export default ArrayView;
