import { Spin } from "antd";
import React, { createContext, ReactElement } from "react";
import { constructInitialValues } from "../../../../../helpers/default-values";
import { getSequences } from "../../../../../helpers/sequences";
import { ModelType } from "../../../../../models/enums/model-type";
import { Views } from "../../../../../models/enums/views";
import { ISequenceState } from "../../../../../models/sequence-state";
import { Dictionary, DictionaryObject } from "../../../../../models/types/dictionary";
import { ITab, IViewModel } from "../../../../../models/view-model";
import ReduxForm from "../redux-form/ReduxForm";
import { getTryValue, getTryValues, loadTryValues, saveTryValues } from "./queries";
import "./FormInitializer.scss";
import {LoadState} from "../../../../../models/enums/load-state";

export const FormContext = createContext({
    tryValues: new Map<string, ISequenceState>(),
    loadingSequence: LoadState.INITIAL,
    addTryValues: (sequences: string[]) => { return; },
    clearTryValues: (sequences: string[]) => { return; }
});
interface Props {
    model: IViewModel;
    tab: ITab;
    selectedItem: any;
    mainEntity: boolean;
    hideId: boolean;
    type: ModelType;
    component?: ReactElement;
    form: string;
    onClose: () => void;
    updateCallback: (close: boolean, item: DictionaryObject | null) => void;
    transformInitialItem?: (initialItem: DictionaryObject) => Dictionary;
    callback?: (result: any) => any;
}

interface State {
    initialItem: DictionaryObject | undefined,
    tryValues: Map<string, ISequenceState>;
    loadingSequence: LoadState;
    addTryValues: (sequences: string[]) => void;
    clearTryValues: (sequences: string[]) => void;
}

export default class FormInitializer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            initialItem: undefined,
            tryValues: new Map<string, ISequenceState>(),
            loadingSequence: LoadState.INITIAL,
            addTryValues: this.addTryValue,
            clearTryValues: this.clearTryValues,
        };
    }

    componentDidMount() {
        this.setTryValues();
    }

    componentDidUpdate(prevProps: Props) {
        if(prevProps.selectedItem !== this.props.selectedItem) {
            this.setTryValues();
        }
    }

    getInitialValues = (tryValues: Map<string, ISequenceState>) => {
        const { model, selectedItem, transformInitialItem } = this.props;
        let initialItem = constructInitialValues(model.fields, Views.FORM, tryValues, selectedItem);
        if(transformInitialItem) {
            initialItem = transformInitialItem(initialItem) as DictionaryObject;
        }
        return initialItem;
    }

    setTryValues() { 
        const { model, tab, selectedItem } = this.props;
        const sequences = getSequences(false, tab, model, selectedItem || {});
        if(sequences.length) {
            getTryValues(sequences).subscribe((res) => {
                const tryValues = loadTryValues(sequences, res);
                this.setState({ initialItem: this.getInitialValues(tryValues), tryValues: tryValues });
            });
        } else {
            this.setState({ initialItem: this.getInitialValues(new Map<string, ISequenceState>()) });
        }
    }

    setTryValue = (sequence: string) => {
        this.setState({ loadingSequence: LoadState.LOADING });
        getTryValue(sequence).subscribe((res) => {
            const tryValues = this.state.tryValues;
            tryValues.set(sequence, res);
            this.setState({ tryValues: tryValues, loadingSequence: LoadState.LOADED });
            this.setState({ loadingSequence: LoadState.INITIAL });
        });
    }

    addTryValue = (sequences: string[]) => {
        if(sequences && sequences.length) {
            this.setTryValue(sequences[0]);
        }
    }

    clearTryValues = (sequences: string[]) => {
        if(sequences && sequences.length) {
            sequences.forEach((seq) => {
                this.state.tryValues.delete(seq);
            });
            this.setState({ tryValues: this.state.tryValues });
        }
    }

    saveTryValues = () => { 
        saveTryValues(this.state.tryValues).subscribe();
    }

    render() {
        const { initialItem } = this.state;

        if (initialItem === undefined) {
            return (
                <Spin
                  tip="Loading form..."
                  className={"form-initializer__spinner"}
                  size={"default"}
                />
              );
        }
        return <FormContext.Provider value={this.state}>
                    <ReduxForm {...this.props} 
                            initialValues={initialItem} 
                            saveTryValues={this.saveTryValues}
                    />
                </FormContext.Provider>
    }
}

export const FormConsumer = FormContext.Consumer;