import { Dialog, Icon } from "@blueprintjs/core";
import { Table, Tooltip } from "antd";
import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { ILang } from "../../../models/lang";
import { IPagingOptions } from "../../../models/paging-options";
import { IPermit } from "../../../models/permit";
import { IRoleWithPermits } from "../../../models/role";
import { selectNext, selectPrev } from "../../../redux/actions/entitiesActions";
import {
  createDublicate,
  getRoles,
  selectRole
} from "../../../redux/actions/roleActions";
import { IAppState } from "../../../redux/states/state";
import GeneralFooter from "../../core/lists/general-footer/GeneralFooter";
import CreateRoles from "../create-roles/CreateRoles";
import "./Roles.scss";

interface Props extends RouteComponentProps, WithTranslation {
  roles: IRoleWithPermits[];
  permits: IPermit[];
  selectedRole: IRoleWithPermits;
  isOpen: boolean;
  loading: boolean;
  getRoles: () => void;
  selectRole: (role: IRoleWithPermits | null) => void;
  createDublicate: (isDublicateCreation: boolean) => void;
}

interface IColumn {
  title: string;
  key?: string;
  width?: number;
  value: string;
  dataIndex: string;
  render?: any;
}

class Roles extends React.PureComponent<Props, any> {
  public state = {
    columns: [
      {
        title: "",
        value: "RoleName",
        dataIndex: "name",
        key: "name",
        render: (name: ILang) => {
          return name.az;
        },
      },
      {
        title: "",
        value: "Actions",
        key: "action",
        render: (value: any, record: any) => {
          return (
            <Tooltip placement="topLeft" title={this.props.t("Actions.Copy")}>
              <Icon
                icon="duplicate"
                color={"#847a7a"}
                onClick={() => {
                  const { roles } = this.props;
                  const selectedRole = roles.find(
                    (r: IRoleWithPermits) => r.id === record["id"]
                  );
                  this.props.selectRole(selectedRole ? selectedRole : null);
                  this.props.createDublicate(true);
                  this.setState({ isOpen: true });
                }}
              />
            </Tooltip>
          );
        },
      },
    ] as IColumn[],
    scrollY: 0,
    isOpen: false,
    selectedId: 0,
  };

  componentDidMount(): void {
    this.props.getRoles();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions = () => {
    this.setState({
      scrollY: window.innerHeight - 300,
    });
  };

  onSelectChange = (selectedId: number) => {
    this.setState({ selectedId });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
    this.props.createDublicate(false);
  };

  onDoubleChange = (selectedId: number) => {
    const { roles } = this.props;
    const selectedRole = roles.find(
      (r: IRoleWithPermits) => r.id === selectedId
    );
    this.props.selectRole(selectedRole ? selectedRole : null);
    this.handleOpen();
  };

  keyPressEvent(e: any) {
    if (e.keyCode === 13) {
      this.handleOpen();
    } else if (e.keyCode === 40) {
      this.selectNext();
    } else if (e.keyCode === 38) {
      this.selectPrev();
    }
  }

  selectPrev = () => {
    const prevItem = selectPrev(this.props.roles, this.state.selectedId);
    this.setState({ selectedId: prevItem });
  };

  selectNext = () => {
    const nextItem = selectNext(this.props.roles, this.state.selectedId);
    this.setState({ selectedId: nextItem });
  };

  public render() {
    const { t, roles, loading } = this.props;
    this.state.columns.map(
      (item) => (item.title = `${t(`Role.${item.value}`)}`)
    );
    const { columns, selectedId, scrollY } = this.state;

    return (
      <div className="roles-page">
        <div
          tabIndex={0}
          onKeyUp={(e) => {
            this.keyPressEvent(e);
          }}
        >
          <Table
            scroll={{
              y: scrollY,
            }}
            loading={loading}
            tableLayout={"auto"}
            columns={columns}
            dataSource={roles}
            size="small"
            pagination={false}
            className={"scroll-table"}
            rowKey={"id"}
            footer={() => (
              <GeneralFooter
                allowCreate={true}
                newTitle={"NewRole"}
                hasPagination={false}
                totalCount={0}
                pagingOptions={{} as IPagingOptions}
                createNew={this.createNew}
                selectNext={this.selectNext}
                selectPrev={this.selectPrev}
                refreshItems={() => null}
              />
            )}
            rowClassName={(record: any, index: number) => {
              let rowClass = "all-row ";
              if (index === 0) {
                rowClass += "first-row ";
              }
              if (selectedId === record["id"]) {
                rowClass += "scroll-row selected-row";
              }
              return rowClass;
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  this.onSelectChange(record["id"]);
                },
                onDoubleClick: () => {
                  this.onDoubleChange(record["id"]);
                },
              };
            }}
            bordered
          />
        </div>
        <Dialog
          title={<div className="dialog-header">{t("UI.Role")}</div>}
          onClose={() => this.handleClose()}
          isOpen={this.state.isOpen}
          canOutsideClickClose={false}
          canEscapeKeyClose={true}
        >
          <CreateRoles
            openForm={(isOpen: boolean) => this.setState({ isOpen: isOpen })}
          />
        </Dialog>
      </div>
    );
  }

  private handleOpen = () => {
    this.setState({ isOpen: true });
  };

  private createNew = () => {
    this.props.selectRole(null);
    this.handleOpen();
  };
}

const mapStateToProps = (state: IAppState) => ({
  roles: state.permitionReducer.roles,
  permits: state.permitionReducer.permits,
  selectedRole: state.permitionReducer.selectedRole,
  loading: state.permitionReducer.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getRoles: () => getRoles(),
      selectRole: (role: IRoleWithPermits | null) => selectRole(role),
      createDublicate: (isDublicateCreation: boolean) =>
        createDublicate(isDublicateCreation),
    },
    dispatch
  );

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Roles))
);
