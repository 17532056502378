import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { createEpicMiddleware } from "redux-observable";
import thunk from "redux-thunk";
import { ActionType } from "typesafe-actions";
import { setInterceptors } from "../interceptor/axios-interceptor";
import * as actions from "./actions";
import { checkPrintService } from "./actions/printActions";
import * as API from "./api";
import { rootEpic } from "./epics/epic";
import rootReducer from "./reducers/reducers";
import { IAppState } from "./states/state";

export type ActionsType = ActionType<typeof actions>;

export const history = createBrowserHistory();
const epicMiddleware = createEpicMiddleware<
  ActionsType,
  ActionsType,
  IAppState
>({ dependencies: API });

const initialState = {};
const enhancers = [];

const middleware = [thunk, routerMiddleware(history), epicMiddleware];

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(
  rootReducer(history),
  initialState,
  composedEnhancers
);

epicMiddleware.run(rootEpic);

const { dispatch } = store;
dispatch<any>(setInterceptors());
dispatch<any>(checkPrintService());

export { store, actions };
