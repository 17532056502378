import { FormGroup, Label, TextArea } from "@blueprintjs/core";
import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { WrappedFieldProps } from "redux-form/lib/Field";
import { Views } from "../../../../../models/enums/views";
import { IViewField } from "../../../../../models/view-field";
import "./TextareaField.scss";
import ValidMessages from "../../valid-messages/ValidMessages";
import { mandatory } from "../../../../../validation/validate";
import { renderEmptyField } from "../../controls/empty-btn/EmptyBtn";
import { IFormField } from "../../../../../models/fields/form-field";
import useRelationHook from "../../../../../hooks/relationHook";

const renderField = ({
  input,
  meta: { touched, invalid, error },
  placeholder,
  disabled,
  field,
  relationData
}: WrappedFieldProps & {
  placeholder: string;
  disabled: boolean;
  field: IViewField;
  relationData?: any;
}) => {
  return (
    <div className="row-input">
      <TextArea
        {...input}
        disabled={disabled}
        onChange={(e: any) => input.onChange(!field.relation || field.relation?.changable || !field.relation.func ? e : field.relation.func(e, relationData, field.relation.propertyNames))}
        className={
          error && touched
            ? "bp3-intent-danger any-text bp3-fill form-textarea"
            : "any-text bp3-fill form-textarea"
        }
        growVertically={true}
      />
      {touched && error && <ValidMessages text={error} />}
    </div>
  );
};

export const TextareaField = (props: IFormField) => {
  const { t } = useTranslation();
  const { f, fieldName, view, showEmpty, showLabel, isDraft } = props;

  const TextAreaFilterView = () => {
    return (
      <Row>
        <FormGroup className="form-group-text">
          <Label htmlFor={fieldName}>{t(f.name)}</Label>
          <div className="any-text__container">
            <Field
              name={fieldName}
              type="text"
              component={renderField}
              field={f}
              onChange={(value: any) =>
                props.onChange && props.onChange(value)
              }
            />
            {!f.noSearchEmpty && showEmpty && (
              <Field
                name={fieldName + "__EMPTY"}
                type="checkbox"
                onClick={() => { }}
                onChange={(e: any) => { }}
                field={f}
                view={view}
                component={renderEmptyField}
              />
            )}
          </div>
        </FormGroup>
      </Row>
    );
  };

  const TextAreaFormView = () => {
    const relationData = useRelationHook({
      f: props.f,
      fieldName: props.fieldName,
      formName: props.formName
    });
    
    return (
      <Row>
        {showLabel && (
          <Col span={4}>
            <Label htmlFor={fieldName}>
              <span>{t(f.name)}</span>
              <span className="required">{f.required ? "*" : ""}</span>
            </Label>
          </Col>
        )}
        <Col span={20}>
          <div className="any-text__container">
            <Field
              name={fieldName}
              disabled={f.readonly || (f.writable === false) || f.name.toLowerCase() === "id"}
              type="text"
              field={f}
              relationData={relationData}
              onChange={(e: any) => {
                if (props.onChange) {
                  props.onChange(e && e.target ? e.target.value : null);
                }
              }}
              component={renderField} 
              validate={f.required && !isDraft ? mandatory : undefined}
            />
          </div>
        </Col>
      </Row>
    );
  };

  switch (view) {
    case Views.FILTER:
      return TextAreaFilterView();
    case Views.FORM:
      return TextAreaFormView();
    case Views.REFERENCE:
      return <span>Text</span>;
    default:
      return <span>Text</span>;
  }
};

export default TextareaField;
