import {
  PRINT_METHOD, SET_CONTROL_PF
} from "../constants/print";
import { IStatePrint } from "../states/state-print";

const INITIAL_STATE: IStatePrint = {
  printService: false,
  controlPf: null
};

const printReducer = (
  state = INITIAL_STATE,
  action: { type: string; payload: any }
) => {
  const prevState = state;
  switch (action.type) {
    case PRINT_METHOD:
      return {
        ...prevState,
        printService: action.payload,
      };
    case SET_CONTROL_PF:
      return {
        ...prevState,
        controlPf: action.payload,
      };
    default:
      return prevState;
  }
};

export default printReducer;
