import React from "react";
import { useTranslation } from "react-i18next";
import { WrappedFieldInputProps } from "redux-form";
import { IViewField } from "../../../../../models/view-field";
import ReferenceView from "../../../lists/reference-view/ReferenceView";
import "./CompanyDetails.scss";

export const CompanyDetails = (props: { field: IViewField, input: WrappedFieldInputProps }) => {
  const { t } = useTranslation();
  const { field, input } = props;
  return (
    <div>
      {field.detailedView && field.detailedView.map(dv => <div className="detailed-view__el">{
        <ReferenceView field={field} viewArray={[dv]} showEmpty={true} item={input.value} showFields={true} delimiter="" />
      }</div>)}
    </div>
  );
};

export default CompanyDetails;
