import { IViewField } from "./../models/view-field";

export const requiredByState = (field: IViewField, state: string): boolean => {
  if (!field.requiredState) {
    return false;
  }

  if (field.requiredState === "*") {
    return true;
  } else if (
    field.requiredState.length &&
    field.requiredState.includes(state)
  ) {
    return true;
  } else {
    return false;
  }
};
