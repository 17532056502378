import { DictionaryObject } from "./../../models/types/dictionary";
import { createAction } from "typesafe-actions";
import { entities } from "../constants";
import { IPagingOptions } from "../../models/paging-options";
import { IDefaultSorting } from "../../models/default-sorting";

export const updateAcceptEntity = createAction(
  entities.ACCEPT_ENTITY,
  (resolve) => (
    modelName: string,
    entity: DictionaryObject,
    refreshModel: string
  ) => resolve({ modelName, entity, refreshModel })
);

export const getEntities = createAction(
  entities.GET_ENTITIES,
  (resolve) => (modelName: string) => resolve({ modelName })
);

export const loadEntities = createAction(
  entities.LOAD_ENTITIES,
  (resolve) => (items: DictionaryObject[]) => resolve({ items })
);

export const refreshItem = createAction(
  entities.REFRESH_ITEM,
  (resolve) => (modelName: string, id: string) => resolve({ modelName, id })
);

export const loadItem = createAction(
  entities.LOAD_ITEM,
  (resolve) => (item: DictionaryObject) => resolve({ item })
);

export const updatePaging = createAction(
  entities.UPDATE_ENTITIES_PAGING,
  (resolve) => (pagingOptions: IPagingOptions) => resolve({ pagingOptions })
);

export const updateSorting = createAction(
  entities.UPDATE_ENTITIES_SORTING,
  (resolve) => (sortingOptions: IDefaultSorting) => resolve({ sortingOptions })
);

export const getRelatedCard = createAction(
  entities.GET_RELATED_CARD,
  (resolve) => (modelName: string, id: string) => resolve({ modelName, id })
);

export const loadRelatedCard = createAction(
  entities.LOAD_RELATED_CARD,
  (resolve) => (card: string, cardData: DictionaryObject) =>
    resolve({ card, cardData })
);

export const removeRelatedCard = createAction(
  entities.REMOVE_RELATED_CARD,
  (resolve) => (card: string) => resolve({ card })
);

export const clearRelatedCards = createAction(entities.CLEAR_RELATED_CARDS);
