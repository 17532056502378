import { Label } from "@blueprintjs/core";
import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { change, getFormValues } from "redux-form";
import { Views } from "../../../../../models/enums/views";
import { FieldFactory } from "../../../../../models/fields/field-factory";
import {
  Dictionary,
  DictionaryArray,
  DictionaryObject
} from "../../../../../models/types/dictionary";
import { IViewField } from "../../../../../models/view-field";
import { selectItem } from "../../../../../redux/actions/entitiesActions";
import { IAppState } from "../../../../../redux/states/state";
import EntityFieldFilter from "../../../filters/fields/entity-field/EntityFieldFilter";
import "./CardField.scss";

const isEmpty = (data: Dictionary) => {
  return !data || (Array.isArray(data) && !data.length);
};

export const CardField = (props: {
  f: IViewField;
  selectedItem: any;
  view: Views;
  onChange?: (e: any) => void;
  fieldName: string;
  listeners?: Map<string, any>;
  formName: string;
}) => {
  const { view, selectedItem, f, formName } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const parentFormValues = useSelector((state: IAppState) => getFormValues(formName)(state))

  const filterView = () => {
    return <EntityFieldFilter {...props} />;
  };

  const removeDoc = (id: number, selectedItem: DictionaryObject) => {
    if (f.many) {
      selectedItem[f.name] = (selectedItem[f.name] as DictionaryArray).filter((v) => (v as DictionaryObject)["id"] !== id);
    } else {
      selectedItem[f.name] = null;
    }
    dispatch(change(formName, f.name, selectedItem[f.name]));
    dispatch(selectItem({ ...selectedItem }));
  };

  const formView = () => {
    const { f } = props;

    if (f.referenceView && selectedItem && selectedItem[f.name]) {
      const data = selectedItem[f.name];
      if (isEmpty(data)) {
        return (<span></span>);
      }
      const renderedField = new FieldFactory({
        ...f,
        component: f.many ? "Multiple" : "Single"
      }).createField();
      return (
        <Row className="related-card__view">
          <Col span={7}>
            <Label>{t(f.model)}</Label>
          </Col>
          <Col span={17}>
            <div className="related-card__info">
              <div className="related-card__detail">
                {/* <i>{t(f.name)}: </i> */}
                {/* <span>{renderedField.viewRender(data)}</span> */}
                {renderedField.tagRender(data, (id: number) => removeDoc(id, parentFormValues), true)}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    return null;
  };

  switch (view) {
    case Views.FILTER:
      return filterView();
    case Views.FORM:
      return formView();
    case Views.REFERENCE:
      return <span>Not implemented</span>;
    default:
      return <span>Not implemented</span>;
  }
};
export default CardField;
