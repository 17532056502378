import { ButtonGroup } from "@blueprintjs/core";
import { Pagination } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IConstraint } from "../../../../../models/constraint";
import { ModelType } from "../../../../../models/enums/model-type";
import { Dictionary } from "../../../../../models/types/dictionary";
import {
  getReference,
  loadReference,
  setFilterReference,
} from "../../../../../redux/actions/referencesActions";
import { IAppState } from "../../../../../redux/states/state";
import { defaultPagingOptions } from "../../../../references/selected-reference/defaultPagingOptions";
import CommonFilterForm from "../../../filters/common-filter/CommonFilter";
import BaseTable from "../../../lists/base-table/BaseTable";
import "./CardSearch.scss";

export const CardSearch = (props: {
  modelName: string;
  dbname: string;
  filter: IConstraint[];
  getRelatedCard: (result: Dictionary) => Dictionary;
}) => {
  const { modelName, dbname, filter, getRelatedCard } = props;

  const [selectedItem, selectItem] = useState<Dictionary>(null);
  const [, selectPage] = useState(0);

  const references = useSelector((state: IAppState) =>
    state.referencesReducer.references.get(modelName)
  );
  const totalCount = useSelector(
    (state: IAppState) => state.referencesReducer.totalCount
  );
  const filterReference = useSelector(
    (state: IAppState) => state.referencesReducer.filterReference
  );
  const loadingSearch = useSelector(
    (state: IAppState) => state.referencesReducer.loadingSearch
  );

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(loadReference({ name: modelName, items: [] }));
    };
  }, [dispatch, modelName]);

  const select = (result: Dictionary) => {
    selectItem(result);
    getRelatedCard(result);
  };

  const onClear = () => {
    dispatch(setFilterReference([]));
    dispatch(
      getReference(modelName, dbname, [], defaultPagingOptions)
    );
  };

  const onSearch = (constraints: IConstraint[]) => {
    const _contraints = [...filter, ...constraints];
    dispatch(setFilterReference(_contraints));
    dispatch(
      getReference(modelName, dbname, [..._contraints], defaultPagingOptions)
    );
  };

  const renderFooter = () => {
    return (
      <>
        <ButtonGroup className={`footer_group`}>
          <Pagination
            className="footer-pagination"
            total={totalCount}
            defaultPageSize={defaultPagingOptions.pageSize}
            showLessItems={true}
            showSizeChanger={false}
            pageSize={defaultPagingOptions.pageSize}
            defaultCurrent={1}
            onChange={(e: any) => {
              selectPage(e - 1);
              dispatch(
                getReference(modelName, dbname, [...filterReference], {
                  page: e - 1,
                  pageSize: defaultPagingOptions.pageSize,
                  showDeleted: false,
                })
              );
            }}
          />
        </ButtonGroup>
      </>
    );
  };

  return (
    <div className="card-search-dialog__body">
      <div className="card-search-dialog__content">
        <CommonFilterForm
          modelName={modelName}
          form={modelName + "__CARD_SEARCH"}
          onClear={onClear}
          onSearch={onSearch}
        />
        <BaseTable
          viewPath={'list'}
          loading={loadingSearch}
          allowSelect={false}
          data={references ?? []}
          type={ModelType.Entity}
          model={modelName}
          selectedItem={selectedItem}
          openForm={() => null}
          selectItem={(item: any) => select(item)}
          pagingOptions={defaultPagingOptions}
          footer={() => renderFooter()}
        />
      </div>
    </div>
  );
};
export default CardSearch;
