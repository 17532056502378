import axios from "axios";
import { ThunkAction } from "redux-thunk";
import { Action } from "typesafe-actions";
import { ENTITIES_BACKEND } from "../../backend-connection";
import { ITransformParams } from "../../components/core/forms/transformations/transform-params";
import { ConstraintType } from "../../models/enums/constraint-type";
import { DictionaryObject } from "../../models/types/dictionary";
import { IViewField } from "../../models/view-field";
import { IAppState } from "../states/state";
import { showError } from "./errorsActions";
import { loadRelatedCard, refreshItem } from "./newEntitiesActions";

export const relateSimpleCard = (params: ITransformParams): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => { 
  const { result, selectedItem, currentModel, cardModel } = params;
    const updateFunc = () => {
      dispatch(
        refreshItem(
          currentModel.dbname,
          (selectedItem as DictionaryObject)["id"]?.toString() || "0"
        )
      );
    };
    if (!selectedItem || !result || !cardModel) {
      return;
    }
    dispatch(existAndNotSimpleCard(params, updateFunc));
  };

const getSimpleCard = (
  reflectField: IViewField,
  result: DictionaryObject
): Promise<any> => {
  return axios(
    `${ENTITIES_BACKEND}/findAll?modelName=SimpleCard&page.showDeleted=false`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify([
        {
          field: reflectField.name + ".id",
          type: ConstraintType.Eq,
          value: (result as DictionaryObject)["id"] as number,
        },
      ]),
    }
  );
};

const ifNoAnySimpleCard = (params: ITransformParams) => {
  const { result, currentModel, selectedModel, cardModel } = params;
  if (!cardModel) {
    return null;
  }

  const currentField = cardModel.fields.find(
    (f: IViewField) => f.model === currentModel.dbname
  );
  const reflectField = cardModel.fields.find(
    (f: IViewField) => f.model === selectedModel.dbname
  );
  if (!currentField || !reflectField) {
    return;
  }
  const card = { id: 0 } as DictionaryObject;
  card[reflectField.name] = [result];
  return card;
};

const ifHasSimpleCardResult = (
  params: ITransformParams,
  card: DictionaryObject
) => {
  const { selectedItem, currentModel, cardModel } = params;
  if (!cardModel) {
    return null;
  }

  const currentField = cardModel.fields.find(
    (f: IViewField) => f.model === currentModel.dbname
  );
  if (!currentField) {
    return;
  }
  const _card = { ...card } || ({} as DictionaryObject);
  _card[currentField.name] = _card[currentField.name] || [];
  (_card[currentField.name] as DictionaryObject[]).push(
    selectedItem as DictionaryObject
  );
  return card;
};

const ifHasSimpleCardCurrent = (
  params: ITransformParams,
  card: DictionaryObject
) => {
  const { result, selectedModel, cardModel } = params;
  if (!cardModel) {
    return null;
  }

  const reflectField = cardModel.fields.find(
    (f: IViewField) => f.model === selectedModel.dbname
  );
  if (!reflectField) {
    return;
  }
  const _card = { ...card } || ({} as DictionaryObject);
  _card[reflectField.name] = _card[reflectField.name] || [];
  (_card[reflectField.name] as DictionaryObject[]).push(
    result as DictionaryObject
  );
  return card;
};

const existAndNotSimpleCard = (
  params: ITransformParams,
  updateFunc: () => void
): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  const {
    result,
    selectedItem,
    currentModel,
    selectedModel,
    cards,
    cardModel,
  } = params;
  if (!selectedItem || !result || !cardModel) {
    return;
  }
  const currentField = currentModel.fields.find(
    (f: IViewField) => f.model === "SimpleCard"
  );
  const reflectField = selectedModel.fields.find(
    (f: IViewField) => f.model === "SimpleCard"
  );

  if (!currentField || !reflectField) {
    return;
  }

  const currentCard = cards.get("SimpleCard");
  const reflectCard = (result as DictionaryObject)[reflectField.name];

  if (currentCard && reflectCard) {
    if (
      (currentCard as DictionaryObject)["id"] ===
      (reflectCard as DictionaryObject)["id"]
    ) {
      dispatch(showError("ERROR.CardExist"));
    } else {
      dispatch(showError("ERROR.BothCards"));
    }
    return;
  } else if (currentCard && !reflectCard) {
    const card = ifHasSimpleCardCurrent(params, currentCard);
    if (card) {
      dispatch(loadRelatedCard("SimpleCard", card));
    }
  } else if (!currentCard && reflectCard) {
    const cardField = cardModel.fields.find(
      (f: IViewField) => f.model === selectedModel.dbname
    );
    if (!cardField) {
      return null;
    }
    getSimpleCard(cardField, result as DictionaryObject).then((response) => {
      if (response && response.data && response.data.length) {
        const card = ifHasSimpleCardResult(params, response.data[0]);
        if (card) {
          dispatch(loadRelatedCard("SimpleCard", card));
        }
      }
    });
  } else {
    const card = ifNoAnySimpleCard(params);
    if (card) {
      dispatch(loadRelatedCard("SimpleCard", card));
    }
  }
};
