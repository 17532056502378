import { FieldFactory } from "./../models/fields/field-factory";
import { IViewField } from "./../models/view-field";
import { IViewModel } from "./../models/view-model";
import { IConstraintTree } from "./../models/constraint";
import { IConstraint } from "../models/constraint";

export const filterToConstraint = (
  filter: any,
  prefix = "",
  models: Map<string, IViewModel>,
  modelName: string
) => {
  let constraints: IConstraint[] = [];

  const model = models.get(modelName);

  if (!model || !model.fields || !model.fields.length) {
    return constraints;
  }

  if (Object.keys(filter).length === 0) {
    return constraints;
  }
  Object.keys(filter).forEach((k) => {
    if (filter[k]) {
      const fieldName = getFieldName(k);
      const foundField = model.fields.find(
        (f: IViewField) => fieldName.split(".")[0] === f.name
      );
      if (foundField) {
        if(foundField.customFilter) {
          if(foundField.customFilter.constraints) {
            constraints = [...constraints, ...foundField.customFilter.constraints(filter[k], filter)];
          }
        } else {
          const generalField = new FieldFactory(foundField).createField();
          if (Array.isArray(filter[k])) {
            constraints.push({
              type: "In",
              field: prefix + k,
              values: filter[k],
            } as IConstraint);
          } else {
            if (k.includes("__EMPTY")) {
              constraints.push(generalField.emptyConstraint(prefix + fieldName));
            } else {
              constraints = [
                ...constraints,
                ...generalField.valueConstraint(prefix + fieldName, filter[k]),
              ];
            }
          }
  
          if (
            Object.keys(filter[k]).length > 0 &&
            (!filter[k] || !filter[k]["id"])
          ) {
            constraints = [
              ...constraints,
              ...filterToConstraint(
                filter[k],
                k + ".",
                models,
                generalField.viewField.model
              ),
            ];
          }
        }
        
      } else {

        if (k.includes("_RELATED_")) {

          constraints.push({
            type: 11,
            field: fieldName,
            value: "%" + k.substring(k.indexOf("_RELATED_") + 9) + "/" + filter[k].id + "%",
          } as IConstraint);
        }
      }
    }
  });

  return constraints;
};

export function filterContraintToTree(
  constraints: IConstraint[]
): IConstraintTree {
  const tree: IConstraintTree = {
    rule: "And",
    constraints: [],
    nodes: [],
  };
  if (!constraints || !constraints.length) return tree;

  const groupedConstraints: { [k: string]: IConstraint[] } = constraints.reduce(
    (result: { [k: string]: IConstraint[] }, constraint: IConstraint) => {
      let name = constraint.field;
      if (name.substr(name.length - 7, 7) === "__EMPTY") {
        name = name.substr(0, name.length - 7);
      }

      result[name] = result[name] || [];

      result[name].push(constraint);

      return result;
    },
    {}
  );
  Object.keys(groupedConstraints).forEach((key: string) => {
    if (groupedConstraints[key].length === 1) {
      tree.constraints.push(groupedConstraints[key][0]);
    } else if (groupedConstraints[key].length > 1) {

      const emptyConstraints = groupedConstraints[key].filter((costraint: IConstraint) => costraint.type === "Null" || costraint.type === "NullOrEmpty");
      const valueConstraints = groupedConstraints[key].filter((costraint: IConstraint) => costraint.type !== "Null" && costraint.type !== "NullOrEmpty");

      tree.nodes.push({
        rule: "Or",
        constraints: [],
        nodes: [
          {
            rule: "And",
            constraints: emptyConstraints,
            nodes: [],
          },
          {
            rule: "And",
            constraints: valueConstraints,
            nodes: [],
          },
        ],
      });
    }

  });
  return tree;
}

export const getFieldName = (name: string): string => {
  if (name.substr(name.length - 7, 7) === "__EMPTY") {
    name = name.substr(0, name.length - 7);
  }
  if (name.indexOf("_RELATED_") !== -1) {
    name = name.substr(0, name.indexOf("_RELATED_"));
    name += "Calc"
  }
  return name;
};
