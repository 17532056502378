import { Col, Row } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Views } from "../../../../../../models/enums/views";
import { FormField } from "../../../../../../models/fields/form-field";
import { IViewField } from "../../../../../../models/view-field";
import { IViewModel } from "../../../../../../models/view-model";
import CustomFormField from "../../../fields/form-types/FormTypes";
import { bindActionCreators, Dispatch } from "redux";
import "./ExpredForm.scss";
import { DictionaryArray, DictionaryObject } from "../../../../../../models/types/dictionary";
import { connect } from "react-redux";
import { InjectedFormProps, reduxForm } from "redux-form";
import { Button, Intent } from "@blueprintjs/core";
import { setConfirm } from "../../../../../../redux/actions/alertActions";
import { createEntity, deactivateEntity, updateEntity } from "../../../../../../redux/actions/entitiesActions";
import { IStateAlerts } from "../../../../../../redux/states/state-alerts";
import { getSamples } from "../../../../../../redux/actions/aemActions";
import { saveTryValue } from "../../../../../../redux/actions/newSequencesActions";
import { removeEmptyObject } from "../../../../../../helpers/remove-empty-object";
import { extendedPreview } from "../../../../../../redux/actions/printActions";

interface Props extends WithTranslation {
    referenceModel: IViewModel;
    relatedField: IViewField;
    sample: DictionaryObject | null;
    selectedItem: DictionaryObject;
    form: string;
    closeForm: () => void;
    refreshSamples: () => void;
    setConfirm: (props: IStateAlerts) => void;
    deactivateEntity: (modelName: string, sample: DictionaryObject, updateCallback?: () => void) => void;
    updateEntity: (modelName: string, data: DictionaryObject, sequences: string[], item: DictionaryObject, updateCallback?: () => void) => void;
    createEntity: (modelName: string, data: DictionaryObject, sequences: string[], item: DictionaryObject, updateCallback?: () => void) => void;
    extendedPreview: (value: string, action: string, fileName?: string) => void;
}

interface State {

}

class ExpredForm extends React.PureComponent<Props & InjectedFormProps<{}, Props>, State> {
    constructor(props: Props & InjectedFormProps<{}, Props>) {
        super(props);
        this.state = {

        };
    }

    submitForm = (values: DictionaryObject) => {
        if (!this.props.valid) return;
        if (values && values['id']) {
            this.props.updateEntity("Ekspred", removeEmptyObject(values), [], removeEmptyObject(values), () => this.props.refreshSamples());
        } else {
            values["Registration"] = this.props.selectedItem;
            this.props.createEntity("Ekspred", removeEmptyObject(values), ["ExpredNumber"], removeEmptyObject(values), () => {
                this.props.refreshSamples();
            });
        }
    };

    allowSave = (sample: DictionaryObject | null) => {
        if (!sample) return true;
        return !sample['AnalysisSend'];
    }

    allowDelete = (sample: DictionaryObject | null) => {
        if (!sample) return false;
        return !sample['AnalysisSend'];
    }

    removeSample = (sample: DictionaryObject) => {
        const { t } = this.props;
        this.props.setConfirm({
            message: t("UI.Sure"),
            isOpen: true,
            onConfirm: () => {
                this.props.deactivateEntity("Ekspred", sample, () => this.props.refreshSamples());
            }
        });
    };

    render() {
        const { referenceModel, relatedField, sample, form, t, handleSubmit } = this.props;
        return (<form onSubmit={handleSubmit(this.submitForm)} className="expred-form">
            <Row>
                {referenceModel
                    ? referenceModel.fields
                        .filter((field) => relatedField.referenceView?.includes(field.name))
                        .map((field: IViewField) => {
                            const onChange = (e: any) => {
                            };
                            return (
                                <Col
                                    span={(24 * field.col) / referenceModel.grid}
                                >
                                    <CustomFormField
                                        {...new FormField(
                                            //{ ...field, readonly: !(writables?.indexOf(field.name) > -1) },
                                            { ...field, writable: true },
                                            field.name,
                                            sample,
                                            Views.FORM,
                                            true,
                                            false,
                                            form,
                                            false,
                                            false,
                                            undefined,
                                            onChange
                                        )}
                                    />
                                </Col>
                            );
                        })
                    : null}

                <Row className="expred-form__actions">
                    {sample && (sample.ScanDocuments as DictionaryArray).length > 0 && <Button
                        key={"ScanDocuments"}
                        intent={Intent.PRIMARY}
                        onClick={() => {
                            let ScanDocuments = sample.ScanDocuments as DictionaryArray;
                            let record = ScanDocuments && ScanDocuments[ScanDocuments?.length - 1] as DictionaryObject;
                            record && record["ScanDocumentNumber"] && this.props.extendedPreview(record["FileUid"] as string, "preview", record["ScanDocumentNumber"] as string);
                        }}
                    >
                        {t("UI.PreviewAct")}
                    </Button>}
                    {sample && sample['id'] && <Button intent={Intent.DANGER} disabled={!this.allowDelete(sample)} onClick={() => this.removeSample(sample)}>{t("UI.Remove")}</Button>}


                    <Button intent={Intent.PRIMARY} type="submit" disabled={!this.allowSave(sample)}>{t("UI.Save")}</Button>
                    <Button onClick={() => {
                        if (this.props.dirty) {
                            this.props.setConfirm({
                                message: t("UI.CloseMessage"),
                                isOpen: true,
                                onConfirm: () => {
                                    this.props.closeForm();
                                }
                            })
                        } else {
                            this.props.closeForm();
                        }
                    }}>{t("UI.Close")}</Button>
                </Row>
            </Row>
        </form>)
    }
};

const mapStateToProps = (state: {

}) => ({

});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            deactivateEntity: (modelName, sample, updateCallback) => deactivateEntity(modelName, sample, updateCallback),
            setConfirm: (props) => setConfirm(props),
            updateEntity: (modelName, data, sequences, item, updateCallback) => updateEntity(modelName, data, sequences, item, updateCallback),
            createEntity: (modelName, data, sequences, item, updateCallback) => createEntity(modelName, data, sequences, item, updateCallback),
            getSamples: (selectedItem) => getSamples(selectedItem),
            saveTryValue: (sequences) => saveTryValue(sequences),
            extendedPreview: (value, action, fileName) => extendedPreview(value, action, fileName)
        },
        dispatch
    );

const ReduxExpredForm = reduxForm<{}, Props>({
    enableReinitialize: true
})(ExpredForm);

const ConnectedExpredForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(ReduxExpredForm);

export default withTranslation()(ConnectedExpredForm);
