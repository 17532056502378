import { IConstraintTree } from "./../../models/constraint";
import axios from "axios";
import { saveAs } from "file-saver";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { TEMPLATES_BACKEND } from "../../backend-connection";
import { ITemplate } from "../../models/template";
import { deleteFile, saveFile, uploadFile } from "./attachmentsActions";
import { showError, showSuccess } from "./errorsActions";
import { project } from "../../configs";
import { IAppState } from "../states/state";

export const LOAD_TEMPLATES = "LOAD_TEMPLATES";
export const REMOVE_TEMPLATE = "REMOVE_TEMPLATE";
export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
export const LOADING_TEMPLATES = "LOADING_TEMPLATES";
export const SET_TEMPLATES_TOTAL_COUNT = "SET_TEMPLATES_TOTAL_COUNT";

export function loadTemplates(templates: ITemplate[]) {
  return {
    type: LOAD_TEMPLATES,
    payload: templates,
  };
}

export function loadingTemplates(loading: boolean) {
  return {
    type: LOADING_TEMPLATES,
    payload: loading,
  };
}

export function setTemplatesTotalCount(totalCount: number) {
  return {
    type: SET_TEMPLATES_TOTAL_COUNT,
    payload: totalCount,
  };
}

export function removeTemplate(name: string) {
  return {
    type: REMOVE_TEMPLATE,
    payload: name,
  };
}

export function changeTemplate(template: ITemplate) {
  return {
    type: UPDATE_TEMPLATE,
    payload: template,
  };
}

export const createTemplate = (template: ITemplate):ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => { 
    saveFile(template.attachmentId).then((res) => {
      return axios(`${TEMPLATES_BACKEND}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(template),
      })
        .then((result) => {
          dispatch(showSuccess("template saved!"));
          dispatch(getTemplates(0, 15));
        })
        .catch((err) => dispatch(showError("create template error")));
    });
  };

export const updateTemplate = (template: ITemplate, formData: FormData | null):ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  if (formData) {
      uploadFile(formData).then((res) => {
        deleteFile(template.attachmentId).then((response) => {
          if (res && res.data && res.data.id) {
            saveFile(res.data.id).then((response) => {
              template.attachmentId = res.data.id;
              return axios(`${TEMPLATES_BACKEND}/` + template.name, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                },
                data: JSON.stringify(template),
              })
                .then((result) => {
                  dispatch(showSuccess("template changed!"));
                  dispatch(changeTemplate(template));
                })
                .catch((err) => dispatch(showError("create template error")));
            });
          }
        });
      });
  } else {
      return axios(`${TEMPLATES_BACKEND}/` + template.name, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(template),
      })
        .then((result) => {
          dispatch(showSuccess("template changed!"));
          dispatch(changeTemplate(template));
        })
        .catch((err) => dispatch(showError("create template error")));
    };
}

export const deleteTemplate = (name: string, fileId: string):ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
    return axios(`${TEMPLATES_BACKEND}/` + name, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        dispatch(showSuccess("template deleted!"));
        dispatch(removeTemplate(name));
        deleteFile(fileId).then(() => { });
      })
      .catch((err) => dispatch(showError("template delete error")));
  };

export const getTemplates = (page: number, pageSize: number): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  dispatch(loadingTemplates(true));
  return axios(
    `${TEMPLATES_BACKEND}/?page=` +
    page +
    "&pageSize=" +
    pageSize +
    "&deleted=false",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((result) => {
      if (result.data && result.data.length) {
        dispatch(loadingTemplates(false));
        dispatch(loadTemplates(result.data));
        dispatch(getTemplateCount());
      }
    })
    .catch((err) => {
      dispatch(loadingTemplates(false));
      dispatch(showError("template get error"));
    });
};

export const getTemplateCount = (): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  axios(`${TEMPLATES_BACKEND}/count?deleted=false`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((result) => {
      if (result && result.data) {
        dispatch(setTemplatesTotalCount(result.data));
      }
    })
    .catch((err) => { });
};

export const printFormV2 = (
  name: string,
  modelName: string,
  constraints: IConstraintTree,
  pfTitle: string,
  printService: boolean,
  action: string,
  id?: string,
  printReason?: string,
  printCount?: string
): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  const queryId = id ? "&id=" + id : "";
  const reason = printReason ? "&reason=" + printReason : "";
  const count = printCount ? "&count=" + printCount : "";
  const printUrl = (printService ? (project.config as any).printUrl + '/api/document/print/' : TEMPLATES_BACKEND + "/printV2/") + name;
  return axios(
    printUrl + "?modelName=" + modelName + queryId + "&action=" + action + reason + count,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "arraybuffer",
      data: JSON.stringify(constraints),
    }
  )
    .then((result) => {
      if (!printService) {
        const fileType = result.headers["content-type"];
        const blob = new File([result.data], pfTitle, { type: fileType });
        saveAs(blob);
      }
      if (result.status === 406) {
        dispatch(showError("ERROR.406"))
      }
    })
    .catch((err) => dispatch(showError("ERROR.4501")));
};
