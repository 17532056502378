import { IViewModel } from "../../models/view-model";
import {
  LOAD_MODEL,
  LOADING_MODELS,
  SET_ACTIVE_MODEL,
  SET_ACTIVE_FORM
} from "../actions/modelsActions";
import { IStateModels } from "../states/state-models";

const INITIAL_STATE: IStateModels = {
  models: new Map<string, IViewModel>(),
  activeModel: "IncomingDocument",
  loading: false,
  archived: false,
  dbname: "IncomingDocument",
  activeAllowCreate: true,
  activeRoute: '',
  statesFilter: undefined,
};

const modelsReducer = (
  state = INITIAL_STATE,
  action: { type: string; payload: any }
) => {
  const prevState = state;
  switch (action.type) {
    case LOAD_MODEL:
      const { models } = prevState;
      models.set(action.payload.name, action.payload.model);
      return {
        ...prevState,
        models: models,
      };
    case LOADING_MODELS:
      return {
        ...prevState,
        loading: action.payload,
      };
    case SET_ACTIVE_MODEL:
      return {
        ...prevState,
        activeModel: action.payload.modelName,
        activeAllowCreate: action.payload.allowCreate,
        dbname: action.payload.dbname,
        statesFilter: action.payload.statesFilter,
        archived: action.payload.archived,
        activeRoute: action.payload.activeRoute 
      };
    case SET_ACTIVE_FORM:
      return {
        ...prevState,
        activeForm: action.payload.activeForm
      };
    default:
      return prevState;
  }
};

export default modelsReducer;
