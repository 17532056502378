import { Tag } from "@blueprintjs/core";
import React from "react";
import { useDispatch } from "react-redux";
import { nextValue } from "../../../../../redux/actions/sequencesActions";
import "./Generate.scss";

interface Props {
  sequence: string;
  onChange: (e: any) => void;
}

export const Generate = (props: Props) => {
  const { sequence, onChange } = props;
  const dispatch = useDispatch();

  const generate = () => {
    nextValue(sequence).then((res) => {
      onChange(res.data);
    });
  }
  const relieve = () => {
  }

  return (
    <div className="generate-row">
      <Tag minimal icon="refresh" onClick={generate}></Tag>
      <Tag minimal icon="cross" onClick={relieve}></Tag>
    </div>
  );
};

export default Generate;
