import React from "react";
import "./TablePopup.scss";
import { IViewField } from "../../../../../models/view-field";
import { Views } from "../../../../../models/enums/views";
import { useDispatch } from "react-redux";
import { checkShowConstraints } from "../../../../../helpers/check-show-constraints";
import { autofill, FieldArray, WrappedFieldArrayProps } from "redux-form";
import TablePopupForm from "../../controls/table-popup-form/TablePopupForm";
import EntityFieldFilter from "../../../filters/fields/entity-field/EntityFieldFilter";

const renderTablePopup = ({
  fields,
  meta: { error, submitFailed },
  field,
  selectedItem,
  formName,
  disabled
}: WrappedFieldArrayProps & {
  field: IViewField;
  selectedItem: any;
  formName: string;
  disabled: boolean;
}) => {
  return (
    <div className="row-input">
      <TablePopupForm
        formName={formName}
        fields={fields}
        f={field}
        disabled={disabled}
        selectedItem={selectedItem}
      />
    </div>
  );
};

export const TablePopup = (props: {
  f: IViewField;
  selectedItem: any;
  view: Views;
  onChange?: (e: any) => void;
  fieldName: string;
  listeners?: Map<string, any>;
  formName: string;
}) => {
  const { f, selectedItem, fieldName, view, formName } = props;
  const dispatch = useDispatch();
  const TablePopupFilterView = () => {
    return <EntityFieldFilter {...props} />;
  };

  const TablePopupFormView = () => {
    const { listeners } = props;
    const show = checkShowConstraints(f, listeners);
    if (show) {
      return (
        <FieldArray
          key={f.name}
          name={fieldName}
          field={f}
          formName={formName}
          selectedItem={selectedItem}
          disabled={f.readonly}
          component={renderTablePopup}
        />
      );
    } else {
      dispatch(
        autofill(
          "OutgoingDocument__FORM",
          f.name,
          selectedItem ? selectedItem[f.name] : []
        )
      );
      dispatch(
        autofill(
          "IncomingDocument__FORM",
          f.name,
          selectedItem ? selectedItem[f.name] : []
        )
      );
      return <span />;
    }
  };

  switch (view) {
    case Views.FILTER:
      return TablePopupFilterView();
    case Views.FORM:
      return TablePopupFormView();
    case Views.REFERENCE:
      return <span>Text</span>;
    default:
      return <span>Text</span>;
  }
};
export default TablePopup;
