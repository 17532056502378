import { Intent } from "@blueprintjs/core";
import { createAction } from "typesafe-actions";
import { errors } from "../constants";

export const clearToasts = createAction(errors.CLEAR_TOASTS);

export const toast = createAction(
  errors.ENQUEUE_TOAST,
  (resolve) => (toastObject: any) => resolve(toastObject)
);

export const dismissToast = createAction(
  errors.DISMISS_TOAST,
  (resolve) => (dismiss: any) => resolve(dismiss)
);

export function showError(errorMessage: string) {
  return toast({
    iconName: "credit-card",
    intent: Intent.DANGER,
    message: errorMessage,
  });
}

export function showWarning(errorMessage: string) {
  return toast({
    iconName: "credit-card",
    intent: Intent.WARNING,
    message: errorMessage,
  });
}

export function showSuccess(message: string) {
  return toast({
    iconName: "credit-card",
    intent: Intent.SUCCESS,
    message: message,
  });
}

export function showInfo(message: string) {
  return toast({
    iconName: "credit-card",
    intent: Intent.PRIMARY,
    message: message,
  });
}
