import React from "react";
import { useDispatch } from "react-redux";
import { autofill, FieldArray, WrappedFieldArrayProps } from "redux-form";
import { checkShowConstraints } from "../../../../../helpers/check-show-constraints";
import { Views } from "../../../../../models/enums/views";
import { IViewField } from "../../../../../models/view-field";
import { mandatory } from "../../../../../validation/validate";
import EntityFieldFilter from "../../../filters/fields/entity-field/EntityFieldFilter";
import ArrayFillForm from "../../controls/array-fill-form/ArrayFillForm";
import "./ArrayField.scss";

const renderArrayField = ({
  fields,
  meta: { error, submitFailed },
  field,
  selectedItem,
  formName
}: WrappedFieldArrayProps & {
  field: IViewField;
  selectedItem: any;
  disabled?: boolean;
  formName: string;
}) => {
  return (
    <div className="row-input">
      <ArrayFillForm
        error={error}
        submitFailed={submitFailed}
        formName={formName}
        fields={fields}
        f={field}
        selectedItem={selectedItem}
      />
    </div>
  );
};

export const ArrayField = (props: {
  f: IViewField;
  selectedItem: any;
  view: Views;
  fieldName: string;
  listeners?: Map<string, any>;
  formName: string;
  isDraft: boolean;
}) => {
  const { f, selectedItem, fieldName, view, formName, isDraft } = props;
  const dispatch = useDispatch();

  const ArrayFilterView = () => {
    return <EntityFieldFilter {...props} />;
  };

  const ArrayFormView = () => {
    const { listeners } = props;
    const show = checkShowConstraints(f, listeners);
    if (show) {
      return (
        <FieldArray
          key={f.name}
          name={fieldName}
          field={f}
          selectedItem={selectedItem}
          component={renderArrayField}
          formName={formName}
          validate={f.required && !isDraft ? mandatory : undefined}
        />
      );
    } else {
      dispatch(
        autofill(
          "OutgoingDocument__FORM",
          f.name,
          selectedItem ? selectedItem[f.name] : []
        )
      );
      dispatch(
        autofill(
          "IncomingDocument__FORM",
          f.name,
          selectedItem ? selectedItem[f.name] : []
        )
      );
      return <span />;
    }
  };

  switch (view) {
    case Views.FILTER:
      return ArrayFilterView();
    case Views.FORM:
      return ArrayFormView();
    case Views.REFERENCE:
      return <span>Text</span>;
    default:
      return <span>Text</span>;
  }
};

export default ArrayField;
