import { Button, Label, Tag } from "@blueprintjs/core";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FieldArrayFieldsProps } from "redux-form/lib/FieldArray";
import { IConstraint } from "../../../../../models/constraint";
import { Views } from "../../../../../models/enums/views";
import { FieldFactory } from "../../../../../models/fields/field-factory";
import { ISequenceState } from "../../../../../models/sequence-state";
import { DictionaryArray, DictionaryObject } from "../../../../../models/types/dictionary";
import { IViewField } from "../../../../../models/view-field";
import { referenceFormIsOpen } from "../../../../../redux/actions/referencesActions";
import ReferenceView from "../../../lists/reference-view/ReferenceView";
import ReferenceField from "../../fields/reference-field/ReferenceField";
import "./ManyFillForm.scss";

interface Props {
  f: IViewField;
  selectedItem: any;
  fields: FieldArrayFieldsProps<any>;
  constraints?: IConstraint[];
  formName: string;
}

export const ManyFillForm = (props: Props) => {
  const { f, selectedItem, fields, formName } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const defaultObject = (f: IViewField) => {
    const field = new FieldFactory(f).createField();
    return field.defaultValue(
      selectedItem,
      new Map<string, ISequenceState>(),
      false
    );
  };

  const onAdd = () => {
    if (fields && fields.length > 0) {
      const defaultObj = defaultObject(f) as DictionaryArray;
      const prevField = fields.get(fields.length - 1);
      if (
        Object.keys(prevField).filter(
          (key) => key !== "default" && !!prevField[key]
        ).length > 0
      ) {
        fields.push({ ...(defaultObj[0] as DictionaryObject), default: true });
        prevField.default = undefined;
      }
    }
  };

  const editItem = (item: DictionaryObject) => {
    dispatch(referenceFormIsOpen(f.model, true, item, () => null));
  };


  const onChange = (e: any) => {
    if (!e) { return; }
    e.default = true;
  };
  return (
    <Row>
      <Row className="array-field__header">
        <Col span={24}>
          <Label>
            <span>{t(f.name)}</span>
            <span className="required">{f.required ? "*" : ""}</span>
          </Label>
        </Col>
      </Row>
      <Row className="array-field" gutter={16} key={f.name}>
        <Col className="array-field__form" span={12}>
          <Row>
            <Col span={16}>
              {fields.map((field1: string, index: number) => {
                return (
                  <div
                    key={field1 + index}
                    className={
                      index === fields.length - 1 ? "" : "display-none"
                    }
                  >
                    <ReferenceField
                      f={{ ...f, allowEdit: false }}
                      selectedItem={null}
                      view={Views.FORM}
                      hidden={false}
                      isDraft={false}
                      fieldName={field1}
                      onChange={onChange}
                      showLabel={true}
                      formName={formName}
                    />
                  </div>
                );
              })}
            </Col>
            <Col span={8}>
              <div className="row button-row">
                <Button icon="plus" small onClick={() => onAdd()} />
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <div className="block-result">
            {fields
              ? fields.map((name: any, index: number) => {
                const item = fields.get(index);
                if (item.default) {
                  return null;
                }

                const onRemove = (index: number) => {
                  fields.remove(index);
                  if (fields.length > 0) {
                    const prevField = fields.get(fields.length - 1);
                    prevField.default = true;
                  }
                };
                return (
                  <Tag
                    key={index}
                    intent={Intent.NONE}
                    multiline
                    onRemove={() => onRemove(index)}
                  >
                    <>
                      <ReferenceView
                        viewArray={f.referenceView || []}
                        item={item}
                        field={{ ...f, component: "Single" }}
                      />
                      {/* {f.allowEdit ? <Button icon="edit" onClick={() => editItem(item)}/> : null} */}
                    </>
                  </Tag>
                );
              })
              : null}
          </div>
          {f.detailedView && <div className="block-result"> {fields
            ? fields.map((name: string, index: number) => {
              const item = fields.get(index);
              if (item.default) {
                return null;
              }
              return (
                <Tag
                  key={index}
                  intent={Intent.NONE}
                  multiline
                >
                  {f.numerable && (index + 1) + ")"}
                  <ReferenceView
                    field={{ ...f, component: "Single" }}
                    viewArray={f.detailedView || []}
                    showFields={true}
                    delimiter=", "
                    item={item} />
                </Tag>)
            }) : null
          }
          </div>}
        </Col>
      </Row>
    </Row>
  );
};

export default ManyFillForm;
