import { Button, Classes } from "@blueprintjs/core";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import { Spin } from "antd";
// @ts-ignore
import classNames from "classnames";
import React, { ReactElement } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { InjectedFormProps, reduxForm } from "redux-form";
import { checkRequiredFields } from "../../../../../helpers/check-required-fields";
import {
  getState,
  getTransformationsByState,
} from "../../../../../helpers/entities";
import { formListeners } from "../../../../../helpers/form-listeners";
import { removeEmptyObject } from "../../../../../helpers/remove-empty-object";
import { getSequences } from "../../../../../helpers/sequences";
import { withPermissions } from "../../../../../hocs/WithPermissions";
import { ModelType } from "../../../../../models/enums/model-type";
import {
  Dictionary,
  DictionaryObject,
} from "../../../../../models/types/dictionary";
import { IViewField } from "../../../../../models/view-field";
import { ITab, IViewModel } from "../../../../../models/view-model";
import { setConfirm } from "../../../../../redux/actions/alertActions";
import {
  createEntity,
  deactivateEntity,
  deleteEntity,
  restoreEntity,
  selectItem,
  updateEntity,
} from "../../../../../redux/actions/entitiesActions";
import { showError } from "../../../../../redux/actions/errorsActions";
import { IAppState } from "../../../../../redux/states/state";
import { IStateAlerts } from "../../../../../redux/states/state-alerts";
import CopyPaste from "../../copy-paste/CopyPaste";
import CustomGrid from "../../custom-grids/custom-grid/CustomGrid";
import PrintButtons from "../../print-buttons/PrintButtons";
import StateChange from "../../state-management/state-change/StateChange";
import Transformation from "../../transformations/transformation/Transformation";
import { InnerTable } from "../inner-table/inner-table/InnerTable";
import "./ReduxForm.scss";

interface Props extends WithTranslation {
  tab: ITab;
  selectedItem: any;
  mainEntity: boolean;
  hideId: boolean;
  model?: IViewModel;
  relatedCard: DictionaryObject | null;
  type: ModelType;
  component?: ReactElement;
  createEntity: (
    modelName: string,
    data: DictionaryObject,
    sequences: string[],
    selectedItem: DictionaryObject,
    updateCallback: (close: boolean, item: DictionaryObject | null) => void,
    callback?: (item: Dictionary) => Dictionary,
    transformCallback?: (item: Dictionary) => void
  ) => void;
  updateEntity: (
    modelName: string,
    data: DictionaryObject,
    sequences: string[],
    selectedItem: DictionaryObject,
    updateCallback: (close: boolean, item: DictionaryObject | null) => void,
    callback?: (item: Dictionary) => Dictionary,
    transformCallback?: (item: Dictionary) => void
  ) => void;
  deactivateEntity: (
    modelName: string,
    data: DictionaryObject,
    updateCallback: (close: boolean, item: DictionaryObject | null) => void,
    callback?: (item: Dictionary) => Dictionary
  ) => void;
  deleteEntity: (
    modelName: string,
    data: DictionaryObject,
    updateCallback: (close: boolean, item: DictionaryObject | null) => void,
    callback?: (item: Dictionary) => Dictionary
  ) => void;
  restoreEntity: (
    modelName: string,
    data: DictionaryObject,
    updateCallback: (close: boolean, item: DictionaryObject | null) => void,
    callback?: (item: Dictionary) => Dictionary
  ) => void;
  selectItem: (data: DictionaryObject) => void;
  listeners: Map<string, any>;
  onSubmit: (v: any) => void;
  hasPermits: (permit: string) => boolean;
  formLoading: boolean;
  changeLoader: (loading: boolean) => void;
  showError: (error: string) => void;
  onClose: () => void;
  saveTryValues: () => void;
  updateCallback: (close: boolean, item: DictionaryObject | null) => void;
  transformCallback: (item: Dictionary) => void;
  callback?: (result: Dictionary) => Dictionary;
  models: Map<string, IViewModel>;
  setConfirm: (props: IStateAlerts) => void;
}
const selectNestedEntities = (model: IViewModel) => {
  if (!model) {
    return [];
  }
  return model.fields.filter((f: IViewField) => f.component === "OneToOne");
};

class ReduxForm extends React.PureComponent<
  Props & InjectedFormProps<{}, Props>
> {
  state = {
    loading: false,
    isDraft: false,
  };
  componentWillUnmount() {
    this.props.destroy();
  }

  updateNestedEnities = (model: IViewModel, item: DictionaryObject) => {
    const relatedEntities = selectNestedEntities(model);
    relatedEntities.forEach((re: IViewField) => {
      const nestedEntity = item[re.name] as DictionaryObject;
      if (nestedEntity && nestedEntity["id"]) {
        this.props.updateEntity(re.model, nestedEntity, [], item, () => null);
      }
    });
  };

  submitForm = (values: any) => {
    const {
      model,
      tab,
      saveTryValues,
      selectedItem,
      updateCallback,
      callback,
      transformCallback,
    } = this.props;
    const { isDraft } = this.state;

    if (model && values) {
      if (!isDraft && !checkRequiredFields(model.fields, values)) {
        this.props.showError("ERROR.CheckFields");
      } else {
        values["IsDraft"] = isDraft;
        this.setState({ loading: true });
        const sequences = getSequences(
          true,
          tab,
          model,
          this.props.initialValues
        );
        if (values["id"]) {
          this.props.updateEntity(
            model.dbname,
            removeEmptyObject(values),
            sequences,
            removeEmptyObject(values),
            (close, item) => {
              if (saveTryValues) {
                saveTryValues();
              }
              updateCallback(close, item);
              this.setState({ loading: false });
            },
            () => null,
            transformCallback
          );
          this.updateNestedEnities(model, values);
        } else {
          this.props.createEntity(
            model.dbname,
            removeEmptyObject(values),
            sequences,
            removeEmptyObject(values),
            (close, item) => {
              if (saveTryValues) {
                saveTryValues();
              }
              updateCallback(close, item);
              this.setState({ loading: false });
            },
            callback,
            transformCallback
          );
        }
      }
    }
  };

  changeEntityActivity = (values: any) => {
    const { model, updateCallback } = this.props;
    if (model && values["id"] && !values["deleted"])
      this.props.deactivateEntity(model.dbname, values, updateCallback);

    if (model && values["id"] && values["deleted"])
      this.props.restoreEntity(model.dbname, values, updateCallback);
  };

  deleteEntity = (values: any) => {
    const { model, updateCallback } = this.props;
    if (model && values["id"])
      this.props.deleteEntity(model.dbname, values, updateCallback);
  };

  handleKeyDown = function (e: any, cb?: any) {
    if (e.key === "Enter" && e.shiftKey === false) {
      e.preventDefault();
    }
  };

  renderTransformation(selectedItem: DictionaryObject, model: IViewModel) {
    const state = getState(selectedItem) || model.defaultState;
    const transformations = getTransformationsByState(state, model);
    if (transformations && transformations.length) {
      return (
        <Transformation
          formName={this.props.form}
          transformations={transformations}
          model={model}
        />
      );
    }
  }

  beforeSumbit = (e: any, isDraft: boolean) => {
    e.preventDefault();
    this.dispatchAction({ isDraft: isDraft }).then(() => {
      this.props.handleSubmit(this.submitForm)();
    });
  };

  dispatchAction = ({ isDraft }: { isDraft: boolean }) =>
    new Promise<void>((resolve) => {
      this.setState({ isDraft: isDraft }, resolve);
    });

  changeItem = (item: Dictionary) => {
    if (!item) {
      return;
    }
    if (typeof item === "object") {
      const newItem = { ...item };
      if ((newItem as DictionaryObject)["id"]) {
        (newItem as DictionaryObject)["id"] = undefined;
        this.props.selectItem(newItem as DictionaryObject);
      }
    }
  };
  render() {
    const {
      selectedItem,
      model,
      t,
      listeners,
      form,
      type,
      tab,
      mainEntity,
      component,
    } = this.props;
    if (!model) {
      return <span>Not a model...</span>;
    }

    return (
      <div style={{ position: "relative" }}>
        {this.state.loading && (
          <Spin tip="Loading..." className={"form-spinner"} />
        )}
        <form
          className="grid-form"
          autoComplete="off"
          onKeyDown={(e) => {
            this.handleKeyDown(e);
          }}
        >
          <div className="dialog__body">
            <div className="dialog__content">
              {component ? component : <span></span>}
              <CustomGrid
                tab={tab}
                hideId={this.props.hideId}
                item={this.props.initialValues}
                isDraft={this.state.isDraft}
                model={model}
                formName={form}
                listeners={listeners}
              />
            </div>
          </div>
          <div
            className={classNames(
              "footer-actions__container",
              Classes.DIALOG_FOOTER
            )}
          >
            <div
              className={classNames(
                "footer-actions__left",
                Classes.DIALOG_FOOTER_ACTIONS
              )}
            >
              {type === ModelType.Dictionary &&
              selectedItem &&
              !selectedItem?.Archived &&
              this.props.initialValues &&
              model &&
              this.props.hasPermits("REFD") ? (
                <Button
                  intent={Intent.DANGER}
                  onClick={() => {
                    this.props.setConfirm({
                      message: t("UI.Sure"),
                      isOpen: true,
                      onConfirm: () => {
                        this.deleteEntity(this.props.initialValues as any);
                      },
                    });
                  }}
                >
                  {t("UI.Remove")}
                </Button>
              ) : null}
              {selectedItem &&
              !selectedItem?.Archived &&
              this.props.initialValues &&
              model &&
              (!model.permits ||
                this.props.hasPermits(model.permits.delete)) ? (
                <Button
                  intent={
                    selectedItem.deleted ? Intent.SUCCESS : Intent.PRIMARY
                  }
                  onClick={() => {
                    this.props.setConfirm({
                      message: t("UI.Sure"),
                      isOpen: true,
                      onConfirm: () => {
                        this.changeEntityActivity(
                          this.props.initialValues as any
                        );
                      },
                    });
                  }}
                >
                  {t(selectedItem.deleted ? "UI.Restore" : "UI.Deactivate")}
                </Button>
              ) : null}
              {mainEntity &&
                this.renderTransformation(this.props.initialValues, model)}
            </div>
            <div
              className={classNames(
                "footer-actions__right",
                Classes.DIALOG_FOOTER_ACTIONS
              )}
            >
              {model.pasteFields && model.pasteFields?.length && (
                <CopyPaste
                  item={selectedItem}
                  model={model}
                  changeItem={(item: any) => {
                    model.pasteFields?.forEach((field: string) =>
                      this.props.change(field, item[field])
                    );
                  }}
                />
              )}
              {model.viewAnalysisDetails &&
                model.analysisDetailsModel &&
                tab.title == "General" && (
                  <InnerTable
                    selectedItem={selectedItem}
                    listeners={listeners}
                    formName={form}
                    parentModel={model}
                    f={model?.fields.find(
                      (field) => field.model === model.analysisDetailsModel
                    )}
                    model={this.props.models.get(model.analysisDetailsModel)}
                    item={this.props.initialValues}
                    type={this.props.type}
                  />
                )}
              {mainEntity && (
                <StateChange
                  selectedItem={selectedItem}
                  type={type}
                  model={model}
                  onClose={this.props.updateCallback}
                />
              )}
              <PrintButtons
                tab={tab}
                selectedItem={selectedItem}
                model={model}
                type={type}
              />
              {model.hasDraft && (
                <Button
                  type="button"
                  intent={Intent.PRIMARY}
                  onClick={(e: React.MouseEvent) => {
                    this.beforeSumbit(e, true);
                  }}
                >
                  {t("UI.IsDraft")}
                </Button>
              )}
              {!this.props.initialValues ||
              !(this.props.initialValues as any)["id"] ||
              (model &&
                (!model.permits ||
                  !model.permits.update ||
                  this.props.hasPermits(model.permits.update))) ? (
                <Button
                  type="button"
                  intent={Intent.PRIMARY}
                  onClick={(e: React.MouseEvent<HTMLElement>) =>
                    this.beforeSumbit(e, false)
                  }
                >
                  {t("UI.Save")}
                </Button>
              ) : null}
              <Button onClick={() => this.props.onClose()}>
                {t("UI.Close")}
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createEntity: (
        modelName,
        data,
        sequences,
        selectedItem,
        updateCallback,
        callback,
        transformCallback
      ) =>
        createEntity(
          modelName,
          data,
          sequences,
          selectedItem,
          updateCallback,
          callback,
          transformCallback
        ),
      updateEntity: (
        modelName,
        data,
        sequences,
        selectedItem,
        updateCallback,
        callback,
        transformCallback
      ) =>
        updateEntity(
          modelName,
          data,
          sequences,
          selectedItem,
          updateCallback,
          callback,
          transformCallback
        ),
      deactivateEntity: (modelName, data, updateCallback, callback) =>
        deactivateEntity(modelName, data, updateCallback, callback),
      deleteEntity: (modelName, data, updateCallback, callback) =>
        deleteEntity(modelName, data, updateCallback, callback),
      restoreEntity: (modelName, data, updateCallback, callback) =>
        restoreEntity(modelName, data, updateCallback, callback),
      selectItem: (data) => selectItem(data),
      showError: (error) => showError(error),
      setConfirm: (props) => setConfirm(props),
    },
    dispatch
  );

const mapStateToProps = (state: IAppState, initialProps: any) => {
  const listeners = formListeners(
    initialProps.form,
    state,
    initialProps.model ? initialProps.model.fields : []
  );
  return {
    listeners: listeners,
    models: state.modelsReducer.models,
  };
};

const PermitionForm = withPermissions<Props & InjectedFormProps<{}, Props>>(
  ReduxForm
);

const Form = reduxForm<{}, Props>({
  touchOnChange: true,
  enableReinitialize: true,
})(PermitionForm);

const ConnectedForm = connect(mapStateToProps, mapDispatchToProps)(Form);

export default withTranslation()(ConnectedForm);
