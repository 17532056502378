import { ISignInData } from "../../../models/sign-in-data";

export const validateLogin = (values: any) => {
  const errors = {} as ISignInData;
  if (!values.loginOrEmail) {
    errors.loginOrEmail = "Required";
  }
  if (!values.password) {
    errors.password = "Required";
  }
  return errors;
};
