import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCountByFilter } from "../../../redux/actions/entitiesActions";
import { IAppState } from "../../../redux/states/state";
import "./ElementCount.scss";

export default function ElementCount(props: any) {
    const selectedCount = useSelector((state: IAppState) => state.entitiesReducer.selectedCount);
    const modelName = useSelector((state: IAppState) => state.modelsReducer.activeModel);
    const selectedId = useSelector((state: IAppState) => state.entitiesReducer.selectedId);
    const { t } = useTranslation();
    const selectedItem = useSelector((state: IAppState) => state.entitiesReducer.items).find((element: any) => element["id"] === selectedId);
    const dispatch = useDispatch();
    useEffect(() => {
        if(modelName === "ControlMark") {
            selectedItem && dispatch(getCountByFilter(modelName, { CtrMarkCompany: selectedItem["CtrMarkCompany"] }));
        }
    }, [selectedItem]);
    return <div className="count-conteiner">
        {modelName === "ControlMark" && <div>{t("SelectedCount")}: {selectedCount}</div>}
    </div>;
}