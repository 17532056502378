import { DictionaryObject } from "./../models/types/dictionary";
import { IViewField } from "../models/view-field";
import { ITab, IViewModel } from "../models/view-model";

export const getSequences = (
  notEmpty: boolean,
  tab: ITab,
  model: IViewModel,
  selectedItem: DictionaryObject
): string[] => {
  if(!tab || !tab.fields) { return []; }
  return model.fields
    .filter(
      (f: IViewField) =>
        f.sequence &&
        f.twoStepSequence &&
        ((!notEmpty && !selectedItem[f.name]) ||
          (notEmpty && selectedItem[f.name])) &&
        tab.fields.indexOf(f.name) !== -1
    )
    .map((field: IViewField) => {
      if(field.sequence === "SpecContractNumber") {
        if(!selectedItem || !selectedItem["ExpertiseType"]) {
          return field.sequence || "";
        }
        switch((selectedItem["ExpertiseType"] as DictionaryObject)["Name"]) {
          case "Qeydiyyat": 
            return "RegContractNumber";
          case "qeydiyyat": 
            return "RegContractNumber";
          case "İlkin qeydiyyat": 
            return "RegContractNumber";
          case "Yenidən qeydiyyat":
            return "YRegContractNumber";
          case "yenidən qeydiyyat":
            return "YRegContractNumber";
          case "Dəyişiklik":
            return "SpecContractNumber";
          default:
            return field.sequence || "";
        }
      }
      else  if(field.sequence === "PrelimContractNumber") {
        if(!selectedItem || !selectedItem["ExpertiseType"]) {
          return field.sequence || "";
        }
        switch((selectedItem["ExpertiseType"] as DictionaryObject)["Name"]) {
          case "Qeydiyyat": 
            return "PrelimContractNumber";
          case "qeydiyyat": 
            return "PrelimContractNumber";
          case "İlkin qeydiyyat": 
            return "PrelimContractNumber";
          case "Yenidən qeydiyyat":
            return "PrelimYRegContractNumber";
          case "yenidən qeydiyyat":
            return "PrelimYRegContractNumber";
          case "Dəyişiklik":
            return "PrelimDContractNumber";
          default:
            return field.sequence || "";
        }
      }
      else if(field.sequence === "DVRegistrationCard") {
        if(selectedItem && selectedItem['id']) {
          return "";
        } else {
          return field.sequence;
        }
      } else {
        return field.sequence || "";
      }
    }).filter(d => !!d);
};
