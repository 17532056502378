import { Button, Label } from "@blueprintjs/core";
import { Col, Row } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { ISignInData } from "../../../models/sign-in-data";
import { signIn } from "../../../redux/actions/authorizationActions";
import { IAppState } from "../../../redux/states/state";
import { renderPasswordField } from "../../core/forms/controls/password-input/PasswordInput";
import { renderTextField } from "../../core/forms/controls/text-input/TextInput";
import "./LoginForm.scss";
import { validateLogin } from "./validate";

interface Props extends WithTranslation {
  signIn: (signInData: ISignInData) => void;
}

class LoginForm extends React.Component<
  Props & InjectedFormProps<{}, Props>,
  any
> {
  submitForm = (signInData: any) => {
    const isValid = validateLogin(signInData);
    if (isValid) {
      if (signInData.loginOrEmail.includes("@")) {
        this.props.signIn({
          email: signInData.loginOrEmail,
          password: signInData.password,
        });
      } else {
        this.props.signIn({
          username: signInData.loginOrEmail,
          password: signInData.password,
        });
      }
    }
  };

  render() {
    const { t, handleSubmit } = this.props;

    return (
      <div className="registration">
        <div className="registration__header">{t("UI.SignIn")}</div>
        <div className="registration__body">
          <form
            onSubmit={handleSubmit(this.submitForm)}
            className="registration__form"
          >
            <Row className="registration__row" gutter={16}>
              <Col span={6}>
                <Label className="label" htmlFor="user-signin">
                  {t("UI.User")}
                </Label>
              </Col>
              <Col span={18}>
                <Field
                  name="loginOrEmail"
                  type="text"
                  component={renderTextField}
                />
              </Col>
            </Row>
            <Row className="registration__row" gutter={16}>
              <Col span={6}>
                <Label className="label" htmlFor="user-signin">
                  {t("UI.Password")}
                </Label>
              </Col>
              <Col span={18}>
                <Field name="password" component={renderPasswordField} />
              </Col>
            </Row>
            <div className="button-row">
              <Button
                className="registration__button"
                intent="success"
                text={t("UI.SignIn")}
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      signIn: (signInData: ISignInData) => signIn(signInData),
    },
    dispatch
  );

const LogForm = reduxForm<{}, Props>({
  form: "LoginForm",
  enableReinitialize: true,
  validate: validateLogin,
})(LoginForm);

const ConnectedLoginForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(LogForm);

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedLoginForm)
);
