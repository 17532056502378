import axios from "axios";
import { ATTACHMENTS_BACKEND } from "../../backend-connection";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { showError } from "./errorsActions";
import { IAppState } from "../states/state";

export const UPLOAD = "UPLOAD";
export const GET_FILE = "GET_FILE";
export const LOAD_FILE = "LOAD_FILE";
export const ATTACHMENT_TYPES = "ATTACHMENT_TYPES";
export const LOADING_FILES = "LOADING_FILES";

export function loadingFiles(loading: boolean) {
  return {
    type: LOADING_FILES,
    payload: loading,
  };
}
export function setAttachmentTypes(attachmentTypes: any[]) {
  return {
    type: ATTACHMENT_TYPES,
    payload: attachmentTypes,
  };
}

export function loadFile(file: { id: string; file: any }) {
  return {
    type: LOAD_FILE,
    payload: file,
  };
}

export function uploadFile(file: any) {
  return axios(`${ATTACHMENTS_BACKEND}/upload`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: file,
  });
  // .catch((err) => dispatch(showError('upload file error')));
}

export function getFile(id: string) {
  return axios
    .get(`${ATTACHMENTS_BACKEND}/get/` + id, {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "arraybuffer",
    })
    .catch((err) => null);
}

export function saveFile(id: string) {
  return axios(`${ATTACHMENTS_BACKEND}/save/` + id, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
  // .catch((err) => dispatch(showError('save file error')));
}

export function updateFile(id: string) {
  return axios(`${ATTACHMENTS_BACKEND}/update?id=` + id, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
  // .catch((err) => dispatch(showError('update file error')));
}

export function deleteFile(id: string) {
  return axios(`${ATTACHMENTS_BACKEND}/` + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
  // .catch((err) => dispatch(showError('save file error')));
}

export const getFiles = (ids: string[]): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => { 
  const requests = ids.map((id) => getFile(id));
    dispatch(loadingFiles(true));
    return axios
      .all(requests)
      .then((allResponses) => {
        allResponses.forEach((res, index) => {
          if (res) {
            dispatch(
              loadFile({
                id: ids[index],
                file: res,
              })
            );
          }
        });
        dispatch(loadingFiles(false));
      })
      .catch((err) => {
        dispatch(showError("g"));
        dispatch(loadingFiles(false));
      });
  };

export const saveFiles = (id: string): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => { 
    return axios(`${ATTACHMENTS_BACKEND}/save/` + id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
}