import {
  ChangeAction,
  FieldChange,
  IFieldChange,
  IFieldRead,
} from "./field-change";
import { IChange } from "./changes-history";
import { IViewModel } from "../view-model";
import { Dictionary, DictionaryObject } from "../types/dictionary";
import { IViewField } from "../view-field";
import { TFunction } from "i18next";
import React from "react";
import { Icon } from "@blueprintjs/core";
import {
  IChangesDetails,
  readInfoWithId,
  renderFields,
} from "./changes-factory";

export class ChangesUpdate implements IChangesDetails {
  info: IFieldRead[] = [];
  changes: IFieldChange[] = [];

  constructor(change: IChange, model?: IViewModel) {
    this.getDetails(change, model);
  }

  getDetails(change: IChange, model?: IViewModel): void {
    this.changes = this.compareJson(
      change.before as DictionaryObject,
      change.after as DictionaryObject,
      model
    );
    this.info = readInfoWithId(change.before as DictionaryObject, model);
  }

  compareJson(
    before?: Dictionary,
    after?: Dictionary,
    model?: IViewModel
  ): IFieldChange[] {
    const triples: IFieldChange[] = [];
    if (!before || !after || !model) {
      return triples;
    }

    // Join all keys that need to be checked
    const setKeys = new Set(Object.keys(after));
    Object.keys(before).forEach((k: string) => {
      if (!setKeys.has(k)) {
        setKeys.add(k);
      }
    });

    // No need to show update datetime
    if (setKeys.has("updated")) {
      setKeys.delete("updated");
    }

    // No need to show create datetime
    if (setKeys.has("created")) {
      setKeys.delete("created");
    }

    // No need to show stateHistory
    if (setKeys.has("stateHistory")) {
      setKeys.delete("stateHistory");
    }

    Array.from(setKeys).forEach((k) => {
      const modelField: IViewField | undefined = model.fields.find(
        (f: IViewField) => f.name === k
      );

      if (!modelField) {
        return;
      }

      const fieldChange: IFieldChange = new FieldChange(
        k,
        (before as DictionaryObject)[k],
        (after as DictionaryObject)[k],
        modelField
      );
      if (fieldChange.action !== ChangeAction.Unchanged) {
        triples.push({
          fieldName: k,
          action: fieldChange.action,
          before: fieldChange.before,
          after: fieldChange.after,
        });
      }
    });

    return triples;
  }

  renderInfo(t: TFunction): React.ReactNode {
    return renderFields(this.info, t);
  }

  renderChanges(t: TFunction): React.ReactNode {
    return (
      <div>
        {this.changes.length ? (
          this.changes.map((tr: IFieldChange) => {
            return (
              <span key={tr.fieldName}>
                <b>{t(tr.fieldName)}</b>
                <span
                  style={
                    tr.action === ChangeAction.Deleted
                      ? {
                          marginLeft: "6px",
                          textDecoration: "line-through",
                        }
                      : { marginLeft: "6px" }
                  }
                >
                  {tr.before}
                </span>
                {tr.action === ChangeAction.Updated ? (
                  <Icon style={{ margin: "0 8px" }} icon="arrow-right" />
                ) : null}
                <span>{tr.after}</span>
                <br />
              </span>
            );
          })
        ) : (
          <span>{t("UI.NoChanges")}</span>
        )}
      </div>
    );
  }

  getShortDescription(t: TFunction): React.ReactNode {
    return <span>{t("ChangesShortDesc.Update")}</span>;
  }
}
