import React, { Suspense } from "react";
import "./App.css";
import Layout from "./components/layout/Layout";
import { Route, Switch } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Suspense fallback="loading">
        <Switch>
          <Route path={`/`} component={Layout} />
        </Switch>
      </Suspense>
    </div>
  );
}

export default App;
