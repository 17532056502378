import { IViewModel } from "./view-model";
import { Dictionary } from "./types/dictionary";
export interface ICopyItem {
  getItem: () => Dictionary;
  getModel: () => IViewModel;
}

export class CopyItem {
  private model: IViewModel;
  private item: Dictionary;

  constructor(model: IViewModel, item: Dictionary) {
    this.model = model;
    this.item = item;
  }

  public getItem(): Dictionary {
    return this.item;
  }

  public getModel(): IViewModel {
    return this.model;
  }
}
