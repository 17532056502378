import { Button, Dialog, Intent, Label } from "@blueprintjs/core";
import { Col, Row } from "antd";
import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm
} from "redux-form";
import { IConstraint } from "../../../models/constraint";
import { IEmployee } from "../../../models/employee";
import { ConstraintType } from "../../../models/enums/constraint-type";
import { Views } from "../../../models/enums/views";
import { IRole } from "../../../models/role";
import { DictionaryObject } from "../../../models/types/dictionary";
import { IUser } from "../../../models/users/user";
import { setConfirm } from "../../../redux/actions/alertActions";
import { getReferenceQuery } from "../../../redux/actions/referencesActions";
import { createUser, selectUser, updateUser } from "../../../redux/actions/usersActions";
import { IAppState } from "../../../redux/states/state";
import { IStateAlerts } from "../../../redux/states/state-alerts";
import { renderPasswordField } from "../../core/forms/controls/password-input/PasswordInput";
import { renderSimpleSelectField } from "../../core/forms/controls/simple-select/SimpleSelect";
import { renderTextField } from "../../core/forms/controls/text-input/TextInput";
import { renderReferenceField } from "../../core/forms/fields/reference-field/ReferenceField";
import "./CreateUser.scss";
import { validateUser } from "./validate";

interface Props extends RouteComponentProps, WithTranslation {
  createUser: (user: IUser, roleId: number) => void;
  updateUser: (
    user: IUser,
    roleId: number | null,
    prevEmployee: IEmployee | null
  ) => void;
  selectUser: (user: IUser) => void;
  openForm: (open: boolean) => void;
  setConfirm: (props: IStateAlerts) => void;
  roles: IRole[];
  isOpen: boolean;
  userInfo: IUser | null;
  userEmployee: IEmployee | null;
  prevEmployee: IEmployee | null;
  isEmployee: string;
}

class CreateUser extends React.PureComponent<
  Props & InjectedFormProps<{}, Props>,
  any
> {
  constructor(props: Props & InjectedFormProps<{}, Props>) {
    super(props);
    this.state = {
      employees: [],
    };
  }

  private handleClose = () => {
    this.props.openForm(false);
    this.props.selectUser({} as IUser);
    this.props.reset();
  };

  public render() {
    const { t } = this.props;
    const { isOpen } = this.props;

    return (
      <Dialog
        className="create-employees__dialog"
        title={<div className="dialog-header">{t("UI.User")}</div>}
        onClose={this.handleClose}
        isOpen={isOpen}
        canOutsideClickClose={false}
        canEscapeKeyClose={true}
      >
        {isOpen ? this.CreateUserPanel() : undefined}
      </Dialog>
    );
  }

  updateStatus = (user: IUser, status: number) => {
    const { userInfo } = this.props;
    if (userInfo && user) {
      if (userInfo.id !== user.id) {
        user.status = status;
        user.roleId = undefined;
        user.roleName = undefined;
        user.timezone = "Asia/Baku";
        user.registered = undefined;
        this.props.updateUser(user, null, null);
        this.handleClose();
      }
    }
  };

  createUser = (user: any) => {
    const newUser = { ...user };

    if (!newUser.id) {
      newUser.roleId = newUser.role ? newUser.role.id : undefined;
      newUser.registered = undefined;
      this.props.createUser(user, newUser.roleId);
    }
  };

  updateUser = (user: any) => {
    const { prevEmployee } = this.props;
    const newUser = { ...user };
    newUser.timezone = "Asia/Baku";
    newUser.registered = undefined;
    this.props.updateUser(
      newUser,
      newUser.role.id || newUser.roleId,
      prevEmployee
    );
  };

  submitForm = (user: any) => {
    if (!user.id) {
      this.createUser(user);
    } else {
      this.updateUser(user);
    }
    this.handleClose();
  };

  handleKeyDown = function (e: any, cb?: any) {
    if (e.key === "Enter" && e.shiftKey === false) {
      e.preventDefault();
    }
  };

  searchEmployees(filters: IConstraint[]) {
    getReferenceQuery("Employee", "", filters, 0, 15).then((result) => {
      if (result) {
        this.setState({ employees: result });
      } else {
        this.setState({ employees: [] });
      }
    });
  }

  renderEmployeeField() {
    const { t } = this.props;
    if (this.props.isEmployee === "Employee") {
      const filters: IConstraint[] = [];
      // filters.push({
      //     type: ConstraintType.NullOrEmpty,
      //     field: "UserId"
      // });
      return (
        <Row className="registration__row">
          <Col span={4}>
            <Label className="label" htmlFor="employee">
              {t("EmployeeRef.Employee")}
            </Label>
          </Col>
          <Col
            span={20}
            onClick={() => {
              this.searchEmployees(filters);
            }}
          >
            <Field
              name="employee"
              type="text"
              placeholder={t("EmployeeRef.Employee")}
              onChangeQuery={(value: string, event: any) => {
                if (value && event) {
                  filters.push({
                    type: ConstraintType.Like,
                    field: "ShortName",
                    value: `%` + value + `%`,
                  });
                  this.searchEmployees(filters);
                }
              }}
              onChange={(e: any) => {
                this.props.change("email", e && e["Email"] ? e["Email"] : "");
              }}
              t={t}
              view={Views.FILTER}
              size="large"
              reference={this.state.employees}
              field={{
                searchField: "ShortName",
              }}
              component={renderReferenceField}
            />
          </Col>
        </Row>
      );
    }
  }

  renderEmployeeInfo() {
    const { t, userEmployee } = this.props;
    if (this.props.isEmployee === "Employee" && userEmployee) {
      return (
        <React.Fragment>
          <Row className="registration__row">
            <Col span={4}>
              <Label className="label" htmlFor="lastName">
                {t("EmployeeRef.LastName")}
              </Label>
            </Col>
            <Col span={20}>
              <div>
                <span>{userEmployee?.LastName}</span>
              </div>
            </Col>
          </Row>
          <Row className="registration__row">
            <Col span={4}>
              <Label className="label" htmlFor="firstName">
                {t("EmployeeRef.FirstName")}
              </Label>
            </Col>
            <Col span={20}>
              <div>
                <span>{userEmployee?.FirstName}</span>
              </div>
            </Col>
          </Row>
          <Row className="registration__row">
            <Col span={4}>
              <Label className="label" htmlFor="secondName">
                {t("EmployeeRef.SecondName")}
              </Label>
            </Col>
            <Col span={20}>
              <div>
                <span>{userEmployee?.SecondName}</span>
              </div>
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }

  renderEmployeeBlock() {
    const { t } = this.props;

    const renderView = (item: string) => {
      return t(item);
    };

    return (
      <Col span={10} className="create-employees__column">
        <Row className="registration__row">
          <Col span={4}>
            <Label className="label" htmlFor="userType">
              {t("EmployeeRef.UserType")}
            </Label>
          </Col>
          <Col span={20}>
            <Field
              name="userType"
              type="text"
              placeholder={t("EmployeeRef.UserType")}
              t={t}
              items={["SystemUser", "Employee"]}
              // disabled={!!(this.props.initialValues as any)["id"]}
              renderView={renderView}
              component={renderSimpleSelectField}
            />
          </Col>
        </Row>
        {this.renderEmployeeField()}
        {this.renderEmployeeInfo()}
      </Col>
    );
  }

  renderUserForm() {
    const { t, roles, userInfo } = this.props;

    const renderView = (role: DictionaryObject) => {
      return ((role["name"] as DictionaryObject) || (role as DictionaryObject))[
        "az"
      ];
    };

    return (
      <Col span={14} className="create-employees__column">
        <Row className="registration__row">
          <Col span={4}>
            <Label className="label" htmlFor="username">
              {t("EmployeeRef.UserName")}
            </Label>
          </Col>
          <Col span={20}>
            <Field
              name="username"
              type="text"
              disabled={
                !!userInfo &&
                !!this.props.initialValues &&
                userInfo.id ===
                  (this.props.initialValues as DictionaryObject)["id"]
              }
              placeholder={t("EmployeeRef.UserName")}
              component={renderTextField}
            />
          </Col>
        </Row>
        <Row className="registration__row">
          <Col span={4}>
            <Label className="label" htmlFor="email">
              {t("EmployeeRef.Email")}
            </Label>
          </Col>
          <Col span={20}>
            <Field
              name="email"
              type="text"
              disabled={
                !!userInfo &&
                !!this.props.initialValues &&
                userInfo.id ===
                  (this.props.initialValues as DictionaryObject)["id"]
              }
              placeholder={t("EmployeeRef.Email")}
              component={renderTextField}
            />
          </Col>
        </Row>
        <Row className="registration__row">
          <Col span={4}>
            <Label className="label" htmlFor="role">
              {t("EmployeeRef.Role")}
            </Label>
          </Col>
          <Col span={20}>
            <Field
              name="role"
              type="text"
              items={roles}
              placeholder={t("EmployeeRef.Role")}
              renderView={renderView}
              component={renderSimpleSelectField}
            />
          </Col>
        </Row>
        <Row className="registration__row">
          <Col span={4}>
            <Label className="label" htmlFor="password">
              {t("EmployeeRef.Password")}
            </Label>
          </Col>
          <Col span={20}>
            <Field
              name="password"
              disabled={
                !!userInfo &&
                !!this.props.initialValues &&
                userInfo.id === (this.props.initialValues as any)["id"]
              }
              placeholder={t("EmployeeRef.Password")}
              component={renderPasswordField}
            />
          </Col>
        </Row>
        {/*<div className="row">*/}
        {/*    <Field name="passwordConfirm" type="text" component={(field: any) => {*/}
        {/*        return <InputGroup {...field.input} className="row-input" placeholder={t('User.PasswordConfirm')}/>}}*/}
        {/*    />*/}
        {/*</div>*/}
      </Col>
    );
  }

  public CreateUserPanel = () => {
    const { t, handleSubmit } = this.props;
    return (
      <form
        onSubmit={handleSubmit(this.submitForm)}
        onKeyDown={(e) => {
          this.handleKeyDown(e);
        }}
        className="create-employees"
        autoComplete="off"
      >
        <div className="create-employees-body">
          <Row>
            {this.renderEmployeeBlock()}
            {this.renderUserForm()}
          </Row>
        </div>
        <Row className="button-row">
          <div className="button-row__group">
            {this.props.initialValues &&
            (this.props.initialValues as IUser).status !== 2 ? (
              <Button
                text={t("UI.Block")}
                intent={Intent.DANGER}
                onClick={() =>
                  this.updateStatus(this.props.initialValues as IUser, 2)
                }
              />
            ) : null}
            {this.props.initialValues &&
            (this.props.initialValues as IUser).status === 2 ? (
              <Button
                text={t("UI.Activate")}
                intent={Intent.SUCCESS}
                onClick={() =>
                  this.updateStatus(this.props.initialValues as IUser, 1)
                }
              />
            ) : null}
            {this.props.initialValues ? (
              <Button
                text={t("UI.Remove")}
                intent={Intent.DANGER}
                onClick={() => {
                  this.props.setConfirm({
                    message: t("UI.Sure"),
                    isOpen: true,
                    onConfirm: () => {
                      this.updateStatus(this.props.initialValues as IUser, 3)
                    },
                  });
                }
                }
              />
            ) : null}
          </div>
          <Button text={t("UI.Save")} intent={Intent.PRIMARY} type="submit" />
        </Row>
      </form>
    );
  };
}

const selector = (form: string, state: any, ...other: any) =>
  formValueSelector(form)(state, ...other);

const mapStateToProps = (state: IAppState) => ({
  initialValues: state.usersReducer.selectedUser
    ? {
        ...state.usersReducer.selectedUser,
        userType: state.usersReducer.prevEmployee ? "Employee" : "SystemUser",
        employee: state.usersReducer.prevEmployee,
        role: state.usersReducer.selectedUser.roleName,
      }
    : undefined,
  userInfo: state.authorizationReducer.userInfo,
  roles: state.permitionReducer.roles,
  isEmployee: selector("UserForm", state, "userType"),
  userEmployee: selector("UserForm", state, "employee"),
  prevEmployee: state.usersReducer.prevEmployee,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createUser: (user: IUser, roleId: number) => createUser(user, roleId),
      updateUser: (
        user: IUser,
        roleId: number | null,
        prevEmployee: IEmployee | null
      ) => updateUser(user, roleId, prevEmployee),
      selectUser: (user: IUser) => selectUser(user),
      setConfirm: (props: any) => setConfirm(props),
    },
    dispatch
  );

const CreateUserForm = reduxForm<{}, Props>({
  form: "UserForm",
  enableReinitialize: true,
  validate: validateUser,
})(CreateUser);

const ConnectedCreateUserForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUserForm);

export default withTranslation()(withRouter(ConnectedCreateUserForm));
