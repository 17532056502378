import { Button, Intent } from "@blueprintjs/core";
import { Col, Row } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { InjectedFormProps, reduxForm } from "redux-form";
import { Views } from "../../../../../../models/enums/views";
import { DictionaryObject } from "../../../../../../models/types/dictionary";
import { IViewField } from "../../../../../../models/view-field";
import { updateEntity } from "../../../../../../redux/actions/entitiesActions";
import { refreshItem } from "../../../../../../redux/actions/newEntitiesActions";
import { IAppState } from "../../../../../../redux/states/state";
import TextareaField from "../../../fields/textarea-field/TextareaField";
import "./ExpredNote.scss";
import { validateNote } from "./validate";

interface Props extends WithTranslation {
    fieldNote: IViewField;
    selectedItem: DictionaryObject;
    activeModel: string;
    updateEntity: (modelName: string, data: DictionaryObject, sequences: string[], item: DictionaryObject, updateCallback?: () => void) => void;
    refreshItem: (modelName: string, id: string) => void;

}

interface State {

}

class ExpredNote extends React.PureComponent<Props & InjectedFormProps<{}, Props>, State> {
    constructor(props: Props & InjectedFormProps<{}, Props>) {
        super(props);
        this.state = {

        };
    }

    submitForm = (values: DictionaryObject) => {
        if (!this.props.valid) return;
        if (values && values['id']) {
            this.props.updateEntity("DVRegistration", values, [], values, () => {
                this.props.reset();
                let id = this.props.selectedItem && this.props.selectedItem["id"] as string;
                this.props.refreshItem(this.props.activeModel, id);
            });
        }
    };

    render() {
        const { fieldNote, form, selectedItem, t, handleSubmit } = this.props;
        return (<form onSubmit={handleSubmit(this.submitForm)} className="expred-note">
            <Row className="expred-note__row">
                <Col span={22}>
                    <TextareaField
                        f={fieldNote}
                        fieldName={fieldNote.name}
                        view={Views.FORM}
                        showLabel={true}
                        hidden={false}
                        isDraft={false}
                        formName={form}
                        selectedItem={selectedItem}
                    />
                </Col>
                <Col span={2}>
                    <Button intent={Intent.PRIMARY} disabled={!this.props.dirty} type="submit">{t("UI.Save")}</Button>
                </Col>
            </Row>
        </form>)
    }
};

const mapStateToProps = (state: IAppState) => ({
    activeModel: state.modelsReducer.activeModel
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            updateEntity: (modelName, data, sequences, item, updateCallback) => updateEntity(modelName, data, sequences, item, updateCallback),
            refreshItem: (modelName, id) => refreshItem(modelName, id)
        },
        dispatch
    );

const ReduxExpredNote = reduxForm<{}, Props>({
    form: "ExpredNote",
    enableReinitialize: true,
    validate: validateNote
})(ExpredNote);

const ConnectedExpredNote = connect(
    mapStateToProps,
    mapDispatchToProps
)(ReduxExpredNote);

export default withTranslation()(ConnectedExpredNote);
