import React from "react";
import "./TextField.scss";
import { FormGroup, Icon, InputGroup, Label, Tag } from "@blueprintjs/core";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { IViewField } from "../../../../../models/view-field";
import { Views } from "../../../../../models/enums/views";
import { autofill, Field } from "redux-form";
import { WrappedFieldProps } from "redux-form/lib/Field";
import { useDispatch } from "react-redux";
import { checkShowConstraints } from "../../../../../helpers/check-show-constraints";
import { checkDisabledConstraints } from "../../../../../helpers/check-disabled-constraints";
import { mandatory } from "../../../../../validation/validate";
import ValidMessages from "../../valid-messages/ValidMessages";
import { renderEmptyField } from "../../controls/empty-btn/EmptyBtn";
import { IFormField } from "../../../../../models/fields/form-field";
import { DictionaryObject } from "../../../../../models/types/dictionary";
import Sybmols from "../../controls/symbols/Symbols";
import Generate from "../../controls/generate/Generate";
import useRelationHook from "../../../../../hooks/relationHook";

export const renderTextFieldClear = ({
  input,
  meta: { touched, invalid, error },
  placeholder,
  disabled,
  field,
  selectedItem,
  formName,
  filter,
  relationData
}: WrappedFieldProps & {
  placeholder: string;
  disabled: boolean;
  field: IViewField;
  selectedItem: any;
  formName: string;
  filter: boolean;
  relationData?: any;
}) => {
  const clearText = () => {
    if (input.value) {
      input.onChange("");
    }
  };

  return (
    <div className="row-input">
      <InputGroup
        {...input}
        className={error && touched ? "bp3-intent-danger any-text" : "any-text"}
        disabled={disabled}
        leftElement={field.prefix ? tag(field.prefix) : undefined}
        onChange={(e: any) => input.onChange(!field.relation || field.relation?.changable || !field.relation.func ? e : field.relation.func(e, relationData, field.relation.propertyNames))}
        rightElement={
          <>
            {input.value && !disabled && (<Icon
              className="clear-btn"
              icon="small-cross"
              onClick={() => clearText()}
            />)}
            {field.postfix ? tag(field.postfix) : undefined}
          </>
        }
      />
      {field.generate && field.sequence && !filter && <Generate sequence={field.sequence} onChange={input.onChange}/>}
      <Sybmols chars={field.specialChars} value={input.value} onChange={input.onChange}/>
      {touched && error && <ValidMessages text={error} />}
    </div>
  );
};

const tag = (value: string) => {
  return <Tag minimal={true}>{value}</Tag>;
};

const formatText = (field: IViewField) => (value: string) => {
  return value !== undefined ?
    (field.prefix ? field.prefix : '') + value + (field.postfix ? field.postfix : '')
    : ''
};

const cmParser = (field: IViewField) => (value: string) => {
  let _value = "";
  if (!value) return _value;
  _value = value;
  if (field.prefix) {
    var regexPre = new RegExp(field.prefix, "g");
    _value = value.replace(regexPre, "")
  }
  if (field.postfix) {
    var regexPost = new RegExp(field.postfix, "g");
    _value = value.replace(regexPost, "")
  }
  return _value;
}


export const TextField = (props: IFormField) => {
  const { t } = useTranslation();
  const { f, fieldName, view, showEmpty, showLabel, hidden, isDraft } = props;
  const selectedItem: DictionaryObject | null = props.selectedItem as DictionaryObject;
  const dispatch = useDispatch();

  const TextFilterView = () => {
    return (
      <Row>
        <FormGroup className="form-group-text">
          <Label htmlFor={fieldName}>{t(f.name)}</Label>
          <div className="any-text__container">
            <Field
              name={fieldName}
              type="text"
              component={renderTextFieldClear}
              field={f}
              parse={cmParser(f)}
              format={formatText(f)}
              filter={true}
              onChange={(value: any) =>
                props.onChange && props.onChange(value)
              }
            />
            {!f.noSearchEmpty && showEmpty && (
              <Field
                name={fieldName + "__EMPTY"}
                type="checkbox"
                onClick={() => { }}
                onChange={(e: any) => { }}
                field={f}
                view={view}
                component={renderEmptyField}
              />
            )}
          </div>
        </FormGroup>
      </Row>
    );
  };

  const TextFormView = () => {
    const { listeners } = props;
    const show = checkShowConstraints(f, listeners);
    const disabled = checkDisabledConstraints(f, listeners);

    const relationData = useRelationHook({
      f: props.f,
      fieldName: props.fieldName,
      formName: props.formName
    });
    if (show) {
      return (
        <Row className={hidden ? "display-none" : ""}>
          {showLabel && (
            <Col span={8}>
              <Label htmlFor={fieldName}>
                <span>{t(f.name)}</span>
                <span className="required">{f.required ? "*" : ""}</span>
              </Label>
            </Col>
          )}
          <Col span={16}>
            <div className="any-text__container">
              <Field
                name={fieldName}
                disabled={
                  f.readonly || (f.writable === false) || f.name.toLowerCase() === "id" || disabled
                }
                type="text"
                field={f}
                formName={props.formName}
                selectedItem={props.selectedItem}
                relationData={relationData}
                onChange={(e: any) => {
                  if (props.onChange) {
                    props.onChange(
                      e && e.target
                        ? e.target.value
                        : e && (typeof e === "string" || typeof e === "number")
                          ? e
                          : null
                    );
                  }
                }}
                format={cmParser(f)}
                parse={formatText(f)}
                component={renderTextFieldClear}
                validate={f.required && !isDraft ? mandatory : undefined}
              />
            </div>
          </Col>
        </Row>
      );
    } else {
      dispatch(
        autofill(
          "OutgoingDocument__FORM",
          f.name,
          selectedItem ? selectedItem[f.name] : undefined
        )
      );
      dispatch(
        autofill(
          "IncomingDocument__FORM",
          f.name,
          selectedItem ? selectedItem[f.name] : undefined
        )
      );
      return <span />;
    }
  };

  switch (view) {
    case Views.FILTER:
      return TextFilterView();
    case Views.FORM:
      return TextFormView();
    case Views.REFERENCE:
      return <span>Text</span>;
    default:
      return <span>Text</span>;
  }
};
export default TextField;
