import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./scss/index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import { store } from "./redux/store";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";
import "react-datasheet/lib/react-datasheet.css";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./redux/store";
import Moment from "moment-timezone";

Moment.tz.setDefault("Azerbaijan/Baku");

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </I18nextProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
