import ApplicationResult from "./ApplicationResult.json";
import AttachmentType from "./AttachmentType.json";
import Company from "./Company.json";
import Country from "./Country.json";
import DeliveryForm from "./DeliveryForm.json";
import Department from "./Department.json";
import DocumentAttachmentKind from "./DocumentAttachmentKind.json";
import DocumentForm from "./DocumentForm.json";
import DocumentKind from "./DocumentKind.json";
import Employee from "./Employee.json";
import EmployeeSpeciality from "./EmployeeSpeciality.json";
import Holidays from "./Holidays.json";
import CompanyLegal from "./CompanyLegal.json";
import InquiryKind from "./InquiryKind.json";
import City from "./City.json";
import Representative from "./Representative.json"; 

export const dictionaries = [
    ApplicationResult as any,
    AttachmentType as any,
    Company as any,
    Country as any,
    DeliveryForm as any,
    Department as any,
    DocumentAttachmentKind as any,
    DocumentForm as any,
    DocumentKind as any,
    Employee as any,
    EmployeeSpeciality as any,
    Holidays as any,
    CompanyLegal as any,
    InquiryKind as any,
    City as any,
    Representative as any
];