import React from "react";

import { Alert, Intent } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../redux/states/state";
import { hideConfirm } from "../../../redux/actions/alertActions";
import { useTranslation } from "react-i18next";
import "./alert.scss";


export default function Confirm() {
    const props = useSelector((state: IAppState) => state.alertsReducer);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { message } = props;
    return (props.isOpen ? <Alert
        className={!props.onConfirm ? "withoutСonfirmation" : ""}
        cancelButtonText={t("UI.Cancel")}
        confirmButtonText={t("UI.OK")}
        icon="issue"
        intent={Intent.DANGER}
        {...props}
        onClose={(confirmed, event) => { dispatch(hideConfirm()); props.onClose && props.onClose(confirmed, event) }}
    >
        <p>
            {message}
        </p>
    </Alert>: null)
}