import { Action } from "redux";
import {
  AUTH_BACKEND,
  ENTITIES_BACKEND,
  PERMITS_BACKEND,
} from "../../backend-connection";
import { ISignInData } from "../../models/sign-in-data";
import { showError, showSuccess } from "./errorsActions";
import { goToRoute } from "./navigationActions";
import { push } from "connected-react-router";
import { IAuthData } from "../../models/auth-data";
import axios from "axios";
import { IUser } from "../../models/users/user";
import { IEmployee } from "../../models/employee";
import { IConstraint } from "../../models/constraint";
import { ConstraintType } from "../../models/enums/constraint-type";
import { IPermit } from "../../models/permit";
import { IAppState } from "../states/state";
import { loadPermits } from "./roleActions";
import { ThunkAction } from "redux-thunk";

export const LOAD_USER_INFO = "USER_INFO";
export const SIGN_OUT = "SIGN_OUT";
export const LOAD_EMPLOYEE = "LOAD_EMPLOYEE";
export const AUTHORIZATION_PROCESS = "AUTORIZATION_PROCESS";
export const PERMITS_BY_USER = "PERMITS_BY_USER";

export function loadUserInfo(userInfo: IUser | null) {
  return {
    type: LOAD_USER_INFO,
    payload: userInfo,
  };
}

export function loadEmployeeData(employee: IEmployee | null) {
  return {
    type: LOAD_EMPLOYEE,
    payload: employee,
  };
}

export function getPermitsByUser(permits: IPermit[]) {
  return {
    type: PERMITS_BY_USER,
    payload: permits,
  };
}

export function setAuthData(authData: IAuthData) {
  localStorage.setItem("aemAuth", JSON.stringify(authData));
}

export function removeAuthData() {
  localStorage.removeItem("aemAuth");
}


export function authorizationProcess(inProcess: boolean) {
  return {
    type: AUTHORIZATION_PROCESS,
    payload: inProcess,
  };
}

const clearAuthorization = (): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  dispatch(loadUserInfo(null));
  dispatch(loadEmployeeData(null));
  dispatch(loadPermits([]));
};

export const getSelf = (): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  dispatch(clearAuthorization());
  dispatch(authorizationProcess(true));

  axios(`${AUTH_BACKEND}/self`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((result) => {
      if (result.status === 200) {
        dispatch(loadUserInfo(result.data));
        dispatch(getCurrentEmployee(result.data));
        dispatch(getPermits(result.data.id));
      } else {
        dispatch(loadUserInfo(null));
        dispatch(authorizationProcess(false));
      }
    })
    .catch((err) => {
      dispatch(loadUserInfo(null));
      dispatch(authorizationProcess(false));
      //dispatch(showError('Error get user data'))
    });
};

export const getCurrentEmployee = (userInfo: IUser): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  dispatch(loadEmployeeData(null));
  axios(`${ENTITIES_BACKEND}/findAll?modelName=Employee`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: [
      {
        field: "UserId",
        value: userInfo.id.toString(),
        type: ConstraintType.Eq,
      } as IConstraint,
    ],
  })
    .then((result) => {
      if (result.status === 200 && result.data && result.data.length) {
        dispatch(loadEmployeeData(result.data[0]));
      } else {
        dispatch(loadEmployeeData(null));
      }
      dispatch(authorizationProcess(false));
    })
    .catch((err) => {
      dispatch(loadEmployeeData(null));
      dispatch(authorizationProcess(false));
      //dispatch(showError('Error get user data'))
    });
};

export const getPermits = (userId: number): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  dispatch(getPermitsByUser([]));
  axios(`${PERMITS_BACKEND}/get?userId=` + userId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((result) => {
      dispatch(getPermitsByUser(result.data));
    })
    .catch((err) => {
      dispatch(getPermitsByUser([]));
      //dispatch(showError('Error get user data'))
    });
};

export const retryRefreshToken = (): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  refreshToken()
    .then((res) => res.json())
    .then((res) => {
      setAuthData(res);
    })
    .catch((err) => {
      removeAuthData();
    });
};

export function refreshToken() {
  const token = JSON.parse(localStorage.getItem("aemAuth") || '{}');
  if (token) {
    return fetch(`${AUTH_BACKEND}/refresh`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: token ? JSON.parse(token).refresh : "",
      },
      redirect: "follow",
      referrer: "no-referrer",
    });
  } else {
    return Promise.reject(null);
  }
}

export const refresh = async (token: IAuthData, dispatch: (a: any) => void) => {
  try {
      console.log("Refresh token started");
      const response = await fetch(`${AUTH_BACKEND}/refresh`, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
              "Content-Type": "application/json",
              Authorization: token.refresh,
          },
          redirect: "error", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *client
          body: JSON.stringify({}),
      });

      if (response.status === 401) {
          localStorage.removeItem('aemAuth');
          dispatch(signOut());
          dispatch(goToRoute("/sign-in"));

          console.log("Refresh token expired");
          return undefined;
      }

      if (response.status !== 200) {
          console.log("Refresh token failed");
          return undefined;
      }

      const json = (await response.json());

      localStorage.setItem("aemAuth", JSON.stringify(json));

      console.log("Token refreshed");
      return json as IAuthData;
  } catch (e) {
      console.log("Refresh token error", e);
      // Ignore
  }
  return undefined;
};

export const signIn = (signInData: ISignInData): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  return axios(`${AUTH_BACKEND}/signIn`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(signInData),
  })
    .then((result) => {
      if (result && result.data && result.data.auth) {
        setAuthData(result.data);
        dispatch(getSelf());
        dispatch(goToRoute("dynamic/incoming-document")); 
      }
    })
    .catch((err) => {
      const error = err.response;
      dispatch(
        showError(
          error && error.data && error.data.code
            ? "ERROR." + error.data.code
            : "ERROR.3002"
        )
      );
    });
};

export const changePassword = (user: IUser): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  axios(`${AUTH_BACKEND}/changePassword`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(user),
  })
    .then((result) => {
      dispatch(showSuccess("Password changed!"));
      dispatch(signOut());
    })
    .catch((err) => {
      dispatch(showError("Password change error"));
    });
};

export const signOutReq = (): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  axios(`${AUTH_BACKEND}/signOut`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((result) => { })
    .catch((err) => { });
};

export const signOut = (): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  removeAuthData();
  dispatch(loadUserInfo(null));
  dispatch(signOutReq());
  dispatch(push("/sign-in"));
};

export function userHasPermit(permits: IPermit[], permit: string) {
  return !!permits.find((p: IPermit) => p.code === permit);
}

export const hasUserPermit = (permit: string): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  const permits: IPermit[] = getState().authorizationReducer.permits;
  return userHasPermit(permits, permit);
}
