import React from "react";
// @ts-ignore
import Barcode from 'react-barcode';
import { IFormField } from "../../../../../models/fields/form-field";
import { DictionaryObject } from "../../../../../models/types/dictionary";

const BarcodeField = (props: IFormField) => {
  const value = props.selectedItem ? (props.selectedItem as DictionaryObject)[props.f.reflectField || ""] : null;
  if(!value) return null;
  return <Barcode
    renderer='img'
    value={value?.toString()}
    height={25}
    fontSize={11}
    marginTop={2}
    marginBottom={2}
  />;
};

export default BarcodeField;
