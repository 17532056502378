import { ISequenceState } from "../models/sequence-state";
import { Dictionary } from "../models/types/dictionary";
import { IDefaultField, IViewField } from "../models/view-field";
import { DictionaryObject } from "./../models/types/dictionary";
import { store } from "../redux/store";
import { IEmployee } from "../models/employee";

export const processDefault = (
  viewField: IViewField,
  entity: Dictionary,
  tryValue: Map<string, ISequenceState>,
  process: boolean
) => {
  const defaultObject: IDefaultField = viewField.default;
  const state = store.getState();
  if (defaultObject.time === "PROCESS" && !process) {
    return null;
  }
  return byDefaultObject(defaultObject, entity, viewField, tryValue, state.authorizationReducer.currentEmployee);
};

const byDefaultObject = (
  defaultObject: IDefaultField,
  entity: Dictionary,
  viewField: IViewField,
  tryValue: Map<string, ISequenceState>,
  currentEmployee: IEmployee | null
) => {
  switch (defaultObject.type) {
    case "CALCULATION": {
      return calculationDefault(defaultObject, entity, tryValue, currentEmployee);
    }
    case "COPY": {
      return copyDefault(defaultObject, entity);
    }
    case "VALUE": {
      return valueDefault(viewField, defaultObject, entity, tryValue, currentEmployee);
    }
    default: {
      return null;
    }
  }
};
const calculationDefault = (
  defaultObject: IDefaultField,
  entity: Dictionary,
  tryValue: Map<string, ISequenceState>,
  currentEmployee: IEmployee | null
) => {
  if (defaultObject.func) {
    return defaultObject.func(tryValue, entity, currentEmployee);
  } else {
    return null;
  }
};

const copyDefault = (defaultObject: IDefaultField, entity: Dictionary) => {
  if (entity && defaultObject.fromField) {
    return (entity as DictionaryObject)[defaultObject.fromField];
  } else {
    return null;
  }
};


const valueDefault = (viewField: IViewField, defaultObject: IDefaultField, entity: Dictionary, tryValue: Map<string, ISequenceState>, currentEmployee: IEmployee | null) => {
  if (defaultObject.value !== undefined) {
    return defaultObject.value;
  }
  else {
    if (defaultObject.defaultFields && defaultObject.defaultFields.length) {
      const defaultObj: DictionaryObject = { default: true };
      defaultObject.defaultFields.forEach((cf: IDefaultField) => {
        if (cf.field) {
          defaultObj[cf.field] = byDefaultObject(cf, entity, viewField, tryValue, currentEmployee);
          defaultObj.default = true;
        }
      });
      return defaultObj;
    } else {
      return null;
    }
  }
};
