import { Dialog } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ModelType } from "../../../../../models/enums/model-type";
import { Views } from "../../../../../models/enums/views";
import { DictionaryObject } from "../../../../../models/types/dictionary";
import { setConfirm } from "../../../../../redux/actions/alertActions";
import { IAppState } from "../../../../../redux/states/state";
import Acceptance, { defaultCheck, getAcceptEmployee } from "../../controls/acceptance/Acceptance";
import StateView from "../../state-management/state-view/StateView";
import FormTabs from "../form-tabs/FormTabs";
import "./GenericForms.scss";

const GenericForms = (props: {
  isOpen: boolean;
  type: ModelType;
  selectedItem: any;
  modelName: string;
  dbname: string;
  onClose: () => void;
  updateCallback: (close: boolean, item: DictionaryObject | null) => void;
  transformCallback?: (currentItem: DictionaryObject) => void;
}) => {
  const {
    isOpen,
    selectedItem,
    type,
    modelName,
    dbname,
    updateCallback,
    transformCallback,
    onClose,
  } = props;

  const { t } = useTranslation();
  const model = useSelector((state: IAppState) =>
    state.modelsReducer.models.get(modelName)
  );
  const currentEmployee = useSelector(
    (state: IAppState) => state.authorizationReducer.currentEmployee
  );
  const tryValue = useSelector(
    (state: IAppState) => state.sequencesReducer.tryValue
  );
  const dispatch = useDispatch();
  const [force, forceUpdate] = useState(false);
  useEffect(() => {
    const acceptEmployee = model ? getAcceptEmployee(
      selectedItem,
      currentEmployee,
      model
    ) : null;

    if (model && currentEmployee && selectedItem && isOpen && acceptEmployee && !defaultCheck(acceptEmployee, model)) {
      dispatch(setConfirm({
        message: <div style={{ width: "100px" }}><Acceptance
          model={model}
          selectedItem={selectedItem}
          views={Views.FORM}
        /></div>,
        isOpen: true,
        cancelButtonText: t("UI.Close"),
        onClose: () => forceUpdate(!force)
      }))
    }

  }, [isOpen])

  if (!model) {
    return <div></div>;
  }

  return (
    <Dialog
      onClose={onClose}
      title={
        type === ModelType.Entity && selectedItem && selectedItem["state"] ? (
          <div className="general-header">
            <div className="general-header__title">
              {t(modelName)}
              <StateView state={selectedItem["state"] as string} />
            </div>
            <div className="general-header__right">
              <Acceptance
                model={model}
                selectedItem={selectedItem}
                views={Views.FORM}
              />
            </div>
          </div>
        ) : (
            t(modelName)
          )
      }
      className="dialog"
      isOpen={isOpen}
      canEscapeKeyClose={true}
      canOutsideClickClose={false}
    >
      {isOpen && model && (
        <FormTabs
          type={type}
          item={selectedItem}
          modelName={modelName}
          model={model}
          dbname={dbname}
          tryValue={tryValue}
          onClose={onClose}
          updateCallback={updateCallback}
          transformCallback={transformCallback}
        />
      )}
    </Dialog>
  );
};

export default GenericForms;
