import { Button } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { reduxForm } from "redux-form";
import { constructInitialValues } from "../../../../helpers/default-values";
import { Views } from "../../../../models/enums/views";
import { FormField } from "../../../../models/fields/form-field";
import { IViewField } from "../../../../models/view-field";
import { IViewModel } from "../../../../models/view-model";
import { setFilter } from "../../../../redux/actions/entitiesActions";
import CustomFormField from "../../forms/fields/form-types/FormTypes";
import "./LargeFilter.scss";
import { ISequenceState } from "../../../../models/sequence-state";
import { IConstraint } from "../../../../models/constraint";
import { Dictionary } from "../../../../models/types/dictionary";

interface Props {
  model: IViewModel;
}

const LargeFilter = (
  props: {
    form: string;
    handleSubmit: any;
    initialize: any;
  } & Props
) => {
  const dispatch = useDispatch();
  const { initialize } = props;
  const { t } = useTranslation();
  const { model } = props;

  useEffect(() => {
    if (model) {
      initialize(
        constructInitialValues(
          model.fields,
          Views.FILTER,
          new Map<string, ISequenceState>(),
          null
        )
      );
    }
  }, [model]);

  if (!model) {
    return <span>Loading...</span>;
  }

  const { handleSubmit } = props;
  const customHandleSubmit = (values: any) => {
    dispatch(setFilter(values));
  };

  const customHandleReset = () => {
    props.initialize(
      constructInitialValues(
        model.fields,
        Views.FILTER,
        new Map<string, ISequenceState>(),
        null
      )
    );
    dispatch(setFilter({}));
  };

  const handleKeyDown = (e: any, cb?: any) => {
    if (e.key === "Enter" && e.shiftKey === false) {
      e.preventDefault();
    }
  };
  return (
    <div className="filter-model">
      <form
        onSubmit={handleSubmit(customHandleSubmit)}
        autoComplete="off"
        onKeyDown={(e) => {
          handleKeyDown(e);
        }}
        onReset={handleSubmit(customHandleReset)}
      >
        <div className="action-button">
          <Button type="submit" icon="small-tick" text={t("UI.Filter")} />
          <Button type="reset" icon="small-cross" text={t("UI.Clear")} />
        </div>
        <div className="filters">
          {model.fields
            .filter((field: IViewField) => field.visible.filter)
            .map((f: IViewField) => {
              return (
                <div key={f.name}>
                  <CustomFormField
                    {...new FormField(
                      { ...f, readonly: false },
                      f.name,
                      null,
                      Views.FILTER,
                      true,
                      false,
                      props.form,
                      false,
                      false,
                      new Map<string, IConstraint>(),
                      (e: Dictionary) => { },
                      true
                    )}
                  />
                </div>
              );
            })}
        </div>
      </form>
    </div>
  );
};

const LargeFilterForm = reduxForm<{}, Props>({})(LargeFilter);

export default LargeFilterForm;
