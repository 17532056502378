import { IStateChanges } from "../states/state-changes";
import {
  LOAD_CHANGES_HISTORY,
  LOADING_CHANGES_HISTORY,
  SET_CHANGE_FILTER,
  SET_CHANGE_TOTAL_COUNT,
  LOAD_CHANGES_DETAIL,
  CLEAR_CHANGES,
} from "../actions/changesActions";
import { IChange } from "../../models/changes/changes-history";

const INITIAL_STATE: IStateChanges = {
  changes: new Map<number, IChange>(),
  loading: false,
  filter: {},
  totalChangesCount: 0,
};

const changesReducer = (
  state = INITIAL_STATE,
  action: { type: string; payload: any }
) => {
  const prevState = state;
  switch (action.type) {
    case LOAD_CHANGES_HISTORY:
      const map: Map<number, IChange> = new Map<number, IChange>();
      action.payload.forEach((item: IChange) => {
        map.set(item.id, item);
      });
      return {
        ...state,
        changes: map,
      };
    case LOADING_CHANGES_HISTORY:
      return {
        ...state,
        loading: action.payload,
      };
    case LOAD_CHANGES_DETAIL:
      if (action.payload && prevState.changes.get(action.payload.id)) {
        prevState.changes.set(action.payload.id, action.payload);
      }
      return {
        ...state,
        changes: prevState.changes,
      };
    case SET_CHANGE_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case SET_CHANGE_TOTAL_COUNT:
      return {
        ...state,
        totalChangesCount: action.payload,
      };
    case CLEAR_CHANGES:
      return {
        ...state,
        changes: new Map<string, IChange>(),
      };
    default:
      return state;
  }
};

export default changesReducer;
