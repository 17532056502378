import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { dynamicConstraints } from "../../../helpers/dynamic-constraints";
import { IEmployee } from "../../../models/employee";
import { IPagingOptions } from "../../../models/paging-options";
import { Dictionary } from "../../../models/types/dictionary";
import { clearItems, saveDefaultsConditions } from "../../../redux/actions/entitiesActions";
import { updatePaging } from "../../../redux/actions/newEntitiesActions";
import { IAppState } from "../../../redux/states/state";
import ByDV from "../../core/filters/by-dv/ByDV";
import HeaderFilter from "../../core/filters/header-filter/HeaderFilter";
import { defaultPagingOptions } from "../../core/lists/default-paging";
import ListFooter from "../../core/lists/list-footer/ListFooter";
import MainListAnt from "../../core/lists/main-list-ant/MainListAnt";
import "./Reports.scss";

const isCustomByDV = (activeModel: string) => activeModel.toLowerCase().includes('by') && activeModel.toLowerCase().includes('dv');

function Reports(props: {
    pagingOptions: IPagingOptions;
    currentEmployee: IEmployee | null;
}) {
    const { pagingOptions, currentEmployee } = props;
    const activeModel = useSelector(
        (state: IAppState) => state.modelsReducer.activeModel
    );
    const [canExport, setCanExport] = useState(false);
    const model = useSelector((state: any) =>
        state.modelsReducer.models.get(activeModel)
    );
    const dispatch = useDispatch();
    useEffect(() => {
        if (model) {
            const defaultConditions = model.defaultConditions;
            dispatch(updatePaging({
                ...defaultPagingOptions,
                noPagination: model.noPagination
            }));
            dispatch(clearItems());
            dispatch(saveDefaultsConditions(
                dynamicConstraints(currentEmployee, defaultConditions, model && model.yearFilter ? new Date().getFullYear() : undefined)
            ))
        }
    }, [model]);

    if (!model) {
        return null;
    }

    const renderCustomFilter = () => {
        if(isCustomByDV(activeModel)) {
            return <ByDV model={model} form={activeModel + "__FILTER"} setCanExport={setCanExport}/>
        } else {
            return <HeaderFilter model={model} form={activeModel + "__FILTER"} setCanExport={setCanExport} />
        }
    }

    return (
        <React.Fragment>
            <div>
                {renderCustomFilter()}
            </div>
            <MainListAnt
                selectItem={(item: Dictionary) => null}
                selectedItem={null}
                openForm={() => null}
                pagingOptions={pagingOptions}
                footer={(selectPrev: () => void, selectNext: () => void) => (
                    <ListFooter
                        openForm={() => null}
                        updatePagingOps={(pagingOptions: IPagingOptions) => {

                        }}
                        canExport={canExport}
                        createNew={false}
                        selectPrev={selectPrev}
                        selectNext={selectNext}
                    />
                )}
                model={activeModel}
            />
        </React.Fragment>
    ) 
}


const mapStateToProps = (state: IAppState) => ({
    pagingOptions: state.entitiesReducer.pagingOptions,
    currentEmployee: state.authorizationReducer.currentEmployee
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
