import { Button, Dialog, Label } from "@blueprintjs/core";
import * as Classes from "@blueprintjs/core/lib/esm/common/classes";
import { Col, Row } from "antd";
import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { IUser } from "../../../models/users/user";
import { changePassword } from "../../../redux/actions/authorizationActions";
import { IAppState } from "../../../redux/states/state";
import { renderPasswordField } from "../../core/forms/controls/password-input/PasswordInput";
import "./ChangePassword.scss";
import { validateChangePassword } from "./validate";

interface Props extends WithTranslation {
  userInfo: IUser | null;
  changePassword: (user: IUser) => void;
}

class ChangePassword extends React.PureComponent<
  Props & InjectedFormProps<{}, Props>,
  any
> {
  public state = {
    isOpen: false,
    user: {
      id: 0,
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      phone: "",
    },
  };

  public render() {
    const { t } = this.props;
    const { isOpen } = this.state;

    return (
      <div>
        <Button text={t("UI.ChangePassword")} onClick={this.handleOpen} />
        <Dialog
          title={t("UI.ChangePassword")}
          onClose={this.handleClose}
          isOpen={isOpen}
          canOutsideClickClose={false}
          canEscapeKeyClose={true}
        >
          {this.CreateUserPanel()}
        </Dialog>
      </div>
    );
  }

  private handleClose = () => this.setState({ isOpen: false });
  private handleOpen = () => this.setState({ isOpen: true });

  submitForm = (user: any) => {
    const { userInfo } = this.props;
    if (userInfo) {
      userInfo.password = user.newPassword;
      this.props.changePassword(userInfo);
    }
    this.handleClose();
  };

  handleKeyDown = function (e: any, cb?: any) {
    if (e.key === "Enter" && e.shiftKey === false) {
      e.preventDefault();
    }
  };

  public CreateUserPanel = () => {
    const { t, handleSubmit } = this.props;
    return (
      <form
        onSubmit={handleSubmit(this.submitForm)}
        autoComplete="off"
        onKeyDown={(e) => {
          this.handleKeyDown(e);
        }}
        className="change-password"
      >
        <div className="change-password-form">
          <div className="change-password-form_body">
            {/*<div className="div-style">*/}
            {/*    <Field name="password" type="text"*/}
            {/*           placeholder={t('EmployeeRef.Password')}*/}
            {/*           component={renderField}*/}
            {/*    />*/}
            {/*</div>*/}
            {/*<hr/>*/}
            <div className="div-password">
              <Row>
                <Col>
                  <Label>{t("EmployeeRef.NewPassword")}</Label>
                </Col>
                <Col>
                  <Field
                    name="newPassword"
                    type="text"
                    placeholder={t("EmployeeRef.NewPassword")}
                    component={renderPasswordField}
                  />
                </Col>
              </Row>
            </div>
            <div className="div-password">
              <Row>
                <Col>
                  <Label>{t("EmployeeRef.ConfirmPassword")}</Label>
                </Col>
                <Col>
                  <Field
                    name="confirmPassword"
                    type="text"
                    placeholder={t("EmployeeRef.ConfirmPassword")}
                    component={renderPasswordField}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button type="submit"> {t("UI.Save")}</Button>
          </div>
        </div>
      </form>
    );
  };
}

const mapStateToProps = (state: IAppState) => ({
  initialValues: state.usersReducer.selectedUser,
  userInfo: state.authorizationReducer.userInfo,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      changePassword: (user: IUser) => changePassword(user),
    },
    dispatch
  );

const ChangePasswordForm = reduxForm<{}, Props>({
  form: "ChangePasswordForm",
  enableReinitialize: true,
  validate: validateChangePassword,
})(ChangePassword);

const ConnectedChangePasswordForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordForm);

export default withTranslation()(ConnectedChangePasswordForm);
