import { FormGroup, Label } from "@blueprintjs/core";
import { Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Field } from "redux-form";
import { Views } from "../../../../../models/enums/views";
import { IState } from "../../../../../models/state";
import { IViewField } from "../../../../../models/view-field";
import { IViewModel } from "../../../../../models/view-model";
import { IAppState } from "../../../../../redux/states/state";
import { renderReferenceField } from "../reference-field/ReferenceField";
import "./StateField.scss";

interface StateTitle {
  title: string;
  value: string;
}

export const StateField = (props: {
  f: IViewField;
  selectedItem: any;
  view: Views;
  onChange?: (e: any) => void;
  fieldName: string;
  listeners?: Map<string, any>;
  formName: string;
}) => {
  const { t } = useTranslation();
  const { f, fieldName, view } = props;

  const models = useSelector((state: IAppState) => state.modelsReducer.models);
  const activeModel = useSelector(
    (state: IAppState) => state.modelsReducer.activeModel
  );
  const stateFilter = useSelector(
    (state: IAppState) => state.modelsReducer.statesFilter
  )

  const getStates = (model?: IViewModel): StateTitle[] => {
    if (!model) {
      return [];
    }

    if(stateFilter && stateFilter.length) {
      return stateFilter.map((v: string) => {
        return {
          title: t("States." + v),
          value: v,
        };
      });
    } else {
      return Array.from(model.states.values()).map((v: IState) => {
        return {
          title: t("States." + v.name),
          value: v.name,
        };
      });
    }
  };

  const FilterView = () => {
    return (
      <Row key={f.name}>
        <FormGroup className="form-group-text">
          <Label htmlFor={fieldName}>{t(f.name)}</Label>
          <div className="any-text__container">
            <Field
              name={fieldName}
              type="text"
              onClick={() => {}}
              onChange={(e: any) => {
                if (props.onChange) {
                  props.onChange(e);
                }
              }}
              field={f}
              view={view}
              size="AUTOCOMPLETE_SIZE_SMALL"
              reference={getStates(models.get(activeModel))}
              placeholder={""}
              component={renderReferenceField}
            />
          </div>
        </FormGroup>
      </Row>
    );
  };

  switch (view) {
    case Views.FILTER:
      return FilterView();
    case Views.FORM:
      return <span>Not implemented</span>;
    case Views.REFERENCE:
      return <span>Not implemented</span>;
    default:
      return <span>Not implemented</span>;
  }
};
export default StateField;
