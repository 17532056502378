import React, { useState } from "react";
import "./SimpleSelect.scss";
import { Button, MenuItem } from "@blueprintjs/core";
import { IItemRendererProps } from "@blueprintjs/select/src/common/itemRenderer";
import { Select } from "@blueprintjs/select";
import { Selectable } from "../../../../../models/types/dictionary";
import SelectableMethods from "../selectable/Selectable";
import { WrappedFieldProps } from "redux-form/lib/Field";

const FieldSelect = Select.ofType<Selectable>();

export const renderSimpleSelectField = ({
  input,
  meta: { touched, invalid, error },
  placeholder,
  items,
  renderView,
  noSelection,
  disabled,
}: WrappedFieldProps & {
  placeholder: string;
  items: Selectable[];
  renderView: (item: Selectable) => string;
  noSelection: string;
  disabled: boolean;
}) => (
  <div>
    <SimpleSelect
      {...input}
      items={items}
      disabled={disabled}
      renderView={renderView}
      component={
        <Button
          rightIcon="caret-down"
          text={
            input.value
              ? renderView(input.value)
              : "(" + (noSelection || "No Selection") + ")"
          }
        />
      }
    />
    {touched && error && <div>{error}</div>}
  </div>
);

export const SimpleSelect = (props: {
  items: Selectable[];
  renderView: (item: Selectable) => string;
  onChange?: (e: any) => void;
  component: React.ReactNode;
  disabled?: boolean;
}) => {
  const { items, renderView, component, disabled } = props;
  const [activeItem, setActiveItem] = useState();
  const onChange = (value: any) => {
    setActiveItem(value);
    if (props.onChange) {
      props.onChange(value);
    }
  };
  return (
    <FieldSelect
      items={items}
      noResults={<MenuItem disabled={true} text="No results." />}
      popoverProps={{ minimal: true }}
      onItemSelect={onChange}
      disabled={disabled}
      filterable={false}
      activeItem={activeItem}
      itemRenderer={(item: any, itemProps: IItemRendererProps) => {
        return SelectableMethods.renderItem(item, itemProps, renderView);
      }
      }
    >
      {component}
    </FieldSelect>
  );
};

export default SimpleSelect;
