import axios from "axios";
import { Dispatch } from "redux";
import { refresh, removeAuthData, signOut } from "../redux/actions/authorizationActions";
import { IAuthData } from "../models/auth-data";

// const TOKEN_LIFE_TIME = 2 * 60 * 1000;

let refreshPromise: Promise<IAuthData | undefined> | undefined = undefined;

export const syncRefresh = (token: IAuthData, dispatch: (a: any) => void) => {
  if (refreshPromise) {
      return refreshPromise;
  }
  return (refreshPromise = new Promise(async (myResolve, myReject) => {
      const payload = await refresh(token, dispatch);
      myResolve(payload);
      refreshPromise = undefined;
  }));
};

export function setInterceptors() {
  return (dispatch: Dispatch): void => {
    
    const token = JSON.parse(localStorage.getItem("aemAuth") || '{}');
    axios.defaults.headers.common["Authorization"] = token.auth;

    axios.interceptors.request.use((request) => {
      const token = JSON.parse(localStorage.getItem("aemAuth") || '{}');
      request.headers["Authorization"] = token.auth;
      return request;
    });

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (err) => {
        
          const originalReq = err.config;
          if (
            err && err.response &&
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            try {
              originalReq._retry = true;

              let token = JSON.parse(localStorage.getItem("aemAuth") || '{}');
  
              if(!token || !token.refresh) {
                removeAuthData();
                dispatch<any>(signOut());
  
                throw new Error("refresh token empty");
              }
  
              token = await syncRefresh(token, dispatch);
  
              if (!token) {
                removeAuthData();
                dispatch<any>(signOut());

                throw new Error("refresh token failed");
              }
  
              originalReq.headers["Authorization"] = token.auth;
              const newResponse = await axios(originalReq);

              return newResponse;

            } catch (e) {
              console.log('e', e);
            }
          }
          throw err;
      }
    );
  };
}
