import { Button } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { reduxForm } from "redux-form";
import { constructInitialValues } from "../../../../helpers/default-values";
import { IConstraint } from "../../../../models/constraint";
import { Views } from "../../../../models/enums/views";
import { FormField } from "../../../../models/fields/form-field";
import { ISequenceState } from "../../../../models/sequence-state";
import { Dictionary, DictionaryObject, Selectable } from "../../../../models/types/dictionary";
import { IViewField } from "../../../../models/view-field";
import { IViewModel } from "../../../../models/view-model";
import { clearItems, setFilter } from "../../../../redux/actions/entitiesActions";
import { showError } from "../../../../redux/actions/errorsActions";
import { updateSorting } from "../../../../redux/actions/newEntitiesActions";
import SimpleSelect from "../../forms/controls/simple-select/SimpleSelect";
import CustomFormField from "../../forms/fields/form-types/FormTypes";
import AnalysisDone from "../analysis-done/AnalysisDone";
import "./HeaderFilter.scss";

interface Props {
    model: IViewModel;
    setCanExport: CallableFunction;
}

const HeaderFilter = (
    props: {
        form: string;
        handleSubmit: any;
        initialize: any;
    } & Props
) => {
    const dispatch = useDispatch();
    const { initialize } = props;
    const { t } = useTranslation();
    const { model } = props;
    const [sort, setSort] = useState<any>(null);

    const isImmidiate = model.filterReport.length === 0;

    useEffect(() => {
        if (isImmidiate) {
            handleSubmit(customHandleSubmit)();
        }
    }, []);
    useEffect(() => {
        if (model) {
            initialize(
                constructInitialValues(
                    model.fields,
                    Views.FILTER,
                    new Map<string, ISequenceState>(),
                    null
                )
            );
        }
    }, [model]);

    if (!model) {
        return <span>Loading...</span>;
    }

    const { handleSubmit } = props;
    const customHandleSubmit = (values: any) => {
        if (!model.validateReport || model.validateReport(values, props.setCanExport)) {
            dispatch(setFilter(values));
        } else {
            dispatch(showError("ERROR.ValidateReportOptions"));
        }
    };

    const customHandleReset = () => {
        props.initialize(
            constructInitialValues(
                model.fields,
                Views.FILTER,
                new Map<string, ISequenceState>(),
                null
            )
        );
        props.setCanExport(false);
        dispatch(clearItems());
    };

    const handleKeyDown = (e: any, cb?: any) => {
        if (e.key === "Enter" && e.shiftKey === false) {
            e.preventDefault();
        }
    };

    return (
        <div className="header-filter">
            <form
                onSubmit={handleSubmit(customHandleSubmit)}
                autoComplete="off"
                onKeyDown={(e) => {
                    handleKeyDown(e);
                }}
                onReset={handleSubmit(customHandleReset)}
            >
                <div className="filters">
                    {model.filterReport
                        .map((f: IViewField) => {
                            return (
                                <div key={f.name}>
                                    <CustomFormField
                                        {...new FormField(
                                            { ...f, readonly: false },
                                            f.name,
                                            null,
                                            Views.FILTER,
                                            true,
                                            false,
                                            props.form,
                                            false,
                                            false,
                                            new Map<string, IConstraint>(),
                                            (e: Dictionary) => { },
                                            f.reportSearchEmpty
                                        )}
                                    />
                                </div>
                            );
                        })}
                </div>
                {model.dbname === 'AnalysisDone' && <AnalysisDone model={model} />}
                {model.filterSort && <div className="SortSelecter"><SimpleSelect
                    items={model.filterSort}
                    renderView={(value: Selectable) =>
                        value ? t((value as DictionaryObject)["field"] as string) : ""
                    }
                    component={
                        <Button
                            rightIcon="caret-down"
                            text={sort ? t(sort.field as string) : t("UI.Sorting")}
                        />
                    }
                    onChange={(e) => {
                        setSort(e);
                        dispatch(updateSorting(e));
                    }}
                /></div>}
                {!isImmidiate && <div className="action-button">
                    <Button type="submit" icon="small-tick" text={t("UI.Filter")} />
                    <Button type="reset" icon="small-cross" text={t("UI.Clear")} />
                </div>}
            </form>
        </div>
    );
};

const HeaderFilterForm = reduxForm<{}, Props>({})(HeaderFilter);

export default HeaderFilterForm;
