import { IViewField } from "../models/view-field";
import { ConstraintType } from "../models/enums/constraint-type";

export const checkShowConstraints = (
  field: IViewField,
  listeners?: Map<string, any>
) => {
  if (
    !field.viewConstraints ||
    !field.viewConstraints.length ||
    !listeners ||
    !listeners.size
  ) {
    return true;
  }
  const constraint = field.viewConstraints.find(
    (c) => c.type === ConstraintType.NotNull
  );
  if (!constraint) {
    return true;
  }
  const foundContsraint = listeners.get(constraint.field);
  if (!foundContsraint) {
    return true;
  }
  return (
    foundContsraint &&
    constraint.value &&
    constraint.refField &&
    foundContsraint[constraint.refField] &&
    constraint.value === foundContsraint[constraint.refField]
  );
};
