import { Col } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { getState, getTransformationsByState } from "../../../../../helpers/entities";
import { IFormField } from "../../../../../models/fields/form-field";
import { DictionaryObject } from "../../../../../models/types/dictionary";
import { IAppState } from "../../../../../redux/states/state";
import SearchItem from "../../transformations/search-item/SearchItem";
import "./InnerTransformation.scss";

export const InnerTransformation = (props: IFormField) => {
  const { formName, selectedItem, f } = props;

  const models = useSelector((state: IAppState) => state.modelsReducer.models);
  const activeModel = useSelector((state: IAppState) => state.modelsReducer.activeModel);

  const renderTransformation = () => {
   
    const currentModel = models.get(activeModel);
    if(!currentModel) return <></>;

    const state = getState(selectedItem as DictionaryObject) || currentModel.defaultState;
    const transformations = getTransformationsByState(state, currentModel);
    const foundTransformation = transformations.find(t => t.name === f.model);

    if(!foundTransformation) return <></>;

    const selectedModel = models.get(foundTransformation.toName);
    if(!selectedModel) return <></>;
    
    return (
      <Col>
        <SearchItem  
          formName={formName}
          selectedTransformation={foundTransformation}
          selectedModel={selectedModel}
          currentModel={currentModel}
        />
      </Col>
  )};

  return renderTransformation();

};

export default InnerTransformation;
