import { Button } from "@blueprintjs/core";
import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { IChangeRequest } from "../../../models/changes/change-request";
import { ChangesTypes } from "../../../models/enums/changes-types";
import { Views } from "../../../models/enums/views";
import { DictionaryObject, Selectable } from "../../../models/types/dictionary";
import { IUser } from "../../../models/users/user";
import {
  getChangesHistory,
  setChangeFilter
} from "../../../redux/actions/changesActions";
import { getUsersSimple } from "../../../redux/actions/usersActions";
import { IAppState } from "../../../redux/states/state";
import { renderSimpleSelectField } from "../../core/forms/controls/simple-select/SimpleSelect";
import {
  formatStringToDate,
  parseDateToString,
  renderDateField
} from "../../core/forms/fields/date-field/DateField";
import { renderReferenceField } from "../../core/forms/fields/reference-field/ReferenceField";
import { defaultPagingOptions } from "../default-paging";
import "./ChangeFilter.scss";

interface Props extends RouteComponentProps, WithTranslation {
  setChangeFilter: (filter: IChangeRequest) => void;
  getChangesHistory: (filter: IChangeRequest, page: number, pageSize: number) => void;
  getUsersSimple: () => void;
  usersSimple: Map<string, IUser>;
  selectedItem?: DictionaryObject;
  model?: string;
}

class ChangesFilter extends React.PureComponent<
  Props & InjectedFormProps<DictionaryObject, Props>
> {
  componentDidMount(): void {
    const request = this.getFilter({});
    this.props.getUsersSimple();
    this.props.setChangeFilter(request);
  }

  getFilter(filter: DictionaryObject) {
    const { selectedItem, model } = this.props;
    if (model) {
      filter["entity"] = model;
    }
    if (selectedItem && selectedItem["id"]) {
      filter["entityId"] = selectedItem["id"].toString();
    }
    return filter;
  }

  public render() {
    return <div>{this.renderFilterForm()}</div>;
  }

  submitForm = (values: DictionaryObject) => {
    const request = { ...values };
    const type = values["type"] as number;
    const user = values["user"] as DictionaryObject;
    request["type"] = type ? ChangesTypes[type] : undefined;
    request["userId"] = user ? user["id"] : undefined;
    request["user"] = undefined;
    const newRequest = this.getFilter(request);
    this.props.setChangeFilter(newRequest);
    this.props.getChangesHistory(newRequest, 0, defaultPagingOptions.pageSize);
  };

  handleKeyDown = function (e: React.KeyboardEvent<unknown>) {
    if (e.key === "Enter" && e.shiftKey === false) {
      e.preventDefault();
    }
  };
 
  customHandleReset = () => {
    this.props.reset();
    const request = this.getFilter({});
    this.props.setChangeFilter(request);
    this.props.getChangesHistory(request, 0, defaultPagingOptions.pageSize);
  };

  renderFilterForm = () => {
    const { t, handleSubmit, usersSimple } = this.props;
    const changeTypesAll = Object.values(ChangesTypes);
    const changeTypes = changeTypesAll.splice(0, changeTypesAll.length / 2);

    const renderView = (item: Selectable): string => {
      if (!item) {
        return "";
      }
      return t(item as string);
    };

    return (
      <form
        onSubmit={handleSubmit(this.submitForm)}
        autoComplete="off"
        onKeyDown={this.handleKeyDown}
        className="change-filter"
        onReset={handleSubmit(this.customHandleReset)}
      >
        <div className="change-filter_form">
          <div className="change-filter_field">
            <Field
              name="type"
              type="text"
              placeholder={t("ChangesHistory.Operation")}
              items={changeTypes}
              t={t}
              renderView={renderView}
              noSelection={t("UI.NoSelection")}
              component={renderSimpleSelectField}
            />
          </div>
          <div className="change-filter_field">
            <Field
              name="dateTimeFrom"
              type="text"
              placeholder={t("ChangesHistory.Date")}
              t={t}
              parse={(date: Date) => parseDateToString(date, false)}
              format={formatStringToDate}
              component={renderDateField}
            />
          </div>
          <div className="change-filter_field">
            <Field
              name="dateTimeTo"
              type="text"
              placeholder={t("ChangesHistory.Date")}
              t={t}
              parse={(date: Date) => parseDateToString(date, true)}
              format={formatStringToDate}
              component={renderDateField}
            />
          </div>
          <div className="change-filter_field">
            <Field
              name="user"
              type="text"
              placeholder={t("ChangesHistory.User")}
              t={t}
              view={Views.FILTER}
              size="large"
              reference={Array.from(usersSimple.values())}
              field={{
                searchField: "username",
              }}
              component={renderReferenceField}
            />
          </div>
          <div className="change-filter_field">
            <Button type="submit" text={t("UI.Search")} />
            <Button className="resetBtn" type="reset" text={t("UI.Clear")} />
          </div>
        </div>
      </form>
    );
  };
}

const mapStateToProps = (state: IAppState) => ({
  totalChangesCount: state.changesReducer.totalChangesCount,
  usersSimple: state.usersReducer.usersSimple,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setChangeFilter: (filter) => setChangeFilter(filter),
      getChangesHistory: (filter, page, pageSize) => getChangesHistory(filter, page, pageSize),
      getUsersSimple: () => getUsersSimple(),
    },
    dispatch
  );

const CreateChangeFilter = reduxForm<DictionaryObject, Props>({
  form: "ChangeFilter",
  enableReinitialize: true,
})(ChangesFilter);

const ConnectedChangeFilterForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateChangeFilter);

export default withTranslation()(withRouter(ConnectedChangeFilterForm));
