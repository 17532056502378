import { Label } from "@blueprintjs/core";
import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { Views } from "../../../../../models/enums/views";
import { IFormField } from "../../../../../models/fields/form-field";
import { Selectable } from "../../../../../models/types/dictionary";
import { renderSimpleSelectField } from "../../controls/simple-select/SimpleSelect";
import "./EnumField.scss";


export const EnumField = (props: IFormField) => {
  const { t } = useTranslation();
  const { f, view, showLabel, fieldName } = props;


  const FilterView = () => {
    const label = 8;
    const field = 16;
    return (
      <Row key={f.name}>
        {showLabel && (
          <Label htmlFor={fieldName}>
            <span>{t(f.name)}</span>
            <span className="required">{f.required ? "*" : ""}</span>
          </Label>
        )}
        <Field
          name={fieldName}
          type="text"
          items={f.renum || []}
          t={t}
          renderView={(value: Selectable) => t(f.name + '-' + value)}
          noSelection={t("UI.NoSelection")}
          component={renderSimpleSelectField}
        />
      </Row>
    );
  };

  const FormView = () => {
    const label = 8;
    const field = 16;
    return (
      <Row key={f.name}>
        {showLabel && (
          <Col span={label}>
            <Label htmlFor={fieldName}>
              <span>{t(f.name)}</span>
              <span className="required">{f.required ? "*" : ""}</span>
            </Label>
          </Col>
        )}
        <Col
          span={field}>
          <Field
            name={fieldName}
            type="text"
            items={f.renum || []}
            t={t}
            disabled={!f.writable}
            renderView={(value: Selectable) => t(f.name + '-' + value)}
            noSelection={t("UI.NoSelection")}
            component={renderSimpleSelectField}
          />
        </Col>
      </Row>
    );
  };

  switch (view) {
    case Views.FILTER:
      return FilterView();
    case Views.FORM:
      return FormView();
    case Views.REFERENCE:
      return <span>Not implemented</span>;
    default:
      return <span>Not implemented</span>;
  }
};
export default EnumField;
