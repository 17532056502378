import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { project } from "../../../configs/index";
import { setActiveModel } from "../../../redux/actions/modelsActions";
import { IAppState } from "../../../redux/states/state";
import "./DetectModel.scss";

interface State { }

interface Props extends RouteComponentProps {
  activeModel: string;
  setActiveModel: (
    modelName: string,
    dbname: string,
    allowCreate: boolean,
    archived: boolean,
    activeRoute: string,
    statesFilter?: string[]
  ) => void;
}

class DetectModel extends React.Component<Props, State> {
  setActiveModelName(location: any, prevLocation?: any) {
    const { pathname } = location;
    if (!prevLocation || prevLocation.pathname !== location) {
      const currentPath = pathname
        ? pathname.substr(1, pathname.length - 1)
        : "";
      const modelName = (project.routings as any)[currentPath];

      if (modelName && modelName.model) {
        this.props.setActiveModel(
          modelName.model,
          modelName.dbname,
          modelName.allowCreate,
          !!modelName.archived,
          pathname,
          modelName.statesFilter
        );
      }
    }
  }

  componentDidMount(): void {
    this.setActiveModelName(this.props.location);
    let prevLocation = this.props.location;
    this.props.history.listen((location: any, action: any) => {
      this.setActiveModelName(location, prevLocation);
      prevLocation = location;
    });
  }

  render() {
    const { activeModel } = this.props;

    if (!activeModel) {
      return <span>Not a model...</span>;
    }

    return <div></div>;
  }
}

const mapStateToProps = (state: IAppState) => ({
  activeModel: state.modelsReducer.activeModel,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setActiveModel: (
        model: string,
        dbname: string,
        allowCreate: boolean,
        archived: boolean,
        activeRoute: string,
        statesFilter?: string[]
      ) =>
        setActiveModel(
          model,
          dbname,
          allowCreate,
          archived,
          activeRoute,
          statesFilter
        ),
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DetectModel)
);
