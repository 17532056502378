import axios from "axios";
import { USERS_BACKEND } from "../../backend-connection";
import { IUser } from "../../models/users/user";

const createUser = (user: IUser): Promise<any> => {
  return axios(`${USERS_BACKEND}/add`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(user),
  });
};

const updateUser = (user: IUser): Promise<any> => {
  return axios(`${USERS_BACKEND}/update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(user),
  });
};

const getUsers = (
  page: number,
  pageSize: number,
  showDeleted: boolean
): Promise<any> => {
  return axios(
    `${USERS_BACKEND}/findWithRole?pageSize=${pageSize}&page=${page}&showDeleted=` +
      showDeleted,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getUsersCount = (
  page: number,
  pageSize: number,
  showDeleted: boolean
): Promise<any> => {
  return axios(`${USERS_BACKEND}/count`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const setRole = (userId: number, roleId: number): Promise<any> => {
  return axios(`${USERS_BACKEND}/setRole`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      userId: userId,
      roleId: roleId,
    }),
  });
};

const changePassword = (user: IUser): Promise<any> => {
  return axios(`${USERS_BACKEND}/changePassword`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(user),
  });
};

const getUsersSimple = (): Promise<any> => {
  return axios(`${USERS_BACKEND}/find?pageSize=1000`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getUser = (userId: number): Promise<any> => {
  return axios(`${USERS_BACKEND}/get?userId=` + userId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export {
  createUser,
  getUsers,
  setRole,
  getUsersCount,
  updateUser,
  changePassword,
  getUsersSimple,
  getUser,
};
