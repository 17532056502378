import { Epic } from "redux-observable";
import { IAppState } from "../states/state";
import { ActionsType, actions } from "../store";
import * as API from "../api";
import { isActionOf } from "typesafe-actions";
import { concat, from, of } from "rxjs";
import { catchError, filter, map, mergeMap } from "rxjs/operators";

export const getRolePermissions: Epic<
  ActionsType,
  ActionsType,
  IAppState,
  typeof API
> = (action$, store, service) =>
  action$.pipe(
    filter(isActionOf(actions.roleActions.getPermissionsByRole)),
    mergeMap((action) =>
      concat(
        of(actions.roleActions.loadingRolePermissions(true)),
        from(service.rolesApi.getPermissions()).pipe(
          catchError((error) =>
            of(actions.errorsActions.showError("ERROR.GetPermissions"))
          ),
          map((response) =>
            actions.roleActions.loadPermits(response.data || [])
          )
        ),
        from(service.rolesApi.getPermissiosByRole(action.payload.roleId)).pipe(
          catchError((error) =>
            of(actions.errorsActions.showError("ERROR.GetPermissionByRole"))
          ),
          map((response) =>
            actions.roleActions.loadRolePermissions(response.data || [])
          )
        ),
        of(actions.roleActions.loadingRolePermissions(false))
      )
    )
  );

export const getPermissionsEpic: Epic<
  ActionsType,
  ActionsType,
  IAppState,
  typeof API
> = (action$, store, service) =>
  action$.pipe(
    filter(isActionOf(actions.roleActions.getPermissions)),
    mergeMap((action) =>
      concat(
        of(actions.roleActions.loadingRolePermissions(true)),
        from(service.rolesApi.getPermissions()).pipe(
          catchError((error) =>
            of(actions.errorsActions.showError("ERROR.GetPermissions"))
          ),
          map((response) =>
            actions.roleActions.loadPermits(response.data || [])
          )
        ),
        of(actions.roleActions.loadingRolePermissions(false))
      )
    )
  );

export const getRoles: Epic<ActionsType, ActionsType, IAppState, typeof API> = (
  action$,
  store,
  service
) =>
  action$.pipe(
    filter(isActionOf(actions.roleActions.getRoles)),
    mergeMap((action) =>
      concat(
        of(actions.roleActions.loadingRoles(true)),
        from(service.rolesApi.getRoles()).pipe(
          catchError((error) =>
            of(actions.errorsActions.showError("ERROR.GetRoles"))
          ),
          map((response) => actions.roleActions.loadRoles(response.data || []))
        ),
        of(actions.roleActions.loadingRoles(false))
      )
    )
  );

export const createRole: Epic<
  ActionsType,
  ActionsType,
  IAppState,
  typeof API
> = (action$, store, service) =>
  action$.pipe(
    filter(isActionOf(actions.roleActions.createRole)),
    mergeMap((action) =>
      from(service.rolesApi.createRole(action.payload.role)).pipe(
        catchError((error) =>
          of(actions.errorsActions.showError("ERROR.CreateRole"))
        ),
        map((response) => actions.roleActions.getRoles())
      )
    )
  );

export const modifyRole: Epic<
  ActionsType,
  ActionsType,
  IAppState,
  typeof API
> = (action$, store, service) =>
  action$.pipe(
    filter(isActionOf(actions.roleActions.modifyRole)),
    mergeMap((action) =>
      from(service.rolesApi.modifyRole(action.payload.role)).pipe(
        catchError((error) =>
          of(actions.errorsActions.showError("ERROR.ModifyRole"))
        ),
        map((response) => actions.roleActions.getRoles())
      )
    )
  );

export const removeRole: Epic<
  ActionsType,
  ActionsType,
  IAppState,
  typeof API
> = (action$, store, service) =>
  action$.pipe(
    filter(isActionOf(actions.roleActions.removeRole)),
    mergeMap((action) =>
      from(service.rolesApi.removeRole(action.payload.roleId)).pipe(
        catchError((error) =>
          of(actions.errorsActions.showError("ERROR.RemoveRole"))
        ),
        map((response) => actions.roleActions.getRoles())
      )
    )
  );

export default [
  getRolePermissions,
  getRoles,
  createRole,
  modifyRole,
  removeRole,
  getPermissionsEpic,
];
