import { Button, Intent, Label } from "@blueprintjs/core";
import { Col, Row, Table } from "antd";
import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { IPermit } from "../../../models/permit";
import { IRole, IRoleWithPermits } from "../../../models/role";
import { DictionaryObject } from "../../../models/types/dictionary";
import { setConfirm } from "../../../redux/actions/alertActions";
import {
  clearPermits,
  createDublicate,
  createRole,
  getPermissions,
  getPermissionsByRole,
  modifyRole,
  removeRole,
  selectRole
} from "../../../redux/actions/roleActions";
import { IAppState } from "../../../redux/states/state";
import { IStateAlerts } from "../../../redux/states/state-alerts";
import { mandatory } from "../../../validation/validate";
import { renderTextField } from "../../core/forms/controls/text-input/TextInput";
import { renderBooleanField } from "../../core/forms/fields/boolean-field/BooleanField";
import "./CreateRoles.scss";

const joiningPermissions = (permits: IPermit[], rolePermits: IPermit[]) => {
  const _permits: DictionaryObject = {};
  if (!permits.length) {
    return permits;
  }
  permits.forEach((p: IPermit) => {
    _permits[p.id + "u"] = !!rolePermits.find((rp) => rp.id === p.id);
  });
  return _permits;
};

interface Props extends RouteComponentProps, WithTranslation {
  roles: IRole[];
  permits: IPermit[];
  selectedRole: IRoleWithPermits;
  isDublicateCreation: boolean;
  rolePermissions: IPermit[];
  loadingRolePermissions: boolean;
  createRole: (role: IRoleWithPermits) => void;
  removeRole: (roleId: number) => void;
  modifyRole: (rolePermit: IRoleWithPermits) => void;
  selectRole: (role: IRoleWithPermits) => void;
  createDublicate: (isDublicateCreation: boolean) => void;
  getPermissions: () => void;
  getPermissiosByRole: (roleId: number) => void;
  clearPermits: () => void;
  openForm: (isOpen: boolean) => void;
  setConfirm: (props: IStateAlerts) => void;
}

class CreateRole extends React.PureComponent<
  Props & InjectedFormProps<{}, Props>,
  any
> {
  public state = {
    columns: [
      {
        title: this.props.t("UI.Name"),
        dataIndex: "name",
        render: (el: any) => {
          return el["az"];
        },
      },
      {
        title: this.props.t("UI.Allow"),
        render: (el: any, record: IPermit) => {
          return (
            <Field
              name={"permit." + record.id + "u"}
              type="text"
              field={{
                name: record.code,
                component: "Boolean",
                label: record.name,
              }}
              component={renderBooleanField}
            />
          );
        },
      },
    ],
  };

  componentDidMount(): void {
    const id = (this.props.initialValues as DictionaryObject)["id"];
    if (id) {
      this.props.getPermissiosByRole(Number(id));
    } else {
      this.props.getPermissions();
    }
  }

  componentWillUnmount(): void {
    this.props.reset();
    this.props.clearPermits();
    this.props.createDublicate(false);
  }

  submitForm = (role: any) => {
    const { isDublicateCreation } = this.props;

    const newRole: IRoleWithPermits = {
      id: isDublicateCreation ? 0 : role.id,
      name: {
        az: role.name,
      },
      permissionsIds: role.permit
        ? Object.keys(role.permit)
            .filter((k: string) => !!role.permit[k])
            .map((key: string) => parseInt(key))
        : [],
    };

    if (newRole.id === 0) {
      this.props.createRole(newRole);
    } else {
      this.props.modifyRole(newRole);
    }
    this.handleClose();
  };

  removeRole = (roleId: number) => {
    this.props.removeRole(roleId);
    this.handleClose();
  };

  private handleClose = () => {
    this.props.openForm(false);
  };

  handleKeyDown = function (e: any, cb?: any) {
    if (e.key === "Enter" && e.shiftKey === false) {
      e.preventDefault();
    }
  };

  public render() {
    const { t, permits, loadingRolePermissions, handleSubmit } = this.props;
    const { columns } = this.state;

    return (
      <div className="create-role">
        <div className="create-role_body">
          <form
            onSubmit={handleSubmit(this.submitForm)}
            onKeyDown={(e) => {
              this.handleKeyDown(e);
            }}
            className="create-roles"
            autoComplete="off"
          >
            <Row className="input-row" gutter={16}>
              <Col span={8}>
                <Label className="label">{t("Role.RoleName")}</Label>
              </Col>
              <Col span={16}>
                <Field
                  name="name"
                  type="text"
                  placeholder={t("Role.RoleName")}
                  validate={mandatory}
                  component={renderTextField}
                />
              </Col>
            </Row>
            <Table
              loading={loadingRolePermissions}
              className="permit-table"
              columns={columns}
              dataSource={permits}
              pagination={false}
              tableLayout={"auto"}
              rowKey={"id"}
              scroll={{ y: 300 }}
              bordered
            />
            <Row className="button-row">
              {!this.props.isDublicateCreation &&
                !!this.props.initialValues &&
                !!(this.props.initialValues as DictionaryObject)["id"] && (
                  <Button
                    text={t("UI.Remove")}
                    intent={Intent.DANGER}
                    onClick={() => {
                      this.props.setConfirm({
                        message: t("UI.Sure"),
                        isOpen: true,
                        onConfirm: () => {
                          this.removeRole(
                            !!this.props.initialValues
                              ? Number(
                                  (this.props.initialValues as DictionaryObject)[
                                    "id"
                                  ]
                                ) || 0
                              : 0
                          )
                        },
                      });
                    }
                      
                    }
                  />
                )}
              <Button
                text={t("UI.Save")}
                intent={Intent.PRIMARY}
                type="submit"
              />
            </Row>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  roles: state.permitionReducer.roles,
  permits: state.permitionReducer.permits,
  selectedRole: state.permitionReducer.selectedRole,
  isDublicateCreation: state.permitionReducer.isDublicateCreation,
  rolePermissions: state.permitionReducer.rolePermissions,
  loadingRolePermissions: state.permitionReducer.loadingRolePermissions,
  initialValues: state.permitionReducer.selectedRole
    ? {
        ...state.permitionReducer.selectedRole,
        name: state.permitionReducer.isDublicateCreation
          ? ""
          : state.permitionReducer.selectedRole?.name?.az,
        permit: !state.permitionReducer.loadingRolePermissions
          ? joiningPermissions(
              state.permitionReducer.permits,
              state.permitionReducer.rolePermissions
            )
          : {},
      }
    : {
        id: 0,
        name: "",
        permit: {},
      },
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createRole: (role: IRoleWithPermits) => createRole(role),
      removeRole: (roleId: number) => removeRole(roleId),
      modifyRole: (rolePermit: IRoleWithPermits) => modifyRole(rolePermit),
      selectRole: (role: IRoleWithPermits) => selectRole(role),
      createDublicate: (isDublicateCreation: boolean) =>
        createDublicate(isDublicateCreation),
      getPermissiosByRole: (roleId: number) => getPermissionsByRole(roleId),
      getPermissions: () => getPermissions(),
      clearPermits: () => clearPermits(),
      setConfirm: (props: any) => setConfirm(props),
    },
    dispatch
  );

const CreateRoleForm = reduxForm<{}, Props>({
  form: "RoleForm",
  enableReinitialize: true,
})(CreateRole);

const ConnectedCreateRoleForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRoleForm);

export default withTranslation()(withRouter(ConnectedCreateRoleForm));
