import { IViewField } from "../models/view-field";
import { ConstraintType } from "../models/enums/constraint-type";

export const checkDisabledConstraints = (
  field: IViewField,
  listeners?: Map<string, any>
) => {
  if (
    !field.viewConstraints ||
    !field.viewConstraints.length ||
    !listeners ||
    !listeners.size
  ) {
    return false;
  }
  const constraint = field.viewConstraints.find(
    (c) => c.type === ConstraintType.Disable
  );

  if (!constraint) {
    return false;
  }
  const foundContsraint = listeners.get(constraint.field);

  if (!foundContsraint) {
    return false;
  }

  return (
    foundContsraint &&
    constraint.value &&
    constraint.refField &&
    foundContsraint[constraint.refField] &&
    constraint.value === foundContsraint[constraint.refField]
  );
};
