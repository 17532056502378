import React from "react";

const ptIconWrapper = (path: any, viewboxDefault = 24) => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9551 1.88367H14.2729V0L0 2.02772V19.6535L14.2729 22V19.2688H22.9551C23.532 19.2688 24 18.882 24 18.4045V2.74872C24 2.27122 23.532 1.88367 22.9551 1.88367ZM23.0926 18.5493H14.2435L14.2419 17.5146H22.5245V16.4514H14.2339L14.2284 15.0035H22.5237V14.129H14.2117L14.2014 12.6957H22.5237V11.6947H14.1855L14.1759 10.3412H22.5253V9.47318H14.168V7.90687H22.5245V7.03889H14.168V5.40092H22.5245V4.79765H14.168V2.70119H23.0918V18.5493H23.0926Z"
        fill="#265D9C"
      />
      <path
        d="M4.20781 14H5.77076L6.90739 9.22012L7.90241 14H9.39418L11 7H9.67815L8.75507 12.4136L7.6039 7.53118H6.33946L5.13165 12.3474L4.20781 7.53118H3L4.20781 14Z"
        fill="white"
      />
    </svg>
  );
};

export const wordIcon = ptIconWrapper(
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M443.61 271.3H334.34V245.54L154.71 273.27V514.31L334.34 546.4V509.05H443.61C450.87 509.05 456.76 503.76 456.76 497.23V283.13C456.76 276.6 450.87 271.3 443.61 271.3ZM445.34 499.21H333.97L333.95 485.06H438.19V470.52H333.85L333.78 450.72H438.18V438.76H333.57L333.44 419.16H438.18V405.47H333.24L333.12 386.96H438.2V375.09H333.02V353.67H438.19V341.8H333.02V319.4H438.19V311.15H333.02V282.48H445.33V499.21H445.34Z"
      fill="#265D9C"
    />
    <path
      d="M203.81 441.43H224.23L239.08 374.3L252.08 441.43H271.57L292.55 343.12H275.28L263.22 419.15L248.18 350.58H231.66L215.88 418.22L203.81 350.58H188.03L203.81 441.43Z"
      fill="white"
    />
  </g>,
  48
);
