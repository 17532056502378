import React, { Fragment } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { project } from "../../../configs/index";
import { IPermit } from "../../../models/permit";
import { IUser } from "../../../models/users/user";
import {
  getSelf,
  userHasPermit
} from "../../../redux/actions/authorizationActions";
import { goToRoute } from "../../../redux/actions/navigationActions";
import { IAppState } from "../../../redux/states/state";
import ChangesGeneral from "../../changes-history/changes-general/ChangesGeneral";
import Roles from "../../roles/roles/Roles";
import Templates from "../../templates/templates/Templates";
import LoginPage from "../../user/user-login/LoginPage";
import Users from "../../user/users/Users";
import ArchiveView from "../archive/ArchiveView";
import ControlMarks from "../controlMarks/ControlMarks";
import DynamicModelView from "../dynamic-model-view/DynamicModelView";
import Reports from "../reports/Reports";
import "./TopMenu.scss";


interface Props extends RouteComponentProps, WithTranslation {
  getSelf: () => void;
  permits: IPermit[];
  userInfo: IUser | null;
  goToRoute: (route: string) => void;
  inProcess: boolean;
}

class Routings extends React.Component<Props, any> {
  componentDidMount(): void {
    if (this.props.location.pathname !== "/sign-in") {
      this.props.getSelf();
    }
    if (this.props.location.pathname === "/") {
      this.props.goToRoute("dynamic/incoming-document");
    }
  }

  render() {
    const { userInfo, permits, inProcess } = this.props;
    const routings = project.routings as any;
    return (
      <Switch>
        {userInfo && !inProcess ? (
          <Fragment>
            {Object.keys(project.routings).map((r: any) => {
              if(routings[r].permit && !userHasPermit(permits, routings[r].permit)) return <></>;
              switch (true) {
                case r.includes("controlmarks"):
                  return (
                    <Route key={r} path={`/${r}`} exact component={ControlMarks} />
                  );
                case r.includes("reports"):
                  return (
                    <Route key={r} path={`/${r}`} exact component={Reports} />
                  );
                case r.includes("archive"): return (
                  <Route key={r} path={`/${r}`} exact component={ArchiveView} />
                );
                default:
                  return null;
              }
            })}
            <Route path={`/dynamic/`} component={DynamicModelView} />
            <Route exact path="/incoming-document" render={() => <Redirect to="dynamic/incoming-document" />} />
            {/* <Redirect from='/incoming-document' to="/dynamic/incoming-document" /> */}
            {userHasPermit(permits, "UR") ? (
              <Route path={`/roles`} component={Roles} />
            ) : null}
            {userHasPermit(permits, "UR") ? (
              <Route path={`/users`} component={Users} />
            ) : null}
            {userHasPermit(permits, "UR") ? (
              <Route path={`/templates`} component={Templates} />
            ) : null}
            {userHasPermit(permits, "UR") ? (
              <Route path={`/changes`} component={ChangesGeneral} />
            ) : null}
          </Fragment>
        ) : null}
        <Route path={`/sign-in`} component={LoginPage} />
      </Switch>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  userInfo: state.authorizationReducer.userInfo,
  permits: state.authorizationReducer.permits,
  inProcess: state.authorizationReducer.inProcess,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getSelf: () => getSelf(),
      goToRoute: (path) => goToRoute(path),
    },
    dispatch
  );

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Routings))
);
