import React, { ReactElement } from "react";
import { ChangeAction } from "../changes/field-change";
import { IConstraint } from "../constraint";
import { Dictionary } from "../types/dictionary";
import { GeneralField } from "./general-field";

export class TextareaField extends GeneralField {
  compareFunction(before: Dictionary, after: Dictionary): ChangeAction {
    return (before as string) === (after as string)
      ? ChangeAction.Unchanged
      : ChangeAction.Updated;
  }

  viewRender(value: Dictionary): ReactElement {
    if (!value) {
      return <div></div>;
    }
    return <span>{value}</span>;
    // const length = (value as string).length;
    // if (length > 100) {
    //   const splice = (value as string).substr(0, 100);
    //   return <span>{splice + "..."}</span>;
    // } else {
    //   return <span>{value}</span>;
    // }
  }

  stringRender(value: Dictionary): string {
    return value as string;
  }

  tagRender(value: Dictionary, onRemove: (id: number) => void): ReactElement {
    return this.viewRender(value);
  }

  emptyConstraint(fieldName: string): IConstraint {
    return {
      type: "NullOrEmpty",
      field: fieldName,
    } as IConstraint;
  }

  valueConstraint(fieldName: string, value: Dictionary) {
    return [
      {
        type: "Like",
        field: fieldName,
        value: `%${value}%`,
      } as IConstraint,
    ];
  }

  isEmpty(value: Dictionary) {
    return !value;
  }
}
