export const UPDATE_ENTITY = "UPDATE_ENTITY";
export const ACCEPT_ENTITY = "ACCEPT_ENTITY";
export const GET_ENTITIES = "GET_ENTITIES";
export const LOAD_ENTITIES = "LOAD_ENTITIES";
export const REFRESH_ITEM = "REFRESH_ITEM";
export const LOAD_ITEM = "LOAD_ITEM";
export const UPDATE_ENTITIES_SORTING = "UPDATE_ENTITIES_SORTING";
export const UPDATE_ENTITIES_PAGING = "UPDATE_ENTITIES_PAGING";
export const GET_RELATED_CARD = "GET_RELATED_CARD";
export const LOAD_RELATED_CARD = "LOAD_RELATED_CARD";
export const CLEAR_RELATED_CARDS = "CLEAR_RELATED_CARDS";
export const REMOVE_RELATED_CARD = "REMOVE_RELATED_CARD";
