import { FormGroup, Label, PopoverPosition } from "@blueprintjs/core";
import { Col, Row } from "antd";
import { TFunction } from "i18next";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { change, Field, formValueSelector } from "redux-form";
import { WrappedFieldProps } from "redux-form/lib/Field";
import { checkDisabledConstraints } from "../../../../../helpers/check-disabled-constraints";
import { checkFilterConstraints } from "../../../../../helpers/check-filter-constraints";
import { getDeepValue } from "../../../../../helpers/get-deep-value";
import { AUTOCOMPLETE_SIZE } from "../../../../../helpers/ui-constants";
import { IConstraint, IConstraintTree } from "../../../../../models/constraint";
import { IDefaultSorting } from "../../../../../models/default-sorting";
import { ConstraintType } from "../../../../../models/enums/constraint-type";
import { Views } from "../../../../../models/enums/views";
import { IFormField } from "../../../../../models/fields/form-field";
import {
  DictionaryObject,
  Selectable
} from "../../../../../models/types/dictionary";
import { IViewField } from "../../../../../models/view-field";
import {
  referenceFormIsOpen,
  getReferenceQueryForAutocomplite,
  getReferenceQuery
} from "../../../../../redux/actions/referencesActions";
import { IAppState } from "../../../../../redux/states/state";
import { mandatory } from "../../../../../validation/validate";
import ReferenceView from "../../../lists/reference-view/ReferenceView";
import Autocomplete from "../../controls/autocomplete/Autocomplete";
import CompanyDetails from "../../controls/company-details/CompanyDetails";
import { renderEmptyField } from "../../controls/empty-btn/EmptyBtn";
import ValidMessages from "../../valid-messages/ValidMessages";
import "./ReferenceField.scss";

export const renderReferenceField = ({
  input,
  meta: { touched, invalid, error },
  field,
  placeholder,
  reference,
  resetOnClose,
  flatObject,
  onChangeQuery,
  view,
  size,
  disabled,
  createNew,
  editItem,
  transformQuery,
  valueInitial,
  t,
}: WrappedFieldProps & {
  field: IViewField;
  placeholder: string;
  reference: Selectable[];
  resetOnClose: boolean;
  flatObject: boolean;
  onChangeQuery: (query: string, event?: React.ChangeEvent<HTMLInputElement>) => void;
  view: Views;
  size: AUTOCOMPLETE_SIZE;
  disabled?: boolean;
  createNew?: (query: string) => void;
  editItem: (item: DictionaryObject) => void;
  transformQuery?: (query: string) => any,
  valueInitial: any;
  t: TFunction;
}) => {
  const rightBtnClick = () => {
    if (onChangeQuery) {
      input.onChange(null);
      onChangeQuery("");
    }
  };
  const renderView = (item: Selectable): string => {
    if (!item) {
      return "";
    }
    if (typeof item === "object") {
      if (flatObject && field.searchField) {
        return item[field.searchField]?.toString() || "";
      }
      const viewFields = field.referenceView;
      if (viewFields && viewFields.length) {
        const deepValues = viewFields.map((rv) => getDeepValue(item, rv));
        if (deepValues.some((dv) => !!dv)) {
          return deepValues
            .map((dv, index: number) => {
              const value = dv ? dv : " --- ";
              const delimiter =
                value && index > 0 ? field.viewDelimiter || " / " : "";
              return delimiter + value;
            })
            .join("");
        } else {
          return "";
        }
      }
      return (item as DictionaryObject)[field.searchField || "Name"] as string;
    } else {
      return field.needTranslate ? t(item?.toString()) : item?.toString();
    }
  };

  const renderViewHelper = (item: Selectable): string => {
    if (!item) {
      return "";
    }
    if (typeof item === "object") {
      const viewFields = [field.helper || ""];
      if (viewFields && viewFields.length) {
        return viewFields
          .map((rv, index: number) => {
            const deepValue = getDeepValue(item, rv);
            const value = deepValue ? getDeepValue(item, rv) : " --- ";
            const delimiter =
              value && index > 0 ? field.viewDelimiter || " / " : "";
            return delimiter + value;
          })
          .join("");
      }
      return (item as DictionaryObject)[field.helper || "Name"] as string;
    } else {
      return field.needTranslate ? t(item?.toString()) : item?.toString();
    }
  };

  if (valueInitial && disabled && input.value !== valueInitial) {
    input.value = valueInitial;
  }

  const referenceSearchHelper = (field: IViewField, reference: any[]) => {
    if (field.helper) {
      return (
        <Autocomplete
          className={error && touched ? "bp3-intent-danger" : ""}
          items={reference}
          size={size}
          renderView={renderViewHelper}
          position={
            view === Views.FILTER
              ? PopoverPosition.AUTO_END
              : PopoverPosition.AUTO
          }
          input={input}
          flatObject={flatObject}
          onChangeQuery={onChangeQuery}
          createNew={createNew}
          editItem={editItem}
          transformQuery={transformQuery}
          rightBtnClick={rightBtnClick}
          resetOnClose={resetOnClose}
          placeholder={placeholder}
          disabled={disabled}
          autoCreateItem={field.autoCreateItem}
        />
      );
    }
  };
  return (
    <div>
      <Row gutter={16}>
        <Col span={field.helper && view === Views.FORM ? 12 : 0}>
          {referenceSearchHelper(field, reference)}
        </Col>
        <Col span={field.helper && view === Views.FORM ? 12 : 24}>
          <div>
            <Autocomplete
              className={error && touched ? "bp3-intent-danger" : ""}
              items={reference}
              size={size}
              renderView={renderView}
              position={
                view === Views.FILTER
                  ? PopoverPosition.AUTO_END
                  : PopoverPosition.AUTO
              }
              input={input}
              flatObject={flatObject}
              onChangeQuery={onChangeQuery}
              createNew={createNew}
              editItem={editItem}
              transformQuery={transformQuery}
              rightBtnClick={rightBtnClick}
              resetOnClose={resetOnClose}
              placeholder={placeholder}
              disabled={disabled}
              filter={field.filter}
              autoCreateItem={field.autoCreateItem}
            />
          </div>
          {field.detailedView && input.value && input.value.default !== true &&
            <div className="detailed-view">
              {field.model != "Company" && <ReferenceView field={field} viewArray={field.detailedView || []} showEmpty={true} item={input.value} showFields={true} delimiter=", " />}
              {field.model == "Company" && <CompanyDetails field={field} input={input} />}
            </div>
          }
        </Col>
      </Row>
      {touched && error && <ValidMessages text={error} />}
    </div>
  );
};

export const ReferenceField = (props: IFormField) => {
  const { t } = useTranslation();
  const { f, formName, fieldName, view, showEmpty, showLabel, autoChangable, isDraft, listeners } = props;
  const [reference, setReference] = useState<any>([]);
  const [initialValue, setInitialValue] = useState(null);
  const [notOnlyValues, setNotOnlyValues] = useState(true);
  const dispatch = useDispatch();
  const selectedItem = useSelector((state: IAppState) => state.entitiesReducer.selectedItem)

  const referenceModel = useSelector((state: any) => {
    return state.modelsReducer.models.get(f.model)
  }
  );
  const createNew = () => {
    dispatch(referenceFormIsOpen(referenceModel.dbname, true, null, () => null));
    setReference([]);
  };

  const editItem = (item: DictionaryObject) => {
    dispatch(referenceFormIsOpen(referenceModel.dbname, true, item, (close: boolean, value: any) => {
      dispatch(change(formName, fieldName, value));
    }));
    setReference([]);
  };

  const getReferenceAuto = (filters: IConstraint[], pageSize: number = 15, callback?: CallableFunction) => {
    setReference([]);
    let sortingQuery = "";
    const sorting: IDefaultSorting[] = f.sorting
      ? f.sorting
      : [];
    sorting.forEach((sd: IDefaultSorting) => {
      sortingQuery += `&page.sortColumn=${sd.field}&page.sortDirection=${sd.direction}`;
    });
    // getReferenceQuery(f.model, sortingQuery, filters, 0, pageSize).then(
    //   (result) => {
    //     if (result) {
    //       setReference(result);
    //       callback && callback(result)
    //     } else {
    //       setReference([]);
    //     }
    //   });
    const firstTree: IConstraintTree = {
      rule: "And",
      constraints: filters,
      nodes: [],
    };
    // TODO: Магаммед - Здесь была ошибка, нужно не [0] брать а тот что равен searchField
    const _searchFilter = filters.find((f) => f.field === f.searchField);
    getReferenceQueryForAutocomplite(f.model, sortingQuery, firstTree, 0, pageSize).then(
      (resultStart) => {
        const tree: IConstraintTree = {
          rule: "And",
          constraints: [],
          nodes: [],
        };
        if (filters.length > 0 && _searchFilter) {
          tree.nodes.push({
            rule: "And",
            constraints: [],
            nodes: [
              {
                rule: "And",
                constraints: [_searchFilter],
                not: true,
                nodes: [],
              },
              {
                rule: "And",
                constraints: [{ ..._searchFilter, value: "%" + _searchFilter.value }],
                nodes: [],
              },
            ],
          });
          getReferenceQueryForAutocomplite(f.model, sortingQuery, tree, 0, pageSize).then(
            (resultEnd) => {
              if (resultEnd) {
                setReference(resultStart.concat(resultEnd));
                callback && callback(resultStart.concat(resultEnd))
              } else {
                setReference([]);
              }
            })
        }
        else if (resultStart) {
          setReference(resultStart);
          callback && callback(resultStart)
        } else {
          setReference([]);
        }
      }
    );
  }

  const getReferenceSimple = (filters: IConstraint[], pageSize: number = 15, callback?: CallableFunction) => {
    setReference([]);
    let sortingQuery = "";
    const sorting: IDefaultSorting[] = f.sorting
      ? f.sorting
      : [];
    sorting.forEach((sd: IDefaultSorting) => {
      sortingQuery += `&page.sortColumn=${sd.field}&page.sortDirection=${sd.direction}`;
    });
    getReferenceQuery(f.model, sortingQuery, filters, 0, pageSize).then(
      (result) => {
        if (result) {
          setReference(result);
          callback && callback(result)
        } else {
          setReference([]);
        }
      });
  }


  const queryData = (value: string, event: any, searchType: string) => {
    const filters = checkFilterConstraints(f, selectedItem, listeners);
    if (
      value &&
      event &&
      f.searchType &&
      f.searchType === "Autocomplete"
    ) {
      filters.push({
        type: ConstraintType.Like,
        field: f.searchField ? f.searchField : "",
        value: value + "%",
      });
      getReferenceAuto(filters, 100);
    } else if (
      !value &&
      f.searchType &&
      f.searchType === "Autocomplete"
    ) {
      setReference([]);
    }
  }

  const ReferenceFilterView = () => {
    const filters = checkFilterConstraints(f, selectedItem, listeners);
    const disabled = checkDisabledConstraints(f, listeners);

    return (
      <Row
        key={f.name}
        onClick={() => {
          if (!reference.length && f.searchType !== "Autocomplete") {
            getReferenceSimple(filters, 500);
          }
        }}
      >
        <FormGroup className="form-group-text">
          <Label htmlFor={fieldName}>{t(f.name)}</Label>
          <div className="any-text__container">
            <Field
              name={fieldName}
              type="text"
              onClick={() => { }}
              onChange={(e: any) => {
                if (props.onChange) {
                  props.onChange(e);
                }
              }}
              disabled={disabled}
              field={f}
              view={view}
              size="AUTOCOMPLETE_SIZE_SMALL"
              reference={reference}
              placeholder={""}
              resetOnClose={!f.savePlainText}
              flatObject={f.savePlainText}
              onChangeQuery={_.debounce(function (value: string, event: any) {
                queryData(value, event, "Autocomplete")
              }, 400)}
              component={renderReferenceField}
            />
            {!f.noSearchEmpty && showEmpty && (
              <Field
                name={fieldName + "__EMPTY"}
                type="checkbox"
                onClick={() => { }}
                onChange={(e: any) => { }}
                field={f}
                view={view}
                component={renderEmptyField}
              />
            )}
          </div>
        </FormGroup>
      </Row>
    );
  };
  const stateData = useSelector((state: any) => state);

  const referenceValues = (reference: any[], connectedValues: any[]) => {
    const result = reference ? reference : [];
    if (f.getItems) {
      return typeof f.getItems == "function" && f.getItems(selectedItem);
    }

    if (!notOnlyValues) {
      return connectedValues;
    }
    if (connectedValues && connectedValues.length) {
      if (reference && reference.length) {
        connectedValues.forEach((el) => {
          const nonFound = reference.find((v) => v === el);
          if (!nonFound) {
            result.push(el);
          }
        });
      } else {
        result.push(...connectedValues);
      }
    }
    return result;
  };


  const ReferenceFormView = () => {
    const label = f.helper ? 4 : 8;
    const field = f.helper ? 20 : 16;
    const disabled = checkDisabledConstraints(f, listeners) || f.readonly || (f.writable === false);
    const connectedToField = f.connectedTo;
    let selector: any = null;
    if (props.formName) {
      selector = formValueSelector(props.formName);
    }

    if (selector && f.initialValue) {
      const value = selector(stateData, f.initialValue.field);
      if (value && value !== initialValue) {
        setInitialValue(value);
      }
    }
    let valuesFromConnectedField: any = [];

    if (connectedToField && connectedToField.length && selector) {
      connectedToField.forEach((val) => {
        if (!val.notOnlyValues && notOnlyValues) {
          setNotOnlyValues(val.notOnlyValues);
        }
        const values = selector(stateData, val.field);

        if (values && values.length) {
          valuesFromConnectedField.push(...values);
        }
      });
    }

    if (valuesFromConnectedField && valuesFromConnectedField.length) {
      valuesFromConnectedField = valuesFromConnectedField.filter(
        (el: any) => !!Object.keys(el).length
      );
    }

    useEffect(() => {
      if (!autoChangable) return;
      const filters = checkFilterConstraints(f, selectedItem, listeners);
      if (f.listenerAddiction && listeners?.get(f.listenerAddiction))
        if (
          (filters && filters.length) ||
          !reference.length ||
          notOnlyValues
        )
          if (
            !f.searchType ||
            (f.searchType && f.searchType === "Select")
          )
            getReferenceSimple(filters, 100, (result: any[]) => dispatch(change(props.formName, fieldName, result[0])));
    }, [listeners?.get(f.listenerAddiction ? f.listenerAddiction : "")]);

    return (
      <Row key={f.name}>
        {showLabel && (
          <Col span={label}>
            <Label htmlFor={fieldName}>
              <span>{t(f.name)}</span>
              <span className="required">{f.required ? "*" : ""}</span>
            </Label>
          </Col>
        )}
        <Col
          span={field}
          onClick={() => {
            const filters = checkFilterConstraints(f, selectedItem, listeners);
            if (
              (filters && filters.length) ||
              !reference.length ||
              notOnlyValues
            ) {
              if (
                !f.searchType ||
                (f.searchType && f.searchType === "Select")
              ) {
                getReferenceSimple(filters, 100);
              }
            }
          }}
        >
          <Field
            name={fieldName}
            onClick={() => { }}
            disabled={disabled}
            type="text"
            onChange={(e: any) => {
              if (props.onChange) {
                props.onChange(e);
              }
            }}
            field={f}
            valueInitial={initialValue}
            view={view}
            size="AUTOCOMPLETE_SIZE_SMALL"
            reference={referenceValues(reference, valuesFromConnectedField)}
            resetOnClose={!f.savePlainText}
            flatObject={f.savePlainText}
            parse={(e: any) => f.savePlainText && e && f.searchField && e[f.searchField] ? e[f.searchField] : e}
            onChangeQuery={_.debounce(function (value: string, event: any) {
              queryData(value, event, "Autocomplete")
            }, 400)}
            placeholder={""}
            createNew={
              f.searchType && f.searchType === "Autocomplete" && f.allowCreate
                ? createNew
                : undefined
            }
            editItem={f.allowEdit ? editItem: undefined}
            component={renderReferenceField}
            validate={f.required && !isDraft ? mandatory : undefined}
          />
        </Col>
      </Row>
    );
  };

  switch (view) {
    case Views.FILTER:
      return ReferenceFilterView();
    case Views.FORM:
      return ReferenceFormView();
    case Views.REFERENCE:
      return <span>Text</span>;
    default:
      return <span>Text</span>;
  }
};
export default ReferenceField;
