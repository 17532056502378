import { Button, Icon, Intent, Label, Tag } from "@blueprintjs/core";
import { Position } from "@blueprintjs/core/lib/esm/common/position";
import { DateInput } from "@blueprintjs/datetime";
import { Col, Row } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FieldArrayFieldsProps } from "redux-form/lib/FieldArray";
import { IConstraint } from "../../../../../models/constraint";
import { IViewField } from "../../../../../models/view-field";
import { FORMATS } from "../../fields/date-field/DateField";
import "./MultipleDateForm.scss";
/* eslint-disable no-eval */

const parseDateToString = (date: Date, end: boolean) => {
  return date ? moment(date).format("MM/DD/YYYY hh:mm:ss") : "";
};
interface Props {
  f: IViewField;
  selectedItem: any;
  fields: FieldArrayFieldsProps<any>;
  constraints?: IConstraint[];
  formName: string;
}

export const MultipleDateForm = (props: Props) => {
  const { f, fields } = props;
  const [listeners, setListeners] = useState<any>(new Map<string, any>());
  const [selectedDate, setSelectedDate] = useState<string | null>();
  const { t } = useTranslation();
  const [, forceUpdate] = useState(false);
  const [maxDate] = useState(f.maxDate ? eval(f.maxDate) : undefined);

  const onAdd = () => {
    fields.push(selectedDate);
    setSelectedDate(null);
  };
  const onChange = (date: Date) => {
    const parsedDate = parseDateToString(date, true);
    setSelectedDate(parsedDate);

    if (f.listen) {
      listeners.set(f.name, parsedDate);
      setListeners(listeners);
      forceUpdate((s: boolean) => !s);
    }
  };

  return (
    <Row>
      <Row className="array-field__header">
        <Col span={24}>
          <Label>
            <span>{t(f.name)}</span>
            <span className="required">{f.required ? "*" : ""}</span>
          </Label>
        </Col>
      </Row>
      <Row className="array-field" gutter={16} key={f.name}>
        <Col className="array-field__form" span={12}>
          <Row>
            <Col span={16}>
              <div key={f.name}>
                <label>
                  <DateInput
                    canClearSelection={true}
                    showActionsBar={true}
                    closeOnSelection={true}
                    highlightCurrentDay={true}
                    fill
                    value={selectedDate ? new Date(selectedDate) : undefined}
                    disabled={!!f.readonly || (f.writable === false)}
                    {...FORMATS[0]}
                    maxDate={maxDate}
                    onChange={onChange}
                    placeholder={t("UI.SelectDate")}
                    inputProps={{
                      rightElement: <Icon icon="calendar" className="right-btn" />,
                    }}
                    popoverProps={{ position: Position.BOTTOM }}
                    dayPickerProps={{ firstDayOfWeek: 1 }}
                  />
                </label>
              </div>
            </Col>
            <Col span={8}>
              <div className="row button-row">
                <Button icon="plus" small onClick={() => onAdd()} />
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <div className="block-result">
            {fields
              ? fields.map((field: any, index: number) => {
                const item = fields.get(index);
                if (!item || item.default) {
                  return null;
                }

                const onRemove = (index: number) => {
                  fields.remove(index);
                };

                return (
                  <Tag
                    key={index}
                    intent={Intent.NONE}
                    multiline
                    onRemove={() => onRemove(index)}
                  >
                    {item
                      ? moment(item as string, "MM/DD/YYYY hh:mm:ss").format(
                        "DD.MM.YYYY"
                      )
                      : ""}
                  </Tag>
                );
              })
              : null}
          </div>
        </Col>
      </Row>
    </Row>
  );
};

export default MultipleDateForm;
