import React from "react";
import { FieldArray, WrappedFieldArrayProps } from "redux-form";
import { Views } from "../../../../../models/enums/views";
import { IFormField } from "../../../../../models/fields/form-field";
import { DictionaryObject } from "../../../../../models/types/dictionary";
import { IViewField } from "../../../../../models/view-field";
import MultipleTextForm from "../../controls/multiple-text-form/MultipleTextForm";
import TextField from "../text-field/TextField";
import "./MultipleTextField.scss";

const renderMultipleTextField = ({
  fields,
  meta: { error, submitFailed },
  field,
  selectedItem,
  formName,
}: WrappedFieldArrayProps & {
  field: IViewField;
  selectedItem: any;
  formName: string;
}) => {
  return (
    <div className="row-input">
      <MultipleTextForm
        formName={formName}
        fields={fields}
        f={field}
        selectedItem={selectedItem}
      />
      {/*{touched && ((error && <div>{error}</div>))}*/}
    </div>
  );
};

export const MultipleTextField = (props: IFormField) => {
  const { f, fieldName, view, formName } = props;
  const selectedItem: DictionaryObject | null = props.selectedItem as DictionaryObject;

  const ArrayFilterView = () => {
    return <TextField {...props} />;
  };

  const ArrayFormView = () => {
    return (
      <FieldArray
        key={f.name}
        name={fieldName}
        field={f}
        selectedItem={selectedItem}
        formName={formName}
        component={renderMultipleTextField}
      />
    );
  };

  switch (view) {
    case Views.FILTER:
      return ArrayFilterView();
    case Views.FORM:
      return ArrayFormView();
    case Views.REFERENCE:
      return <span>Text</span>;
    default:
      return <span>Text</span>;
  }
};

export default MultipleTextField;
