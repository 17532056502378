export const validateChangePassword = (values: any) => {
  const errors = {} as any;
  if (!values.password) {
    errors.password = "Required";
  }
  if (!values.newPassword) {
    errors.newPassword = "Required";
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = "Required";
  } else if (values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = "Passwords not equal";
  }
  return errors;
};
