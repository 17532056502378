import React from "react";
import { DictionaryObject } from "../../../../../models/types/dictionary";
import { IViewModel, ITab } from "../../../../../models/view-model";
import "./CustomGrid.scss";
import AutoGrid from "../auto-grid/AutoGrid";

const CustomGrid = (props: {
  item: DictionaryObject;
  tab: ITab;
  model: IViewModel;
  formName: string;
  listeners: Map<string, any>;
  hideId: boolean;
  isDraft: boolean;
}) => {
  const { tab, item, model, formName, listeners, hideId, isDraft } = props;
  switch (tab.grid) {
    case "auto-grid":
      return (
        <AutoGrid
          fields={tab.fields}
          selectedItem={item}
          model={model}
          formName={formName}
          listeners={listeners}
          hideId={hideId}
          isDraft={isDraft}
        />
      );
    default:
      return <div></div>;
  }
};

export default CustomGrid;
