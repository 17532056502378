import React, { ReactElement } from "react";
import { ChangeAction } from "../changes/field-change";
import { IConstraint } from "../constraint";
import { Dictionary } from "../types/dictionary";
import { GeneralField } from "./general-field";

export class TextField extends GeneralField {
  compareFunction(before: Dictionary, after: Dictionary): ChangeAction {
    return (before as string) === (after as string)
      ? ChangeAction.Unchanged
      : ChangeAction.Updated;
  }

  viewRender(value: Dictionary): ReactElement {
      return <span> {value ? this.viewField.affix ? this.viewField.affix + value.toString() : value.toString() : ""}</span>;
  }

  stringRender(value: Dictionary): string {
    return value ? value as string : "";
  }

  tagRender(value: Dictionary): ReactElement {
    return this.viewRender(value);
  }

  emptyConstraint(fieldName: string): IConstraint {
    return {
      type: "NullOrEmpty",
      field: fieldName,
    } as IConstraint;
  }

  valueConstraint(fieldName: string, value: Dictionary) {
    return [
      {
        type: "Like",
        field: fieldName,
        value: `%${value}%`,
      } as IConstraint,
    ];
  }

  isEmpty(value: Dictionary) {
    return !value;
  }
}
