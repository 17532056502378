import { Button, Classes, Dialog, Intent, TextArea } from "@blueprintjs/core";
import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { showError } from "../../../../redux/actions/errorsActions";
import "./PrintCount.scss";

export const PrintCount = (props: {
  isOpen: boolean;
  close: () => void;
  printFunc: (printReason: string) => void;
}) => {
  const { t } = useTranslation();
  const { isOpen, close, printFunc } = props;
  const dispatch = useDispatch();
  const [printReason, setPrintReason ] = useState<string>(""); 

  const updatePrint = () => {
    printFunc(printReason);
  };

  return (<Dialog isOpen={isOpen}>
    <div className="dialog__body">
      <div className="dialog__content">
        <p>{t("EnterReasonForRepeatedPrinting")}:</p>
        <br />
        <TextArea rows={7} fill onChange={(e) => setPrintReason(e.target.value)} />
      </div>
    </div>
    <div
      className={classNames(
        "footer-actions__container",
        Classes.DIALOG_FOOTER
      )}
    >
      <Button intent={Intent.PRIMARY} onClick={() => {
        if (printReason !== "") {
          updatePrint();
          close();
        }
        else {
          dispatch(showError(t("EnterReasonForRepeatedPrinting")));
        }
      }}>
        {t("UI.Print")}
      </Button>
      <Button intent={Intent.NONE} onClick={() => close()}>
        {t("UI.Close")}
      </Button>
    </div>
  </Dialog>)
};

export default PrintCount;
