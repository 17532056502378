import {
  Button,
  Classes,
  Intent,
  Popover,
  PopoverInteractionKind,
} from "@blueprintjs/core";
import { Table } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { WrappedFieldInputProps } from "redux-form";
import { FieldFactory } from "../../../../../models/fields/field-factory";
import { DictionaryObject } from "../../../../../models/types/dictionary";
import { IViewField } from "../../../../../models/view-field";
import { referenceFormIsOpen } from "../../../../../redux/actions/referencesActions";
import { IAppState } from "../../../../../redux/states/state";
import SearchForm from "../search-form/SearchForm";
import "./SearchFields.scss";

export const SearchField = (props: {
  input: WrappedFieldInputProps;
  f: IViewField;
  disabled: boolean;
}) => {
  const { input, f } = props;
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const referenceModel = useSelector((state: IAppState) =>
    state.modelsReducer.models.get(f.model)
  );

  const multy = useSelector((state: IAppState) =>
    state.entitiesReducer.selectedItem && Array.isArray(state.entitiesReducer.selectedItem[f.name])
  );

  const models = useSelector((state: any) => state.modelsReducer.models);

  const selectItem = (item: DictionaryObject) => {
    if (multy) {
      input.onChange([...input.value, item]);
    } else {
      input.onChange(item);
    }
  };

  const createNew = () => {
    if (referenceModel && f.allowCreate) {
      dispatch(referenceFormIsOpen(referenceModel.dbname, true, null, () => null));
    }
  };

  const columns: any = referenceModel
    ? referenceModel.fields.filter(p => !f.tableView || f.tableView.indexOf(p.name) >= 0)
      .map((f1: IViewField, i: number) => ({
        title: t(f1.name),
        dataIndex: f1.name,
        render: (elements: any, record: any, index: number) => {
          if (models && models.size) {
            const renderedField = new FieldFactory(f1).createField();
            return renderedField.viewRender(elements)
          } else {
            return "";
          }
        },
      }))
    : [];

  return (
    <div className="search-field">
      <div className="result-block">
        <Popover
          popoverClassName={Classes.POPOVER_CONTENT_SIZING}
          portalClassName="search-portal"
          boundary="viewport"
          canEscapeKeyClose={true}
          hasBackdrop={false}
          inheritDarkTheme={true}
          interactionKind={PopoverInteractionKind.CLICK_TARGET_ONLY}
          minimal={false}
          fill
          modifiers={{
            arrow: { enabled: true },
            flip: { enabled: true },
            keepTogether: { enabled: true },
            preventOverflow: { enabled: true },
          }}
          position={"auto"}
          usePortal={true}
          enforceFocus={false}
          isOpen={isOpen}
        >
          <div className="search-result">
            <div className="search-result__buttons">
              <Button
                intent={Intent.NONE}
                minimal
                small
                icon="search"
                onClick={() => setOpen(true)}
              />
              {f.allowCreate && <Button
                intent={Intent.NONE}
                small
                icon="plus"
                text={t("UI.Add")}
                onClick={createNew}
              />}
            </div>
            <div>{/*f.calculation ? eval(f.calculation)(input.value) : ""*/}

              <Table
                scroll={{
                  x: (columns.length - 1) * 120,
                }}
                size="small"
                tableLayout={"auto"}
                bordered
                columns={columns}
                dataSource={multy ? input.value.map((el: any, index: any) => ({ key: index, ...el })) : [input.value].map((el: any, index: any) => ({ key: index, ...el }))}
                pagination={false}
              />
            </div>
          </div>
          <div>
            <SearchForm
              model={referenceModel}
              form={f.name + "__SEARCH"}
              f={f}
              setOpen={setOpen}
              selectItem={selectItem}
            />
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default SearchField;
