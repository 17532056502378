import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IConstraint } from "../../../../../models/constraint";
import { Views } from "../../../../../models/enums/views";
import { FormField } from "../../../../../models/fields/form-field";
import {
  Dictionary
} from "../../../../../models/types/dictionary";
import { IViewField } from "../../../../../models/view-field";
import { IViewModel } from "../../../../../models/view-model";
import CustomFormField from "../../../forms/fields/form-types/FormTypes";
import "./EntityField.scss";

const getEntityFilterFields = (
  mainField: IViewField,
  viewFields: IViewField[]
) => {
  if (!mainField || !mainField.filterView) {
    return [];
  }
  if (!viewFields || !viewFields.length) {
    return [];
  }
  return viewFields.filter(
    (vf: IViewField) => !!mainField.filterView?.find((rv: any) => rv.split('.')[0] === vf.name)
  );
};

export const EntityFieldFilter = (props: {
  f: IViewField;
  selectedItem: Dictionary;
  view: Views;
  onChange?: (e: any) => void;
  fieldName: string;
  constraints?: IConstraint[];
  formName: string;
  showEmpty?: boolean;
}) => {
  const { f, fieldName, selectedItem, formName, showEmpty } = props;

  const referenceModel: IViewModel | undefined = useSelector((state: any) =>
    state.modelsReducer.models.get(f.model)
  );
  const { t } = useTranslation();

  if (!referenceModel) {
    return null;
  }
  return (
    <fieldset className={f.hasFilterBorders ? "filter-with-borders" : ""}>
      {f.hasFilterBorders && <legend>{t(f.name)}</legend>}
      <>
        {getEntityFilterFields(f, referenceModel?.fields).map(
          (field: IViewField) => {
            let relatedFields = f.filterConstraints?.filter(el =>
              el.name === field.name
            );
            if (relatedFields && relatedFields.length)
              return relatedFields.map((el, index) => {
                return (<CustomFormField
                  {...new FormField(
                    {
                      ...field,
                      name: f.filterConstraints ? field.name + "_RELATED_" + el.label : field.name,
                      viewConstraints: el.constraint ? el.constraint : field.viewConstraints
                    },
                    fieldName + "." + field.name + "_RELATED_" + el.label,
                    selectedItem,
                    Views.FILTER,
                    true,
                    false,
                    formName,
                    false,
                    false,
                    new Map<string, IConstraint>(),
                    (e: Dictionary) => null,
                    showEmpty
                  )}
                />)
              })
            else
              return (
                <CustomFormField
                  {...new FormField(
                    { ...field, readonly: false },
                    fieldName + "." + field.name,
                    selectedItem,
                    Views.FILTER,
                    true,
                    false,
                    formName,
                    false,
                    false,
                    new Map<string, IConstraint>(),
                    (e: Dictionary) => null,
                    showEmpty
                  )}
                />
              );
            /*else
              return <CustomFormField
                {...new FormField(
                  {
                    ...field,
                    component: "Single",
                    model: "Employee",
                    name: "Analytics",
                    referenceView: ["LastName", "FirstName"],
                    searchField: "ShortName",
                    viewDelimiter: " ",
                  },
                  fieldName + "." + field.name,
                  selectedItem,
                  Views.FILTER,
                  true,
                  false,
                  formName,
                  new Map<string, IConstraint>(),
                  (e: Dictionary) => null,
                  showEmpty
                )}
              />*/
          }
        )}

      </>
    </fieldset>


  );
};

export default EntityFieldFilter;
