import { InputGroup } from "@blueprintjs/core";
import React from "react";
import { WrappedFieldProps } from "redux-form";
import ValidMessages from "../../valid-messages/ValidMessages";
import "./TextInput.scss";

export const renderTextField = (
  props: WrappedFieldProps & {
    rightElement?: JSX.Element;
    placeholder: string;
    disabled: boolean;
  }
) => (
  <div>
    <TextInput {...props} />
    {props.meta.touched && props.meta.error && (
      <ValidMessages text={props.meta.error} />
    )}
  </div>
);

export const TextInput = (
  props: WrappedFieldProps & {
    rightElement?: JSX.Element;
    placeholder: string;
    disabled: boolean;
  }
) => {
  const { input, rightElement, placeholder, disabled } = props;
  const { error, touched } = props.meta || {};

  return (
    <InputGroup
      {...input}
      type={"text"}
      disabled={disabled}
      placeholder={placeholder}
      rightElement={rightElement}
      fill
      autoComplete="off"
      onFocus={(event: any) => {
        event.target.setAttribute("autocomplete", "off");
      }}
      className={
        error && touched ? "bp3-intent-danger text-input" : "text-input"
      }
    />
  );
};

export default TextInput;
