import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { IUser } from "../../models/users/user";
import { IAppState } from "../../redux/states/state";
import Confirm from "../core/alerts/alert";
import ToastrMessage from "../core/errors/ToastrMessage";
import DetectModel from "./detect-model/DetectModel";
import DynamicModels from "./dynamic-models/DynamicModels";
import "./Layout.scss";
import Routings from "./top-menu/Routings";
import TopMenu from "./top-menu/TopMenu";

interface Props extends RouteComponentProps, WithTranslation {
  userInfo: IUser | null;
}

class Layout extends React.Component<Props> {
  render() {
    const { userInfo } = this.props;
    return (
      <div className="wrapper">
        <div className={`top-menu`}>
          <TopMenu />
        </div>
        <Routings />
        {userInfo ? <DynamicModels /> : null}
        <DetectModel />
        <ToastrMessage />
        <Confirm />
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  userInfo: state.authorizationReducer.userInfo,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout))
);
