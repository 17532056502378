import { Button, Label } from "@blueprintjs/core";
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { change } from "redux-form";
import { IConstraint } from "../../../../../models/constraint";
import { Views } from "../../../../../models/enums/views";
import { FieldFactory } from "../../../../../models/fields/field-factory";
import { FormField } from "../../../../../models/fields/form-field";
import { ISequenceState } from "../../../../../models/sequence-state";
import {
  Dictionary,
  DictionaryObject
} from "../../../../../models/types/dictionary";
import { IDefaultField, IViewField } from "../../../../../models/view-field";
import { IViewModel } from "../../../../../models/view-model";
import { clearTryValues, getTryValues } from "../../../../../redux/actions/newSequencesActions";
import { IAppState } from "../../../../../redux/states/state";
import { EntityFieldFilter } from "../../../filters/fields/entity-field/EntityFieldFilter";
import CustomFormField from "../form-types/FormTypes";
import "./EntityField.scss";

const getEntityFormFields = (
  mainField: IViewField,
  viewFields: IViewField[]
) => {
  if (!mainField || !mainField.referenceView) {
    return [];
  }
  if (!viewFields || !viewFields.length) {
    return [];
  }
  return viewFields.filter(
    (vf: IViewField) => !!mainField.referenceView?.find((rv) => rv === vf.name)
  );
};

const defaultObject = (f: IViewField, process: boolean, selectedItem: Dictionary, tryValue: Map<string, ISequenceState>) => {
  const field = new FieldFactory(f).createField();
  return field.defaultValue(selectedItem, tryValue, process);
};

const existingEntity = (selectedItem: Dictionary, f: IViewField): boolean => {
  return !!selectedItem &&
    !!(selectedItem as DictionaryObject)[f.name] &&
    !!((selectedItem as DictionaryObject)[f.name] as DictionaryObject)["id"];
};

export const EntityField = (props: {
  f: IViewField;
  selectedItem: Dictionary;
  view: Views;
  onChange?: (e: any) => void;
  fieldName: string;
  constraints?: IConstraint[];
  formName: string;
  showEmpty?: boolean;
}) => {
  const { t } = useTranslation();
  const { f, fieldName, view, selectedItem, formName } = props;
  //#region functions
  const dispatch = useDispatch();

  const initializeField = () => {
    const defaultObj = defaultObject(f, true, selectedItem, tryValue) as DictionaryObject;
    dispatch(change(formName, f.name, defaultObj));
  };

  const getSequences = (): string[] => {
    return f.default && f.default.defaultFields && f.default.defaultFields.length ?
      f.default.defaultFields
        .filter((df: IDefaultField) => df.twoStepSequence)
        .map((d: IDefaultField) => {
          if (d.sequence === "SpecContractNumber") {
            if (!selectedItem || !(selectedItem as DictionaryObject)["ExpertiseType"]) {
              return d.sequence || "";
            }
            switch (((selectedItem as DictionaryObject)["ExpertiseType"] as DictionaryObject)["Name"]) {
              case "Qeydiyyat":
                return "RegContractNumber";
              case "qeydiyyat":
                return "RegContractNumber";
              case "İlkin qeydiyyat":
                return "RegContractNumber";
              case "Yenidən qeydiyyat":
                return "YRegContractNumber";
              case "yenidən qeydiyyat":
                return "YRegContractNumber";
              case "Dəyişiklik":
                return "SpecContractNumber";
              default:
                return d.sequence || "";
            }
          }
          else  if (d.sequence === "PrelimContractNumber") {
            if (!selectedItem || !(selectedItem as DictionaryObject)["ExpertiseType"]) {
              return d.sequence || "";
            }
            switch (((selectedItem as DictionaryObject)["ExpertiseType"] as DictionaryObject)["Name"]) {
              case "Qeydiyyat":
                return "PrelimContractNumber";
              case "qeydiyyat":
                return "PrelimContractNumber";
              case "İlkin qeydiyyat":
                return "PrelimContractNumber";
              case "Yenidən qeydiyyat":
                return "PrelimYRegContractNumber";
              case "yenidən qeydiyyat":
                return "PrelimYRegContractNumber";
              case "Dəyişiklik":
                return "PrelimDContractNumber";
              default:
                return d.sequence || "";
            }
          }
          else {
            return d.sequence || "";
          }
        }) : [];
  };

  const addValue = () => {
    const sequences: string[] = getSequences();
    dispatch(getTryValues(sequences, true));
    setOpen(true);
  };

  const removeValue = () => {
    dispatch(change(formName, f.name, null));
    const sequences: string[] = getSequences();
    dispatch(clearTryValues(sequences));
    setOpen(false);
  };


  //#endregion

  //#region component state
  const [existing,] = useState<boolean>(
    existingEntity(selectedItem, f) ? true : false
  );
  const [open, setOpen] = useState<boolean>(
    existingEntity(selectedItem, f) ? true : false
  );
  //#endregion

  //#region redux props
  const referenceModel: IViewModel | undefined = useSelector((state: any) =>
    state.modelsReducer.models.get(f.model)
  );
  const tryValue: Map<string, ISequenceState> = useSelector(
    (state: IAppState) => state.sequencesReducer.tryValue
  );
  const loadingSeqProcess: boolean = useSelector(
    (state: IAppState) => state.sequencesReducer.loadingSeqProcess
  );
  //#endregion

  //#region hooks
  useEffect(() => {
    !existing && open && !loadingSeqProcess && initializeField();
  }, [existing, open, loadingSeqProcess]);
  //#endregion

  const EntityFormView = () => {
    if (!referenceModel) {
      return null;
    }
    if (!open) {
      return (
        <Row className="entity-field entity-field__new">
          <Button
            className="add-button"
            icon="plus"
            text={t(f.name)}
            onClick={() => addValue()}
          ></Button>
        </Row>
      );
    }
    return (
      <Row>
        <Row className="entity-field__header">
          <Col span={20}>
            <Label>
              <span>{t(fieldName)}</span>
              <span className="required">{f.required ? "*" : ""}</span>
            </Label>
          </Col >

        </Row>
        <Row className="entity-field" key={f.name}>
          {getEntityFormFields(f, referenceModel?.fields).map(
            (field: IViewField) => {
              return (
                <Col
                  key={field.name}
                  span={(24 * field.col) / referenceModel.grid}
                >
                  <CustomFormField
                    {...new FormField(
                      {
                        ...field,
                        getItems: f.getItems && f.getItems[field.name as keyof CallableFunction],
                        allowCreate: f.getItems && f.getItems[field.name as keyof CallableFunction] ? false : field.allowCreate
                      },
                      fieldName + "." + field.name,
                      selectedItem,
                      Views.FORM,
                      true,
                      false,
                      formName,
                      false
                    )}
                  />
                </Col>
              );
            }
          )}

          {open && !existingEntity(selectedItem, f) && <Col className="remove-container" >
            <Button
              className="remove-button"
              icon="remove"
              text={t("UI.Cancel")}
              onClick={() => removeValue()}
            ></Button>
          </Col>}
        </Row>
      </Row>
    );
  };

  switch (view) {
    case Views.FILTER:
      return <EntityFieldFilter {...props} />;
    case Views.FORM:
      return EntityFormView();
    case Views.REFERENCE:
      return <span>Text</span>;
    default:
      return <span>Text</span>;
  }
};

export default EntityField;
