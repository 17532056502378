import { Epic } from "redux-observable";
import { concat, forkJoin, from, of } from "rxjs";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import * as API from "../api";
import { ISequenceState } from "./../../models/sequence-state";
import { IAppState } from "./../states/state";
import { actions, ActionsType } from "./../store";

export const getTryValuesEpic: Epic<
  ActionsType,
  ActionsType,
  IAppState,
  typeof API
> = (action$, store, service) =>
  action$.pipe(
    filter(isActionOf(actions.sequencesActions.getTryValues)),
    mergeMap((action) =>
      concat(
        of(actions.sequencesActions.loadingTryValues(true, action.payload.process)),
        forkJoin(
          action.payload.sequences.map((seq: string) =>
            from(service.sequencesApi.getTryValues(seq)).pipe(
              catchError((error) =>
                of(actions.errorsActions.showError("ERROR.GetSequence"))
              )
            )
          ),
        ).pipe(
          map((response) => {
            return actions.sequencesActions.loadTryValues(action.payload.sequences, response);
          })
        ),
        of(actions.sequencesActions.loadingTryValues(false, action.payload.process))
      )
    )
  );

export const saveTryValuesEpic: Epic<
  ActionsType,
  ActionsType,
  IAppState,
  typeof API
> = (action$, store, service) =>
  action$.pipe(
    filter(isActionOf(actions.sequencesActions.saveTryValues)),
    mergeMap((action) => {
      const sequences = action.payload.sequences.filter(
        (seq: string) =>
          store.value.sequencesReducer.tryValue.get(seq) !== undefined
      );
      return forkJoin(
        sequences.map((seq: string) =>
          from(
            service.sequencesApi.saveValue(
              seq,
              store.value.sequencesReducer.tryValue.get(seq) as ISequenceState
            )
          ).pipe(
            catchError((error) =>
              of(actions.errorsActions.showError("ERROR.SaveSequence"))
            )
          )
        )
      ).pipe(
        map((response) => actions.sequencesActions.clearTryValues(sequences))
      );
    })
  );

export default [getTryValuesEpic, saveTryValuesEpic];
