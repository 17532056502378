import { IRoleWithPermits } from "../../models/role";
import { IStatePermition } from "../states/state-permition";
import {
  LOADING_ROLE_PERMISSIONS,
  LOAD_PERMITS,
  LOAD_ROLE_PERMISSIONS,
  SELECT_ROLE,
  CLEAR_PERMITS,
  LOAD_ROLES,
  LOADING_ROLES,
  IS_DUBLICATE_CREATION,
} from "../constants/roles";

const INITIAL_STATE: IStatePermition = {
  roles: [],
  selectedRole: {} as IRoleWithPermits,
  permits: [],
  loading: false,
  isDublicateCreation: false,
  loadingRolePermissions: false,
  rolePermissions: [],
};

const permitionReducer = (
  state = INITIAL_STATE,
  action: { type: string; payload: any }
) => {
  const prevState = state;
  switch (action.type) {
    case LOAD_ROLES:
      return {
        ...prevState,
        roles: action.payload.roles,
      };
    case LOAD_PERMITS:
      return {
        ...prevState,
        permits: action.payload.permits,
      };
    case SELECT_ROLE:
      return {
        ...prevState,
        selectedRole: action.payload.role,
      };
    case LOADING_ROLES:
      return {
        ...prevState,
        loading: action.payload.loading,
      };
    case IS_DUBLICATE_CREATION:
      return {
        ...prevState,
        isDublicateCreation: action.payload.duplicate,
      };
    case LOAD_ROLE_PERMISSIONS:
      return {
        ...prevState,
        rolePermissions: action.payload.permissions,
      };
    case LOADING_ROLE_PERMISSIONS:
      return {
        ...prevState,
        loadingRolePermissions: action.payload.loading,
      };
    case CLEAR_PERMITS:
      return {
        ...prevState,
        rolePermissions: [],
      };
    default:
      return prevState;
  }
};

export default permitionReducer;
