import { Alignment, Checkbox } from "@blueprintjs/core";
import { Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { WrappedFieldProps } from "redux-form/lib/Field";
import { IConstraint } from "../../../../../models/constraint";
import { Views } from "../../../../../models/enums/views";
import { IViewField } from "../../../../../models/view-field";
import { renderEmptyField } from "../../controls/empty-btn/EmptyBtn";
import "./BooleanField.scss";

export const renderBooleanField = ({
  input,
  meta: { touched, invalid, error },
  field,
  placeholder,
  label,
  disabled
}: WrappedFieldProps & {
  field: IViewField;
  placeholder: string;
  label: string;
  disabled?: boolean;
}) => {
  return (
    <div className="row-input">
      <Checkbox
        alignIndicator={Alignment.RIGHT}
        label={label}
        value={input.value}
        onChange={input.onChange}
        placeholder={placeholder}
        disabled={disabled}
        //defaultChecked={input.value !== "" ? input.value : false}
        checked={input.value !== "" ? input.value : false}
      />
      {/*{touched && ((error && <div>{error}</div>))}*/}
    </div>
  );
};

const parseBooleanToNumber = (value: boolean) => {
  return +value;
};

export const BooleanField = (props: {
  f: IViewField;
  selectedItem: any;
  view: Views;
  onChange?: (e: any) => void;
  fieldName: string;
  constraints?: IConstraint[];
  showEmpty?: boolean;
}) => {
  const { t } = useTranslation();
  const { f, fieldName, view, showEmpty } = props;

  const BooleanFilterView = () => {
    return (
      <Row key={f.name}>
        <div className="row">
          <Field
            name={fieldName}
            type="text"
            classType={"date-range"}
            label={f.label ? t(f.label) : ""}
            field={f}
            onChange={(value: any) =>
              props.onChange && props.onChange(value)
            }
            parse={f.parseToNumber ? parseBooleanToNumber : undefined}
            component={renderBooleanField}
          />
          {!f.noSearchEmpty && showEmpty && (
            <Field
              name={fieldName + "__EMPTY"}
              type="checkbox"
              onClick={() => { }}
              onChange={(e: any) => { }}
              field={f}
              view={view}
              component={renderEmptyField}
            />
          )}
        </div>
      </Row>
    );
  };

  const BooleanFormView = () => {
    return (
      <Row key={f.name}>
        <div className="row">
          <Field
            name={fieldName}
            type="text"
            disabled={f.readonly || (f.writable === false)}
            label={f.label ? t(f.label) : ""}
            classType={"date-range"}
            field={f}
            parse={f.parseToNumber ? parseBooleanToNumber : undefined}
            component={renderBooleanField}
          />
        </div>
      </Row>
    );
  };

  switch (view) {
    case Views.FILTER:
      return BooleanFilterView();
    case Views.FORM:
      return BooleanFormView();
    case Views.REFERENCE:
      return <span>Text</span>;
    default:
      return <span>Text</span>;
  }
};

export default BooleanField;
