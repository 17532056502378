import { Views } from "../models/enums/views";
import { FieldFactory } from "../models/fields/field-factory";
import { IGeneralField } from "../models/fields/general-field";
import { ISequenceState } from "../models/sequence-state";
import { IViewModel } from "../models/view-model";
import { DictionaryArray, DictionaryObject } from "./../models/types/dictionary";
import { IViewField } from "./../models/view-field";
import _ from "lodash";

const isMultiple = (viewField: IViewField) => {
  return viewField.component === "Multiple" ||
    viewField.component === "SimpleMultiSelect" ||
    viewField.component === "EditableTable" ||
    viewField.component === "TablePopup" ||
    viewField.component === "ManyToMany" ||
    viewField.component === "DateMultiple"
}

export const constructInitialValues = (
  renderFields: IViewField[],
  view: Views,
  tryValue: Map<string, ISequenceState>,
  existingItem: DictionaryObject | null
) => {
  const initialValues: DictionaryObject = existingItem ? _.cloneDeep(existingItem) : {};
  if (!renderFields || !renderFields.length || view !== Views.FORM)
    return initialValues;

  const filteredFields = renderFields.filter(
    (field: IViewField) => field.visible.form || field.name === "Archived"
  );
  filteredFields.forEach((viewField: IViewField) => {
    if (viewField.default !== undefined) {
      const field: IGeneralField = new FieldFactory(viewField).createField();
      if (!isMultiple(field.viewField)) {
        initialValues[viewField.name] = initialValues[viewField.name] || field.defaultValue(existingItem, tryValue, false);
      } else {
        initialValues[viewField.name] = initialValues[viewField.name] || [];
        (initialValues[viewField.name] as DictionaryArray).push(field.defaultValue(existingItem, tryValue, false));
      }
    }
  });
  return initialValues;
};
