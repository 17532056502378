import { TFunction } from "i18next";
import React from "react";
import { getFieldsFromTree } from "../../helpers/get-fields-from-tree";
import { ChangesTypes } from "../enums/changes-types";
import { FieldFactory } from "../fields/field-factory";
import { Dictionary, DictionaryObject } from "../types/dictionary";
import { IViewField } from "../view-field";
import { IViewModel } from "../view-model";
import { IApiPrintInfo } from "./api-print-info";
import { ChangesCreate } from "./change-create";
import { ChangesDelete } from "./change-delete";
import { ChangesExport } from "./change-export";
import { ChangesList } from "./change-list";
import { ChangesMarkdeleted } from "./change-markdeleted";
import { ChangesPrint } from "./change-print";
import { ChangesRead } from "./change-read";
import { ChangesRestoreDeleted } from "./change-restoredeleted";
import { ChangesStatus } from "./change-status";
import { ChangesUpdate } from "./change-update";
import { IChange } from "./changes-history";
import {
  IConstraintChanges,
  IConstraintTreeChanges,
} from "./constraint-changes";
import { IFieldRead } from "./field-change";

export interface IChangesDetails {
  getShortDescription: (t: TFunction) => React.ReactNode;
  renderInfo: (t: TFunction) => React.ReactNode;
  renderChanges: (t: TFunction) => React.ReactNode;
}

export class ChangesFactory {
  change: IChange;
  model?: IViewModel;

  constructor(changes: IChange, model?: IViewModel) {
    this.change = changes;
    this.model = model;
  }

  parseChange(): IChangesDetails {
    const changes = this.change;
    const model = this.model;
    switch (changes.type) {
      case ChangesTypes.Create:
        return new ChangesCreate(changes, model);
      case ChangesTypes.Delete:
        return new ChangesDelete(changes, model);
      case ChangesTypes.Export:
        return new ChangesExport(changes, model);
      case ChangesTypes.List:
        return new ChangesList(changes, model);
      case ChangesTypes.MarkDeleted:
        return new ChangesMarkdeleted(changes, model);
      case ChangesTypes.Print:
        return new ChangesPrint(changes, model);
      case ChangesTypes.Read:
        return new ChangesRead(changes, model);
      case ChangesTypes.RestoreDeleted:
        return new ChangesRestoreDeleted(changes, model);
      case ChangesTypes.Update:
        return new ChangesUpdate(changes, model);
      case ChangesTypes.UpdateState:
        return new ChangesStatus(changes, model);
      default:
        return new ChangesRead(changes, model);
    }
  }

  getGeneralInfo(t: TFunction): React.ReactNode {
    return (
      <div>
        {this.change.entity ? (
          <div>
            <b>{t("ChangesHistory.Entity")}: </b> {t(this.change.entity)}{" "}
          </div>
        ) : null}
        {this.change.entityId ? (
          <div>
            <b>ID: </b> {this.change.entityId}{" "}
          </div>
        ) : null}
      </div>
    );
  }
}

export const readInfoWithId = (
  details?: Dictionary,
  model?: IViewModel
): IFieldRead[] => {
  if (!details || !model) {
    return [];
  }
  return model.fields
    .filter((f: IViewField) => f.visible.history)
    .map((f: IViewField) => {
      const fieldFactory = new FieldFactory(f);
      return {
        fieldName: f.name,
        value: fieldFactory
          .createField()
          .viewRender((details as DictionaryObject)[f.name]),
      };
    });
};

export const renderFields = (
  fieldRead: IFieldRead[],
  t: TFunction
): React.ReactNode => {
  return fieldRead.map((fr: IFieldRead) => {
    return (
      <div key={fr.fieldName}>
        <b>{t(fr.fieldName)}: </b>
        <span>{fr.value}</span>
      </div>
    );
  });
};

export const readInfoConstraints = (
  constraints?: IConstraintChanges[] | IConstraintTreeChanges,
  model?: IViewModel
): IFieldRead[] => {
  if (!constraints || !model) {
    return [];
  }
  if (Array.isArray(constraints)) {
    return constraints.map((cc: IConstraintChanges) => {
      return {
        fieldName: cc.Field ? (cc.Field as string).split(".")[0] : "",
        value: cc.Value,
      };
    });
  } else {
    let fields: string[] = [];
    getFieldsFromTree(constraints, fields);
    fields = Array.from(new Set(fields));
    return fields.map((field: string) => {
      return {
        fieldName: field ? field.split(".")[0] : "",
        value: "",
      };
    });
  }
};

export const readInfoPrint = (
  printInfo?: IApiPrintInfo,
  model?: IViewModel
): IFieldRead[] => {
  if (!printInfo || !model) {
    return [];
  }
  const result: IFieldRead[] = [];
  const constraints = printInfo.Constraints;
  const constraintTree = printInfo.ConstraintTree;

  if (constraints && constraints.length) {
    constraints.forEach((cc: IConstraintChanges) => {
      result.push({
        fieldName: cc.Field ? (cc.Field as string).split(".")[0] : "",
        value: cc.Value,
      });
    });
  } else if (constraintTree) {
    let fields: string[] = [];
    getFieldsFromTree(constraintTree, fields);
    fields = Array.from(new Set(fields));
    fields.forEach((field: string) => {
      result.push({
        fieldName: field ? field.split(".")[0] : "",
        value: "",
      });
    });
  }
  return result;
};

export const renderConstraints = (
  fieldRead: IFieldRead[],
  t: TFunction
): React.ReactNode => {
  return (
    <div>
      <div className="filter-params">{t("ChangesHistory.FilterParam")}</div>
      {!fieldRead.length ? (
        <span>{t("UI.ShowAll")}</span>
      ) : (
        fieldRead.map((fr: IFieldRead, index: number) => {
          const delimiter = index > 0 ? ", " : " ";
          return (
            <span key={fr.fieldName}>
              <span>{delimiter}</span>
              <span>{t(fr.fieldName)}</span>
              {/*<span>{fr.value}</span>*/}
            </span>
          );
        })
      )}
    </div>
  );
};

export const renderPrintDetails = (
  formName: string,
  fieldRead: IFieldRead[],
  t: TFunction
): React.ReactNode => {
  return (
    <div>
      <div>
        <b>{t("UI.PrintFormName")}: </b>
        <span>{t("PrintForms." + formName)}</span>
      </div>
      <div className="filter-params">{t("ChangesHistory.FilterParam")}</div>
      {!fieldRead.length ? (
        <span>{t("UI.ShowAll")}</span>
      ) : (
        fieldRead.map((fr: IFieldRead, index: number) => {
          const delimiter = index > 0 ? ", " : " ";
          return (
            <span key={fr.fieldName}>
              <span>{delimiter}</span>
              <span>{t(fr.fieldName)}</span>
              {/*<span>{fr.value}</span>*/}
            </span>
          );
        })
      )}
    </div>
  );
};

export const getFormName = (printInfo?: IApiPrintInfo): string => {
  return printInfo ? printInfo.Name : "";
};
