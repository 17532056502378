import { combineEpics } from "redux-observable";
import usersEpic from "./users-epic";
import rolesEpic from "./roles-epic";
import entitiesEpic from "./entities-epic";
import sequencesEpic from "./sequences-epic";

export const rootEpic = combineEpics(
  ...usersEpic,
  ...rolesEpic,
  ...entitiesEpic,
  ...sequencesEpic
);
