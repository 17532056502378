import { IViewField } from "../models/view-field";

export const checkRequiredFields = (
  fields: IViewField[],
  item: { [k: string]: object }
) => {
  let isValid = true;
  if (!fields || !fields.length || !item) {
    return !isValid;
  }

  fields.forEach((field: IViewField) => {
    if (field.required) {
      if (!item[field.name]) {
        isValid = false;
      }
    }
  });

  return isValid;
};
