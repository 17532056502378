import * as React from "react";
import { connect } from "react-redux";
import { IPermit } from "../models/permit";
import { IAppState } from "../redux/states/state";
import { Diff } from "utility-types";

// These props will be injected into the base component
interface InjectedProps {
  hasPermits: (permit: string) => boolean;
}

export function withPermissions<P extends InjectedProps>(
  BaseComponent: React.ComponentType<P>
) {
  const mapStateToProps = (state: IAppState) => ({
    permits: state.authorizationReducer.permits,
  });

  // type HocProps = ReturnType<typeof mapStateToProps> &
  //     typeof dispatchProps & {
  //     // here you can extend ConnectedHoc with new props
  //     overrideCount?: number;
  // };

  type HocProps = ReturnType<typeof mapStateToProps> & {
    // here you can extend ConnectedHoc with new props
    permits: IPermit[];
  };

  class WrappedComponent extends React.Component<HocProps> {
    static displayName = `withConnectedCount(${BaseComponent.name})`;

    // reference to original wrapped component
    static readonly WrappedComponent = BaseComponent;

    constructor(props: HocProps) {
      super(props);
      this.hasPermits = this.hasPermits.bind(this);
    }

    hasPermits(permit: string): boolean {
      return !!(
        this.props.permits &&
        this.props.permits.length &&
        this.props.permits.find((c) => c.code === permit)
      );
    }

    public render() {
      const { permits, ...restProps } = this.props;
      return (
        <BaseComponent {...(restProps as P)} hasPermits={this.hasPermits} />
      );
    }
  }

  return connect<
    ReturnType<typeof mapStateToProps>,
    undefined,
    Diff<P, InjectedProps>,
    IAppState
  >(mapStateToProps)(WrappedComponent);
}