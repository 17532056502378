import { Label } from "@blueprintjs/core";
import { Col, Row } from "antd";
import { TFunction } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { WrappedFieldProps } from "redux-form/lib/Field";
import { getDeepValue } from "../../../../../helpers/get-deep-value";
import { Views } from "../../../../../models/enums/views";
import { IFormField } from "../../../../../models/fields/form-field";
import {
  DictionaryArray,
  DictionaryObject,
  Selectable,
} from "../../../../../models/types/dictionary";
import { IViewField } from "../../../../../models/view-field";
import { getReferenceQuery } from "../../../../../redux/actions/referencesActions";
import { project } from "./../../../../../configs/index";
import SimpleMultiselect from "../../controls/simple-multiselect/SimpleMultiselect";
import ReferenceField from "../reference-field/ReferenceField";
import "./MultiselectField.scss";

export const renderMultipleField = ({
  input,
  meta: { touched, invalid, error },
  field,
  placeholder,
  disabled,
  reference,
  t,
}: WrappedFieldProps & {
  field: IViewField;
  placeholder: string;
  disabled: boolean;
  reference: any[];
  t: TFunction;
}) => {
  const renderView = (item: Selectable): string => {
    if (!item) {
      return "";
    }
    if (typeof item === "object") {
      const viewFields = field.referenceView;
      if (viewFields && viewFields.length) {
        return viewFields
          .map((rv, index: number) => {
            const deepValue = getDeepValue(item, rv);
            const value = deepValue ? deepValue : " --- ";
            const delimiter =
              value && index > 0 ? field.viewDelimiter || " / " : "";
            return delimiter + value;
          })
          .join("");
      }
      return (item as DictionaryObject)[field.searchField || "Name"] as string;
    } else {
      return field.needTranslate ? t(item?.toString()) : item?.toString();
    }
  };

  return (
    <Row gutter={16}>
      <Col>
        <SimpleMultiselect
          input={input}
          disabled={disabled}
          options={reference}
          selectedItems={[]}
          renderView={renderView}
        />
      </Col>
    </Row>
  );
};

export const MultiselectField = (props: IFormField) => {
  const { t } = useTranslation();
  const { f, fieldName, view } = props;
  const [reference, setReference] = useState<DictionaryArray>([]);

  const MultiselectFilterView = () => {
    return <ReferenceField {...props} />;
  };

  const MultiselectFormView = () => {
    const label = f.helper ? 4 : 8;
    const field = f.helper ? 20 : 16;

    return (
      <Row key={f.name}>
        <Col span={label}>
          <Label htmlFor={fieldName}>{t(f.name)}</Label>
        </Col>
        <Col
          span={field}
          onClick={() => {
            if (!f.searchType || (f.searchType && f.searchType === "Select")) {
              setReference(project.employeeGroups);
              if (f.model) {
                setReference([]);
                getReferenceQuery(f.model, "", [], 0, 100).then((result) => {
                  if (result) {
                    setReference(result);
                  } else {
                    setReference([]);
                  }
                });
              } else {
                setReference(project.employeeGroups);
              }
            }
          }}
        >
          <Field
            name={fieldName}
            onClick={() => { }}
            type="text"
            onChange={(e: any) => {
              if (props.onChange) {
                props.onChange(e);
              }
            }}
            disabled={f.readonly || (f.writable === false)}
            field={f}
            reference={reference}
            view={view}
            t={t}
            placeholder={t("UI.SelectDate")}
            component={renderMultipleField}
          />
        </Col>
      </Row>
    );
  };

  switch (view) {
    case Views.FILTER:
      return MultiselectFilterView();
    case Views.FORM:
      return MultiselectFormView();
    case Views.REFERENCE:
      return <span>Text</span>;
    default:
      return <span>Text</span>;
  }
};

export default MultiselectField;
