import { Button, Checkbox, Collapse, FileInput, Icon } from "@blueprintjs/core";
import { Col, Row } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { constructInitialValues } from "../../../../../helpers/default-values";
import { IEmployee } from "../../../../../models/employee";
import { Views } from "../../../../../models/enums/views";
import { ISequenceState } from "../../../../../models/sequence-state";
import { DictionaryObject } from "../../../../../models/types/dictionary";
import { IViewField } from "../../../../../models/view-field";
import { IViewModel } from "../../../../../models/view-model";
import { getSamples, loadSamples, updateSamples } from "../../../../../redux/actions/aemActions";
import { saveFiles, uploadFile } from "../../../../../redux/actions/attachmentsActions";
import { showInfo, showSuccess } from "../../../../../redux/actions/errorsActions";
import { getTryValues } from "../../../../../redux/actions/newSequencesActions";
import { IAppState } from "../../../../../redux/states/state";
import ExpredForm from "./expred-form/ExpredForm";
import ExpredNote from "./expred-note/ExpredNote";
import "./ExpredAccordion.scss";

interface Props {
    modelName: string;
    selectedItem: any;
    models: Map<string, IViewModel>;
    samples: DictionaryObject[],
    loadingSamples: boolean;
    tryValue: Map<string, ISequenceState>;
    currentEmployee: IEmployee | null;
    getSamples: (selectedItem: DictionaryObject) => void;
    updateSamples: (samples: DictionaryObject[], updateCallback: () => void) => void;
    loadSamples: (samples: DictionaryObject[]) => void;
    getTryValues: (sequences: string[]) => void;
    saveFile: (fileUid: string) => void;
    showInfo: (message: string) => void;
    showSuccess: (message: string) => void;
}

interface State {
    opened: number,
    selectingAct: boolean,
    selectingSend: boolean,
    selected: DictionaryObject,
    adding: boolean;
    fileUid: string;
    pageCount: number;
}
class ExpredAccordion extends React.PureComponent<Props & WithTranslation, State> {
    constructor(props: Props & WithTranslation) {
        super(props);
        this.state = {
            opened: -1,
            selectingAct: false,
            selectingSend: false,
            selected: {},
            adding: false,
            fileUid: "",
            pageCount: 0
        };
    }

    componentDidMount() {
        this.props.getSamples(this.props.selectedItem);
    }

    componentWillUnmount() {
        this.props.loadSamples([]);
    }

    refreshSamples = () => {
        const { selectedItem } = this.props;
        this.props.getSamples(selectedItem);
        this.setState({ opened: -1, adding: false });
    }

    getRelatedField = (field: string): IViewField | null => {
        const { modelName, models } = this.props;
        const parentModel = models.get(modelName);
        if (!parentModel) return null;
        const relatedField = parentModel.fields.find(f => f.name === field);
        if (!relatedField) return null;
        return relatedField;
    }

    onAdd = () => {
        this.props.getTryValues(["ExpredNumber"]);
        this.setState({ adding: true });
    };

    selectsample = (value: any, sample: DictionaryObject) => {
        if (sample && sample['id']) {
            value.target.checked ?
                this.state.selected[sample['id'].toString()] = sample :
                this.state.selected[sample['id'].toString()] = undefined
        }
        this.setState({ selected: this.state.selected });
    }

    onAttachAct = () => {
        const { t } = this.props;
        const selectedArray = Object.values(this.state.selected).filter(v => !!v);
        if (!this.state.selectingAct) {
            this.setState({ selectingAct: true });
        } else {
            if (selectedArray.length === 0 || !this.state.fileUid) {
                return this.props.showInfo(t('ERROR.NothingSelected'));
            }
            const samplesArray = Array.from(selectedArray)
                .map((c) => {
                    let scanDocuments = (c as DictionaryObject)["ScanDocuments"];
                    if (!scanDocuments) {
                        (c as DictionaryObject)["ScanDocuments"] = [];
                    }
                    ((c as DictionaryObject)["ScanDocuments"] as DictionaryObject[]).push(
                        {
                            FileUid: this.state.fileUid,
                            ScanDocumentNumber: 'AKT' + (c as any)?.id,
                            Employee: this.props.currentEmployee,
                            PagesNumber: this.state.pageCount,
                            ScanAttachmentDate: new Date().toISOString(),
                            AttachmentType: {
                                id: 9
                            },
                        });
                    return c;
                });
            this.props.updateSamples(samplesArray as DictionaryObject[], () => {
                this.refreshSamples()
                this.props.saveFile(this.state.fileUid);
                this.props.showSuccess("MESSAGE.ActAttached");
                this.setState({ fileUid: "", pageCount: 0 })
            });
            this.setState({ selected: {}, selectingAct: false });
        }
    };
    onUnattachAct = () => {
        this.setState({ selectingAct: false, selectingSend: false, fileUid: "", pageCount: 0 });
    }
    onSendAnalysis = () => {
        const { t } = this.props;
        const selectedArray = Object.values(this.state.selected).filter(v => !!v);

        if (!this.state.selectingSend) {
            this.setState({ selectingSend: true });
        } else {
            if (selectedArray.length === 0) {
                return this.props.showInfo(t('ERROR.NothingSelected'));
            }
            const samplesArray = Array.from(selectedArray).filter(v => !!v && !(v as DictionaryObject)["AnalysisSend"])
                .map((c) => { (c as DictionaryObject)["AnalysisSend"] = true; (c as DictionaryObject)["AnalysisDate"] = new Date().toUTCString(); return c; });
            this.props.updateSamples(samplesArray as DictionaryObject[], () => {
                this.props.showSuccess("MESSAGE.AnalysisSend");
                this.refreshSamples();
            });
            this.setState({ selected: {}, selectingSend: false, fileUid: "", pageCount: 0 });
        }
    };

    fileUpload = (e: any) => {
        if (e.target.files.length) {
            const arrFiles: File[] = Array.from(e.target.files);
            const formData = new FormData();
            formData.append("file", arrFiles[0], arrFiles[0].name);
            uploadFile(formData).then((response) => {
                this.setState({ fileUid: response.data.id, pageCount: response.data.pageCount })
            });
        }
    }

    render() {
        const { opened, selectingSend, selectingAct, adding, fileUid } = this.state;
        const { t, selectedItem, samples, tryValue } = this.props;
        const referenceModel = this.props.models.get("Ekspred");
        const relatedExpred = this.getRelatedField("Ekspred");
        const fieldNote = this.getRelatedField("EkspredNote");
        if (!relatedExpred || !fieldNote || !referenceModel) { return null; }
        const selecting = selectingSend || selectingAct;
        return (
            <Row className="expred-accordion">
                <Col span={24}>
                    <Row className="expred-accordion__header">
                        <Col className="expred-accordion__add" span={8}>
                            <Button disabled={opened !== -1 || selecting} onClick={this.onAdd}>
                                <Icon icon="plus" /><span>{t('UI.AddExpred')}</span>
                            </Button>
                        </Col>
                        <Col className="expred-accordion__attach" span={16}>
                            <Button disabled={opened !== -1 || selectingAct || !samples.length} onClick={this.onSendAnalysis}>
                                <Icon icon="lab-test" /><span>{!selectingSend ? t('UI.SendAnalysis') : t('UI.Send')}</span>
                            </Button>
                            {selectingAct && <FileInput
                                text="Aktı seçin"
                                buttonText={"Əlavə"}
                                hasSelection={!!fileUid}
                                fill={false}
                                onInputChange={this.fileUpload}
                            />}
                            <Button disabled={opened !== -1 || selectingSend || !samples.length} onClick={this.onAttachAct}>
                                <Icon icon="paperclip" /><span>{!selectingAct ? t('UI.AttachAct') : t('UI.SaveAct')}</span>
                            </Button>
                            {selecting && <Button onClick={this.onUnattachAct}>
                                <Icon icon="cross" />
                            </Button>}
                        </Col>
                    </Row>

                    {adding && <Row>
                        <ExpredForm
                            referenceModel={referenceModel}
                            relatedField={relatedExpred}
                            sample={null}
                            selectedItem={selectedItem}
                            form={'DVRegistration_Expred__' + 0}
                            initialValues={constructInitialValues(referenceModel.fields, Views.FORM, tryValue, {})}
                            closeForm={() => this.setState({ adding: false })}
                            refreshSamples={() => this.refreshSamples()}
                        />
                    </Row>}

                    {samples
                        ? samples.map((sample: DictionaryObject, index: number) => {
                            if (sample && sample.deleted) return null;
                            const _defaultObject = constructInitialValues(referenceModel.fields, Views.FORM, new Map<string, ISequenceState>(), sample);
                            return (<Col className="expred-accordion__sample-block" span={24} key={sample && sample['id'] ? sample['id'].toString() : index}>
                                <Row>
                                    <Col span={selecting ? 1 : 0}>
                                        {selecting ? <Checkbox onChange={(checked) => this.selectsample(checked, sample)} /> : null}
                                    </Col>
                                    <Col span={selecting ? 23 : 24}>
                                        {sample && sample['id'] && <Button disabled={selecting} className="expred-accordion__sample-btn" onClick={() => opened != index ? this.setState({ opened: index }) : this.setState({ opened: -1 })}>
                                            <div className="expred-accordion__sample sample-item">
                                                <div className="sample-item__label">{t('Sample')}:</div>
                                                <div className="sample-item__value">{relatedExpred.calculation ? relatedExpred.calculation(sample) : ""} {sample["AnalysisSend"] ? " ✓ Analizə göndərildi" : ""}</div>
                                                <Icon icon={opened != index ? "caret-down" : "caret-up"} />
                                            </div>
                                        </Button>}
                                    </Col>
                                </Row>
                                <Col span={24} className="row-fields">
                                    <Collapse isOpen={opened == index}
                                        keepChildrenMounted={false}
                                        transitionDuration={300}
                                    >
                                        <Row>
                                            <ExpredForm
                                                referenceModel={referenceModel}
                                                relatedField={relatedExpred}
                                                sample={sample}
                                                selectedItem={selectedItem}
                                                form={'DVRegistration_Expred_' + index}
                                                initialValues={_defaultObject}
                                                closeForm={() => this.setState({ opened: -1 })}
                                                refreshSamples={() => this.refreshSamples()}
                                            />
                                        </Row>

                                    </Collapse></Col>
                            </Col>)
                        })
                        : []
                    }
                </Col>
                <ExpredNote
                    selectedItem={selectedItem}
                    fieldNote={fieldNote}
                    initialValues={selectedItem}
                />
            </Row>
        );
    }
};

const mapStateToProps = (state: IAppState) => ({
    models: state.modelsReducer.models,
    samples: state.aemReducer.samples,
    loadingSamples: state.aemReducer.loadingSamples,
    tryValue: state.sequencesReducer.tryValue,
    currentEmployee: state.authorizationReducer.currentEmployee
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getSamples: (selectedItem) => getSamples(selectedItem),
            loadSamples: (samples) => loadSamples(samples),
            updateSamples: (samples, updateCallback) => updateSamples(samples, updateCallback),
            getTryValues: (sequences) => getTryValues(sequences),
            saveFile: (fileUid) => saveFiles(fileUid),
            showInfo: (message) => showInfo(message),
            showSuccess: (message) => showSuccess(message)
        },
        dispatch
    );

const ConnectedExpredAccordion = connect(
    mapStateToProps,
    mapDispatchToProps
)(ExpredAccordion);

export default withTranslation()(ConnectedExpredAccordion);
