import { Epic } from "redux-observable";
import { concat, from, of } from "rxjs";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import * as API from "../api";
import { IAppState } from "./../states/state";
import { actions, ActionsType } from "./../store";

export const createUserEpic: Epic<
  ActionsType,
  ActionsType,
  IAppState,
  typeof API
> = (action$, store, service) =>
  action$.pipe(
    filter(isActionOf(actions.newUsersActions.createUser)),
    switchMap((action) =>
      from(service.usersApi.createUser(action.payload.user)).pipe(
        catchError((error) =>
          of(actions.errorsActions.showError("ERROR.CreateUser"))
        ),
        mergeMap((response) => {
          if (
            !response ||
            !response.data ||
            !response.data.id ||
            !action.payload.roleId
          ) {
            return of(action);
          } else {
            return from(
              service.usersApi.setRole(response.data.id, action.payload.roleId)
            ).pipe(
              map((innerResponse) => response.data),
              catchError((error) =>
                of(actions.errorsActions.showError("ERROR.SetRole"))
              )
            );
          }
        }),
        mergeMap((response) => {
          if (!response || !response.id || !action.payload.user.employee) {
            return of(action);
          } else {
            return from(
              service.entitiesApi.updateEntity("Employee", {
                ...action.payload.user.employee,
                UserId: response.id,
              })
            ).pipe(
              catchError((error) =>
                of(actions.errorsActions.showError("ERROR.SetUserEmployee"))
              )
            );
          }
        }),
        map((response) => actions.newUsersActions.refreshUsers())
      )
    )
  );

export const updateUserEpic: Epic<
  ActionsType,
  ActionsType,
  IAppState,
  typeof API
> = (action$, store, service) =>
  action$.pipe(
    filter(isActionOf(actions.newUsersActions.updateUser)),
    switchMap((action) =>
      from(service.usersApi.updateUser(action.payload.user)).pipe(
        catchError((error) =>
          of(actions.errorsActions.showError("ERROR.UpdateUser"))
        ),
        mergeMap((response) => {
          if (
            !response ||
            !response.data ||
            !response.data.id ||
            !action.payload.roleId
          ) {
            return of(action);
          } else {
            return from(
              service.usersApi.setRole(response.data.id, action.payload.roleId)
            ).pipe(
              catchError((error) =>
                of(actions.errorsActions.showError("ERROR.SetRole"))
              )
            );
          }
        }),
        mergeMap((response) => {
          if (!action.payload.user.employee && !action.payload.prevEmployee) {
            return of(action);
          } else {
            if (
              action.payload.user.userType === "SystemUser" &&
              action.payload.prevEmployee
            ) {
              return from(
                service.entitiesApi.updateEntity("Employee", {
                  ...action.payload.user.employee,
                  UserId: undefined,
                })
              ).pipe(
                catchError((error) =>
                  of(actions.errorsActions.showError("ERROR.UpdateUser"))
                )
              );
            } else if (
              action.payload.user.userType === "Employee" &&
              action.payload.user.employee &&
              action.payload.prevEmployee &&
              action.payload.user.employee.id !== action.payload.prevEmployee.id
            ) {
              return concat(
                from(
                  service.entitiesApi.updateEntity("Employee", {
                    ...action.payload.user.employee,
                    UserId: action.payload.user.id,
                  })
                ).pipe(
                  catchError((error) =>
                    of(actions.errorsActions.showError("ERROR.UpdateUser"))
                  )
                ),
                from(
                  service.entitiesApi.updateEntity("Employee", {
                    ...action.payload.prevEmployee,
                    UserId: undefined,
                  })
                ).pipe(
                  catchError((error) =>
                    of(actions.errorsActions.showError("ERROR.UpdateUser"))
                  )
                )
              );
            } else if (
              action.payload.user.userType === "Employee" &&
              action.payload.user.employee &&
              !action.payload.prevEmployee
            ) {
              return from(
                service.entitiesApi.updateEntity("Employee", {
                  ...action.payload.user.employee,
                  UserId: action.payload.user.id,
                })
              ).pipe(
                catchError((error) =>
                  of(actions.errorsActions.showError("ERROR.UpdateUser"))
                )
              );
            } else {
              return of(action);
            }
          }
        }),
        mergeMap((response) => {
          if (!action.payload.user.password) {
            return of(action);
          } else {
            return from(
              service.usersApi.changePassword(action.payload.user)
            ).pipe(
              catchError((error) =>
                of(actions.errorsActions.showError("ERROR.ChangePassword"))
              )
            );
          }
        }),
        map((response) => actions.newUsersActions.refreshUsers())
      )
    )
  );

export const refreshUsersEpic: Epic<
  ActionsType,
  ActionsType,
  IAppState,
  typeof API
> = (action$, store, service) =>
  action$.pipe(
    filter(isActionOf(actions.newUsersActions.refreshUsers)),
    mergeMap((action) => {
      const pagingOptions =
        action.payload.pagingOptions || store.value.usersReducer.pagingOptions;
      return concat(
        of(actions.newUsersActions.loadingUsers(true)),
        from(
          service.usersApi.getUsers(
            pagingOptions.page,
            pagingOptions.pageSize,
            pagingOptions.showDeleted
          )
        ).pipe(
          map((response) => actions.newUsersActions.loadUsers(response.data)),
          catchError((error) =>
            of(actions.errorsActions.showError("ERROR.GetUsers"))
          )
        ),
        from(
          service.usersApi.getUsersCount(
            pagingOptions.page,
            pagingOptions.pageSize,
            pagingOptions.showDeleted
          )
        ).pipe(
          map((response) =>
            actions.newUsersActions.setUsersCount(
              response.data ? response.data.value : 0
            )
          ),
          catchError((error) =>
            of(actions.errorsActions.showError("ERROR.GetUsersCount"))
          )
        ),
        of(actions.newUsersActions.updatePaging(pagingOptions)),
        of(actions.newUsersActions.loadingUsers(false))
      );
    })
  );

export const getUsersSimpleEpic: Epic<
  ActionsType,
  ActionsType,
  IAppState,
  typeof API
> = (action$, store, service) =>
  action$.pipe(
    filter(isActionOf(actions.newUsersActions.getUsersSimple)),
    switchMap((action) =>
      from(service.usersApi.getUsersSimple()).pipe(
        catchError((error) =>
          of(actions.errorsActions.showError("ERROR.GetUsers"))
        ),
        map((response) =>
          actions.newUsersActions.loadUsersSimple(response.data)
        )
      )
    )
  );

export const getEmployeeByUserIdEpic: Epic<
  ActionsType,
  ActionsType,
  IAppState,
  typeof API
> = (action$, store, service) =>
  action$.pipe(
    filter(isActionOf(actions.newUsersActions.getEmployeeByUserId)),
    switchMap((action) =>
      from(
        service.entitiesApi.findAll("Employee", [
          {
            type: "Eq",
            field: "UserId",
            value: action.payload.userId?.toString(),
          },
        ])
      ).pipe(
        catchError((error) =>
          of(actions.errorsActions.showError("ERROR.GetEmployeeByUser"))
        ),
        map((response) =>
          actions.newUsersActions.setUserEmployee(response.data)
        )
      )
    )
  );

export default [
  createUserEpic,
  refreshUsersEpic,
  updateUserEpic,
  getUsersSimpleEpic,
  getEmployeeByUserIdEpic,
];
