import React, { ReactElement } from "react";
import { ChangeAction } from "../changes/field-change";
import { IConstraint } from "../constraint";
import { Dictionary, DictionaryObject } from "../types/dictionary";
import { GeneralField } from "./general-field";

export class NumberIntervalField extends GeneralField {
  compareFunction(before: Dictionary, after: Dictionary): ChangeAction {
    return (before as number) === (after as number)
      ? ChangeAction.Unchanged
      : ChangeAction.Updated;
  }

  viewRender(value: Dictionary): ReactElement {
    return <span>{value ? value.toString() : ""}</span>;
  }

  stringRender(value: Dictionary): string {
    return value ? value.toString() : "";
  }

  tagRender(value: Dictionary, onRemove: (id: number) => void): ReactElement {
    return this.viewRender(value);
  }

  emptyConstraint(fieldName: string): IConstraint {
    return {
      type: "Null",
      field: fieldName,
    } as IConstraint;
  }

  valueConstraint(fieldName: string, value: Dictionary) {
    if (!value || typeof value !== "object") return [];

    const valueObject = value as DictionaryObject;
    const constraints = [];

    if (
      valueObject.hasOwnProperty("start") ||
      valueObject.hasOwnProperty("end")
    ) {
      const start = valueObject.start as string;
      const end = valueObject.end as string;

      if (start) {
        constraints.push({
          type: "Gte",
          field: fieldName,
          value: start,
        } as IConstraint);
      }
      if (end) {
        constraints.push({
          type: "Lte",
          field: fieldName,
          value: end,
        } as IConstraint);
      }
    }
    return constraints;
  }

  isEmpty(value: Dictionary) {
    return !value;
  }
}
