import React, { MouseEventHandler } from "react";
import { MenuItem } from "@blueprintjs/core";
import { IItemModifiers } from "@blueprintjs/select/src/common/itemRenderer";
import { Selectable } from "../../../../../models/types/dictionary";

export abstract class SelectableMethods {
  public static renderItem = (
    item: any,
    {
      handleClick,
      modifiers,
      query,
    }: {
      handleClick: MouseEventHandler;
      modifiers: IItemModifiers;
      query: string;
    },
    renderView: (item: Selectable) => string
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const text: string = renderView(item);
    const key = (item: Selectable): string => {
      if (typeof item === "string") {
        return item;
      } else if (typeof item === "number") {
        return (item as number)?.toString();
      } else if (typeof item === "object") {
        return item["id"] as string;
      } else {
        return "";
      }
    };
    return (
      <MenuItem
        className="any-select__item"
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={key(item) ? key(item) : text}
        onClick={handleClick}
        text={text}
      />
    );
  };

  public static renderInputValue = (
    item: Selectable,
    value: Selectable | undefined,
    renderView: (item: Selectable) => string
  ) => {
    return value ? renderView(value) : renderView(item);
  };

  public static compareItems = (
    itemA: Selectable,
    itemB: Selectable,
    renderView: (item: Selectable) => string
  ) => {
    if (!renderView(itemA) || !renderView(itemB)) {
      return false;
    }
    return renderView(itemA).toLowerCase() === renderView(itemB).toLowerCase();
  };

  public static filterItems = (
    query: any,
    item: any,
    _index: any,
    exactMatch: any,
    renderView: (item: Selectable) => string
  ) => {
    if (!renderView(item)) {
      return false;
    }
    const normalizedTitle = renderView(item)
      .replace(/İ|i|I|ı/g, "i")
      .toLowerCase();
    const normalizedQuery = query.replace(/İ|i|I|ı/g, "i").toLowerCase();

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    } else {
      return (
        `${renderView(item)}. ${normalizedTitle} ${renderView(item)}`.indexOf(
          normalizedQuery
        ) >= 0
      );
    }
  };
}

export default SelectableMethods;
