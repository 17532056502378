import { Col, Row, Table, Tooltip } from "antd";
import { TableRowSelection } from "antd/lib/table/interface";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { project } from "../../../../configs/index";
import { IDefaultSorting } from "../../../../models/default-sorting";
import { ModelType } from "../../../../models/enums/model-type";
import { FieldFactory } from "../../../../models/fields/field-factory";
import { IPagingOptions } from "../../../../models/paging-options";
import { DictionaryObject } from "../../../../models/types/dictionary";
import { IViewField } from "../../../../models/view-field";
import { IViewModel } from "../../../../models/view-model";
import {
  onSelectedIdChange,
  selectNext,
  selectPrev
} from "../../../../redux/actions/entitiesActions";
import { updateSorting } from "../../../../redux/actions/newEntitiesActions";
import { IAppState } from "../../../../redux/states/state";
import { TableStateChange } from "../../forms/state-management/table-state-change/TableStateChange";
import "./BaseTable.scss";

interface Props extends WithTranslation {
  model: string;
  data: any[];
  footer: any;
  type: ModelType;
  viewPath: string;
  loading?: boolean;
  scrollY?: number;
  models: Map<string, IViewModel>;
  openForm: (isOpen: boolean) => void;
  selectItem: (selectedItem: any) => void;
  selectedItem: any;
  filter: any;
  statesFilter: string[] | undefined;
  allowSelect: boolean;
  pagingOptions: IPagingOptions;
  sortingOptions: IDefaultSorting | null;
  getEntities?: CallableFunction;
  updateSorting: CallableFunction;
  setSelectedId: CallableFunction;
}

interface IColumn {
  title: string;
  key?: string;
  width?: number | string;
  dataIndex?: string;
  className?: string;
  render?: any;
  fixed?: any;
  ellipsis?: boolean;
}
class BaseTable extends React.Component<Props, any> {
  public state = {
    model: {} as IViewModel,
    columns: [] as IColumn[],
    selectedId: 0,
    selectedRows: [],
  };

  constructor(props: Props) {
    super(props);

    this.props.setSelectedId(0);
  }

  onSelectChange = (selectedId: number) => {
    this.props.setSelectedId(selectedId);
    this.setState({ selectedId });
  };

  onDoubleChange = (selectedId: number) => {
    const selectedItem = this.props.data.find((d) => d["id"] === selectedId);
    this.props.selectItem(selectedItem);
    this.props.openForm(true);
  };

  componentDidMount = async () => { 
    this.getColumns(this.props.model);
  };

  componentDidUpdate(prevProps: Props, prevState: any) {
    if (
      prevProps.pagingOptions.pageSize !== this.props.pagingOptions.pageSize ||
      prevProps.model !== this.props.model
    ) {
      this.getColumns(this.props.model);  
    }
  } 

  private getColumns(modelName: string) {
    const model: IViewModel | undefined = this.props.models.get(modelName);
    const pageSize: number = this.props.pagingOptions.pageSize;
    const enableListOrder = project.config.enableListOrder;;
    const sortingOptions = this.props.sortingOptions;

    if (!model) {
      this.setState({ model: null });
      this.setState({ columns: [] });
    } else {
      const { t } = this.props;
      const columns: IColumn[] = model.fields
        .filter((f) => f.visible.list)
        .map((f: IViewField, i: number) => ({
          title: t(f.name),
          dataIndex: f.name,
          width: 80 * f.tableCol,
          sorter: f.sorter,
          className: f.sorter ? "sorted-column" : "",
          defaultSortOrder: sortingOptions && sortingOptions.field === f.name && sortingOptions.direction === "Asc" ? 'ascend' : 'descend',
          render: (elements: any, record: any) => {
            let indicator = null;
            if (
              model &&
              model.indicators &&
              model.indicators.length &&
              record
            ) {
              indicator = model.indicators.find((ind) =>
                ind.condition(record)
              );
            }
            if (this.props.models.size > 0) {
              const renderedField = new FieldFactory(f).createField();
              if (!!indicator) {
                return (
                  <Tooltip placement="topLeft" title={indicator.title}>
                    <div>
                      {!!renderedField
                        ? renderedField.viewRender(elements)!
                        : ""}
                    </div>
                  </Tooltip>
                );
              }
              return renderedField.viewRender(elements);
            } else {
              return "";
            }
          },
        }));

      if (model.type === ModelType.Entity && !model.hideState) {
        columns.unshift({
          title: t("Status"),
          width: 100,
          className: "row-header",
          ellipsis: true,
          render: (record: any, text: any, index: number) => {
            const renderState = (
              <div className={record && record.state && "state"}>
                {record && record.state ? t("States." + record.state) : ""}
              </div>
            );
            return renderState;
          },
        });
      }

      if (!model.hideOrder && enableListOrder === true) {
        columns.unshift({
          title: t("UI.OrderNumber"),
          key: "index",
          ellipsis: true,
          width: 33,
          className: "row-header",
          render: (record: any, text: any, index: number) => {
            return this.props.pagingOptions.page * pageSize + index + 1;
          },
        });
      }

      // columns[columns.length - 1].width = "auto";

      this.setState({ model: model });
      this.setState({ columns: columns });
    }
  }

  keyPressEvent(e: any) {
    if (e.keyCode === 13) {
      this.props.openForm(true);
    } else if (e.keyCode === 40) {
      this.selectNext();
    } else if (e.keyCode === 38) {
      this.selectPrev();
    }
  }

  selectPrev = () => {
    const prevItem = selectPrev(this.props.data, this.state.selectedId);
    this.setState({ selectedId: prevItem });
  };

  selectNext = () => {
    const nextItem = selectNext(this.props.data, this.state.selectedId);
    this.setState({ selectedId: nextItem });
  };

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const { getEntities, updateSorting, model, pagingOptions } = this.props;
    const sortedField: string | undefined = this.props.models.get(model)?.fields
      .filter((f) => f.name === sorter.field)[0]?.sortedField;
    let field = sorter.field;
    if (sortedField) field = sortedField;
    let newSort = { field: field, direction: sorter.order === "ascend" ? "Asc" : "Desc" };
    getEntities && getEntities(model, pagingOptions, newSort);
    updateSorting(newSort);


  };

  render() {
    const { columns, model, selectedRows, selectedId } = this.state;
    const { loading, scrollY, statesFilter } = this.props;
    const activeState = statesFilter && statesFilter.length ?
      statesFilter[0] || "" : "";

    const checkbox: any = document.getElementsByClassName(
      "ant-table-selection-column"
    );
    if (
      model &&
      model.states &&
      model.states.size &&
      model.allowMultiselect &&
      activeState
    ) {
      const states = model.states.get(activeState.toString());
      if (states && states.toName && states.toName.length) {
        if (checkbox && checkbox.length) {
          for (let i = 0; i < checkbox.length; i++) {
            checkbox[i].style.display = "table-cell";
          }
        }
      }
    }

    const rowSelection:
      | TableRowSelection<any>
      | undefined = model.allowMultiselect
        ? {
          type: "checkbox" as const,
          columnWidth: 20,
          onChange: (selectedRowKeys: any, selectedRows: any) => {
            this.setState({ selectedRows });
          },
        }
        : undefined;
    return (
      <>
        {model.allowMultiselect && (
          <Row className={"buttons-view"}>
            <Col span={24} className={"filter-close-icon align-right"}>
              <TableStateChange
                state={activeState.toString()}
                selectedItems={selectedRows}
                model={model}
              />
            </Col>
          </Row>
        )}
        <div
          tabIndex={0}
          onKeyUp={(e) => {
            this.keyPressEvent(e);
          }}
        >
          <Table
            loading={loading}
            tableLayout={"auto"}
            columns={columns}
            dataSource={this.props.data}
            rowSelection={rowSelection}
            scroll={{
              x: "max-content",
              y: scrollY ? scrollY : 400,
              scrollToFirstRowOnChange: true,
            }}
            size="small"
            pagination={false}
            footer={() => this.props.footer(this.selectPrev, this.selectNext)}
            rowKey="id"
            rowClassName={(record: any, index: number) => {
              let rowClass = "all-row ";
              if (index === 0) {
                rowClass += "first-row ";
              }
              if (selectedId === record["id"]) {
                rowClass += "scroll-row selected-row ";
              }
              if (record.deleted) {
                rowClass += "bg-grey-300 ";
              }
              if (
                !model ||
                !model.indicators ||
                !model.indicators.length
              )
                return rowClass;
              model.indicators.forEach((ind) => {
                if (ind.condition(record)) {
                  rowClass += "bg-" + ind.color + " ";
                }
              });
              return rowClass;
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  this.onSelectChange(record["id"]);
                },
                onDoubleClick: () => {
                  this.onDoubleChange(record["id"]);
                },
              };
            }}
            bordered
            onChange={this.handleTableChange}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  models: state.modelsReducer.models,
  filter: state.entitiesReducer.filter,
  statesFilter: state.modelsReducer.statesFilter,
  sortingOptions: state.entitiesReducer.sortingOptions
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    updateSorting: (sortingOptions: IDefaultSorting) =>
      updateSorting(sortingOptions),
    setSelectedId: (id: number) => onSelectedIdChange(id)
  }, dispatch)
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(BaseTable)
);
