import { Dialog } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ModelType } from "../../../../../models/enums/model-type";
import { Dictionary, DictionaryObject } from "../../../../../models/types/dictionary";
import { ITab, IViewModel } from "../../../../../models/view-model";
import { clearDVChanges, getDVChanges } from "../../../../../redux/actions/aemActions";
import { IAppState } from "../../../../../redux/states/state";
import BaseTable from "../../../lists/base-table/BaseTable";
import { defaultPagingOptions } from "../../../lists/default-paging";
import FormInitializer from "../../form-modal/form-initializer/FormInitializer";
import "./DVChanges.scss";

const DVChanges = (props: { item: DictionaryObject; model: IViewModel }) => {
  const { item } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modelName = "DVRegChanges";

  const items = useSelector((state: IAppState) => state.aemReducer.dvchanges);
  const loading = useSelector((state: IAppState) => state.aemReducer.loadingChanges);
  const selectedModel = useSelector((state: IAppState) => state.modelsReducer.models.get(modelName));
  const [isOpen, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<DictionaryObject | null>(null);

  const currentTab = (currentModel: IViewModel | undefined) => {
    return currentModel && currentModel.tabs
        ? currentModel.tabs.find((tab: ITab) => tab.title === "DVChangesTab")
        : null;
  };
  const selectItem = (item: DictionaryObject | null) => {
    setOpen(true);
    setSelectedItem(item);
  };

  useEffect(() => {
    dispatch(getDVChanges(item));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearDVChanges());
    }
  }, []);

  if(!selectedModel) {
    return null;
  }
  
  const tab = currentTab(selectedModel);
  if(!tab) {
    return null;
  }

  return (
    <div className="dv-changes">
      <Dialog 
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        title={t(modelName)}
        className="dialog"
       >
          <FormInitializer 
            tab={tab}
            model={selectedModel}
            selectedItem={selectedItem}
            mainEntity={false}
            hideId={false}
            type={ModelType.Entity}
            form={
                "DVChangesInner__FORM"
            }
            transformInitialItem={(initialItem: DictionaryObject) => initialItem}
            updateCallback={() => { dispatch(getDVChanges(item)) }}
            onClose={() => setOpen(false)}/>
      </Dialog>
      <BaseTable
          viewPath={'list'}
          loading={loading}
          allowSelect={false}
          data={items ?? []}
          type={ModelType.Entity}
          model={modelName}
          selectedItem={null}
          openForm={() => null}
          selectItem={(item: any) => selectItem(item)}
          pagingOptions={defaultPagingOptions}
          footer={() => null}
        />
    </div>
  );
};

export default DVChanges;
