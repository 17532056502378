import { AUTHORIZATION_PROCESS } from "./../actions/authorizationActions";
import {
  LOAD_EMPLOYEE,
  LOAD_USER_INFO,
  PERMITS_BY_USER,
} from "../actions/authorizationActions";
import { IStateAuthorization } from "../states/state-authorization";

const INITIAL_STATE: IStateAuthorization = {
  userInfo: null,
  currentEmployee: null,
  permits: [],
  inProcess: false,
};

const authorizationReducer = (
  state = INITIAL_STATE,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case LOAD_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case LOAD_EMPLOYEE:
      return {
        ...state,
        currentEmployee: action.payload,
      };
    case PERMITS_BY_USER:
      return {
        ...state,
        permits: action.payload,
      };
    case AUTHORIZATION_PROCESS:
      return {
        ...state,
        inProcess: action.payload,
      };
    default:
      return state;
  }
};

export default authorizationReducer;
