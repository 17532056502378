import { IReference } from "../../models/reference";
import { ENTITIES_BACKEND } from "../../backend-connection";
import { Action, Dispatch } from "redux";
import axios from "axios";
import { IConstraint, IConstraintTree } from "../../models/constraint";
import { showError } from "./errorsActions";
import { ThunkAction } from "redux-thunk";
import { ConstraintType } from "../../models/enums/constraint-type";
import { IDefaultSorting } from "../../models/default-sorting";
import { IAppState } from "../states/state";
import { years } from "../../helpers/year-reference";
import { project } from "./../../configs/index";
import { IPagingOptions } from "../../models/paging-options";
import { DictionaryObject } from "../../models/types/dictionary";

export const GET_REFERENCE = "GET_REFERENCE";
export const LOAD_REFERENCE = "LOAD_REFERENCE";
export const GET_REFERENCES = "GET_REFERENCES";
export const LOAD_REFERENCES = "LOAD_REFERENCES";
export const ADD_TO_REFERENCE = "ADD_TO_REFERENCE";
export const EDIT_REFERENCE = "EDIT_REFERENCE";
export const DELETE_FROM_REFERENCE = "ADD_TO_REFERENCE";
export const SET_REFERENCE_TOTAL_COUNT = "SET_REFERENCE_TOTAL_COUNT";
export const OPEN_REFERENCE_FORM = "OPEN_REFERENCE_FORM";
export const SELECT_REFERENCE_ITEM = "SELECT_REFERENCE_ITEM";
export const SET_FILTER_REFERENCE = "SET_FILTER_REFERENCE";
export const LOADING_SEARCH = "LOADING_SEARCH";

export function setReferenceTotalCount(count: number) {
  return {
    type: SET_REFERENCE_TOTAL_COUNT,
    payload: count,
  };
}

export function referenceFormIsOpen(refName: string, isOpen: boolean, editItem: DictionaryObject | null, updateFieldCall: (close: boolean, value: any) => void) {
  return {
    type: OPEN_REFERENCE_FORM,
    payload: {
      isOpen: isOpen,
      refName: refName,
      editItem: editItem,
      updateFieldCall: updateFieldCall
    },
  };
}

export function selectReferenceItem(selectedItem: any) {
  return {
    type: SELECT_REFERENCE_ITEM,
    payload: selectedItem,
  };
}

export function setFilterReference(filter: IConstraint[]) {
  return {
    type: SET_FILTER_REFERENCE,
    payload: filter,
  };
}

export function loadingSearch(loading: boolean) {
  return {
    type: LOADING_SEARCH,
    payload: loading,
  };
}

export function getReferences(userRole: string) {
  return (dispatch: Dispatch) => {
    dispatch(loadReferences(project.references));
  };
}

export const getReference = (
  modelName: string,
  dbname: string,
  search?: IConstraint[],
  pagingOptions?: IPagingOptions,
  sortingOptions: IDefaultSorting | null = null
): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  {
    const { page, pageSize, showDeleted } = { ...pagingOptions };
    let sortingQuery = "";
    const state: IAppState = getState();

    const { models } = state.modelsReducer;

    const model = models.get(modelName);

    if (model) {
      const sorting: IDefaultSorting[] = model.defaultSorting
        ? model.defaultSorting
        : [];
      if (!sortingOptions)
        sorting.forEach((sd: IDefaultSorting) => {
          sortingQuery += `&page.sortColumn=${sd.field}&page.sortDirection=${sd.direction}`;
        });
      else sortingQuery += `&page.sortColumn=${sortingOptions.field}&page.sortDirection=${sortingOptions.direction}`;
    }
    dispatch(loadingSearch(true));
    getReferenceQuery(
      dbname,
      sortingQuery,
      search,
      page,
      pageSize,
      showDeleted
    ).then((result) => {
      if (result) {
        dispatch(loadReference({ name: modelName, items: result }));
        dispatch(
          referenceTotalCount(dbname, search ? search : [], pagingOptions)
        );
      }
      dispatch(loadingSearch(false));
    });
  };
}

export function getReferenceQuery(
  modelName: string,
  sortingQuery = "",
  search?: IConstraint[],
  page?: number,
  pageSize = 15,
  showDeleted = false
) {
  if (modelName === "Years") {
    return new Promise<number[]>((resolve) => resolve(years(0, 30)));
  }
  if (modelName === "Existance") {
    return new Promise<string[]>((resolve) => resolve(["Var", "Yox"]));
  }
  if (modelName === "YesNo") {
    return new Promise<string[]>((resolve) => resolve(["Bəli", "Yox"]));
  }
  const searchValues: IConstraint[] = [];
  if (search) {
    search.forEach((s) => {
      if (!s.value) {
        searchValues.push(s);
        return;
      }
      if (s.type === ConstraintType.Like) {
        const searchEl = { ...s };
        searchEl.value = "%" + s.value + "%";
        searchValues.push(searchEl);
      } else {
        searchValues.push(s);
      }
    });
  }

  const paging =
    page !== undefined
      ? `&page.showDeleted=${showDeleted}&page.limit=${pageSize}&page.offset=` +
      page * pageSize
      : `&page.limit=${1000}&page.showDeleted=${showDeleted}`;

  return axios(
    `${ENTITIES_BACKEND}/findAll?modelName=${modelName}` +
    paging +
    sortingQuery,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(searchValues),
    }
  ).then((result) => {
    if (result && result.data) {

      return result.data;
    } else {
      return null;
    }
  });
}

export function getReferenceQueryForAutocomplite(
  modelName: string,
  sortingQuery = "",
  search?: IConstraintTree,
  page?: number,
  pageSize = 15,
  showDeleted = false
) {
  if (modelName === "Years") {
    return new Promise<number[]>((resolve) => resolve(years(0, 30)));
  }
  if (modelName === "Existance") {
    return new Promise<string[]>((resolve) => resolve(["Var", "Yox"]));
  }
  if (modelName === "YesNo") {
    return new Promise<string[]>((resolve) => resolve(["Bəli", "Yox"]));
  }

  const paging =
    page !== undefined
      ? `&page.showDeleted=${showDeleted}&page.limit=${pageSize}&page.offset=` +
      page * pageSize
      : `&page.limit=${1000}&page.showDeleted=${showDeleted}`;

  return axios(
    `${ENTITIES_BACKEND}/findAllV2?modelName=${modelName}` +
    paging +
    sortingQuery,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(search),
    }
  ).then((result) => {
    if (result && result.data) {

      return result.data;
    } else {
      return null;
    }
  });
}

export function referenceTotalCount(
  modelName: string,
  filter: IConstraint[],
  pagingOptions?: IPagingOptions
) {
  const { showDeleted } = { ...pagingOptions };

  const searchValues: IConstraint[] = [];
  if (filter) {
    filter.forEach((s) => {
      if (!s.value) {
        searchValues.push(s);
        return;
      }
      if (s.type === ConstraintType.Like) {
        const searchEl = { ...s };
        searchEl.value = "%" + s.value + "%";
        searchValues.push(searchEl);
      } else {
        searchValues.push(s);
      }
    });
  }

  return (dispatch: Dispatch) => {
    axios(
      `${ENTITIES_BACKEND}/count?modelName=` +
      modelName +
      `&id=2&page.showDeleted=` +
      showDeleted,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(searchValues),
      }
    )
      .then((response) => {
        const countData = response.data;
        dispatch(setReferenceTotalCount(countData ? countData.value : 0));
      })
      .catch((err) => showError("error getting data count"));
  };
}

export function loadReference(reference: IReference) {
  return {
    type: LOAD_REFERENCE,
    payload: reference,
  };
}

export function loadReferences(references: string[]) {
  return {
    type: LOAD_REFERENCES,
    payload: references,
  };
}
