import { getDeepValue } from "./get-deep-value";
import { IViewField } from "../models/view-field";
import { ConstraintType } from "../models/enums/constraint-type";
import { IConstraint } from "../models/constraint";
import { DictionaryObject } from "../models/types/dictionary";

export const checkFilterConstraints = (
  field: IViewField,
  item: DictionaryObject,
  listeners?: Map<string, any>,
) => {
  let filters: IConstraint[] = [];
  if (!field.viewConstraints || !field.viewConstraints.length) {
    return filters;
  }

  filters = field.viewConstraints.filter(
    (c) =>
      (c.type === ConstraintType.Like || c.type === ConstraintType.Eq) &&
      c.value
  );
  const constraints = field.viewConstraints.filter((c) => !c.value);

  if (!constraints || !constraints.length) {
    return filters;
  }
  constraints.forEach((c: IConstraint) => {
    const constraint = { ...c };
    if (!constraint.value && c.listenField && listeners) {
      const foundContsraint = listeners.get(c.listenField);
      if (!foundContsraint) {
        return;
      }
      if (typeof foundContsraint === "object") {
        const searchProperty = constraint.searchField
          ? constraint.searchField
          : "id";
        const path = constraint.nested
          ? constraint.field + "." + searchProperty
          : searchProperty;

        constraint.value = foundContsraint
          ? getDeepValue(foundContsraint, path)
          : undefined;
        constraint.field = constraint.field + "." + searchProperty;
        constraint.searchField = undefined;
      } else {
        constraint.value = foundContsraint;
      }
    }
    if(!constraint.value && c.itemField) {
      const foundContsraint = getDeepValue(item, c.itemField);
      if (!foundContsraint) {
        return;
      }
      constraint.value = foundContsraint;
    }
    filters.push(constraint);
  });
  return filters;
};

export const checkFilterConstraintsItem = (
  field: IViewField,
  item: DictionaryObject
) => {
  let filters: IConstraint[] = [];
  if (!field.viewConstraints || !field.viewConstraints.length) {
    return filters;
  }


  filters = field.viewConstraints.filter(
    (c) =>
      (c.type === ConstraintType.Like || c.type === ConstraintType.Eq) &&
      c.value
  );
  const constraints = field.viewConstraints.filter((c) => !c.value);

  if (!constraints || !constraints.length || !item) {
    return filters;
  }
  constraints.forEach((c: IConstraint) => {
    const constraint = { ...c };
    if (!constraint.value && c.listenField) {
      const foundContsraint = item[c.listenField];
      if (!foundContsraint) {
        return;
      }
      if (typeof foundContsraint === "object") {
        const searchProperty = constraint.searchField
          ? constraint.searchField
          : "id";
        const path = constraint.nested
          ? constraint.field + "." + searchProperty
          : searchProperty;

        constraint.value = foundContsraint
          ? getDeepValue(foundContsraint, path)
          : undefined;
        constraint.field = constraint.field + "." + searchProperty;
        constraint.searchField = undefined;
      } else {
        constraint.value = foundContsraint;
      }
    }
    filters.push(constraint);
  });
  return filters;
};
