import React, { ReactElement } from "react";
import { ChangeAction } from "../changes/field-change";
import { IConstraint } from "../constraint";
import { Dictionary } from "../types/dictionary";
import { IViewField } from "../view-field";
import { GeneralField } from "./general-field";
import i18n from "../../i18n";

export class EnumField extends GeneralField {
  compareFunction(before: Dictionary, after: Dictionary): ChangeAction {
    return (before as string) === (after as string)
      ? ChangeAction.Unchanged
      : ChangeAction.Updated;
  }

  viewRender(value: Dictionary, referenceView?: string[], showFields?: boolean): ReactElement {
    return <span>{value ? i18n.t(this.viewField.name + '-' + value) : ""}</span>
  }

  stringRender(value: Dictionary, viewFields?: IViewField[]): string {
    return value ? this.viewField.name + '-' + value : "";
  }

  tagRender(value: Dictionary, onRemove: (id: number) => void, showFields?: boolean): ReactElement {
    return <div></div>;
  }

  emptyConstraint(fieldName: string): IConstraint {
    return {
      type: "Null",
      field: fieldName,
    } as IConstraint;
  }

  valueConstraint(fieldName: string, value: Dictionary) {
    return [
      {
        type: "Eq",
        field: fieldName,
        value: value ? value.toString() : "",
      } as IConstraint,
    ];
  }

  isEmpty(value: Dictionary) {
    return !value;
  }
}
