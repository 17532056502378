import axios from "axios";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import scrollIntoView from "scroll-into-view";
import { ENTITIES_BACKEND } from "../../backend-connection";
import { showProgressBar } from "../../components/core/progress-bar/ProgressBar";
import { getJoinedConditions } from "../../helpers/dynamic-constraints";
import {
  filterContraintToTree,
  filterToConstraint,
} from "../../helpers/filter-to-constraint";
import { IConstraint } from "../../models/constraint";
import { IDefaultSorting } from "../../models/default-sorting";
import { IEmployee } from "../../models/employee";
import { Dictionary } from "../../models/types/dictionary";
import { IAppState } from "../states/state";
import { IConstraintTree } from "./../../models/constraint";
import { ICopyItem } from "./../../models/copy-item";
import { IPagingOptions } from "./../../models/paging-options";
import { DictionaryObject } from "./../../models/types/dictionary";
import {
  deleteFile,
  loadingFiles,
  saveFile,
  uploadFile,
} from "./attachmentsActions";
import { dismissToast, showError, showSuccess } from "./errorsActions";
import { refreshItem, updatePaging } from "./newEntitiesActions";
import { saveTryValues } from "./newSequencesActions";
import { nextValue } from "./sequencesActions";

export const OPEN_FORM = "OPEN_FORM";
export const SELECT_ITEM = "SELECT_ITEM";
export const SELECT_ID = "SELECT_ID";
export const SELECTED_COUNT = "SELECTED_COUNT";
export const LOAD_ITEMS = "LOAD_ITEMS";
export const SET_TOTAL_COUNT = "SET_TOTAL_COUNT";
export const SET_FILTER = "SET_FILTER";
export const LOADING_ITEMS = "LOADING_ITEMS";
export const SET_DONE_CONDITIONS = "SET_DONE_CONDITIONS";
export const SET_MINE_CONDITIONS = "SET_MINE_CONDITIONS";
export const SET_DEFAULT_CONDITIONS = "SET_DEFAULT_CONDITIONS";
export const SET_ARCHIVE_CONDITIONS = "SET_ARCHIVE_CONDITIONS";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const CLEAR_CONSTRAINTS = "CLEAR_CONSTRAINTS";
export const SET_RELATED_CARD = "SET_RELATED_CARD";
export const SET_TEMP_RELATED_CARD = "SET_TEMP_RELATED_CARD";
export const COPY = "COPY";
export const PASTE = "PASTE";
export const CLEAR_ITEMS = "CLEAR_ITEMS";
export const SHOW_DELETED = "SHOW_DELETED";

export function loadItems(entitiesData: any) {
  return {
    type: LOAD_ITEMS,
    payload: {
      items: entitiesData,
    },
  };
}

export function clearItems() {
  return {
    type: CLEAR_ITEMS
  };
}

export function loadingItems(loading: boolean) {
  return {
    type: LOADING_ITEMS,
    payload: loading,
  };
}

export function clearFilters() {
  return {
    type: CLEAR_FILTERS,
  };
}

export function copy(copyItem: ICopyItem) {
  return {
    type: COPY,
    payload: copyItem,
  };
}

export function paste() {
  return {
    type: PASTE,
  };
}

export const showDeleted = (
  show: boolean
): ThunkAction<void, IAppState, unknown, Action<string>> => (
  dispatch,
  getState
) => {
  dispatch({
    type: SHOW_DELETED,
    payload: show,
  });

  const state: IAppState = getState();

  dispatch(
    getEntities2(
      state.modelsReducer.dbname,
      {
        ...state.entitiesReducer.pagingOptions,
        showDeleted: show,
        page: 0,
      },
      state.entitiesReducer.sortingOptions
    )
  );
};

export const setFilter = (
  filter: any
): ThunkAction<void, IAppState, unknown, Action<string>> => (
  dispatch,
  getState
) => {
  const state: IAppState = getState();

  dispatch({
    type: SET_FILTER,
    payload: filter,
  });

  dispatch(
    getEntities2(
      state.modelsReducer.dbname,
      {
        ...state.entitiesReducer.pagingOptions,
        showDeleted: state.entitiesReducer.showDeleted,
        page: 0,
      },
      state.entitiesReducer.sortingOptions
    )
  );
};

export const setDoneConditions = (doneConditions: IConstraintTree | null): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  dispatch({
    type: SET_DONE_CONDITIONS,
    payload: doneConditions,
  });

  const state: IAppState = getState();

  dispatch(
    getEntities2(
      state.modelsReducer.dbname,
      {
        ...state.entitiesReducer.pagingOptions,
        showDeleted: state.entitiesReducer.showDeleted,
        page: 0,
      },
      state.entitiesReducer.sortingOptions
    )
  );
};

export const setMineConditions = (mineConditions: IConstraintTree | null): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  dispatch({
    type: SET_MINE_CONDITIONS,
    payload: mineConditions,
  });

  const state: IAppState = getState();

  dispatch(
    getEntities2(
      state.modelsReducer.dbname,
      {
        ...state.entitiesReducer.pagingOptions,
        showDeleted: state.entitiesReducer.showDeleted,
        page: 0,
      },
      state.entitiesReducer.sortingOptions
    )
  );
};

export const setArchiveYear = (archiveCondition: IConstraintTree | null): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  dispatch({
    type: SET_ARCHIVE_CONDITIONS,
    payload: archiveCondition,
  });

  const state: IAppState = getState();

  dispatch(
    getEntities2(
      state.modelsReducer.dbname,
      {
        ...state.entitiesReducer.pagingOptions,
        showDeleted: state.entitiesReducer.showDeleted,
        page: 0,
      },
      state.entitiesReducer.sortingOptions
    )
  );
};

export const saveDefaultsConditions = (
  defaultConditions: IConstraintTree | null
) => {
  return {
    type: SET_DEFAULT_CONDITIONS,
    payload: defaultConditions,
  }
}

export const setDefaultConditions = (
  defaultConditions: IConstraintTree | null
): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  dispatch({
    type: SET_DEFAULT_CONDITIONS,
    payload: defaultConditions,
  });

  dispatch({
    type: SET_FILTER,
    payload: {},
  });

  const state: IAppState = getState();

  dispatch(
    getEntities2(
      state.modelsReducer.dbname,
      {
        ...state.entitiesReducer.pagingOptions,
        showDeleted: state.entitiesReducer.showDeleted,
        page: 0,
      },
      state.entitiesReducer.sortingOptions
    )
  );
};

export const selectNext = (
  data: DictionaryObject[],
  selectedId: number
): number => {
  let nextItem: DictionaryObject;
  if (selectedId && data && data.length) {
    const currentIndex = data.findIndex((c) => c["id"] === selectedId);

    nextItem =
      currentIndex < data.length - 1 ? data[currentIndex + 1] : data[0];
  } else {
    nextItem = data[0];
  }

  const selected: HTMLElement | null = document.querySelector(".scroll-row");
  if (selected) {
    scrollIntoView(selected, {
      align: {
        top: 0,
        left: 0,
      },
    });
  }

  return nextItem ? +(nextItem["id"] || 0) : 0;
};

export const selectPrev = (
  data: DictionaryObject[],
  selectedId: number
): number => {
  let prevItem: DictionaryObject;
  if (selectedId && data && data.length) {
    const currentIndex = data.findIndex((c) => c["id"] === selectedId);
    prevItem = currentIndex >= 1 ? data[currentIndex - 1] : data[0];
  } else {
    prevItem = data[0];
  }

  const selected: HTMLElement | null = document.querySelector(".scroll-row");
  if (selected) {
    scrollIntoView(selected, {
      align: {
        top: 20,
        left: 0,
      },
    });
  }

  return prevItem ? +(prevItem["id"] || 0) : 0;
};

export function selectItem(selectedItem: any) {
  return {
    type: SELECT_ITEM,
    payload: selectedItem,
  };
}

export function setTotalCount(count: number) {
  return {
    type: SET_TOTAL_COUNT,
    payload: count,
  };
}

export const createEntity = (
  modelName: string,
  data: DictionaryObject,
  sequences: string[],
  selectedItem: DictionaryObject,
  updateCallback?: (close: boolean, item?: DictionaryObject) => void,
  callback?: (item: Dictionary) => Dictionary,
  transformCallback?: (item: Dictionary) => void
): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  return axios(`${ENTITIES_BACKEND}/create?modelName=${modelName}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  })
    .then((response) => {
      dispatch(showSuccess("UI.Saved"));
      if (updateCallback) {
        updateCallback(false, response.data);
      }
      if (callback) {
        callback(response.data);
      }
      if (response && response.data && transformCallback) {
        transformCallback({ ...selectedItem, id: response.data["id"], state: response.data["state"] });
      }
      dispatch(saveTryValues(sequences));
    })
    .catch((err) => dispatch(showError("ERROR.Save")));
};

export const deleteEntity = (
  modelName: string,
  data: DictionaryObject,
  updateCallback?: (close: boolean, item: DictionaryObject | null) => void,
  callback?: (item: Dictionary) => Dictionary
): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  return axios(`${ENTITIES_BACKEND}/delete?modelName=${modelName}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  })
    .then((response) => {
      if (updateCallback) {
        updateCallback(true, null);
      }
      if (callback) {
        callback(response.data);
      }
    })
    .catch((err) => dispatch(showError("ERROR.ItemDelete")));
};

export const deactivateEntity = (
  modelName: string,
  data: DictionaryObject,
  updateCallback?: (close: boolean, item: DictionaryObject) => void,
  callback?: (item: Dictionary) => Dictionary
): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  return axios(`${ENTITIES_BACKEND}/markDeleted?modelName=${modelName}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  })
    .then((response) => {
      if (updateCallback) {
        updateCallback(true, response.data);
      }
      if (callback) {
        callback(response.data);
      }
      dispatch(showSuccess("ERROR.SuccessMarkDeleted"))
    })
    .catch((err) => dispatch(showError("error getting data")));
};

export const restoreEntity = (
  modelName: string,
  data: DictionaryObject,
  updateCallback?: (close: boolean, item: DictionaryObject | null) => void,
  callback?: (item: Dictionary) => Dictionary
): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  return axios(`${ENTITIES_BACKEND}/restoreDeleted?modelName=${modelName}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  })
    .then((response) => {
      if (updateCallback) {
        updateCallback(true, null);
      }
      if (callback) {
        callback(response.data);
      }
    })
    .catch((err) => dispatch(showError("error getting data")));
};

export function updateEntityRequest(modelName: string, data: any) {
  return axios(`${ENTITIES_BACKEND}/update?modelName=${modelName}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  }).catch((err) => showError("error getting data"));
}

export const updateEntity = (
  modelName: string,
  data: DictionaryObject,
  sequences: string[],
  selectedItem: DictionaryObject,
  updateCallback?: (close: boolean, item: DictionaryObject) => void,
  callback?: (item: Dictionary) => Dictionary,
  transformCallback?: (item: Dictionary) => void
): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  const state: IAppState = getState();
  const tryValues = state.sequencesReducer.tryValue;
  return axios(`${ENTITIES_BACKEND}/update?modelName=${modelName}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  })
    .then((response) => {
      if (updateCallback) {
        updateCallback(false, response.data);
      }
      if (callback) {
        callback(data);
      }
      if (transformCallback) {
        transformCallback(selectedItem);
      }
      dispatch(saveTryValues(Array.from(tryValues.keys())));
    })
    .catch((err) => dispatch(showError("ERROR.UpdateItem")));
};

export const getEntities2 = (modelName: string, pagingOptions: IPagingOptions, sortingOptions: IDefaultSorting | null = null): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  dispatch(loadingItems(true));
  dispatch(clearItems());

  let pagingString = "";

  if(!pagingOptions.noPagination) {
    dispatch(updatePaging(pagingOptions));
    pagingString = `&page.offset=${pagingOptions.page * pagingOptions.pageSize}&page.limit=${pagingOptions.pageSize}`;
  }

  const showDeleted = pagingOptions.showDeleted === true;
  const state: IAppState = getState();

  const { filter } = state.entitiesReducer;
  const { models } = state.modelsReducer;
  const statesFilter = state.modelsReducer.statesFilter;

  filter["state"] = filter["state"] ? filter["state"] :
    (statesFilter && statesFilter.length ? statesFilter : undefined);

  const model = models.get(modelName);
  const filterConstraint: IConstraint[] = filterToConstraint(
    filter,
    "",
    models,
    modelName
  );

  const tree = filterContraintToTree(filterConstraint);
  let sortingQuery = "";

  if (model) {
    const sorting: IDefaultSorting[] = model.defaultSorting
      ? model.defaultSorting
      : [];
    if (!sortingOptions)
      sorting.forEach((sd: IDefaultSorting) => {
        sortingQuery += `&page.sortColumn=${sd.field}&page.sortDirection=${sd.direction}`;
      });
    else sortingQuery += `&page.sortColumn=${sortingOptions.field}&page.sortDirection=${sortingOptions.direction}`;
  }

  const joinedConstraints = getJoinedConditions(state.entitiesReducer, tree);
  axios(
    `${ENTITIES_BACKEND}/findAllV2?modelName=${modelName}${pagingString}&page.showDeleted=${showDeleted}` +
      sortingQuery,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(joinedConstraints),
    }
  )
    .then((response) => {
      dispatch(loadItems(response.data));
      dispatch(loadingItems(false));
      dispatch(reselectItem(response.data, state.entitiesReducer.selectedItem));
      dispatch(getTotalCountV2(modelName, joinedConstraints, showDeleted));
    })
    .catch((err) => {
      dispatch(showError("ERROR.GetList"));
      dispatch(loadingItems(false));
    });
};


export const getEntitiesForControlMarks = (value: string, pagingOptions: IPagingOptions, sortingOptions: IDefaultSorting | null = null): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  dispatch(loadingItems(true));
  dispatch(clearItems());
  dispatch(updatePaging(pagingOptions));
  const state: IAppState = getState();

  const constraints: IConstraint[] = [{
    type: "Eq",
    field: "DVB_Series",
    value: value,
  }];
  const tree = filterContraintToTree(constraints);
  const modelName = "ControlMark";

  axios(
    `${ENTITIES_BACKEND}/findAllV2?modelName=DVBase&page.showDeleted=false`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(tree),
    }
  )
    .then(({ data }) => {
      const invoices = data.filter((el: any) => el.DVB_Invoice).map((el: any) => el.DVB_Invoice);
      const constraintsControl: IConstraint[] = [{
        type: "In",
        field: "Invoice",
        values: invoices,
      }];
      const treeControl = filterContraintToTree(constraintsControl);
      axios(
        `${ENTITIES_BACKEND}/findAllV2?modelName=${modelName}&page.offset=${pagingOptions.page * pagingOptions.pageSize
        }&page.limit=${pagingOptions.pageSize}&page.showDeleted=false`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify(treeControl),
        }
      ).then((response) => {
        dispatch(loadItems(response.data));
        dispatch(loadingItems(false));
        dispatch(
          reselectItem(response.data, state.entitiesReducer.selectedItem)
        );
        //dispatch(getTotalCountV2(modelName, {}));})
      })
        .catch((err) => {
          dispatch(showError("ERROR.GetList"));
          dispatch(loadingItems(false));
        });
    })
    .catch((err) => {
      dispatch(showError("ERROR.GetList"));
      dispatch(loadingItems(false));
    });
};

export const getTotalCount = (modelName: string, filter: IConstraint[]): ThunkAction<void, IAppState, unknown, Action<string>> => async (dispatch, getState) => {
  axios(`${ENTITIES_BACKEND}/count?modelName=` + modelName, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(filter),
  })
    .then((response) => {
      const countData = response.data;
      dispatch(setTotalCount(countData ? countData.value : 0));
    })
    .catch((err) => dispatch(showError("ERROR.GetCount")));
};

export const getCountByFilter = (modelName: string, filter: any): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {

  const state: IAppState = getState();
  const { models } = state.modelsReducer;
  const statesFilter = state.modelsReducer.statesFilter;

  filter["state"] = filter["state"] ? filter["state"] :
    (statesFilter && statesFilter.length ? statesFilter : undefined);

  const filterConstraint: IConstraint[] = filterToConstraint(
    filter,
    "",
    models,
    modelName
  );

  const tree = filterContraintToTree(filterConstraint);

  const joinedConstraints = getJoinedConditions(state.entitiesReducer, tree);

  axios(`${ENTITIES_BACKEND}/countV2?modelName=` + modelName, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(joinedConstraints),
  })
    .then((response) => {
      const countData = response.data;
      dispatch(setSelectedCount(countData ? countData.value : 0));
    })
    .catch((err) => dispatch(showError("ERROR.GetCount")));
};
export const getTotalCountV2 = (
  modelName: string,
  filter: IConstraintTree,
  showDeleted: boolean
): ThunkAction<void, IAppState, unknown, Action<string>> => (
  dispatch,
  getState
) => {
  axios(
    `${ENTITIES_BACKEND}/countV2?modelName=` +
      modelName +
      "&page.showDeleted=" +
      showDeleted,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(filter),
    }
  )
    .then((response) => {
      const countData = response.data;
      dispatch(setTotalCount(countData ? countData.value : 0));
    })
    .catch((err) => dispatch(showError("ERROR.GetCount")));
};

export const reselectItem = (
  data: DictionaryObject[],
  selectedItem: DictionaryObject
): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  if (!data || !data.length || !selectedItem) {
    return;
  }
  const item = data.find(
    (d: DictionaryObject) => d["id"] === selectedItem["id"]
  );
  if (!item) {
    return;
  }
  dispatch(selectItem(item));
};

export const uploadDocumentsAttachments = (
  modelName: string,
  data: any,
  sequence: string,
  file: FormData,
  attachmentType: any
): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  const state: IAppState = getState();
  const employee: IEmployee | null =
    state.authorizationReducer.currentEmployee;
  dispatch(showProgressBar());
  uploadFile(file)
    .catch((error) => {
      dispatch(dismissToast({ dismiss: true, dismissKey: "progressBar" }));

      if (error && error.response) {
        if (error.response.status === 413) {
          dispatch(showError("ERROR.413"));
        } else {
          dispatch(showError("ERROR.UploadFile"));
        }
      }
    })
    .then((response) => {
      if (response && response.data && response.data.id) {
        const docId = response.data.id;
        const pageCount = response.data.pageCount || undefined;
        saveFile(response.data.id).then((response) => {
          nextValue("FileName").then((response) => {
            const scanDocument = {
              ScanDocumentNumber: sequence + "-" + response.data,
              FileUid: docId,
              Employee: employee,
              PagesNumber: pageCount,
              ScanAttachmentDate: new Date().toISOString(),
              AttachmentType: attachmentType,
            };
            if (!data["ScanDocuments"]) {
              data["ScanDocuments"] = [];
            }
            data["ScanDocuments"].push(scanDocument);
            updateEntityRequest(modelName, data).then((response) => {
              dispatch(
                dismissToast({ dismiss: true, dismissKey: "progressBar" })
              );
              dispatch(
                refreshItem(modelName, data['id'])
              );
            });
          });
        });
      }
    });
};

export const deleteDocumentsAttachments = (
  modelName: string,
  data: any,
  id: string
): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  dispatch(loadingFiles(true));
  if (data["ScanDocuments"] && data["ScanDocuments"].length) {
    const scanDocument = data["ScanDocuments"].find(
      (sd: any) => sd.id === id
    );
    const index = data["ScanDocuments"].findIndex((sd: any) => sd.id === id);

    data["ScanDocuments"].splice(index, 1);
    return axios
      .all([
        deleteFile(scanDocument["FileUid"]),
        updateEntityRequest(modelName, data),
      ])
      .then((response) => {
        dispatch(
          refreshItem(modelName, data['id'])
        );
        dispatch(loadingFiles(false));
      });
  }
};

export const updateState = (modelName: string, data: object, updateCallback: () => void): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  axios(`${ENTITIES_BACKEND}/updateState?modelName=${modelName}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  })
    .then((response) => {
      updateCallback();
    })
    .catch((err) => dispatch(showError("ERROR.UpdateState")));
};

export const updateStateMultipleItems = (modelName: string, data: object[]): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  const state: IAppState = getState(); axios(`${ENTITIES_BACKEND}/updateStates?modelName=${modelName}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  })
    .then((response) => {
      dispatch(getEntities2(modelName, state.entitiesReducer.pagingOptions, state.entitiesReducer.sortingOptions));
    })
    .catch((err) => dispatch(showError("ERROR.UpdateStates")));
};

export function logGet(modelName: string, id: string) {
  if (id) {
    axios(`${ENTITIES_BACKEND}/logGet?modelName=${modelName}&id=${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }).then();
  }
}

export function setSelectedId(id: number) {
  return {
    type: SELECT_ID,
    payload: id
  }
}

export function setSelectedCount(count: number) {
  return {
    type: SELECTED_COUNT,
    payload: count
  }
}

export const onSelectedIdChange = (id: number): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
  if (id === 0)
    dispatch(setSelectedCount(0));
  dispatch(setSelectedId(id));
};