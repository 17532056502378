import { IStateTemplate } from "../states/state-templates";
import {
  LOAD_TEMPLATES,
  LOADING_TEMPLATES,
  REMOVE_TEMPLATE,
  SET_TEMPLATES_TOTAL_COUNT,
  UPDATE_TEMPLATE,
} from "../actions/templatesActions";

const INITIAL_STATE: IStateTemplate = {
  templates: [],
  loading: false,
  totalCount: 0,
};

const usersReducer = (
  state = INITIAL_STATE,
  action: { type: string; payload: any }
) => {
  const prevState = state;
  const templates = prevState.templates;
  switch (action.type) {
    case LOAD_TEMPLATES:
      return {
        ...prevState,
        templates: action.payload,
      };
    case LOADING_TEMPLATES:
      return {
        ...prevState,
        loading: action.payload,
      };
    case REMOVE_TEMPLATE:
      if (templates) {
        const findIndex = templates.findIndex((t) => t.name === action.payload);
        if (findIndex) {
          templates.splice(findIndex, 1);
        }
      }
      return {
        ...prevState,
        templates: templates ? [...templates] : null,
      };
    case UPDATE_TEMPLATE:
      if (templates) {
        const findIndex = templates.findIndex((t) => t.name === action.payload);
        if (findIndex) {
          templates[findIndex] = action.payload;
        }
      }
      return {
        ...prevState,
        templates: templates ? [...templates] : null,
      };
    case SET_TEMPLATES_TOTAL_COUNT:
      return {
        ...prevState,
        totalCount: action.payload,
      };
    default:
      return prevState;
  }
};

export default usersReducer;
