import { Toaster } from "@blueprintjs/core";
import { Position } from "@blueprintjs/core/lib/esm/common/position";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
  clearToasts,
  dismissToast,
} from "../../../redux/actions/errorsActions";
import { IAppState } from "../../../redux/states/state";
import "./ToastrMessage.scss";

interface Props extends WithTranslation {
  toastQueue: any[]; 
  dismiss: boolean;
  dismissKey: string;
  clearToasts: () => void;
  dismissToast: (dismiss: any) => void;
}

class ToastrMessage extends React.Component<Props, any> {
  // @ts-ignore
  private toaster: Toaster;
  private refHandlers = {
    toaster: (ref: Toaster) => (this.toaster = ref),
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.dismiss) {
      this.toaster.dismiss(this.props.dismissKey);
      this.props.dismissToast({ dismiss: false, dismissKey: "" });
    }
    if (this.props.toastQueue.length > 0) {
      this.props.toastQueue.forEach((toast) => {
        if (toast.reactElement) {
          delete toast.reactElement;
        } else {
          toast.message = this.translateMessage(toast);
        }
        this.toaster.show(toast, toast.key);
      });
      this.props.clearToasts();
    }
  }

  translateMessage(toast: any) {
    const { t } = this.props;
    return t(toast.message);
  }

  render() {
    return (
      <Toaster
        autoFocus={false}
        canEscapeKeyClear={true}
        position={Position.TOP}
        maxToasts={5}
        ref={this.refHandlers.toaster}
      />
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  toastQueue: state.errorsReducer.toastQueue,
  dismiss: state.errorsReducer.dismiss,
  dismissKey: state.errorsReducer.dismissKey,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      clearToasts: () => clearToasts(),
      dismissToast: (dismiss: any) => dismissToast(dismiss),
    },
    dispatch
  );

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ToastrMessage)
);
