import { Button, Checkbox, Icon, Intent } from "@blueprintjs/core";
import { Col, Row, Spin } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { dynamicConstraints } from "../../../helpers/dynamic-constraints";
import { years } from "../../../helpers/year-reference";
import { IConstraintTree } from "../../../models/constraint";
import { IEmployee } from "../../../models/employee";
import { Selectable } from "../../../models/types/dictionary";
import { IUser } from "../../../models/users/user";
import { IViewModel } from "../../../models/view-model";
import { hasUserPermit } from "../../../redux/actions/authorizationActions";
import {
  clearFilters,
  setArchiveYear,
  setDefaultConditions,
  setDoneConditions,
  setMineConditions,
  showDeleted,
} from "../../../redux/actions/entitiesActions";
import { IStateAuthorization } from "../../../redux/states/state-authorization";
import { IStateEntities } from "../../../redux/states/state-entities";
import { IStateModels } from "../../../redux/states/state-models";
import { IStateNavigation } from "../../../redux/states/state-navigation";
import SimpleSelect from "../../core/forms/controls/simple-select/SimpleSelect";
import ListLayout from "../../core/lists/list-layout/ListLayout";
import SideBar from "../side-bar/SideBar";
import TableLegend from "../table-legend/TableLegend";
import "./DynamicModelView.scss";

interface State {
  leftOpen: boolean;
  rightOpen: boolean;
  mine: boolean;
  done: boolean;
  modelName: string;
  year: number;
  deleted: boolean;
}

interface Props extends RouteComponentProps, WithTranslation {
  routing: string;
  setActiveModel: (modelName: string) => void;
  activeModel: IViewModel | undefined;
  userInfo: IUser | null;
  loading: boolean;
  setDoneConditions: (doneConditions: IConstraintTree | null) => void;
  setMineConditions: (mineConditions: IConstraintTree | null) => void;
  setDefaultConditions: (defaultConditions: IConstraintTree | null) => void;
  setArchiveYear: (archiveConditions: IConstraintTree | null) => void;
  clearFilters: () => void;
  hasUserPermit: (permit: string) => void;
  showDeleted: (show: boolean) => void;
  currentEmployee: IEmployee | null;
  filter: any;
}

class InnerDynamicModel extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      leftOpen: true,
      rightOpen: true,
      mine: false,
      done: false,
      modelName: "",
      deleted: false,
      year: new Date().getFullYear(),
    };
  }

  toggleSidebar = (event: any) => {
    this.setState({ leftOpen: !this.state.leftOpen });
  };

  mineChecked = () => {
    const { activeModel, currentEmployee } = this.props;
    const mineConditions = activeModel ? activeModel.mineConditions : undefined;
    this.setState({ mine: !this.state.mine });
    this.props.setMineConditions(
      !this.state.mine
        ? dynamicConstraints(currentEmployee, mineConditions)
        : null
    );
  };

  doneChecked = () => {
    const { activeModel } = this.props;
    const doneConditions = activeModel ? activeModel.doneConditions : undefined;
    this.setState({ done: !this.state.done });
    this.props.setDoneConditions(
      !this.state.done ? dynamicConstraints(null, doneConditions) : null
    );
  };

  deletedChecked = () => {
    this.setState({ deleted: !this.state.deleted });
    this.props.showDeleted(!this.state.deleted);
  };

  selectYear = (event: number) => {
    this.setState({ year: event });
    this.getByYear(event);
  };

  getByYear(year: number) {
    const { activeModel, currentEmployee } = this.props;
    if (!activeModel) {
      return;
    }
    const defaultConditions = activeModel.defaultConditions;
    this.props.setDefaultConditions(
      dynamicConstraints(currentEmployee, defaultConditions, year)
    );
  }

  render() {
    const leftOpen = !this.state.leftOpen ? "open" : "closed";
    const { activeModel, loading, t, currentEmployee } = this.props;

    if (loading) {
      return (
        <Spin
          tip="Loading models..."
          className={"large-spinner"}
          size={"large"}
        />
      );
    }
    if (!activeModel) {
      return <span></span>;
    }

    return (
      <div className="main-layout">
        <div id="left" className={leftOpen}>
          <div className={`sidebar ${leftOpen}`}>
            <SideBar />
          </div>
        </div>
        <div id="main">
          <Row className="display-row list-header">
            <Col
              span={4}
              className={"filter-close-icon display-row"}
              onClick={this.toggleSidebar}
            >
              <Icon
                icon={"filter-list"}
                iconSize={Icon.SIZE_LARGE}
                intent={Intent.NONE}
              />
              <span>{t("UI.Search")}</span>
            </Col>
            {this.props.activeModel && this.props.activeModel.yearFilter && (
              <Col span={4} className={"filter-close-icon display-row"}>
                <SimpleSelect
                  items={years(0, 5)}
                  renderView={(value: Selectable) =>
                    value ? value.toString() : ""
                  }
                  component={
                    <Button
                      rightIcon="caret-down"
                      text={
                        this.state.year
                          ? this.state.year.toString()
                          : "(No Selection)"
                      }
                    />
                  }
                  onChange={this.selectYear}
                />
              </Col>
            )}
            <Col span={4} className={"filter-close-icon display-row"}>
              {currentEmployee ? (
                <Checkbox
                  checked={this.state.mine}
                  label={t("UI.Mine")}
                  onChange={this.mineChecked}
                />
              ) : null}
            </Col>
            {activeModel.doneConditions && (
              <Col
                span={4}
                className={"filter-close-icon display-row align-right"}
              >
                <Checkbox
                  checked={this.state.done}
                  label={t("UI.Opened")}
                  onChange={this.doneChecked}
                />
              </Col>
            )}
            {!!(this.props.hasUserPermit("RSTRE") as any) && (
              <Col
                span={4}
                className={"filter-close-icon display-row align-right"}
              >
                <Checkbox
                  checked={this.state.deleted}
                  label={t("UI.Deleted")}
                  onChange={this.deletedChecked}
                />
              </Col>
            )}
            <Col
              span={8}
              className={"filter-close-icon display-row align-right"}
            >
              <TableLegend model={this.props.activeModel} />
            </Col>
          </Row>
          <div className="content">
            <ListLayout createNew={true} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: {
  navigationReducer: IStateNavigation;
  modelsReducer: IStateModels;
  authorizationReducer: IStateAuthorization;
  entitiesReducer: IStateEntities;
}) => ({
  userInfo: state.authorizationReducer.userInfo,
  currentEmployee: state.authorizationReducer.currentEmployee,
  loading: state.modelsReducer.loading,
  activeModel: state.modelsReducer.models.get(state.modelsReducer.activeModel),
  filter: state.entitiesReducer.filter,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setDoneConditions: (doneConditions) => setDoneConditions(doneConditions),
      setMineConditions: (mineConditions) => setMineConditions(mineConditions),
      setDefaultConditions: (defaultConditions) =>
        setDefaultConditions(defaultConditions),
      clearFilters: () => clearFilters(),
      setArchiveYear: (archiveConditions) => setArchiveYear(archiveConditions),
      hasUserPermit: (permit) => hasUserPermit(permit),
      showDeleted: (show) => showDeleted(show),
    },
    dispatch
  );

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(InnerDynamicModel))
);
