import { Dictionary, DictionaryObject } from "../models/types/dictionary";
import { project } from "./../configs/index";
/* eslint-disable no-eval */

export default function preprocessing(fields: any) {
    let temp: Dictionary = [];
    temp = Array.isArray(fields) ? [] : {};
    for (let item in fields) {
        switch (typeof fields[item]) {
            case "string":
                switch (true) {
                    case fields[item].startsWith("fn:"):
                        (temp as DictionaryObject)[item] = getFunction(fields[item])
                        break;
                    default:
                        (temp as DictionaryObject)[item] = fields[item];
                        break;
                }
                break;
            case "object":
                (temp as DictionaryObject)[item] = preprocessing(fields[item]);
                break;
            default:
                (temp as DictionaryObject)[item] = fields[item];
                break;

        }
    }
    return temp
}

function getFunction(processedString: string) {
    let processedArray = processedString.split(":");
    let fileName = processedArray[1];
    let functionName = processedArray[2];


    switch (fileName) {
        case "indicators":
            if ((project.indicators as { [k: string]: CallableFunction })[functionName])
                return eval((project.indicators as any)[functionName]);
                break;
        case "defaults":
            if ((project.defaults as { [k: string]: CallableFunction })[functionName])
                return eval((project.defaults as any)[functionName]);
                break;
        case "filters":
            if ((project.filters as { [k: string]: CallableFunction })[functionName])
                return eval((project.filters as any)[functionName]);
                break;
        case "triggerSave":
            if ((project.triggerSave as { [k: string]: CallableFunction })[functionName])
                return eval((project.triggerSave as any)[functionName]);
                break;
        case "stopEdit":
            if ((project.stopEdit as { [k: string]: CallableFunction })[functionName])
                return eval((project.stopEdit as any)[functionName]);
                break;
        case "calcView":
            if ((project.calcView as { [k: string]: CallableFunction })[functionName])
                return eval((project.calcView as any)[functionName]);
                break;
        case "customFilter":
            if ((project.customFilter as { [k: string]: CallableFunction })[functionName])
                return eval((project.customFilter as any)[functionName]);
                break;
        default:
            return error(functionName)
    }
}


function error(functionName: string) {
    return () => console.error(`Can't find ${functionName} function`)
}