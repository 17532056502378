import { IChange } from "./changes-history";
import { IViewModel } from "../view-model";
import { DictionaryObject } from "../types/dictionary";
import { TFunction } from "i18next";
import React from "react";
import {
  IChangesDetails,
  readInfoWithId,
  renderFields,
} from "./changes-factory";
import { IFieldChange, IFieldRead } from "./field-change";

export class ChangesRestoreDeleted implements IChangesDetails {
  info: IFieldRead[] = [];
  changes: IFieldChange[] = [];

  constructor(change: IChange, model?: IViewModel) {
    this.getDetails();
    this.info = readInfoWithId(change.before as DictionaryObject, model);
  }

  getDetails(): void {
    this.changes = [];
  }

  renderInfo(t: TFunction): React.ReactNode {
    return renderFields(this.info, t);
  }

  renderChanges(): React.ReactNode {
    return null;
  }

  getShortDescription(t: TFunction): React.ReactNode {
    return <span>{t("ChangesShortDesc.MarkDeleted")}</span>;
  }
}
