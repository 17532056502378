import { Button, Intent } from "@blueprintjs/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { reduxForm } from "redux-form";
import { constructInitialValues } from "../../../../helpers/default-values";
import { dynamicConstraints } from "../../../../helpers/dynamic-constraints";
import { IConstraint } from "../../../../models/constraint";
import { ConstraintType } from "../../../../models/enums/constraint-type";
import { Views } from "../../../../models/enums/views";
import { FormField } from "../../../../models/fields/form-field";
import { ISequenceState } from "../../../../models/sequence-state";
import { Dictionary, DictionaryObject, Selectable } from "../../../../models/types/dictionary";
import { IViewField } from "../../../../models/view-field";
import { IViewModel } from "../../../../models/view-model";
import { clearItems, saveDefaultsConditions, setFilter } from "../../../../redux/actions/entitiesActions";
import { showError } from "../../../../redux/actions/errorsActions";
import { setActiveModel } from "../../../../redux/actions/modelsActions";
import { IAppState } from "../../../../redux/states/state";
import SimpleSelect from "../../forms/controls/simple-select/SimpleSelect";
import CustomFormField from "../../forms/fields/form-types/FormTypes";
import ReferenceField from "../../forms/fields/reference-field/ReferenceField";
import "./ByDV.scss";

const subreports = [
    {
        name: "ByDVMain",
        defaultConditions: {
            rule: "And",
            nodes: [
                {
                    rule: "And",
                    constraints: [
                        {
                            type: "Eq",
                            field: "AnalysisArea.Code",
                            value: "Microbio"
                        }
                    ]
                }
            ]
        },
        viewConstraints: [
            {
                field: "AnalysisAreas.Code",
                value: "Microbio",
                type: ConstraintType.Eq
            }
        ]
    },
    {
        name: "ByDVResult",
        defaultConditions: {
            rule: "And",
            nodes: [
                {
                    rule: "And",
                    constraints: [
                        {
                            type: "Eq",
                            field: "AnalysisArea.Code",
                            value: "PhysChem"
                        }
                    ]
                }
            ]
        },
        viewConstraints: [
            {
                field: "AnalysisAreas.Code",
                value: "PhysChem",
                type: ConstraintType.Eq
            }
        ]
    },
    {
        name: "ByDVEnter",
        defaultConditions: {
            rule: "And",
            nodes: [
                {
                    rule: "And",
                    constraints: [
                        {
                            type: "Eq",
                            field: "AnalysisArea.Code",
                            value: "Microbio"
                        }
                    ]
                }
            ]
        },
        viewConstraints: [
            {
                field: "AnalysisAreas.Code",
                value: "Microbio",
                type: ConstraintType.Eq
            }
        ]
    },
    {
        name: "ByDVMicro",
        defaultConditions: {
            rule: "And",
            nodes: [
                {
                    rule: "And",
                    constraints: [
                        {
                            type: "Eq",
                            field: "AnalysisArea.Code",
                            value: "Microbio"
                        }
                    ]
                }
            ]
        },
        viewConstraints: [
            {
                field: "AnalysisAreas.Code",
                value: "Microbio",
                type: ConstraintType.Eq
            }
        ]
    }
] as {name: string, defaultConditions: DictionaryObject, viewConstraints: IConstraint[]}[];

interface Props {
    model: IViewModel;
    setCanExport: CallableFunction;
}

function ByDVFilter(props: {
    form: string;
    handleSubmit: any;
    initialize: any;
} & Props) {
    const dispatch = useDispatch();
    const { initialize, handleSubmit } = props;
    const { t } = useTranslation();
    const { model } = props;
    const [selectedReport, selectReport] = useState(subreports[0]);
    const currentEmployee = useSelector((state: IAppState) => state.authorizationReducer.currentEmployee);
    
    const url = "/reports/by-dv-report";

    if (!model) {
        return <span>Loading...</span>;
    }

    
    const customHandleSubmit = (values: any) => {
        if (!model.validateReport || model.validateReport(values, props.setCanExport)) {
            dispatch(setFilter(values));
        } else {
            dispatch(showError("ERROR.ValidateReportOptions"));
        }
    };

    const customHandleReset = () => {
        props.initialize(
            constructInitialValues(
                model.fields,
                Views.FILTER,
                new Map<string, ISequenceState>(),
                null
            )
        );
        props.setCanExport(false);
        dispatch(clearItems());
    };

    const beforeSumbit = (e: any, grouped: boolean) => {
        e.preventDefault();
        handleSubmit(customHandleSubmit)();
    };


    const handleKeyDown = (e: any, cb?: any) => {
        if (e.key === "Enter" && e.shiftKey === false) {
            e.preventDefault();
        }
    };

    const groupedReport = (e: any) => {
        dispatch(setActiveModel(selectedReport.name + 'Group', selectedReport.name + 'Group', false, false, url));
        beforeSumbit(e, true);
    }

    const ordinaryReport = (e: any) => {
        dispatch(setActiveModel(selectedReport.name, selectedReport.name, false, false, url));
        beforeSumbit(e, true);
    }

    const renderSubreports = () => {
        return (<SimpleSelect
            items={subreports}
            renderView={(value: Selectable) =>
                value ? t((value as DictionaryObject)["name"] as string) : ""
            } 
            component={
                <Button
                    rightIcon="caret-down"
                    text={selectedReport ? t(selectedReport.name as string) : ""}
                />
            }
            onChange={(e) => {
                selectReport(e);
                dispatch(saveDefaultsConditions(
                    dynamicConstraints(currentEmployee, e.defaultConditions, model && model.yearFilter ? new Date().getFullYear() : undefined)
                ));
            }}
        />);
    }

    const renderEmployeeSelect = () => {
        const employeeField = model.filterReport.find((f: IViewField) => f.name === "SelectEmployee");
        if(!employeeField) return <></>;
        const reference = new FormField(
            {...employeeField, viewConstraints: selectedReport.viewConstraints},
            employeeField.name,
            null,
            Views.FILTER,
            true,
            false,
            props.form,
            false,
            false,
            new Map(),
            (value) => {
            },
            false
          );
          return <ReferenceField {...reference} />
    }

   
    if (!model) {
        return null;
    }
    return (
        <div className="header-filter">
            <form
                onSubmit={handleSubmit(customHandleSubmit)}
                autoComplete="off"
                onKeyDown={(e) => {
                    handleKeyDown(e);
                }}
                onReset={handleSubmit(customHandleReset)}
            >
                <div className="filters by-dv__filters">
                    {model.filterReport
                        .filter((f: IViewField) => f.name !== "SelectEmployee")
                        .map((f: IViewField) => {
                            return (
                                <div key={f.name}>
                                    <CustomFormField
                                        {...new FormField(
                                            { ...f, readonly: false },
                                            f.name,
                                            null,
                                            Views.FILTER,
                                            true,
                                            false,
                                            props.form,
                                            false,
                                            false,
                                            new Map<string, IConstraint>(),
                                            (e: Dictionary) => { },
                                            f.reportSearchEmpty
                                        )}
                                    />
                                </div>
                            );
                    })}
                    <div className="by-dv__select">
                        <div className="by-dv__select-report">{renderSubreports()}</div>
                        {renderEmployeeSelect()}
                    </div>
                </div>
                <div className="action-button by-dv__buttons">
                    <Button icon="small-tick" text={t("UI.OK")} onClick={(e: any) => ordinaryReport(e)}/>
                    <div className="by-dv__select-report">
                        <div className="by-dv__grouping">
                            <span>{t('UI.Grouping')}</span>
                            <Button icon="small-tick" intent={Intent.NONE} onClick={(e: any) => groupedReport(e)}>{t('OK')}</Button>
                        </div>
                    </div>
                    <Button type="reset" icon="small-cross" text={t("UI.Clear")} />
                </div>
            </form>
        </div>
    )
}


const ByDVForm = reduxForm<{}, Props>({})(ByDVFilter);

export default ByDVForm;
