
import moment from "moment";

export function IncomingDocumentReminder(record) {
    if(!record['ExecutionPeriod']) return false;
    const createdDate = moment(record['IncomingDocumentDate']);
    const difference = moment().endOf('day').diff(moment(createdDate), 'days');
    const period = (+(record['ExecutionPeriod']) || 0);
    return (period - difference) <= 2 && (period - difference) > 0;
} 

export function IncomingDocumentDelayed(record) {
    if(!record['ExecutionPeriod']) return false;
    const createdDate = moment(record['IncomingDocumentDate']);
    const difference = moment().endOf('day').diff(moment(createdDate), 'days');
    const period = (+(record['ExecutionPeriod']) || 0);
    return (period - difference) <= 0; 
} 

export function ContractPeriodSoon(record) {
    const createdDate = record['ContractEnd'];
    const newContract = record['NewContractEnd'];
    let difference;

    if(record['NewContractEnd']) {
        difference = moment().endOf('day').diff(moment(newContract).endOf('day'), 'days');
    } else {
        difference = moment().endOf('day').diff(moment(createdDate).endOf('day'), 'days');
    }
    return (difference) >= -2 && (difference) <= 0; 
} 

export function testfunc(record) {
    return false;
} 

export default {
    IncomingDocumentReminder,
    IncomingDocumentDelayed,
    testfunc,
    ContractPeriodSoon
}
  



