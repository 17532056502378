import { Button, Icon, InputGroup, Intent, Label, Tag } from "@blueprintjs/core";
import { Col, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FieldArrayFieldsProps } from "redux-form/lib/FieldArray";
import { IConstraint } from "../../../../../models/constraint";
import { IViewField } from "../../../../../models/view-field";
import "./MultipleTextForm.scss";

interface Props {
  f: IViewField;
  selectedItem: any;
  fields: FieldArrayFieldsProps<any>;
  constraints?: IConstraint[];
  formName: string;
}

export const MultipleTextForm = (props: Props) => {
  const { f, fields } = props;
  const [selectedText, setSelectedText] = useState<string>("");
  const { t } = useTranslation();

  const onAdd = () => {
    fields.push(selectedText);
    setSelectedText("");
  };

  const onChange = (text: string) => {
    setSelectedText(text);
  };

  const clearText = () => {
    setSelectedText("");
  };

  return (
    <Row>
      <Row className="array-field__header">
        <Col span={24}>
          <Label>
            <span>{t(f.name)}</span>
            <span className="required">{f.required ? "*" : ""}</span>
          </Label>
        </Col>
      </Row>
      <Row className="array-field" gutter={16} key={f.name}>
        <Col className="array-field__form" span={12}>
          <Row>
            <Col span={16}>
              <div key={f.name}>
                <InputGroup
                  value={selectedText}
                  onChange={(e: any) => onChange(e.target.value)}
                  type={"text"}
                  disabled={!!f.readonly || (f.writable === false)}
                  placeholder={t("")}
                  rightElement={
                    selectedText ? (
                      <Icon
                        className="clear-btn"
                        icon="small-cross"
                        onClick={() => clearText()}
                      />
                    ) : (
                        <span />
                      )
                  }
                  fill
                  autoComplete="off"
                  onFocus={(event: any) => {
                    event.target.setAttribute("autocomplete", "off");
                  }}
                />
              </div>
            </Col>
            <Col span={8}>
              <div className="row button-row">
                <Button icon="plus" small onClick={() => onAdd()} />
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <div className="block-result">
            {fields
              ? fields.map((field: any, index: number) => {
                const item = fields.get(index);
                if (item.default) {
                  return null;
                }

                const onRemove = (index: number) => {
                  fields.remove(index);
                };
                return (
                  <Tag
                    key={index}
                    intent={Intent.NONE}
                    multiline
                    onRemove={() => onRemove(index)}
                  >
                    {item}
                  </Tag>
                );
              })
              : null}
          </div>
        </Col>
      </Row>
    </Row>
  );
};

export default MultipleTextForm;
