import { push } from "connected-react-router";

export const NAVIGATE_TO = "NAVIGATE_TO";
export const LOAD_ROUTES = "LOAD_ROUTES";

export function goToRoute(route: string) {
  return push("/" + route);
}

export function navigateTo(route: string) {
  return {
    type: NAVIGATE_TO,
    payload: route,
  };
}

export function loadRoutes(routes: any[]) {
  return {
    type: LOAD_ROUTES,
    payload: routes,
  };
}
