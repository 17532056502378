import { FormGroup, Label } from "@blueprintjs/core";
import { TimePicker } from "@blueprintjs/datetime";
import { Col, Row } from "antd";
import Moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Field, WrappedFieldInputProps } from "redux-form";
import { WrappedFieldProps } from "redux-form/lib/Field";
import { Views } from "../../../../../models/enums/views";
import { IViewField } from "../../../../../models/view-field";
import "./TimeField.scss";
import ValidMessages from "../../valid-messages/ValidMessages";
import { mandatory } from "../../../../../validation/validate";
import { renderEmptyField } from "../../controls/empty-btn/EmptyBtn";
import { IFormField } from "../../../../../models/fields/form-field";

export const renderTimeField = ({
  input,
  meta: { touched, invalid, error },
  classType,
  disabled,
}: WrappedFieldProps & {
  input: WrappedFieldInputProps;
  meta: any;
  field: IViewField;
  placeholder: string;
  startDate: string;
  endDate: string;
  disabled: boolean;
  classType: string;
  maxDate: Date;
}) => {
  return (
    <div className="row-input">
      <label>
        <TimePicker
          disabled={!!disabled}
          showArrowButtons={true}
          onChange={(e) => {
            input.onChange(parseTimeToString(e));
          }}
          value={formatStringToDate(input.value)}
        />
        {touched && error && <ValidMessages text={error} />}
      </label>
    </div>
  );
};

export const parseTimeToString = (date: Date) => {
  if (date) {
    const moment = Moment(date);
    return moment.format("HH:mm") + ":00";
  }
  return null;
};

export const formatStringToDate = (value: string) => {
  const timeArray = value !== "" ? value.split(":").map((el) => parseInt(el)) : [0, 0];
  const DateTime = new Date(2000, 1, 1, timeArray[0], timeArray[1]);
  return typeof value === "string" ? DateTime : value;
};

export const TimeField = (props: IFormField) => {
  const { t } = useTranslation();
  const { f, fieldName, view, showEmpty, showLabel, isDraft } = props;
  const TimeFilterView = () => {
    return (
      <Row className="time-field" key={f.name}>
        <FormGroup>
          <Label htmlFor={fieldName}>{t(f.name)}</Label>
          <div className="row">
            <Field
              name={fieldName + ".startTime"}
              type="text"
              field={f}
              classType={"time-range"}
              component={renderTimeField}
            />
            <Field
              name={fieldName + ".endTime"}
              type="text"
              // onChange={(value: any) => changeEndDate(value)}
              classType={"time-range"}
              field={f}
              component={renderTimeField}
            />
            {!f.noSearchEmpty && showEmpty && (
              <Field
                name={fieldName + "__EMPTY"}
                type="checkbox"
                onClick={() => {
                }}
                onChange={(e: any) => { }}
                field={f}
                view={view}
                component={renderEmptyField}
              />
            )}
          </div>
        </FormGroup>
      </Row>
    );
  };

  const TimeFormView = () => {
    return (
      <Row className="time-field time-field_simple" key={f.name}>
        {showLabel && (
          <Col span={8}>
            <Label htmlFor={fieldName}>
              <span>{t(f.name)}</span>
              <span className="required">{f.required ? "*" : ""}</span>
            </Label>
          </Col>
        )}
        <Col span={16}>
          <Field
            name={fieldName}
            type="text"
            disabled={f.name.toLowerCase() === "created" || f.readonly || (f.writable === false)}
            field={f}
            component={renderTimeField}
            validate={f.required && !isDraft ? mandatory : undefined}
          />
        </Col>
      </Row>
    );
  };

  switch (view) {
    case Views.FILTER:
      return TimeFilterView();
    case Views.FORM:
      return TimeFormView();
    case Views.REFERENCE:
      return <span>Text</span>;
    default:
      return <span>Text</span>;
  }
};

export default TimeField;
