import React, { ReactElement } from "react";
import { ReferenceView } from "../../components/core/lists/reference-view/ReferenceView";
import { ChangeAction } from "../changes/field-change";
import { IConstraint } from "../constraint";
import { Dictionary, DictionaryObject } from "../types/dictionary";
import { IViewField } from "../view-field";
import { FieldFactory } from "./field-factory";
import { GeneralField, IGeneralField } from "./general-field";

export class EntityField extends GeneralField {
  compareFunction(before: Dictionary, after: Dictionary): ChangeAction {
    return (before as DictionaryObject) &&
      (after as DictionaryObject) &&
      (before as DictionaryObject)["id"] === (after as DictionaryObject)["id"]
      ? ChangeAction.Unchanged
      : ChangeAction.Updated;
  }

  viewRender(value: Dictionary): ReactElement {
    return value ? (
      <ReferenceView field={this.viewField} viewArray={this.viewField.referenceView || []} item={value as DictionaryObject} />
    ) : (
      <span></span>
    );
  }

  stringRender(value: Dictionary, viewFields?: IViewField[]): string {
    if (!viewFields) {
      return "";
    }
    return value
      ? viewFields
          .map((f: IViewField, indexF: number) => {
            const fieldFactory = new FieldFactory(f);
            const newField: IGeneralField = fieldFactory.createField();
            const delimiter = indexF > 0 ? " - " : "";
            return (
              delimiter +
              newField.stringRender((value as DictionaryObject)[f.name])
            );
          })
          .join(" ")
      : "";
  }

  tagRender(value: Dictionary, onRemove: (id: number) => void): ReactElement {
    return this.viewRender(value);
  }

  emptyConstraint(fieldName: string): IConstraint {
    return {
      type: "Null",
      field: fieldName + ".id",
    } as IConstraint;
  }

  valueConstraint(fieldName: string, value: Dictionary) {
    if (
      !value ||
      typeof value !== "object" ||
      !(value as DictionaryObject)["id"]
    )
      return [];

    return [
      {
        type: "Eq",
        field: fieldName + ".id",
        value: value,
      } as IConstraint,
    ];
  }

  isEmpty(value: Dictionary) {
    return !value;
  }
}
