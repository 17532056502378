import React, { Fragment, useState } from "react";
import "./PasswordInput.scss";
import { Button, InputGroup, Intent, Tooltip } from "@blueprintjs/core";
import { WrappedFieldProps } from "redux-form";
import ValidMessages from "../../valid-messages/ValidMessages";

export const renderPasswordField = (
  props: WrappedFieldProps & { disabled: boolean }
) => (
  <Fragment>
    <PasswordInput {...props} />
    {props.meta.touched && props.meta.error && (
      <ValidMessages text={props.meta.error} />
    )}
  </Fragment>
);

export const PasswordInput = (
  props: WrappedFieldProps & { disabled: boolean }
) => {
  const { input, disabled } = props;
  const { error, touched } = props.meta;
  const [showPassword, setShowPassword] = useState(false);

  return (
    <InputGroup
      {...input}
      disabled={disabled}
      type={showPassword ? "text" : "password"}
      rightElement={
        <Tooltip content={`${showPassword ? "Hide" : "Show"} Password`}>
          <Button
            icon={showPassword ? "unlock" : "lock"}
            intent={Intent.WARNING}
            minimal
            onClick={() => setShowPassword(!showPassword)}
          />
        </Tooltip>
      }
      fill
      className={
        error && touched ? "bp3-intent-danger text-input" : "text-input"
      }
    />
  );
};

export default PasswordInput;
