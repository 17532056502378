import { Button, ButtonGroup } from "@blueprintjs/core";
import { Pagination, Select } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getJoinedConditions } from "../../../../helpers/dynamic-constraints";
import {
  filterContraintToTree,
  filterToConstraint,
} from "../../../../helpers/filter-to-constraint";
import { ConstraintType } from "../../../../models/enums/constraint-type";
import { IPagingOptions } from "../../../../models/paging-options";
import { hasUserPermit } from "../../../../redux/actions/authorizationActions";
import {
  getEntities2,
  selectItem,
} from "../../../../redux/actions/entitiesActions";
import { printFormV2 } from "../../../../redux/actions/templatesActions";
import { IAppState } from "../../../../redux/states/state";
import ElementCount from "../../../layout/ElementCount/ElementCount";
import { defaultPagingOptions, elementsPerPage } from "../default-paging";
import ListCount from "../list-count/ListCount";
import "./ListFooter.scss";

export const ListFooter = (props: {
  updatePagingOps: (pagingOptiongs: IPagingOptions) => void;
  selectPrev: () => void;
  selectNext: () => void;
  openForm: (open: boolean) => void;
  createNew: boolean;
  canExport: boolean;
}) => {
  const { t } = useTranslation();
  const {
    openForm,
    createNew,
    canExport,
    selectPrev,
    selectNext,
    updatePagingOps,
  } = props;
  const dispatch = useDispatch();
  const archived = useSelector((state: any) => state.modelsReducer.archived);
  const totalCount = useSelector(
    (state: any) => state.entitiesReducer.totalCount
  );
  const activeModel = useSelector(
    (state: any) => state.modelsReducer.activeModel
  );
  const dbname = useSelector((state: any) => state.modelsReducer.dbname);
  const sortingOptions = useSelector(
    (state: IAppState) => state.entitiesReducer.sortingOptions
  );
  const activeAllowCreate = useSelector(
    (state: any) => state.modelsReducer.activeAllowCreate
  );
  const entitiesReducer = useSelector(
    (state: IAppState) => state.entitiesReducer
  );

  const pagingOptions = useSelector(
    (state: IAppState) => state.entitiesReducer.pagingOptions
  );

  const model = useSelector((state: IAppState) =>
    state.modelsReducer.models.get(activeModel)
  );
  const models = useSelector((state: IAppState) => state.modelsReducer.models);
  const filter = useSelector(
    (state: IAppState) => state.entitiesReducer.filter
  );

  useEffect(() => {
    return () => {
      updatePagingOps({ ...pagingOptions, pageSize: defaultPagingOptions.pageSize });
    };
  }, [dispatch]);

  if (!model) return <></>;

  return (
    <div className={`footer`}>
      {createNew &&
        !archived &&
        activeAllowCreate &&
        model.permits &&
        (!model.permits.create ||
          !!dispatch(hasUserPermit(model.permits.create))) ? (
        <Button
          className={`footer_first`}
          text={t("UI.CreateNew")}
          onClick={(e: any) => {
            // getWeather(10, 12);
            openForm(true);
            dispatch(selectItem(null));
          }}
        />
      ) : null}
      {model.exportToExcel && 
        model.exportToExcel.name != "DVReyestr" && model.exportToExcel.name != "DVFarmReyestr" &&
        (!model.exportToExcel.permit ||
          !!dispatch(hasUserPermit(model.exportToExcel.permit))) ? (
        <Button
          className={`footer_second`}
          icon="export"
          disabled={!canExport}
          text={"Excel"}
          onClick={() => {
            const filterConstraint = filterToConstraint(
              filter,
              "",
              models,
              activeModel
            );
            const tree = filterContraintToTree(filterConstraint);
            dispatch(
              printFormV2(
                (model.exportToExcel || {}).name || "",
                model.dbname,
                getJoinedConditions(entitiesReducer, tree),
                t(model.name),
                false,
                "preview"
              )
            );
          }}
        />
      ) : null}
      {model.exportToExcel &&
        model.exportToExcel.name === "DVReyestr" &&
        !!dispatch(hasUserPermit("DRR")) ? (
        <Button
          className={`footer_second`}
          icon="export"
          text={"Reestr"}
          onClick={() => {
            const filterConstraint = filterToConstraint(
              filter,
              "",
              models,
              activeModel
            );
            const tree = filterContraintToTree([...filterConstraint, ...[{type: ConstraintType.Eq, field: "deleted", value: false}]]);
            dispatch(
              printFormV2(
                "DVReyestr",
                "DVReyestrReport",
                getJoinedConditions(entitiesReducer, tree),
                t(model.name),
                false,
                "preview"
              )
            );
          }}
        />
      ) : null}
       {model.exportToExcel &&
        model.exportToExcel.name === "DVFarmReyestr" &&
        !!dispatch(hasUserPermit("DRR")) ? (
        <Button
          className={`footer_second`}
          icon="export"
          text={"Farm Reestr"}
          onClick={() => {
            const filterConstraint = filterToConstraint(
              filter,
              "",
              models,
              activeModel
            );
            const tree = filterContraintToTree([...filterConstraint, ...[{type: ConstraintType.Eq, field: "deleted", value: false}]]);
            dispatch(
              printFormV2(
                "DVFarmReyestr",
                "DVFarmReport",
                getJoinedConditions(entitiesReducer, tree),
                t(model.name),
                false,
                "preview"
              )
            );
          }}
        />
      ) : null}
      {model &&
        model.dbname === "IncomingDocument" &&
        !!dispatch(hasUserPermit("ISH")) ? (
        <Button
          className={`footer_second`}
          icon="export"
          text={"Hesabat"}
          onClick={() => {
            const filterConstraint = filterToConstraint(
              filter,
              "",
              models,
              activeModel
            );
            const tree = filterContraintToTree(filterConstraint);
            dispatch(
              printFormV2(
                "IdxalHesabat",
                model.dbname,
                getJoinedConditions(entitiesReducer, tree),
                t(model.name),
                false,
                "preview"
              )
            );
          }}
        />
      ) : null}
      <ButtonGroup className={`footer_group`}>
        {!model.noPagination && <Pagination
          className="footer-pagination"
          total={totalCount}
          defaultPageSize={pagingOptions.pageSize}
          showLessItems={true}
          showSizeChanger={false}
          current={pagingOptions.page + 1}
          pageSize={pagingOptions.pageSize}
          onChange={(e: any) => {
            updatePagingOps({ ...pagingOptions, page: e - 1 });
            dispatch(getEntities2(dbname, { ...pagingOptions, page: e - 1 }, sortingOptions));
          }}
        />}
        {/*<Button icon="step-backward" />*/}
        <Button className="navigation-arrow" icon="chevron-up" onClick={selectPrev} />
        <Button className="navigation-arrow" icon="chevron-down" onClick={selectNext} />
        {/*<Button icon="step-forward"/>*/}
        {!model.noPagination && <Select
          value={pagingOptions.pageSize}
          className={"count-select"}
          onChange={(value) => {
            updatePagingOps({ ...pagingOptions, pageSize: value });
            dispatch(
              getEntities2(dbname, { ...pagingOptions, pageSize: value }, sortingOptions)
            );
          }}
        >
          {elementsPerPage.map((count) => {
            return renderOption(count);
          })}
        </Select>}
        <div>
          <ListCount totalCount={totalCount} />
          <ElementCount />
        </div>
      </ButtonGroup>
    </div>
  );
};

const renderOption = (value: number) => {
  const { Option } = Select;
  return (
    <Option key={value} value={value}>
      {value}
    </Option>
  );
};

export default ListFooter;
