import { default as moment } from "moment";
import React, { ReactElement } from "react";
import { ChangeAction } from "../changes/field-change";
import { IConstraint } from "../constraint";
import { Dictionary, DictionaryObject } from "../types/dictionary";
import { GeneralField } from "./general-field";

export class DateField extends GeneralField {
  compareFunction(before: Dictionary, after: Dictionary): ChangeAction {
    return moment(before as string).format("DD.MM.YYYY") ===
      moment(after as string).format("DD.MM.YYYY")
      ? ChangeAction.Unchanged
      : ChangeAction.Updated;
  }

  viewRender(value: Dictionary): ReactElement {
    return (
      <span>{value ? moment(value as string).format("DD.MM.YYYY") : ""}</span>
    );
  }

  stringRender(value: Dictionary): string {
    return value ? moment(value as string).format("DD.MM.YYYY") : "";
  }

  tagRender(value: Dictionary, onRemove: (id: number) => void): ReactElement {
    return this.viewRender(value);
  }

  emptyConstraint(fieldName: string): IConstraint {
    return {
      type: "Null",
      field: fieldName,
    } as IConstraint;
  }

  valueConstraint(fieldName: string, value: Dictionary) {
    if (!value || typeof value !== "object") return [];

    const valueObject = value as DictionaryObject;
    const constraints = [];

    if (
      valueObject.hasOwnProperty("startDate") ||
      valueObject.hasOwnProperty("endDate") || 
      valueObject.hasOwnProperty("oneDate")
    ) {
      const startDate = valueObject.startDate as string;
      const endDate = valueObject.endDate as string;
      const oneDate = valueObject.oneDate as string;

      if (startDate) {
        constraints.push({
          type: "Gte",
          field: fieldName,
          value: startDate,
        } as IConstraint);
      }
      if (endDate) {
        constraints.push({
          type: "Lte",
          field: fieldName,
          value: endDate,
        } as IConstraint);
      }
      if (oneDate) {
        constraints.push({
          type: "Lte",
          field: fieldName,
          value: moment(oneDate).endOf('day').utc().format(),
        } as IConstraint);
        constraints.push({
          type: "Gte",
          field: fieldName,
          value: moment(oneDate).startOf('day').utc().format(),
        } as IConstraint);
      }
    }
    return constraints;
  }

  isEmpty(value: Dictionary) {
    return !value;
  }
}
