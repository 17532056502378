import { createReducer } from "typesafe-actions";

import { ActionsType } from "./../store";

export interface WeatherState {
  weather?: any;
}

export const initialState = {};

export const newUserReducer = createReducer<WeatherState, ActionsType>(
  initialState
);
// .handleAction(actions.newUsersActions.weatherSetAction, (state, action) => ({
//   ...state,
//   weather: {},
// }))
// .handleAction(actions.newUsersActions.weatherErrorAction, (state, action) => {
//   console.error(action.payload);
//   return state;
// });
