export enum ConstraintType {
  MinLength,
  MaxLength,
  Gt,
  Lt,
  Gte,
  Lte,
  Eq,
  Neq,
  NotNull,
  Null,
  In,
  Like,
  NullOrEmpty,
  Disable = 25,
}
