import React, { useEffect, useState } from "react";
import { Alignment, Button, ButtonGroup, Checkbox } from "@blueprintjs/core";
import "./SelectedReference.scss";
import { Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import BaseTable from "../../core/lists/base-table/BaseTable";
import CommonFilter from "../../core/filters/common-filter/CommonFilter";
import { ModelType } from "../../../models/enums/model-type";
import {
  getReference,
  referenceFormIsOpen,
  selectReferenceItem,
  setFilterReference,
} from "../../../redux/actions/referencesActions";
import GenericForms from "../../core/forms/form-modal/generic-form/GenericForms";
import { IConstraint } from "../../../models/constraint";
import ListCount from "../../core/lists/list-count/ListCount";
import { IViewModel } from "../../../models/view-model";
import { IAppState } from "../../../redux/states/state";
import { defaultPagingOptions } from "./defaultPagingOptions";
import { IPagingOptions } from "../../../models/paging-options";
import { IDefaultSorting } from "../../../models/default-sorting";
import { hasUserPermit } from "../../../redux/actions/authorizationActions";
import { printFormV2 } from "../../../redux/actions/templatesActions";

interface Props {
  modelName: string;
}

const SelectedReference = (props: Props) => {
  const { modelName } = props;
  const model: IViewModel | undefined = useSelector((state: any) =>
    state.modelsReducer.models.get(modelName)
  );
  const reference = useSelector((state: any) =>
    state.referencesReducer.references.get(modelName)
  );
  const totalCount = useSelector(
    (state: IAppState) => state.referencesReducer.totalCount
  );
  const selectedItem = useSelector(
    (state: IAppState) => state.referencesReducer.selectedItem
  );
  const filterReference = useSelector(
    (state: IAppState) => state.referencesReducer.filterReference
  );
  const loadingSearch = useSelector(
    (state: IAppState) => state.referencesReducer.loadingSearch
  );

  const [isOpen, setOpen] = useState(false);
  const [pagingOptions, setPagingOptions] = useState(defaultPagingOptions);
  const referenceName = model ? model.dbname : modelName;
  const defaultConstraints =
    model && model.defaultConstraints ? model.defaultConstraints : [];

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(setFilterReference([]));
    dispatch(
      getReference(
        modelName,
        referenceName,
        defaultConstraints,
        defaultPagingOptions
      )
    );

    return () => {
      dispatch(selectReferenceItem(null));
    };
  }, [referenceName, dispatch]);

  if (!model) {
    return <span>Not a model...</span>;
  }

  const data = reference ? reference : [];

  const updateCallback = () => {
    setOpen(false);
    dispatch(
      getReference(
        modelName,
        referenceName,
        [...defaultConstraints, ...filterReference],
        pagingOptions
      )
    );
    dispatch(referenceFormIsOpen(modelName, false, null, () => null));
  };

  const onClose = () => {
    setOpen(false);
  };

  const onClear = () => {
    dispatch(setFilterReference([]));
    setPagingOptions({
      ...pagingOptions,
      page: 0,
    });
    dispatch(
      getReference(modelName, referenceName, defaultConstraints, {
        ...pagingOptions,
        page: 0,
      })
    );
  };

  const onSearch = (constraints: IConstraint[]) => {
    dispatch(setFilterReference(constraints));
    setPagingOptions({
      ...pagingOptions,
      page: 0,
    });
    dispatch(
      getReference(
        modelName,
        referenceName,
        [...defaultConstraints, ...constraints],
        { ...pagingOptions, page: 0 }
      )
    );
  };

  const onChangeShowDeleted = (e: any) => {
    setPagingOptions({
      ...pagingOptions,
      showDeleted: e.currentTarget.checked,
    });
    dispatch(
      getReference(
        modelName,
        referenceName,
        [...defaultConstraints, ...filterReference],
        { ...pagingOptions, showDeleted: e.currentTarget.checked }
      )
    );
  };
  return (
    <div className="distributor">
      <div className="distributor_header">
        <CommonFilter
          modelName={modelName}
          form={modelName + "__SMALL_FILTER"}
          onClear={onClear}
          onSearch={onSearch}
        />

        <div className="distributor_header_showDeleted">
          <Checkbox
            alignIndicator={Alignment.RIGHT}
            label={t("UI.ShowDeleted")}
            onChange={onChangeShowDeleted}
            defaultChecked={false}
            checked={pagingOptions.showDeleted}
          />
        </div>
      </div>
      <div className="distributor_body">
        <BaseTable
          viewPath={"list"}
          loading={loadingSearch}
          allowSelect={false}
          data={data}
          type={ModelType.Dictionary}
          model={modelName}
          selectedItem={selectedItem}
          openForm={setOpen}
          selectItem={(item: any) => dispatch(selectReferenceItem(item))}
          getEntities={(
            modelName: string,
            pagingOptions: IPagingOptions,
            sortingOptions?: IDefaultSorting
          ) =>
            dispatch(
              getReference(
                modelName,
                referenceName,
                undefined,
                pagingOptions,
                sortingOptions
              )
            )
          }
          pagingOptions={pagingOptions}
          footer={() => (
            <div className="footer">
              {model && model.mayCreate && (
                <Button
                  className="footer_first"
                  text={t("UI.CreateNew")}
                  onClick={(e: any) => {
                    setOpen(true);
                    dispatch(selectReferenceItem(null));
                  }}
                />
              )}
              {model.exportToExcel &&
              model.exportToExcel.name != "DVReyestr" &&
              (!model.exportToExcel.permit ||
                !!dispatch(hasUserPermit(model.exportToExcel.permit))) ? (
                <Button
                  className={`footer_second`}
                  icon="export"
                  text={"Excel"}
                  onClick={() => {
                    dispatch(
                      printFormV2(
                        (model.exportToExcel || {}).name || "",
                        (model.exportToExcel || {}).model || model.dbname,
                        {
                          rule: "And",
                          constraints: filterReference,
                          nodes: [],
                        },
                        t(model.name),
                        false,
                        "preview"
                      )
                    );
                  }}
                />
              ) : null}
              <ButtonGroup className="footer_group">
                <Pagination
                  className="footer-pagination"
                  total={totalCount}
                  defaultPageSize={defaultPagingOptions.pageSize}
                  showLessItems={true}
                  showSizeChanger={false}
                  current={pagingOptions.page + 1}
                  onChange={(e: any) => {
                    setPagingOptions({ ...pagingOptions, page: e - 1 });
                    dispatch(
                      getReference(
                        modelName,
                        referenceName,
                        [...defaultConstraints, ...filterReference],
                        { ...pagingOptions, page: e - 1 }
                      )
                    );
                  }}
                />
                <ListCount totalCount={totalCount} />
              </ButtonGroup>
            </div>
          )}
        />
      </div>
      <GenericForms
        isOpen={isOpen}
        selectedItem={selectedItem}
        updateCallback={updateCallback}
        type={ModelType.Dictionary}
        modelName={modelName}
        dbname={referenceName}
        onClose={onClose}
      />
    </div>
  );
};

export default SelectedReference;
