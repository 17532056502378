import { IStateEntities } from "./../redux/states/state-entities";
import { IConstraintTree } from "./../models/constraint";
import { IEmployee } from "../models/employee";
import { IConstraint } from "../models/constraint";

export function dynamicConstraints(
  currentEmployee: IEmployee | null,
  constraintTree: IConstraintTree | undefined,
  year?: number
): IConstraintTree | null {
  if (
    !constraintTree ||
    (!constraintTree.constraints && !constraintTree.nodes)
  ) {
    return null;
  }
  const scopedContraints: IConstraintTree = JSON.parse(
    JSON.stringify(constraintTree)
  );

  processDynamicConstraints(currentEmployee, scopedContraints, year);
  return scopedContraints;
}

function processDynamicConstraints(
  currentEmployee: IEmployee | null,
  constraintTree: IConstraintTree,
  year?: number
) {
  if (
    !constraintTree ||
    (!constraintTree.constraints && !constraintTree.nodes)
  ) {
    return;
  }

  if (constraintTree.exceptions && constraintTree.exceptions.length) {
    let needEscape = false;
    constraintTree.exceptions.forEach((ex: IConstraint) => {
      if (
        currentEmployee &&
        ex.field === "RelatedUsers.id" &&
        ex.value === currentEmployee.id
      ) {
        needEscape = true;
      }
    });
    if (needEscape) {
      constraintTree.constraints = [];
      constraintTree.nodes = [];
    }
  }

  if (constraintTree.constraints) {
    const constraints: IConstraint[] = [];

    constraintTree.constraints.forEach((c: IConstraint) => {
      if (c.field === "RelatedDeps.DepartmentCode" && c.arg === "DepId") {
        if (
          currentEmployee &&
          currentEmployee?.Department &&
          currentEmployee?.Department["DepartmentCode"] &&
          c.exceptions &&
          !c.exceptions.includes(
            currentEmployee?.Department["DepartmentCode"]?.toString()
          )
        ) {
          c.value = currentEmployee.Department["DepartmentCode"];
          c.exceptions = undefined;
          constraints.push(c);
        }
      } else if (c.field === "RelatedUsers.id" && c.arg === "UserId") {
        if (currentEmployee && currentEmployee?.id) {
          c.value = currentEmployee?.id;
          constraints.push(c);
        }
      } else if (c.type === "Gte" && c.arg === "Year" && year) {
        c.value = new Date(year, 0, 1);
        constraints.push(c);
      } else if (c.type === "Lte" && c.arg === "Year" && year) {
        c.value = new Date(year, 11, 31);
        constraints.push(c);
      } else if ((c.type === "Gt" || c.type === "Lt" || c.type === "Gte" || c.type === "Lte") && c.arg === "Today") {
        c.value = new Date();
        constraints.push(c);
      } else {
        constraints.push(c);
      }
    });
    constraintTree.constraints = constraints;
  }

  if (constraintTree.nodes) {
    constraintTree.nodes.forEach((node: IConstraintTree) => {
      processDynamicConstraints(currentEmployee, node, year);
    });
  }
}

export function getJoinedConditions(
  entitiesState: IStateEntities,
  filter: IConstraintTree
) {
  let nodes: IConstraintTree[] = [];

  const {
    defaultConditions,
    mineConditions,
    doneConditions,
    archiveConditions,
  } = entitiesState;

  if (defaultConditions) {
    nodes = [...nodes, defaultConditions];
  }
  if (archiveConditions) {
    nodes = [...nodes, archiveConditions];
  }
  if (mineConditions) {
    nodes = [...nodes, mineConditions];
  }
  if (doneConditions) {
    nodes = [...nodes, doneConditions];
  }
  if (filter) {
    nodes = [...nodes, filter];
  }
  const joinedConstraints: IConstraintTree = {
    rule: "And",
    constraints: [],
    nodes: nodes,
  };
  return joinedConstraints;
}

export function getPrintJoinedConditions(
  entitiesState: IStateEntities,
  filter: IConstraint[]
) {
  let nodes: IConstraint[] = [];

  const { archiveConditions } = entitiesState;

  if (archiveConditions?.constraints) {
    nodes = [...nodes, ...archiveConditions?.constraints];
  }

  return nodes;
}
