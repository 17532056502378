import React from "react";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import { toast } from "../../../redux/actions/errorsActions";
import { ProgressBar } from "@blueprintjs/core";
import "./ProgressBar.scss";

export function showProgressBar() {
  return toast({
    icon: "cloud-upload",
    intent: Intent.NONE,
    key: "progressBar",
    message: (
      <ProgressBar
        className={"progress-bar"}
        intent={Intent.PRIMARY}
        value={1}
      />
    ),
    reactElement: true,
    timeout: 300000,
  });
}
