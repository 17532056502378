import { Icon, Position, Tooltip } from "@blueprintjs/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Indicator, IViewModel } from "../../../models/view-model";
import "./TableLegend.scss";

export default function TableLegend(props: {
    model: IViewModel | undefined
}) {
    const { t } = useTranslation();
    return props.model?.indicators && props.model?.indicators.length > 0 ? <Tooltip
        content={<div className="table-legend">
            {props.model?.indicators.map((indicator: Indicator, index: number) => <p key={index}>
                <div className={"legend-item bg-" + indicator.color} > </div>{indicator.title}</p>)}</div>} position={Position.BOTTOM}>
        <div><Icon icon="grid-view" />{t("UI.Legend")}</div>
    </Tooltip> : null
}