import React, { ReactElement } from "react";
import { ChangeAction } from "../changes/field-change";
import { IConstraint } from "../constraint";
import { Dictionary, DictionaryObject } from "../types/dictionary";
import { GeneralField } from "./general-field";

export class TimeField extends GeneralField {
  compareFunction(before: Dictionary, after: Dictionary): ChangeAction {
    return before === after
      ? ChangeAction.Unchanged
      : ChangeAction.Updated;
  }

  viewRender(value: Dictionary): ReactElement {
    return (
      <span>{value ? value : ""}</span>
    );
  }

  stringRender(value: Dictionary): string {
    return value ? value.toString() : "";
  }

  tagRender(value: Dictionary, onRemove: (id: number) => void): ReactElement {
    return <div></div>;
  }

  emptyConstraint(fieldName: string): IConstraint {
    return {
      type: "Null",
      field: fieldName,
    } as IConstraint;
  }

  valueConstraint(fieldName: string, value: Dictionary) {
    if (!value || typeof value !== "object") return [];
    const valueObject = value as DictionaryObject;
    const constraints = [];

    if (
      valueObject.hasOwnProperty("startTime") ||
      valueObject.hasOwnProperty("endTime")
    ) {
      const startTime = valueObject.startTime as string;
      const endTime = valueObject.endTime as string;

      if (startTime) {
        constraints.push({
          type: "Gte",
          field: fieldName,
          value: startTime,
        } as IConstraint);
      }
      if (endTime) {
        constraints.push({
          type: "Lte",
          field: fieldName,
          value: endTime,
        } as IConstraint);
      }
    }
    return constraints;
  }

  isEmpty(value: Dictionary) {
    return !value;
  }
}
