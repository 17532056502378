import { TFunction } from "i18next";
import React from "react";
import { DictionaryObject } from "../types/dictionary";
import { IViewModel } from "../view-model";
import {
  IChangesDetails,
  readInfoWithId,
  renderFields,
} from "./changes-factory";
import { IChange } from "./changes-history";
import { IFieldChange, IFieldRead } from "./field-change";

export class ChangesRead implements IChangesDetails {
  info: IFieldRead[] = [];
  changes: IFieldChange[] = [];

  constructor(change: IChange, model?: IViewModel) {
    this.info = readInfoWithId(change.before as DictionaryObject, model);
    this.getChanges();
  }

  getChanges(): void {
    this.changes = this.readChanges();
  }

  renderInfo(t: TFunction): React.ReactNode {
    return renderFields(this.info, t);
  }

  readChanges(): IFieldChange[] {
    return [];
  }

  renderChanges(): React.ReactNode {
    return null;
  }

  getShortDescription(t: TFunction): React.ReactNode {
    return <span>{t("ChangesShortDesc.Read")}</span>;
  }
}
