import { Button, Dialog } from "@blueprintjs/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { getFormValues } from "redux-form";
import { transformCalculation } from "../../../../../helpers/transform-calculations";
import { ITransformation } from "../../../../../models/transformation";
import {
    Dictionary,
    DictionaryObject
} from "../../../../../models/types/dictionary";
import { IViewModel } from "../../../../../models/view-model";
import { relateCard } from "../../../../../redux/actions/cardActions";
import { IAppState } from "../../../../../redux/states/state";
import CardSearch from "../card-search/CardSearch";
import { ITransformParams } from "../transform-params";
import "./SearchItem.scss";

const tranformItem = (
    modelFrom: IViewModel | undefined,
    transformation: ITransformation,
    fromItem: Dictionary,
    toItem: Dictionary
) => {
    return transformCalculation(transformation, modelFrom, fromItem, toItem);
};
interface Props extends WithTranslation {
    formName: string;
    models: Map<string, IViewModel>;
    cards: Map<string, DictionaryObject>;
    parentFormValues: any;
    selectedTransformation: ITransformation;
    selectedModel: IViewModel;
    currentModel: IViewModel;
    relateCard: (params: ITransformParams) => void;
    updateCallback?: () => void;
}

interface State {
    isOpenSearch: boolean;
}

class Transformation extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isOpenSearch: false,
        };
    }


    getRelatedCard = (result: Dictionary, existing: boolean): Dictionary => {
        const { parentFormValues, selectedTransformation,
            selectedModel, currentModel, models, cards, updateCallback } = this.props;

        const transformedItem = tranformItem(
            currentModel,
            selectedTransformation,
            result,
            parentFormValues
        );

        if (transformedItem && currentModel && !(transformedItem as DictionaryObject)["state"]) {
            (transformedItem as DictionaryObject)["state"] = currentModel.defaultState;
        }

        const cardModel = models.get(selectedTransformation.card);
        if (!cardModel) {
            return;
        }

        if (selectedTransformation.reverse) {
            this.props.relateCard({
                existing: !!parentFormValues && !!(parentFormValues as DictionaryObject)["id"],
                // result: addCurrentToCard(model.name, selectedItem, cardModel, result),
                result: result,
                selectedItem: transformedItem,
                currentModel: currentModel,
                selectedModel: selectedModel,
                transformation: selectedTransformation,
                cardModel: cardModel, 
                cards: cards,
                showSuccessMessage: this.showSuccessMessage,
                updateCallback: updateCallback,
            });
        } else {
            this.props.relateCard({
                existing: existing,
                result: result,
                // result: addCurrentToCard(model.name, selectedItem, cardModel, result),
                selectedItem: parentFormValues,
                currentModel: currentModel,
                selectedModel: selectedModel,
                transformation: selectedTransformation,
                cardModel: cardModel,
                cards: cards,
                showSuccessMessage: this.showSuccessMessage,
                updateCallback: updateCallback,
            });
        }
        this.setState({ isOpenSearch: false });
        return result;
    };

    showSuccessMessage = (selectedItem: Dictionary): Dictionary => {
        return selectedItem;
    };

    relateExisting = () => {
        this.setState({ isOpenSearch: true });
    };

    render() {
        const { t, selectedTransformation, selectedModel } = this.props;
        const { isOpenSearch } = this.state;

        if (!selectedTransformation || !selectedModel) {
            return <div></div>;
        }

        return (
            <>
                <Button
                    text={t("UI.SearchCard")}
                    onClick={() => this.relateExisting()}
                />
                <Dialog
                    onClose={() => this.setState({ isOpenSearch: false })}
                    title={t(selectedTransformation?.toName || "")}
                    className="card-search-dialog"
                    isOpen={isOpenSearch}
                >
                    {isOpenSearch && (
                        <CardSearch 
                            filter={selectedTransformation?.filter || []}
                            dbname={selectedTransformation?.toName}
                            modelName={selectedTransformation?.toName}
                            getRelatedCard={(result: Dictionary) =>
                                this.getRelatedCard(result, true)
                            }
                        />
                    )}
                </Dialog>
            </>
        );
    }
}

const mapStateToProps = (state: IAppState, initialProps: any) => ({
    models: state.modelsReducer.models,
    parentFormValues: getFormValues(initialProps.formName)(state),
    cards: state.entitiesReducer.cards
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            relateCard: (tranformParams: ITransformParams) =>
                relateCard(tranformParams),
        },
        dispatch
    );

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(Transformation)
);
