import { IChange } from "./changes-history";
import { IViewModel } from "../view-model";
import { Dictionary, DictionaryObject } from "../types/dictionary";
import { TFunction } from "i18next";
import React from "react";
import {
  IChangesDetails,
  readInfoWithId,
  renderFields,
} from "./changes-factory";
import { ChangeAction, IFieldChange, IFieldRead } from "./field-change";
import { Icon } from "@blueprintjs/core";

export class ChangesStatus implements IChangesDetails {
  info: IFieldRead[] = [];
  changes: IFieldChange[] = [];

  constructor(change: IChange, model?: IViewModel) {
    this.getDetails(change, model);
    this.info = readInfoWithId(change.before as DictionaryObject, model);
  }

  getDetails(change: IChange, model?: IViewModel): void {
    this.changes = this.compareJson(
      change.before as DictionaryObject,
      change.after as DictionaryObject,
      model
    );
  }

  compareJson(
    before?: Dictionary,
    after?: Dictionary,
    model?: IViewModel
  ): IFieldChange[] {
    const triples: IFieldChange[] = [];
    if (!before || !after || !model) {
      return triples;
    }
    triples.push({
      fieldName: "state",
      action: ChangeAction.Updated,
      before: (before as DictionaryObject)["state"],
      after: (after as DictionaryObject)["state"],
    });

    return triples;
  }

  renderInfo(t: TFunction): React.ReactNode {
    return renderFields(this.info, t);
  }

  renderChanges(t: TFunction): React.ReactNode {
    return this.changes.map((tr: IFieldChange) => {
      return (
        <span key={tr.fieldName}>
          <b>{t(tr.fieldName)}</b>
          <span style={{ marginLeft: "6px" }}>
            {t("States." + (tr.before as string))}
          </span>
          {tr.action === ChangeAction.Updated ? (
            <Icon style={{ margin: "0 8px" }} icon="arrow-right" />
          ) : null}
          <span>{t("States." + (tr.after as string))}</span>
          <br />
        </span>
      );
    });
  }

  getShortDescription(t: TFunction): React.ReactNode {
    return <span>{t("ChangesShortDesc.UpdateState")}</span>;
  }
}
