import { FormGroup, Icon, Label } from "@blueprintjs/core";
import { Position } from "@blueprintjs/core/lib/esm/common/position";
import { DateInput } from "@blueprintjs/datetime";
import { Col, Row } from "antd";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Field, WrappedFieldInputProps } from "redux-form";
import { WrappedFieldProps } from "redux-form/lib/Field";
import { Views } from "../../../../../models/enums/views";
import { IViewField } from "../../../../../models/view-field";
import "./DateField.scss";
import ValidMessages from "../../valid-messages/ValidMessages";
import { mandatory } from "../../../../../validation/validate";
import { renderEmptyField } from "../../controls/empty-btn/EmptyBtn";
import { IFormField } from "../../../../../models/fields/form-field";
import YearFilter from "../../../filters/fields/year-filter/YearFilter";
import moment from "moment";
/* eslint-disable no-eval */

export const FORMATS = [
  {
    formatDate: (date: Date) => {
      return date == null ? "" : Moment(date).utc().format("DD.MM.YYYY");
    },
    parseDate: (str: string) => {
      return str ? Moment(str, 'DD.MM.YYYY').toDate() : null;
    },
  },
];

export const renderDateField = ({
  input,
  meta: { touched, invalid, error },
  placeholder,
  classType,
  disabled,
  maxDate,
}: WrappedFieldProps & {
  input: WrappedFieldInputProps;
  meta: any;
  field: IViewField;
  placeholder: string;
  startDate: string;
  endDate: string;
  disabled: boolean;
  classType: string;
  maxDate: Date;
}) => {
  return (
    <div className="row-input">
      <label>
        <DateInput
          className={classType ? classType : ""}
          canClearSelection={true}
          highlightCurrentDay={true}
          showActionsBar={true}
          closeOnSelection={true}
          maxDate={maxDate}
          fill
          disabled={!!disabled}
          {...FORMATS[0]}
          onChange={input.onChange}
          placeholder={placeholder}
          value={input.value}
          inputProps={{
            className: error && touched ? "bp3-intent-danger" : "",
            rightElement: <Icon icon="calendar" className="right-btn" />,
          }}
          popoverProps={{ position: Position.BOTTOM }}
          dayPickerProps={{ firstDayOfWeek: 1 }}
        />
        {touched && error && <ValidMessages text={error} />}
      </label>
    </div>
  );
};

export const parseDateToString = (date: Date, end: boolean) => {
  if (date) {
    const moment = Moment(date);
    if (end) {
      return moment.endOf("day").utcOffset(0, true).format('YYYY-MM-DDTHH:mm:ss');
    } else {
      return moment.startOf('day').utcOffset(0, true).format('YYYY-MM-DDTHH:mm:ss');
    }
  }
  return null;
  // return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString() : null;
  // return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
};

export const formatStringToDate = (value: string | Date) => {
  return typeof value === "string" ? new Date(value.includes("Z") ? value : value + "Z") : value;
};

export const DateField = (props: IFormField) => {
  const { t } = useTranslation();
  const { f, fieldName, view, showEmpty, showLabel, isDraft } = props;
  const [datePeriod, setDatePeriod] = useState<any>({});
  const [maxDate] = useState(f.maxDate ? eval(f.maxDate) : undefined);

  // const changeStartDate =  (date: string) => {
  //  setStartDate(date);
  //    if (date) {
  //       setDatePeriod({...datePeriod, startDate: date});
  //    } else {
  //     delete datePeriod.startDate;
  //      setDatePeriod(datePeriod);
  //    }
  // };

  useEffect(() => {
    if (
      datePeriod &&
      Object.keys(datePeriod) &&
      Object.keys(datePeriod).length
    ) {
      if (props.onChange) {
        props.onChange(datePeriod);
      }
    }
  }, [datePeriod]);

  // const changeEndDate =  (date: string) => {
  //   setEndDate(date);
  //     if (date) {
  //        setDatePeriod({...datePeriod, endDate: date});
  //     } else {
  //       delete datePeriod.endDate;
  //        setDatePeriod(datePeriod);
  //     }
  // };

  const DateFilterView = () => {
    return (
      <Row className="date-field" key={f.name}>
        <FormGroup>
          <Label htmlFor={fieldName}>{t(f.name)}</Label>
          <div className="row">
            <Field
              name={fieldName + ".startDate"}
              type="text"
              format={formatStringToDate}
              parse={(date: Date) => parseDateToString(date, false)}
              maxDate={maxDate}
              field={f}
              // onChange={(value: any) => changeStartDate(value)}
              onChange={(value: any) => {
                setDatePeriod({ ...datePeriod, startDate: value });
                props.onChange && props.onChange({ ...datePeriod, startDate: value })
              }}
              classType={"date-range"}
              placeholder={t("UI.SelectDate")}
              component={renderDateField}
            />
            <Field
              name={fieldName + ".endDate"}
              type="text"
              format={formatStringToDate}
              parse={(date: Date) => parseDateToString(date, true)}
              maxDate={maxDate}
              // onChange={(value: any) => changeEndDate(value)}  
              onChange={(value: any) => {
                setDatePeriod({ ...datePeriod, endDate: value });
                props.onChange && props.onChange({ ...datePeriod, endDate: value })
              }}
              classType={"date-range"}
              field={f}
              placeholder={t("UI.SelectDate")}
              component={renderDateField}
            />
            {!f.noSearchEmpty && showEmpty && (
              <Field
                name={fieldName + "__EMPTY"}
                type="checkbox"
                onClick={() => { }}
                onChange={(e: any) => { }}
                field={f}
                view={view}
                component={renderEmptyField}
              />
            )}
          </div>
        </FormGroup>
      </Row>
    );
  };

  const DateFormView = () => {
    return (
      <Row className="date-field date-field_simple" key={f.name}>
        {showLabel && (
          <Col span={8}>
            <Label htmlFor={fieldName}>
              <span>{t(f.name)}</span>
              <span className="required">{f.required ? "*" : ""}</span>
            </Label>
          </Col>
        )}
        <Col span={16}>
          <Field
            name={fieldName}
            type="text"
            disabled={f.readonly || (f.writable === false) || f.name.toLowerCase() === "created"}
            format={formatStringToDate}
            parse={(date: Date) => parseDateToString(date, false)}
            maxDate={maxDate}
            onChange={(e: any) => {
              if (props.onChange) {
                props.onChange(e);
              }
            }}
            field={f}
            placeholder={t("UI.SelectDate")}
            component={renderDateField}
            validate={f.required && !isDraft ? mandatory : undefined}
          />
        </Col>
      </Row>
    );
  };

  switch (view) {
    case Views.FILTER:
      if (props.formName.includes("CARD_SEARCH") && props.f.yearCardSearch)
        return <YearFilter {...props} />;
      else
        return DateFilterView();
    case Views.FORM:
      return DateFormView();
    case Views.REFERENCE:
      return <span>Text</span>;
    default:
      return <span>Text</span>;
  }
};

export default DateField;
