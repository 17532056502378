import { ITemplate } from "../../../models/template";

export const validateTemplate = (values: ITemplate) => {
  const errors = {} as ITemplate;
  if (!values.name) {
    errors.name = "Required";
  }
  if (!values.attachmentId) {
    errors.attachmentId = "Required";
  }
  return errors;
};
