import {
  Button,
  Classes,
  Intent,
  Popover,
  PopoverInteractionKind,
} from "@blueprintjs/core";
import React, { useState } from "react";
import "./FieldsPopupForm.scss";
import { useTranslation } from "react-i18next";

export const FieldPopupForm = (props: {
  content: any;
  Save: CallableFunction;
}) => {
  const { content, Save } = props;
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Popover
        popoverClassName={Classes.POPOVER_CONTENT_SIZING}
        portalClassName="form-field-portal"
        boundary="viewport"
        canEscapeKeyClose={false}
        hasBackdrop={false}
        inheritDarkTheme={true}
        interactionKind={PopoverInteractionKind.CLICK_TARGET_ONLY}
        minimal={false}
        fill
        modifiers={{
          arrow: { enabled: true },
          flip: { enabled: true },
          keepTogether: { enabled: true },
          preventOverflow: { enabled: true },
        }}
        position={"auto"}
        usePortal={true}
        enforceFocus={false}
        isOpen={isOpen}
      >
        <div>
          <Button
            intent={Intent.NONE}
            small
            icon="plus"
            text={t("UI.AddMoreInfo")}
            onClick={() => setOpen(true)}
          />
        </div>
        <div className="popup-field">
          <div className="popup-field__content">{content}</div>
          <div className="popup-field__footer">
            <Button
              intent={Intent.PRIMARY}
              text={t("UI.Save")}
              onClick={() => {
                Save();
                setOpen(false);
              }}
            />
            <Button
              intent={Intent.NONE}
              text={t("UI.Close")}
              onClick={() => setOpen(false)}
            />
          </div>
        </div>
      </Popover>
    </React.Fragment>
  );
};

export default FieldPopupForm;
