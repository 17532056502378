import React, { ReactElement } from "react";
import ArrayView from "../../components/core/lists/array-view/ArrayView";
import ReferenceView from "../../components/core/lists/reference-view/ReferenceView";
import { ChangeAction } from "../changes/field-change";
import { IConstraint } from "../constraint";
import { Dictionary, DictionaryArray, DictionaryObject } from "../types/dictionary";
import { IViewField } from "../view-field";
import { GeneralField } from "./general-field";

export class CardField extends GeneralField {
  compareFunction(before: Dictionary, after: Dictionary): ChangeAction {
    return ChangeAction.Unchanged;
  }

  viewRender(value: Dictionary): ReactElement {
    return this.viewField.many ? 
    <ArrayView field={this.viewField} item={value as DictionaryArray} /> :
    <ReferenceView field={this.viewField} item={value as DictionaryObject} viewArray={this.viewField.listView || []}/>;
  }

  stringRender(value: Dictionary, viewFields?: IViewField[]): string {
    return "";
  }

  tagRender(value: Dictionary, onRemove: (id: number) => void): ReactElement {
    return <div />;
  }

  emptyConstraint(fieldName: string): IConstraint {
    return {
      type: "Null",
      field: fieldName,
    } as IConstraint;
  }

  valueConstraint(fieldName: string, value: Dictionary) {
    if (
      !value ||
      typeof value !== "object" ||
      !(value as DictionaryObject)["id"]
    )
      return [];

    return [
      {
        type: "Eq",
        field: fieldName + ".id",
        value: (value as DictionaryObject)["id"],
      } as IConstraint,
    ];
  }

  isEmpty(value: Dictionary) {
    return !value;
  }
}
