import React from "react";
import "./CopyPaste.scss";
import { useTranslation } from "react-i18next";
import { Button } from "@blueprintjs/core";
import { Dictionary, DictionaryArray, DictionaryObject } from "../../../../models/types/dictionary";
import { IViewModel } from "../../../../models/view-model";
import { copy, paste } from "../../../../redux/actions/entitiesActions";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../../redux/states/state";
import { ICopyItem, CopyItem } from "../../../../models/copy-item";

export const CopyPaste = (props: {
  item: Dictionary;
  model: IViewModel;
  changeItem: (item: Dictionary) => void;
}) => {
  const { t } = useTranslation();
  const { item, model, changeItem } = props;
  const copyItem = useSelector(
    (state: IAppState) => state.entitiesReducer.copyItem
  );

  const dispatch = useDispatch();
  const ArrayComponent = ["Multiple", "EditableTable", "TablePopup"];

  const copyItems = (item: Dictionary) => {

    model.fields.map((field) => {
      if (ArrayComponent.includes(field.component)) {
        item && ((item as DictionaryObject)[field.name] as DictionaryArray).forEach((el, index) => {
          delete (el as DictionaryObject).id;
        });
      }
    });
    const copyItem = new CopyItem(model, item);
    dispatch(copy(copyItem));
  };

  const pasteItem = (copyItems: ICopyItem | null) => {
    if (!copyItems) {
      return;
    }
    if (model.name !== copyItems.getModel()?.name) {
      return;
    }
    changeItem(copyItems.getItem());
    dispatch(paste());
  };

  return (
    <span>
      <Button text={t("UI.Copy")} icon="duplicate" disabled={!item || !(item as DictionaryObject)['id']} onClick={() => copyItems(item)} />
      <Button text={t("UI.Paste")} disabled={!copyItem} onClick={() => pasteItem(copyItem)} />
    </span>
  );
};
export default CopyPaste;
