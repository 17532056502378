import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { change, formValueSelector } from "redux-form";
import { IViewField } from "../models/view-field";

function useRelationHook(props: {
    formName: string;
    f: IViewField;
    fieldName: string;
}) {
    const state = useSelector((state: any) => state);
    const relationData = formValueSelector(props.formName)(state, props.f.relation ? props.f.relation.fieldName : "");
    const value = formValueSelector(props.formName)(state, props.fieldName);
    const dispatch = useDispatch();
    useEffect(() => {
        relationData &&
            props.f.relation?.func &&
            dispatch(change(props.formName, props.fieldName, props.f.relation.func(value, relationData, props.f.relation?.propertyNames)));
    }, [relationData]);
    return relationData;
}

export default useRelationHook;