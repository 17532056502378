export const isMandatory = (isMandatory: boolean) => (
  value: any
): string | undefined => {
  return isMandatory &&
    (!value ||
      (Array.isArray(value) && (!value.length || value[0].default === true)))
    ? "Required"
    : undefined;
};

export const mandatory = isMandatory(true);
