import axios from "axios";
import { ThunkAction } from "redux-thunk";
import { Action } from "typesafe-actions";
import { project } from "../../configs";
import { IConstraintTree } from "../../models/constraint";
import { IPrintForm, PrintAction } from "../../models/view-model";
import { checkPrintApi } from "../api/print-api";
import { PRINT_METHOD, SET_CONTROL_PF } from "../constants/print";
import { IAppState } from "../states/state";
import { getFile } from "./attachmentsActions";
import { showError } from "./errorsActions";
import { printFormV2 } from "./templatesActions";

export const checkPrintService = (): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
    checkPrintApi()
        .then((result) => {
            console.info('printService connected!');
            dispatch(setPrintMethod(true));
        })
        .catch((err) => {
            dispatch(setPrintMethod(false));
            console.error('printService not connected!')
        });
}

export function setPrintMethod(enable: boolean) {
    return {
        type: PRINT_METHOD,
        payload: enable
    }
}

export function setControlPf(pf: IPrintForm | null) {
    return {
        type: SET_CONTROL_PF,
        payload: pf
    }
}

export const extendedPrintApi = (
    name: string,
    modelName: string,
    constraints: IConstraintTree,
    pfTitle: string,
    action: PrintAction,
    id?: string,
    printReason?: string,
    printCount?: string
): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
    const state: IAppState = getState();
    const { printService } = state.printReducer;
    dispatch(printFormV2(name, modelName, constraints, pfTitle, action === "INSTANT" ? printService: false, "print", id, printReason, printCount));
}

export const extendedPreview = (
    value: string,
    action: string,
    fileName?: string
): ThunkAction<void, IAppState, unknown, Action<string>> => (dispatch, getState) => {
    const state: IAppState = getState();
    const { printService } = state.printReducer;
    let _getFile;
    if (!printService) {
        _getFile = getFile(value);
    } else {
        _getFile = getFileStrict(value, action);
    }
    _getFile.then((res) => {
        if (!res) {
            dispatch(showError("ERROR.NoFile"));
            return;
        }
        if (!printService) {
            const fileType = res.headers["content-type"];
            const blob = new File(
                [res.data],
                fileName ? fileName : "Untitled-" + Math.random() + 100,
                { type: fileType }
            );
            saveAs(blob);
        }
    });
};

export function getFileStrict(id: string, action: string) {
    return axios.get(project.config.printUrl + '/api/document/get/' + id + "?action=" + action,
        { headers: { "Content-Type": "application/json" } }
    );
}
