import { Intent, Tag } from "@blueprintjs/core";
import moment from "moment";
import React, { ReactElement } from "react";
import { ChangeAction } from "../changes/field-change";
import { IConstraint } from "../constraint";
import { Dictionary, DictionaryArray } from "../types/dictionary";
import { IViewField } from "../view-field";
import { GeneralField } from "./general-field";

export class DateMultipleField extends GeneralField {
  compareFunction(before: Dictionary, after: Dictionary): ChangeAction {
    if (
      (before as DictionaryArray) &&
      (after as DictionaryArray) &&
      (before as DictionaryArray).length !== (after as DictionaryArray).length
    ) {
      return ChangeAction.Updated;
    } else {
      return (before as DictionaryArray).filter(function (n: Dictionary) {
        return (after as DictionaryArray).indexOf(n) !== -1;
      }).length
        ? ChangeAction.Updated
        : ChangeAction.Unchanged;
    }
  }

  viewRender(value: Dictionary): ReactElement {
    const dateArray = value as string[];
    return (
      <div>
        {dateArray && dateArray.length ? (
          <div>
            {dateArray.map((date: string, index: number) => {
              const delimiter = index > 0 ? " " : "";
              return (
                <span key={index.toString() + date}>
                  <span>{delimiter}</span>
                  <span>
                    {date
                      ? moment(date, "MM/DD/YYYY hh:mm:ss").format("DD.MM.YYYY")
                      : ""}
                  </span>
                </span>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }

  stringRender(value: Dictionary, viewFields?: IViewField[]): string {
    const dateArray = value as string[];
    return dateArray && dateArray.length
      ? dateArray
          .map((date: string, index: number) => {
            return date ? moment(date).format("DD.MM.YYYY") : "";
          })
          .join(", ")
      : "";
  }

  tagRender(value: Dictionary, onRemove: (id: number) => void): ReactElement {
    const dateArray = value as string[];
    return (
      <div>
        {dateArray && dateArray.length ? (
          <div>
            {dateArray.map((date: string, index: number) => {
              return (
                <Tag
                  intent={Intent.NONE}
                  multiline
                  key={index.toString() + (date as string)}
                  onRemove={() => (onRemove ? onRemove(index) : null)}
                >
                  {date
                    ? moment(date, "MM/DD/YYYY hh:mm:ss").format("DD.MM.YYYY")
                    : ""}
                </Tag>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }

  emptyConstraint(fieldName: string): IConstraint {
    return {
      type: "Null",
      field: fieldName,
    } as IConstraint;
  }

  valueConstraint(fieldName: string, value: Dictionary) {
    return [
      {
        type: "Eq",
        field: fieldName,
        value: value,
      } as IConstraint,
    ];
  }

  isEmpty(value: Dictionary) {
    return !value || !(value as DictionaryArray).length;
  }
}
