import { Button, Icon, InputGroup } from "@blueprintjs/core";
import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { WrappedFieldProps } from "redux-form/lib/Field";
import { TemplateType } from "../../../models/enums/template-type";
import { Views } from "../../../models/enums/views";
import { ITemplate } from "../../../models/template";
import { Selectable } from "../../../models/types/dictionary";
import { IViewModel } from "../../../models/view-model";
import {
  createTemplate,
  updateTemplate
} from "../../../redux/actions/templatesActions";
import { IAppState } from "../../../redux/states/state";
import { renderSimpleSelectField } from "../../core/forms/controls/simple-select/SimpleSelect";
import { renderBooleanField } from "../../core/forms/fields/boolean-field/BooleanField";
import FileInputField from "../../core/forms/fields/fileinput-field/FileInputField";
import { renderMultipleField } from "../../core/forms/fields/multiselect-field/MultiselectField";
import ValidMessages from "../../core/forms/valid-messages/ValidMessages";
import "./CreateTemplate.scss";
import { validateTemplate } from "./validate";

interface Props extends WithTranslation {
  createTemplate: (template: ITemplate) => void;
  updateTemplate: (template: ITemplate, formData: FormData | null) => void;
  templates: ITemplate[] | null;
  models: Map<string, IViewModel>;
}

const renderField = ({
  input,
  meta: { touched, invalid, error },
  placeholder,
}: WrappedFieldProps & { placeholder: string }) => {
  const clearText = () => {
    if (input.value) {
      input.onChange("");
    }
  };
  return (
    <div>
      <InputGroup
        className={error && touched ? "bp3-intent-danger" : ""}
        {...input}
        rightElement={
          input.value ? (
            <Icon
              className="clear-btn-large"
              icon="small-cross"
              onClick={() => clearText()}
            />
          ) : (
            <span></span>
          )
        }
        placeholder={placeholder}
      />
      {touched && error && <ValidMessages text={error} />}
    </div>
  );
};

class CreateTemplate extends React.PureComponent<
  Props & InjectedFormProps<{}, Props>,
  any
> {
  constructor(props: Props & InjectedFormProps<{}, Props>) {
    super(props);
    this.state = {
      excelTemplate: false,
    };
  }

  onSelectChange = (selectedRowKeys: any[]) => {
    this.setState({ selectedRowKeys });
  };

  onDoubleChange = (selectedRowKeys: any[]) => {};

  keyPressEvent(e: any) {
    if (e.keyCode === 13) {
    } else {
    }
  }

  createNew = (values: any) => {
    const metadata =
      values && values["metadata"] ? JSON.parse(values["metadata"]) : undefined;
    const template: ITemplate = {
      name: values["name"],
      attachmentId: values["attachmentId"],
      type:
        values["type"] && values["type"] === "Template"
          ? TemplateType.Template
          : TemplateType.Export,
      restricted: values["restricted"],
      metadata:
        values["type"] && values["type"] === "Export" ? metadata : undefined,
    };
    // this.props.updateTemplate(template, null);
    this.props.createTemplate(template);
    this.props.reset();
  };

  renderModelFields() {
    const { t } = this.props;
    const models = this.props.models;

    const model = models.get("IncomingDocument");
    return (
      <div>
        <Field
          name="metadata"
          type="text"
          placeholder={t("TemplateRef.Fields")}
          reference={model ? model.fields.map((c) => c.name) : []}
          t={t}
          component={renderMultipleField}
        />
      </div>
    );
  }

  renderMetadata() {
    const { t } = this.props;
    return (
      <div>
        <Field
          name="metadata"
          type="text"
          placeholder={t("TemplateRef.Metadata")}
          t={t}
          component={renderField}
        />
      </div>
    );
  }

  handleKeyDown = function (e: any, cb?: any) {
    if (e.key === "Enter" && e.shiftKey === false) {
      e.preventDefault();
    }
  };

  renderView = (item: Selectable): string => {
    const { t } = this.props;
    if (!item) {
      return "";
    }
    return t(item as string);
  };

  public render() {
    const { t, handleSubmit } = this.props;

    return (
      <form
        onSubmit={handleSubmit(this.createNew)}
        autoComplete="off"
        onKeyDown={(e) => {
          this.handleKeyDown(e);
        }}
      >
        <div className="template-panel_form">
          <div className="template-panel_field">
            <Field
              name="name"
              type="text"
              placeholder={t("TemplateRef.TemplateName")}
              component={renderField}
            />
          </div>
          <div className="template-panel_field">
            <Field
              name="type"
              type="text"
              placeholder={t("TemplateRef.Type")}
              items={["Template", "Export"]}
              onChange={(value: any) => {
                this.setState({ excelTemplate: value === "Export" });
                return value;
              }}
              t={t}
              renderView={this.renderView}
              component={renderSimpleSelectField}
            />
          </div>
          <div className="template-panel_field">
            <FileInputField
              selectedItem={null}
              view={Views.FORM}
              fieldName={"attachmentId"}
            />
          </div>
          {/*{this.state.excelTemplate ? this.renderModelFields(): null}*/}
          {this.state.excelTemplate ? this.renderMetadata() : null}
          {!this.state.excelTemplate ? <div className="template-panel_field">
            <Field
              name="restricted"
              type="text"
              t={t}
              label={t("TemplateRef.Restricted")}
              component={renderBooleanField}
            />
          </div>: null}
          <div className="template-panel_field">
            <Button type="submit" text={t("UI.NewTemplate")} />
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  templates: state.templatesReducer.templates,
  models: state.modelsReducer.models,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createTemplate: (template) => createTemplate(template),
      updateTemplate: (template, formData) =>
        updateTemplate(template, formData),
    },
    dispatch
  );

const CreateTemplateForm = reduxForm<{}, Props>({
  form: "TemplateForm",
  initialValues: {
    type: "Template",
  },
  enableReinitialize: true,
  validate: validateTemplate,
})(CreateTemplate);

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CreateTemplateForm)
);
