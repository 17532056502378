import React from "react";
import "./SideBar.scss";
import LargeFilterForm from "../../core/filters/large-filter/LargeFilter";
import { useSelector } from "react-redux";
import { IAppState } from "../../../redux/states/state";

const SideBar = () => {
  const activeModel = useSelector(
    (state: IAppState) => state.modelsReducer.activeModel
  );
  const model = useSelector((state: any) =>
    state.modelsReducer.models.get(activeModel)
  );
  if (!model) {
    return null;
  }
  return (
    <div>
      <LargeFilterForm model={model} form={activeModel + "__FILTER"} />
    </div>
  );
};

export default SideBar;
