import { Button, Intent } from "@blueprintjs/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { InjectedFormProps, reduxForm } from "redux-form";
import { filterContraintByNames } from "../../../../../helpers/filter-constraint-bynames";
import { formListeners } from "../../../../../helpers/form-listeners";
import { generateKey } from "../../../../../helpers/generate-unique-key";
import { IConstraint } from "../../../../../models/constraint";
import { Views } from "../../../../../models/enums/views";
import { FormField } from "../../../../../models/fields/form-field";
import {
  Dictionary, DictionaryObject
} from "../../../../../models/types/dictionary";
import { IViewField } from "../../../../../models/view-field";
import { IViewModel } from "../../../../../models/view-model";
import { getReferenceQuery } from "../../../../../redux/actions/referencesActions";
import { IAppState } from "../../../../../redux/states/state";
import CustomFormField from "../../fields/form-types/FormTypes";
import "./SearchForm.scss";

interface Props extends WithTranslation {
  f: IViewField;
  setOpen: (isOpen: boolean) => void;
  selectItem: (item: DictionaryObject) => void;
  model: IViewModel;
  listeners: Map<string, IConstraint>;
}

interface State {
  foundItems: DictionaryObject[];
  searched: boolean;
}

class SearchForm extends React.PureComponent<
  Props & InjectedFormProps<{}, Props>,
  State
  > {
  constructor(props: Props & InjectedFormProps<{}, Props>) {
    super(props);
    this.state = {
      foundItems: [],
      searched: false,
    };
  }

  findItem = (values: any) => {
    const { f, model } = this.props;
    this.setState({ searched: false });
    const filter = filterContraintByNames(values, model.fields);
    getReferenceQuery(f.model, "", filter).then((result) => {
      this.setState({ searched: true });
      this.setState({ foundItems: result });
    });
  };

  selectItem = (item: DictionaryObject) => {
    const { setOpen, selectItem } = this.props;
    selectItem(item);
    setOpen(false);
  };

  render() {
    const { setOpen, t, f, form, model, handleSubmit, listeners } = this.props;
    const { foundItems, searched } = this.state;
    const { searchFields } = f;
    const referenceModel = model;
    if (!referenceModel) {
      return <div></div>;
    }

    return (
      <div className="search-form">
        <form autoComplete="off">
          {searchFields && searchFields.length
            ? searchFields.map((sf: string) => {
              const field = referenceModel.fields.find(
                (f: IViewField) => f.name === sf.split('.')[0]
              );
              if (!field) {
                return null;
              }

              return (
                <CustomFormField
                  {...new FormField(
                    {
                      ...field,
                      readonly: false,
                      // model: f.model ? f.model: "",
                      // searchField: field.searchField ? field.name + "." + field.searchField: undefined,
                      // referenceView: referenceView,
                      // viewConstraints: field.filterConstraints,
                      detailedView: undefined
                    },
                    sf,
                    null,
                    Views.FILTER,
                    true,
                    false,
                    form,
                    false,
                    false,
                    listeners,
                    (e: Dictionary) => null,
                    false
                  )}
                />
              );
            })
            : null}
          <div>
            <Button
              intent={Intent.NONE}
              text={t("UI.Search")}
              onClick={() => handleSubmit(this.findItem)()}
            />
            <Button
              intent={Intent.NONE}
              text={t("UI.Close")}
              onClick={() => setOpen(false)}
            />
          </div>
        </form>
        <div className="result">
          <span className="result__title">{t("UI.SearchResult")}</span>
          <div className="result__details">
            {foundItems && foundItems.length ? (
              foundItems.map((fi: DictionaryObject, index: number) => {
                return f.calculation ? (
                  <Button
                    key={generateKey(index.toString())}
                    minimal
                    onClick={() => this.selectItem(fi)}
                  >
                    {f.calculation(fi)}
                  </Button>
                ) : null;
              })
            ) : searched ? (
              <div className="no-result">{t("UI.NothingFound")}</div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (
  state: IAppState,
  initialProps: any
) => {
  const listeners = formListeners(
    initialProps.form,
    state,
    initialProps.model ? initialProps.model.fields : []
  );
  return {
    listeners: listeners,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const SearchFormForm = reduxForm<{}, Props>({})(SearchForm);

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SearchFormForm)
);
