import { ISequenceState } from "../../../../../models/sequence-state";
import { sequencesApi } from "../../../../../redux/api";
import { forkJoin, from, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { errorsActions } from "../../../../../redux/actions";

export const getTryValues = (sequences: string[]) => {
    return forkJoin(
        sequences.map((seq: string) =>
          from(
            sequencesApi.getTryValues(seq)
          ).pipe(
            catchError((error) =>
              of(errorsActions.showError("ERROR.SaveSequence"))
            )
          ).pipe(
              map((res) => res ? res.data : null)
          )
        )
      )
}

export const getTryValue = (seq: string) => {
  return from(sequencesApi.getTryValues(seq))
        .pipe(map((res) => res ? res.data : null));
}

export const loadTryValues = (sequences: string[], states: ISequenceState[]) => {
    const tvMap: Map<string, ISequenceState> = new Map<
        string,
        ISequenceState
      >();

      if (states && states.length) {
        sequences.forEach((seq: string, index: number) => {
          tvMap.set(seq, states[index]);
        });
      }
      return tvMap;
}

export const saveTryValues = (tryValues: Map<string, ISequenceState>) => {
  return forkJoin(
    Array.from(tryValues.keys()).map((seq: string) =>
      from(
        sequencesApi.saveValue(
          seq,
          tryValues.get(seq) as ISequenceState
        )
      ).pipe(
        catchError((error) =>
          of(errorsActions.showError("ERROR.SaveSequence"))
        )
      )
    )
  )
}
