import { ReactElement } from "react";
import { ChangeAction } from "../changes/field-change";
import { Dictionary } from "../types/dictionary";
import { IViewField } from "../view-field";
import { ISequenceState } from "../sequence-state";
import { processDefault } from "../../helpers/process-default";
import { IConstraint } from "../constraint";

export interface IGeneralField {
  viewField: IViewField;
  viewRender: (value: Dictionary, referenceView?: string[]) => ReactElement;
  compareFunction: (before: Dictionary, after: Dictionary) => ChangeAction;
  tagRender: (
    value: Dictionary,
    onRemove: (id: number) => void,
    showFields?: boolean
  ) => ReactElement;
  stringRender: (value: Dictionary, viewFields?: IViewField[]) => string;
  defaultValue: (
    entity: Dictionary,
    tryValue: Map<string, ISequenceState>,
    process: boolean
  ) => Dictionary;
  emptyConstraint: (fieldName: string) => IConstraint;
  valueConstraint: (fieldName: string, value: Dictionary) => IConstraint[];
  isEmpty: (value: Dictionary) => boolean;
}

export abstract class GeneralField implements IGeneralField {
  viewField: IViewField;

  constructor(viewField: IViewField) {
    this.viewField = viewField;
  }

  abstract viewRender(value: Dictionary): ReactElement;
  abstract compareFunction(before: Dictionary, after: Dictionary): ChangeAction;
  abstract tagRender(
    value: Dictionary,
    onRemove: (id: number) => void
  ): ReactElement;
  abstract stringRender(value: Dictionary, viewFields?: IViewField[]): string;
  abstract emptyConstraint(fieldName: string): IConstraint;
  abstract valueConstraint(fieldName: string, value: Dictionary): IConstraint[];
  abstract isEmpty(value: Dictionary): boolean;

  defaultValue(
    entity: Dictionary,
    tryValue: Map<string, ISequenceState>,
    process: boolean
  ): Dictionary {
    return processDefault(this.viewField, entity, tryValue, process);
  }
}
