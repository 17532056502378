import { Button, FileInput } from "@blueprintjs/core";
import { Table } from "antd";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getDeepValue } from "../../../../../helpers/get-deep-value";
import { IViewField } from "../../../../../models/view-field";
import { setConfirm } from "../../../../../redux/actions/alertActions";
import { setAttachmentTypes } from "../../../../../redux/actions/attachmentsActions";
import {
  deleteDocumentsAttachments,
  uploadDocumentsAttachments,
} from "../../../../../redux/actions/entitiesActions";
import { extendedPreview } from "../../../../../redux/actions/printActions";
import { getReferenceQuery } from "../../../../../redux/actions/referencesActions";
import { IAppState } from "../../../../../redux/states/state";
import SimpleSelect from "../../controls/simple-select/SimpleSelect";
import "./AttachmentForm.scss";
interface Props {
  selectedItem: any;
  dbname: string;
  sequence: string;
  field: IViewField | undefined;
}

export const AttachmentForm = (props: Props) => {
  const { t } = useTranslation();
  const { selectedItem, sequence, dbname, field } = props;
  const documentModel = useSelector((state: any) =>
    state.modelsReducer.models.get("ScanDocument")
  );
  const scanDocuments =
    selectedItem && selectedItem["ScanDocuments"]
      ? selectedItem["ScanDocuments"]
      : [];

  const [data, setData]: [any[], any] = useState(scanDocuments);
  const [attachmentType, setAttachmentType]: [any, any] = useState(null);
  const [inputKey, setInputKey] = useState<number>(0);

  const loading: boolean = useSelector(
    (state: IAppState) => state.attachmentsReducer.loading
  );
  const attachmentTypes: any = useSelector(
    (state: IAppState) => state.attachmentsReducer.attachmentTypes
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setData(scanDocuments);

    if (!attachmentTypes || !attachmentTypes.length) {
      getReferenceQuery("AttachmentType", "", [], 0, 1000).then((r) => {
        dispatch(setAttachmentTypes(r));
        if (r && r.length) {
          setAttachmentType(r[0]);
        }
      });
    } else if (!attachmentType) {
      setAttachmentType(attachmentTypes[0]);
    }
  }, [scanDocuments.length]);

  const handleInputChange = (e: any) => {
    const { selectedItem } = props;

    if (e.target.files.length) {
      const arrFiles: File[] = Array.from(e.target.files);
      const formData = new FormData();
      formData.append("file", arrFiles[0], arrFiles[0].name);

      dispatch(
        uploadDocumentsAttachments(
          dbname,
          selectedItem,
          sequence,
          formData,
          attachmentType
        )
      );
      setInputKey(new Date().getTime());
    }
  };

  const getRenderFields = () => {
    const { selectedItem } = props;
    const columns = documentModel.fields
      .filter((f: IViewField) => f.visible.list)
      .map((field: IViewField) => {
        return {
          title: t("ScanDocument." + field.name),
          dataIndex: field.name,
          key: field.name,
          render: (value: any, record: any) => {
            switch (field.name) {
              case "FileUid":
                return (
                  <Button
                    key={record["id"]}
                    minimal
                    onClick={() => {
                      dispatch(
                        extendedPreview(
                          value,
                          "preview",
                          record["ScanDocumentNumber"]
                        )
                      );
                    }}
                  >
                    {t("UI.Preview")}
                  </Button>
                );
              case "Employee": {
                const employee = record["Employee"];
                return employee ? employee["ShortName"] : "";
              }
              case "ScanAttachmentDate":
                return value ? Moment(value).format("DD.MM.YYYY HH:mm") : "";
              case "AttachmentType":
                return value ? getDeepValue(value, "Name") : "";
              default:
                return value;
            }
          },
        };
      });

    columns.push({
      title: t("Actions.Actions"),
      key: "delete",
      render: (value: any, record: any) => {
        return (
          <Button
            disabled={!field?.deletable}
            key={record["id"]}
            minimal
            icon="remove"
            onClick={() => {
              dispatch(
                setConfirm({
                  message: t("UI.Sure"),
                  isOpen: true,
                  onConfirm: () => {
                    dispatch(
                      deleteDocumentsAttachments(
                        dbname,
                        selectedItem,
                        record["id"]
                      )
                    );
                  },
                })
              );
            }}
          />
        );
      },
    });
    return columns;
  };

  if (!documentModel || !documentModel.fields || !documentModel.fields.length) {
    return <span>Loading...</span>;
  }

  if (!sequence) {
    return <span>No sequence for generation...</span>;
  }

  const renderView = (item: any): string => {
    if (!item) {
      return "";
    }
    return item.Name;
  };
  return (
    <div className="documents-panel">
      <div className="documents-panel_header">
        <form className="documents-panel_header_actions">
          <div className="header-button">
            {attachmentTypes && attachmentTypes.length && attachmentType && (
              <div>
                <SimpleSelect
                  items={attachmentTypes}
                  renderView={renderView}
                  onChange={(value: any) => setAttachmentType(value)}
                  component={
                    <Button
                      rightIcon="caret-down"
                      className={"attachment-type-select"}
                      text={renderView(attachmentType)}
                    />
                  }
                />
              </div>
            )}
            <FileInput
              className={"attach-btn"}
              text="Choose file..."
              buttonText={t("UI.Attach")}
              fill={false}
              key={inputKey}
              onInputChange={handleInputChange}
            />
          </div>
          {/*<Button className="header-button">{t(`UI.Attach`)}</Button>*/}
        </form>
      </div>
      <div className="documents-panel_body">
        <Table
          loading={loading}
          columns={getRenderFields()}
          dataSource={[...data]}
          pagination={false}
          rowKey="ScanDocumentNumber"
        />
      </div>
      <div className="documents-panel_footer">
        {/*<div className="documents-panel_footer_actions">*/}
        {/*    <Button className="footer-button">{t(`Print`)}</Button>*/}
        {/*    <Button className="footer-button">{t(`Preview`)}</Button>*/}
        {/*    <Button className="footer-button">{t(`Save`)}</Button>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default AttachmentForm;
