import { IEmployee } from "./../../models/employee";
import { IPagingOptions } from "../../models/paging-options";
import { createAction } from "typesafe-actions";
import { IUser } from "../../models/users/user";
import { users } from "../constants";

export const createUser = createAction(
  users.CREATE_USER,
  (resolve) => (user: IUser, roleId: number) => resolve({ user, roleId })
);

export const updateUser = createAction(
  users.UPDATE_USER,
  (resolve) => (
    user: IUser,
    roleId: number | null,
    prevEmployee: IEmployee | null
  ) => resolve({ user, roleId, prevEmployee })
);

export const setRole = createAction(
  users.SET_ROLE,
  (resolve) => (userId: number, roleId: number) => resolve({ userId, roleId })
);

export const changePassword = createAction(
  users.CHANGE_PASSWORD,
  (resolve) => (user: IUser) => resolve({ user })
);

export const loadingUsers = createAction(
  users.LOADING_USERS,
  (resolve) => (loading: boolean) => resolve(loading)
);

export const loadUsers = createAction(
  users.LOAD_USERS,
  (resolve) => (users: IUser[]) => resolve(users)
);

export const setUsersCount = createAction(
  users.LOADING_USERS_COUNT,
  (resolve) => (count: number) => resolve(count)
);

export const refreshUsers = createAction(
  users.REFRESH_USERS,
  (resolve) => (pagingOptions?: IPagingOptions) => resolve({ pagingOptions })
);

export const updatePaging = createAction(
  users.UPDATE_PAGING,
  (resolve) => (pagingOptions: IPagingOptions) => resolve({ pagingOptions })
);

export const getUsersSimple = createAction(users.GET_USERS_SIMPLE);

export const loadUsersSimple = createAction(
  users.LOAD_USERS_SIMPLE,
  (resolve) => (users: IUser[]) => resolve(users)
);

export const getEmployeeByUserId = createAction(
  users.GET_EMPLOYEE_BY_USERID,
  (resolve) => (userId: number) => resolve({ userId })
);

export const setUserEmployee = createAction(
  users.SET_USER_EMPLOYEE,
  (resolve) => (employee: IEmployee | null) => resolve(employee)
);

export const selectUser = createAction(
  users.SELECT_USER,
  (resolve) => (user: IUser) => resolve(user)
);
