import { Dictionary, DictionaryObject } from "../models/types/dictionary";

export const getDeepValue = (item: Dictionary, path: string) => {
  if (!item) {
    return "";
  }
  const split = path.split(".");
  let current: Dictionary | null = item;
  split.forEach((s: string) => {
    if (!current) {
      current = {};
    }
    if (!(current as DictionaryObject)[s]) {
      current = "";
    } else {
      current = (current as DictionaryObject)[s];
    }
  });

  return current || "";
};
