import IncomingDocument from "./IncomingDocument.json";
import OutgoingDocument from "./OutgoingDocument.json";
import SimpleCard from "./SimpleCard.json";
import ApplicationDocument from "./ApplicationDocument.json";
import ClaimDocument from "./ClaimDocument.json";
import CommandDocument from "./CommandDocument.json";
import InformationDocument from "./InformationDocument.json";
import TripDocument from "./TripDocument.json";
import InquiryFinance from "./InquiryFinance.json";
import InquiryKadr from "./InquiryKadr.json";
import GeneralCommand from "./GeneralCommand.json";
import Contract from "./Contract.json";
import Supports from "./Supports.json";

export const entities = [
    IncomingDocument as any,
    OutgoingDocument as any,
    ApplicationDocument as any,
    ClaimDocument as any,
    CommandDocument as any,
    InformationDocument as any,
    TripDocument as any,
    InquiryFinance as any,
    InquiryKadr as any,
    SimpleCard as any,
    GeneralCommand as any,
    Contract as any,
    Supports as any
];
