import { Button, Intent, Label, Tag } from "@blueprintjs/core";
import { Col, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FieldArrayFieldsProps } from "redux-form/lib/FieldArray";
import { IConstraint } from "../../../../../models/constraint";
import { Views } from "../../../../../models/enums/views";
import { FormField } from "../../../../../models/fields/form-field";
import { IViewField } from "../../../../../models/view-field";
import { IViewModel } from "../../../../../models/view-model";
import ReferenceView from "../../../lists/reference-view/ReferenceView";
import CustomFormField from "../../fields/form-types/FormTypes";
import "./ArrayFillForm.scss";
import {
  DictionaryObject,
  DictionaryArray,
} from "../../../../../models/types/dictionary";
import { FieldFactory } from "../../../../../models/fields/field-factory";
import { ISequenceState } from "../../../../../models/sequence-state";
import ValidMessages from "../../valid-messages/ValidMessages";
import { getWritables } from "../../../../../helpers/get-writables";
import { sortById } from "../../../../../helpers/sort-by-id";

interface Props {
  f: IViewField;
  selectedItem: any;
  fields: FieldArrayFieldsProps<any>;
  error: any;
  submitFailed: boolean;
  constraints?: IConstraint[];
  formName: string;
}

export const ArrayFillForm = (props: Props) => {
  const { f, selectedItem, fields, error, submitFailed } = props;
  const [listeners, setListeners] = useState<any>(new Map<string, any>());
  const { t } = useTranslation();
  const [, forceUpdate] = useState(false);

  const referenceModel: IViewModel | undefined = useSelector((state: any) =>
    state.modelsReducer.models.get(f.model)
  );

  const writables: string[] = useSelector((state: any) => getWritables(state, f));
  const defaultObject = (f: IViewField) => {
    const field = new FieldFactory(f).createField();
    return field.defaultValue(
      selectedItem,
      new Map<string, ISequenceState>(),
      false
    );
  };

  // useEffect(() => {
  //   if (selectedItem && selectedItem["id"]) {
  //     const defaultObj = defaultObject(f) as DictionaryArray;
  //     fields.push({ ...(defaultObj[0] as DictionaryObject), default: true });
  //   }
  // }, [selectedItem]);

  const onAdd = () => {
    if (fields && fields.length > 0) {
      const defaultObj = defaultObject(f) as DictionaryArray;

      const prevField = fields.get(fields.length - 1);
      if (
        Object.keys(prevField).filter(
          (key) => key !== "default" && !!prevField[key]
        ).length > 0
      ) {
        fields.push({ ...(defaultObj[0] as DictionaryObject), default: true });
        prevField.default = undefined;
      }
    }
    setListeners(new Map<string, any>());
    // Не обновляется сразу, потому что видимо не обычный push это, а immutable.
  };

  return (
    <Row>
      <Row className="array-field__header">
        <Col span={24}>
          <Label>
            <span>{t(f.name)}</span>
            <span className="required">{f.required ? "*" : ""}</span>
          </Label>
          {submitFailed && error && <ValidMessages text={error} />}
        </Col>
      </Row>
      <Row className="array-field" gutter={16} key={f.name}>
        <Col className="array-field__form" span={12}>
          <Row>
            <Col span={16}>
              {fields
                ? fields.getAll().sort(sortById).map((field1: string, index: number) => {
                  return referenceModel
                    ? referenceModel.fields
                      .filter((f) => f.visible.form)
                      .map((field: IViewField) => {
                        const onChange = (e: any) => {
                          if (field.listen) {
                            listeners.set(field.name, e);
                            setListeners(listeners);
                            forceUpdate((s: boolean) => !s);
                          }
                        };
                        return (
                          <div
                            key={field.name + index}
                            className={
                              index === fields.length - 1
                                ? "field-row"
                                : "field-row display-none"
                            }
                          >
                            <CustomFormField
                              {...new FormField(
                                { ...field, readonly: !(writables?.indexOf(field.name) > -1) },
                                f.name + "[" + index + "]." + field.name,
                                null,
                                Views.FORM,
                                true,
                                false,
                                props.formName,
                                false,
                                index === fields.length - 1,
                                listeners,
                                onChange,
                                false
                              )}
                            />
                          </div>
                        );
                      })
                    : null;
                })
                : null}
            </Col>
            <Col span={8}>
              <div className="row button-row">
                <Button icon="plus" disabled={!props.f.writable} small onClick={() => onAdd()} />
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <div className="block-result">
            {fields
              ? fields.map((name: string, index: number) => {
                const item = fields.get(index);
                if (item.default) {
                  return null;
                }
                const onRemove = (index: number) => {
                  fields.remove(index);
                  if (fields.length > 0) {
                    const prevField = fields.get(fields.length - 1);
                    prevField.default = true;
                  }
                };

                return (
                  <Tag
                    key={index}
                    intent={Intent.NONE}
                    multiline
                    onRemove={() => onRemove(index)}
                  >
                    {f.numerable && (index + 1) + ")"}
                    <ReferenceView
                      viewArray={f.referenceView || []}
                      item={item}
                      field={{ ...f, component: "Single" }}
                    />
                  </Tag>
                );
              })
              : null}
          </div>
          {f.detailedView && <div className="block-result"> {fields
            ? fields.map((name: string, index: number) => {
              const item = fields.get(index);
              if (item.default) {
                return null;
              }
              return (
                <Tag
                  key={index}
                  intent={Intent.NONE}
                  multiline
                >
                  {f.numerable && (index + 1) + ")"}
                  <ReferenceView
                    field={{ ...f, component: "Single" }}
                    viewArray={f.detailedView || []}
                    item={item} />
                </Tag>)
            }) : null
          }
          </div>}
        </Col>
      </Row>
    </Row>
  );
};

export default ArrayFillForm;
