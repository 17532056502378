import { getDeepValue } from "./get-deep-value";
import { IViewField } from "../models/view-field";
import { IConstraint } from "../models/constraint";
import { ConstraintType } from "../models/enums/constraint-type";

export const filterContraintByNames = (filter: any, fields: IViewField[]) => {
  const constraints: IConstraint[] = [];
  if (Object.keys(filter).length > 0) {
    Object.keys(filter).forEach((k) => {
      if (filter[k]) {
        const field = fields.find((f: IViewField) => f.name === k);
        if (field) {
          const searchField = field.searchField || "id";
          const path = searchField;
          if (filter[k].hasOwnProperty("id")) {
            constraints.push({
              type: ConstraintType.Eq,
              field: k + "." + field.searchField,
              value: getDeepValue(filter[k], path),
            } as IConstraint);
          }
        }
      }
    });
    return constraints;
  } else {
    return [];
  }
};
