import { IViewModel } from "../models/view-model";
import { IViewField } from "../models/view-field";
import { Views } from "../models/enums/views";
import { ISequenceState } from "../models/sequence-state";
import { DictionaryObject } from "../models/types/dictionary";

export const constructStageValues = (
  model: IViewModel,
  view: Views,
  selectedItem: DictionaryObject,
  tryValue?: Map<string, ISequenceState>
) => {
  const stageValues: { [k: string]: any } = selectedItem || {};
  if (!model || !model.fields || !model.fields.length) return stageValues;

  const filteredFields =
    view === Views.FORM
      ? model.fields.filter(
          (field: IViewField) => field.visible.form && field.readable
        )
      : model.fields.filter(
          (field: IViewField) => field.visible.filter && field.readable
        );
  filteredFields.forEach((field: IViewField) => {
    if (field.stageDefault !== undefined) {
      if (field.component === "Date" && field.stageDefault === "Today") {
        if (view === Views.FORM) {
          stageValues[field.name] =
            stageValues[field.name] || new Date().toISOString();
        }
      } else {
        if (view === Views.FORM) {
          if (
            stageValues[field.name] === null ||
            stageValues[field.name] === undefined
          ) {
            stageValues[field.name] = field.stageDefault;
          }
          if (typeof field.stageDefault === "object") {
            for (const key in field.stageDefault) {
              if (stageValues[field.name].hasOwnProperty(key)) {
                stageValues[field.name][key] =
                  stageValues[field.name][key] ||
                  (field.stageDefault as DictionaryObject)[key];
              }
            }
          } else {
            stageValues[field.name] =
              stageValues[field.name] || field.stageDefault;
          }
        }
      }
    }

    if (
      view === Views.FORM &&
      !stageValues[field.name] &&
      tryValue &&
      field.sequence &&
      field.twoStepSequence
    ) {
      const tv = tryValue.get(field.sequence);
      if (tv) {
        stageValues[field.name] = tv.value;
      }
    }
  });
  return stageValues;
};
