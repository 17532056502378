import React, { ReactElement } from "react";
import ArrayView from "../../components/core/lists/array-view/ArrayView";
import { ChangeAction } from "../changes/field-change";
import { IConstraint } from "../constraint";
import {
  Dictionary,
  DictionaryArray,
  DictionaryObject,
} from "../types/dictionary";
import { IViewField } from "../view-field";
import { FieldFactory } from "./field-factory";
import { GeneralField, IGeneralField } from "./general-field";

export class ArrayField extends GeneralField {
  compareFunction(before: Dictionary, after: Dictionary): ChangeAction {
    if (
      (before as DictionaryArray) &&
      (after as DictionaryArray) &&
      (before as DictionaryArray).length !== (after as DictionaryArray).length
    ) {
      return ChangeAction.Updated;
    } else {
      const model: string = this.viewField.model;

      if (!model) {
        return (before as DictionaryArray).filter(function (n: Dictionary) {
          return (after as DictionaryArray).indexOf(n) !== -1;
        }).length
          ? ChangeAction.Updated
          : ChangeAction.Unchanged;
      } else {
        const idsB = (before as DictionaryArray).map(
          (f: Dictionary) => (f as DictionaryObject)["id"]
        );
        const idsA = (after as DictionaryArray).map(
          (f: Dictionary) => (f as DictionaryObject)["id"]
        );
        return idsB.filter(function (n: Dictionary) {
          return idsA.indexOf(n) === -1;
        }).length
          ? ChangeAction.Updated
          : ChangeAction.Unchanged;
      }
    }
  }

  viewRender(value: Dictionary): ReactElement {
    return <ArrayView item={value as DictionaryArray} field={this.viewField} />;
  }

  stringRender(value: Dictionary, viewFields?: IViewField[]): string {
    if (!viewFields) {
      return "";
    }
    return value && (value as DictionaryArray).length
      ? (value as DictionaryArray)
        .map((item: Dictionary, index) => {
          return viewFields
            .map((f: IViewField, indexF: number) => {
              const fieldFactory = new FieldFactory(f);
              const newField: IGeneralField = fieldFactory.createField();
              const delimiter = indexF > 0 ? " " : "";
              return (
                delimiter +
                newField.stringRender((value as DictionaryObject)[f.name])
              );
            })
            .join(" ");
        })
        .join(" ")
      : "";
  }

  tagRender(value: Dictionary, onRemove: (id: number) => void): ReactElement {
    return (
      <ArrayView
        tagView
        item={value as DictionaryArray}
        field={this.viewField}
        onRemove={onRemove}
      />
    );
  }

  emptyConstraint(fieldName: string): IConstraint {
    return {
      type: "Null",
      field: fieldName + ".id",
    } as IConstraint;
  }

  valueConstraint(fieldName: string, value: Dictionary) {
    if (
      !value ||
      typeof value !== "object" ||
      !(value as DictionaryObject)["id"]
    )
      return [];

    return [
      {
        type: "Eq",
        field: fieldName + ".id",
        value: (value as DictionaryObject)["id"],
      } as IConstraint,
    ];
  }

  isEmpty(value: Dictionary) {
    return !value || !(value as DictionaryArray).length;
  }
}
