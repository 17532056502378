import { Button, InputGroup } from "@blueprintjs/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ITemplate } from "../../../models/template";
import { showError } from "../../../redux/actions/errorsActions";
import { updateTemplate } from "../../../redux/actions/templatesActions";
import "./MetadataField.scss";

export const MetadataField = (props: { template: ITemplate }) => {
  const { template } = props;
  const [metadata, setMetadata] = useState(
    template.metadata ? JSON.stringify(template.metadata) : ""
  );

  const dispatch = useDispatch();

  const onChange = (value: any) => {
    setMetadata(value.target.value);
  };

  const saveTemplate = () => {
    const newTemplate: ITemplate = { ...template };
    if (metadata) {
      try {
        newTemplate.metadata = JSON.parse(metadata);
      } catch (error) {
        dispatch(showError("ERROR.InvalidJson"));
      }
    }
    dispatch(updateTemplate(newTemplate, null));
  };

  return (
    <div className="metadata-field">
      <InputGroup
        defaultValue={JSON.stringify(template.metadata)}
        onChange={(e: any) => onChange(e)}
        type={"text"}
        placeholder={"Metadata"}
        fill
        autoComplete="off"
        onFocus={(event: any) => {
          event.target.setAttribute("autocomplete", "off");
        }}
      />
      <Button icon="tick-circle" onClick={() => saveTemplate()}></Button>
    </div>
  );
};

export default MetadataField;
