import { IViewField } from "../view-field";
import { IGeneralField } from "./general-field";
import { TextField } from "./text-field";
import { TextareaField } from "./textarea-field";
import { NumberField } from "./number-field";
import { NumberIntervalField } from "./number-interval-field";
import { BooleanField } from "./boolean-field";
import { DateField } from "./date-field";
import { TimeField } from "./time-field";
import { ObjectField } from "./reference-field";
import { EntityField } from "./entity-field";
import { ArrayField } from "./array-field";
import { CalculationField } from "./calculation-field";
import { DateMultipleField } from "./date-multiple";
import { CardField } from "./card-field";
import { StateField } from "./state-field";
import { EnumField } from "./enum-field";

export class FieldFactory {
  viewField: IViewField;

  constructor(viewField: IViewField) {
    this.viewField = viewField;
  }

  isString(): boolean {
    return this.viewField.component === "Text" || this.viewField.component === "TextInput";
  }

  isTextarea(): boolean {
    return this.viewField.component === "Textarea";
  }

  isNumber(): boolean {
    return this.viewField.component === "Number";
  }

  isDate(): boolean {
    return this.viewField.component === "Date" || this.viewField.component === "OneDate";
  }

  isTime(): boolean {
    return this.viewField.component === "Time";
  }

  isBoolean(): boolean {
    return this.viewField.component === "Boolean";
  }

  isObject(): boolean {
    return this.viewField.component === "Single" ||
      this.viewField.component === "ReferenceSearch";
  }

  isEntity(): boolean {
    return this.viewField.component === "OneToOne";
  }

  isArray(): boolean {
    return (
      this.viewField.component === "Multiple" ||
      this.viewField.component === "SimpleMultiSelect" ||
      this.viewField.component === "EditableTable" ||
      this.viewField.component === "TablePopup" ||
      this.viewField.component === "ManyToMany" ||
      this.viewField.component === "MultiSelect" ||
      this.viewField.component === "Comments"
    );
  }

  isCalculation(): boolean {
    return this.viewField.component === "Calculation";
  }

  isDateMultiple(): boolean {
    return this.viewField.component === "DateMultiple";
  }

  isRelatedCard(): boolean {
    return this.viewField.component === "RelatedCard";
  }

  isStateField(): boolean {
    return this.viewField.component === "State";
  }

  isNumberInterval(): boolean {
    return this.viewField.component === "NumberInterval";
  }

  isEnumField(): boolean {
    return this.viewField.component === "EnumField";
  }

  public createField(): IGeneralField {
    switch (true) {
      case this.isString():
        return new TextField(this.viewField);
      case this.isTextarea():
        return new TextareaField(this.viewField);
      case this.isNumber():
        return new NumberField(this.viewField);
      case this.isNumberInterval():
        return new NumberIntervalField(this.viewField);
      case this.isBoolean():
        return new BooleanField(this.viewField);
      case this.isDate():
        return new DateField(this.viewField);
      case this.isObject():
        return new ObjectField(this.viewField);
      case this.isTime():
        return new TimeField(this.viewField);
      case this.isEntity():
        return new EntityField(this.viewField);
      case this.isArray():
        return new ArrayField(this.viewField);
      case this.isCalculation():
        return new CalculationField(this.viewField);
      case this.isDateMultiple():
        return new DateMultipleField(this.viewField);
      case this.isRelatedCard():
        return new CardField(this.viewField);
      case this.isStateField():
        return new StateField(this.viewField);
      case this.isEnumField(): 
        return new EnumField(this.viewField);
      default:
        return new TextField(this.viewField);
    }
  }
}
