import { defaultPagingOptions } from "../../components/core/lists/default-paging";
import { DictionaryObject } from "./../../models/types/dictionary";
import {
  CLEAR_FILTERS,
  CLEAR_ITEMS,
  COPY,
  LOADING_ITEMS,
  LOAD_ITEMS,
  PASTE,
  SELECT_ITEM,
  SELECT_ID,
  SET_ARCHIVE_CONDITIONS,
  SET_DEFAULT_CONDITIONS,
  SET_DONE_CONDITIONS,
  SET_FILTER,
  SET_MINE_CONDITIONS,
  SET_TOTAL_COUNT,
  SELECTED_COUNT,
  SHOW_DELETED,
} from "../actions/entitiesActions";
import {
  LOAD_ITEM,
  REMOVE_RELATED_CARD,
  UPDATE_ENTITIES_PAGING,
  UPDATE_ENTITIES_SORTING,
} from "../constants/entities";
import { CLEAR_RELATED_CARDS, LOAD_RELATED_CARD } from "../constants/entities";
import { IStateEntities } from "../states/state-entities";

const INITIAL_STATE: IStateEntities = {
  selectedItem: null,
  selectedId: null,
  items: [],
  loading: false,
  pagingOptions: defaultPagingOptions,
  selectedCount: 0,
  totalCount: 0,
  filter: {},
  doneConditions: null,
  mineConditions: null,
  archiveConditions: null,
  defaultConditions: null,
  cards: new Map<string, DictionaryObject>(),
  copyItem: null,
  sortingOptions: null,
  showDeleted: false,
};

const entitiesReducer = (
  state = INITIAL_STATE,
  action: { type: string; payload: any }
) => {
  const prevState = state;
  switch (action.type) {
    case LOAD_ITEMS:
      return {
        ...prevState,
        items: action.payload.items,
      };
    case CLEAR_ITEMS:
      return {
        ...prevState,
        items: [],
        totalCount: 0,
      };
    case LOAD_ITEM:
      const items = [...prevState.items];
      let index = -1;
      if (action.payload.item && prevState.items) {
        index = prevState.items.findIndex(
          (i) => i["id"] === action.payload.item["id"]
        );
        if (index !== -1) {
          items[index] = { ...action.payload.item };
        }
      }
      return {
        ...prevState,
        items: items,
        selectedItem: index !== -1 ? items[index] : prevState.selectedItem,
      };
    case SELECT_ITEM:
      return {
        ...prevState,
        selectedItem: action.payload,
      };
    case SELECT_ID:
      return {
        ...prevState,
        selectedId: action.payload,
      };
    case SET_TOTAL_COUNT:
      return {
        ...prevState,
        totalCount: action.payload,
      };
    case SELECTED_COUNT:
      return {
        ...prevState,
        selectedCount: action.payload,
      };
    case SET_FILTER:
      return {
        ...prevState,
        filter: action.payload,
      };
    case LOADING_ITEMS:
      return {
        ...prevState,
        loading: action.payload,
      };
    case SET_DONE_CONDITIONS:
      return {
        ...prevState,
        doneConditions: action.payload,
      };
    case SET_MINE_CONDITIONS:
      return {
        ...prevState,
        mineConditions: action.payload,
      };
    case SET_ARCHIVE_CONDITIONS:
      return {
        ...prevState,
        archiveConditions: action.payload,
      };
    case SET_DEFAULT_CONDITIONS:
      return {
        ...prevState,
        defaultConditions: action.payload,
      };
    case CLEAR_FILTERS:
      return {
        ...prevState,
        filter: {},
        mineConditions: null,
        doneConditions: null,
        archiveConditions: null,
        defaultConditions: null,
      };
    case LOAD_RELATED_CARD:
      const cards: Map<string, DictionaryObject> = new Map<
        string,
        DictionaryObject
      >(prevState.cards);
      if (action.payload.cardData) {
        cards.set(action.payload.card, action.payload.cardData);
      }
      return {
        ...prevState,
        cards: cards,
      };
    case CLEAR_RELATED_CARDS:
      return {
        ...prevState,
        cards: new Map<string, DictionaryObject>(),
      };
    case REMOVE_RELATED_CARD:
      const rCards: Map<string, DictionaryObject> = new Map<
        string,
        DictionaryObject
      >(prevState.cards);
      if (action.payload.card) {
        rCards.delete(action.payload.card);
      }
      return {
        ...prevState,
        cards: rCards,
      };
    case COPY:
      return {
        ...prevState,
        copyItem: action.payload,
      };
    case PASTE:
      return {
        ...prevState,
        copyItem: null,
      };
    case UPDATE_ENTITIES_PAGING:
      return {
        ...prevState,
        pagingOptions: action.payload.pagingOptions,
      };
    case UPDATE_ENTITIES_SORTING:
      return {
        ...prevState,
        sortingOptions: action.payload.sortingOptions,
      };
    case SHOW_DELETED:
      return {
        ...prevState,
        showDeleted: action.payload,
      };
    default:
      return prevState;
  }
};

export default entitiesReducer;
