import { project } from "./configs/index";

export const MOCKED_BACKEND = "http://localhost:3009";
export const AUTH_BACKEND = project.config.apiUrl + "/auth";
export const ROLES_BACKEND = project.config.apiUrl + "/api/auth/Roles";
export const PERMITS_BACKEND = project.config.apiUrl + "/api/auth/permit";
export const USERS_BACKEND = project.config.apiUrl + "/api/auth/user";
export const ENTITIES_BACKEND = project.config.apiUrl + "/api/dynamic/dao";
export const MODELS_BACKEND = project.config.apiUrl + "/api/dynamic/config";
export const ATTACHMENTS_BACKEND = project.config.apiUrl + "/api/file";
export const TEMPLATES_BACKEND = project.config.apiUrl + "/api/document";
export const CHANGES_BACKEND = project.config.apiUrl + "/api/journal";




