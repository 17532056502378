import { LOAD_SAMPLES, LOAD_DV_CHANGES } from "../constants/aem";
import { IStateAem } from "../states/state-aem";

const INITIAL_STATE: IStateAem = {
    samples: [],
    loadingSamples: false,
    dvchanges: [],
    loadingChanges: false
};

const aemReducer = (
    state = INITIAL_STATE,
    action: { type: string; payload: any }
) => {
    const prevState = state;
    switch (action.type) {
        case LOAD_SAMPLES:
            return { ...prevState, samples: action.payload };
        case LOAD_DV_CHANGES:
            return { ...prevState, dvchanges: action.payload.items, loadingChanges: action.payload.loading };
        default:
            return prevState;
    }
};

export default aemReducer;
