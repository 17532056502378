import { Button, FormGroup, Label } from "@blueprintjs/core";
import { Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Field, WrappedFieldInputProps } from "redux-form";
import { WrappedFieldProps } from "redux-form/lib/Field";
import { years } from "../../../../../helpers/year-reference";
import { IFormField } from "../../../../../models/fields/form-field";
import { Selectable } from "../../../../../models/types/dictionary";
import { IViewField } from "../../../../../models/view-field";
import SimpleSelect from "../../../forms/controls/simple-select/SimpleSelect";
import "./YearFilter.scss";

export const renderYearField = ({
    input,
    meta: { touched, invalid, error },
    placeholder,
    classType,
    disabled,
    maxDate,
}: WrappedFieldProps & {
    input: WrappedFieldInputProps;
    meta: any;
    field: IViewField;
    placeholder: string;
    startDate: string;
    endDate: string;
    disabled: boolean;
    classType: string;
    maxDate: Date;
}) => {
    if (!input.value)
        input.onChange(new Date(new Date().getFullYear(), 0, 1))
    return (
        <SimpleSelect
            items={years(0, 5)}
            renderView={(value: Selectable) =>
                value ? value.toString() : ""
            }
            component={
                <Button
                    rightIcon="caret-down"
                    text={
                        input.value ?
                            input.value.getFullYear()
                            : "(No Selection)"
                    }
                />
            }
            onChange={(e) => {
                input.onChange(new Date(e, 0, 1))
            }}
        />);
};

const parseYearInterval = (value: Date) => {
    if (value) {
        return { startDate: value, endDate: new Date(value.getFullYear(), 11, 31) }
    }
    return null;
};

const formatYearInterval = (value: any) => {
    if (value && value.startDate) {
        return value.startDate;
    }
    return null;
};

export const YearFilter = (props: IFormField) => {
    const { t } = useTranslation();
    const { f, fieldName } = props;
    return (
        <Row className="year-filter" key={f.name}>
            <FormGroup>
                <Label htmlFor={fieldName}>{t(f.name)}</Label>
                <div className="row">
                    <Field
                        name={fieldName}
                        type="text"
                        parse={parseYearInterval}
                        format={formatYearInterval}
                        onChange={(e: any) => {
                            if (props.onChange) {
                                props.onChange({ startDate: e, endDate: new Date(e.getFullYear(), 11, 31).toString() });
                            }
                        }}
                        field={f}
                        component={renderYearField}
                    />
                </div>
            </FormGroup>
        </Row>

    );
};

export default YearFilter;
