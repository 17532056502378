import { Table } from "antd";
import Moment from "moment";
import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { UserStatuses } from "../../../models/enums/user-statuses";
import { ILang } from "../../../models/lang";
import { IPagingOptions } from "../../../models/paging-options";
import { IUser } from "../../../models/users/user";
import { selectNext, selectPrev } from "../../../redux/actions/entitiesActions";
import { getRoles } from "../../../redux/actions/roleActions";
import {
  getEmployeeByUserId,
  refreshUsers, selectUser, updatePaging
} from "../../../redux/actions/usersActions";
import { IAppState } from "../../../redux/states/state";
import GeneralFooter from "../../core/lists/general-footer/GeneralFooter";
import CreateUser from "../create-user/CreateUser";
import { defaultPagingOptions } from "./default-paging";
import "./Users.scss";

interface Props extends RouteComponentProps, WithTranslation {
  users: Map<string, IUser>;
  refreshUsers: (pagingOptions: IPagingOptions) => void;
  updatePaging: (pagingOptions: IPagingOptions) => void;
  selectUser: (user: IUser) => void;
  getEmployeeByUserId: (userId: number) => void;
  getRoles: () => void;
  loading: boolean;
  totalCount: number;
  pagingOptions: IPagingOptions;
  selectedUser: IUser | null;
}

interface IColumn {
  title: string;
  key?: string;
  width?: number;
  value: string;
  dataIndex: string;
  render?: any;
}

class Users extends React.PureComponent<Props, any> {
  public state = {
    columns: [
      {
        title: "",
        value: "UserName",
        dataIndex: "username",
        key: "username",
      },
      {
        title: "",
        value: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "",
        value: "Role",
        dataIndex: "roleName",
        key: "role",
        render: (roleName: ILang) => <span>{roleName?.az}</span>,
      },
      {
        title: "",
        value: "Registered",
        dataIndex: "registered",
        key: "registered",
        render: (date: string) => (
          <span>{Moment(date).format("DD.MM.YYYY")}</span>
        ),
      },
      {
        title: "",
        value: "Status",
        dataIndex: "status",
        key: "status",
        render: (value: number) => (
          <span>{this.props.t("UserStatus." + UserStatuses[value])}</span>
        ),
      },
    ] as IColumn[],
    selectedId: 0,
    scrollY: 0,
    isOpen: false,
  };

  onSelectChange = (selectedId: number) => {
    this.setState({ selectedId });
  };

  onDoubleChange = (selectedId: number) => {
    const { users } = this.props;
    const selectedUser = users.get(selectedId.toString());
    this.props.selectUser(selectedUser ? selectedUser : ({} as IUser));
    if (selectedUser && selectedUser["id"]) {
      this.props.getEmployeeByUserId(selectedUser["id"]);
    }
    this.handleOpen();
  };

  componentDidMount(): void {
    const { pagingOptions } = this.props;
    this.props.refreshUsers(pagingOptions);

    this.props.getRoles();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  componentWillUnmount() {
    this.props.updatePaging(defaultPagingOptions);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      scrollY: window.innerHeight - 300,
    });
  };

  keyPressEvent(e: any) {
    if (e.keyCode === 13) {
      this.handleOpen();
    } else if (e.keyCode === 40) {
      this.selectNext();
    } else if (e.keyCode === 38) {
      this.selectPrev();
    }
  }

  selectPrev = () => {
    const prevItem = selectPrev(
      Array.from(this.props.users.values()),
      this.state.selectedId
    );
    this.setState({ selectedId: prevItem });
  };

  selectNext = () => {
    const nextItem = selectNext(
      Array.from(this.props.users.values()),
      this.state.selectedId
    );
    this.setState({ selectedId: nextItem });
  };

  public render() {
    const { t, users, totalCount, loading, pagingOptions } = this.props;
    const { selectedId } = this.state;

    this.state.columns.map(
      (item) => (item.title = `${t(`EmployeeRef.${item.value}`)}`)
    );
    const { columns, scrollY } = this.state;
    return (
      <div className="users-page">
        <div
          tabIndex={0}
          onKeyUp={(e) => {
            this.keyPressEvent(e);
          }}
        >
          <Table
            scroll={{
              y: scrollY,
            }}
            loading={loading}
            tableLayout={"auto"}
            columns={columns}
            dataSource={Array.from(users.values())}
            size="small"
            pagination={false}
            className={"scroll-table virtual-table"}
            footer={() => (
              <GeneralFooter
                allowCreate={true}
                newTitle={"NewUser"}
                totalCount={totalCount}
                hasPagination={true}
                pagingOptions={pagingOptions}
                createNew={this.createNew}
                selectNext={this.selectNext}
                selectPrev={this.selectPrev}
                refreshItems={this.props.refreshUsers}
              />
            )}
            rowKey="id"
            bordered
            rowClassName={(record: any, index: number) => {
              let rowClass = "all-row ";
              if (index === 0) {
                rowClass += "first-row ";
              }
              if (selectedId === record["id"]) {
                rowClass += "scroll-row selected-row";
              }
              return rowClass;
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  this.onSelectChange(record["id"]);
                },
                onDoubleClick: () => {
                  this.onDoubleChange(record["id"]);
                },
              };
            }}
          />
        </div>
        <CreateUser
          isOpen={this.state.isOpen}
          openForm={() => this.setState({ isOpen: false })}
        />
      </div>
    );
  }

  private handleOpen = () => {
    this.setState({ isOpen: true });
  };

  private createNew = () => {
    this.props.selectUser({} as IUser);
    this.handleOpen();
  };
}

const mapStateToProps = (state: IAppState) => ({
  users: state.usersReducer.users,
  selectedUser: state.usersReducer.selectedUser,
  loading: state.usersReducer.loading,
  totalCount: state.usersReducer.totalCount,
  pagingOptions: state.usersReducer.pagingOptions,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      refreshUsers: (pagingOptions: IPagingOptions) =>
        refreshUsers(pagingOptions),
      selectUser: (user: IUser) => selectUser(user),
      getEmployeeByUserId: (userId: number) => getEmployeeByUserId(userId),
      getRoles: () => getRoles(),
      updatePaging: (pagingOptions: IPagingOptions) =>
        updatePaging(pagingOptions),
    },
    dispatch
  );

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Users))
);
