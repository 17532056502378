import { FileInput } from "@blueprintjs/core";
import { Col, Row } from "antd";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { WrappedFieldProps } from "redux-form/lib/Field";
import { Views } from "../../../../../models/enums/views";
import { uploadFile } from "../../../../../redux/actions/attachmentsActions";
import ValidMessages from "../../valid-messages/ValidMessages";

const renderFileField = ({
  input,
  meta: { touched, invalid, error },
  buttonText,
}: WrappedFieldProps & { placeholder: string; buttonText: string }) => {
  const handleInputChange = (e: any) => {
    if (e.target.files.length) {
      const arrFiles: File[] = Array.from(e.target.files);
      const formData = new FormData();
      formData.append("file", arrFiles[0], arrFiles[0].name);
      // input.onChange("2ad04b22-07b9-4e52-a5d0-8977571dcb8c");
      uploadFile(formData).then((response) => {
        input.onChange(response.data.id);
      });
    }
  };

  return (
    <div>
      <FileInput
        text="Choose file..."
        hasSelection={!!input.value}
        buttonText={buttonText}
        fill={false}
        onInputChange={handleInputChange}
        inputProps={{
          className: error && touched ? "bp3-intent-danger" : "",
        }}
      />
      {touched && error && <ValidMessages text={error} />}
    </div>
  );
};

export const FileInputField = (props: {
  selectedItem: any;
  view: Views;
  onChange?: (e: any) => void;
  fieldName: string;
}) => {
  const { t } = useTranslation();
  const { fieldName, view } = props;

  const FileinputFilterView = () => {
    return null;
  };

  const FileinputFormView = () => {
    return (
      <Row className="date-field date-field_simple" key={fieldName}>
        <Col span={16}>
          <Field
            name={fieldName}
            type="text"
            buttonText={t("UI.Attach")}
            component={renderFileField}
          />
        </Col>
      </Row>
    );
  };

  switch (view) {
    case Views.FILTER:
      return FileinputFilterView();
    case Views.FORM:
      return FileinputFormView();
    case Views.REFERENCE:
      return <span>Text</span>;
    default:
      return <span>Text</span>;
  }
};

export default FileInputField;
