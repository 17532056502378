import {
  Alignment,
  Navbar,
  NavbarDivider,
  NavbarGroup,
  NavbarHeading,
} from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";
import References from "../../references/References";
import UserInfo from "../../user/user-info/UserInfo";
import Sections from "./Sections";
import "./TopMenu.scss";
import {project} from "../../../configs/index";

export const TopMenu = () => {
  const userInfo = useSelector(
    (state: any) => state.authorizationReducer.userInfo
  );

  return (
    <Navbar>
      <NavbarGroup align={Alignment.LEFT}>
        <NavbarHeading>
          {/* <img alt="logo" src={require('./../../../logo.png')} /> */}
          {project.config.applicationName}
        </NavbarHeading>
        <NavbarDivider />
        {userInfo ? <Sections /> : null}
      </NavbarGroup>
      <NavbarGroup align={Alignment.RIGHT}>
        {userInfo ? <References /> : null}
        {userInfo ? <UserInfo /> : null}
      </NavbarGroup>
    </Navbar>
  );
};

export default TopMenu;
