import {
  CLEAR_TOASTS,
  DISMISS_TOAST,
  ENQUEUE_TOAST,
} from "../constants/errors";
import { IStateErrors } from "../states/state-errors";

const INITIAL_STATE: IStateErrors = {
  toastQueue: [],
  dismiss: false,
  dismissKey: "",
};

const errorsReducer = (
  state = INITIAL_STATE,
  action: { type: string; payload: any }
) => {
  const prevState = state;
  switch (action.type) {
    case CLEAR_TOASTS:
      return {
        ...state,
        toastQueue: [],
      };
    case ENQUEUE_TOAST: {
      return {
        ...state,
        toastQueue: [...state.toastQueue, action.payload],
      };
    }
    case DISMISS_TOAST: {
      return {
        ...state,
        dismiss: action.payload.dismiss,
        dismissKey: action.payload.dismissKey,
      };
    }
    default:
      return prevState;
  }
};

export default errorsReducer;
