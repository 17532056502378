import * as _ from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { getFormInitialValues, getFormValues, isDirty } from "redux-form";
import { ModelType } from "../../../../models/enums/model-type";
import { IPagingOptions } from "../../../../models/paging-options";
import {
  Dictionary,
  DictionaryObject
} from "../../../../models/types/dictionary";
import { setConfirm } from "../../../../redux/actions/alertActions";
import { saveCards } from "../../../../redux/actions/cardActions";
import {
  getEntities2,
  selectItem
} from "../../../../redux/actions/entitiesActions";
import {
  clearRelatedCards,
  updatePaging
} from "../../../../redux/actions/newEntitiesActions";
import { clearTryValues } from "../../../../redux/actions/newSequencesActions";
import { IAppState } from "../../../../redux/states/state";
import GenericForms from "../../forms/form-modal/generic-form/GenericForms";
import ListFooter from "../list-footer/ListFooter";
import MainListAnt from "../main-list-ant/MainListAnt";
import "./ListLayout.scss";
import { diff } from 'deep-object-diff';

export const ListLayout = (props: { createNew: boolean, formValues?: any, initialFormValues?: any, isDirty: boolean }) => {
  const loading = useSelector(
    (state: IAppState) => state.modelsReducer.loading
  );
  const activeModel = useSelector(
    (state: IAppState) => state.modelsReducer.activeModel
  );
  const dbname = useSelector((state: IAppState) => state.modelsReducer.dbname);
  const selectedItem = useSelector(
    (state: IAppState) => state.entitiesReducer.selectedItem
  );
  const tryValue = useSelector(
    (state: IAppState) => state.sequencesReducer.tryValue
  );
  const model = useSelector((state: IAppState) =>
    state.modelsReducer.models.get(activeModel)
  );
  const models = useSelector((state: IAppState) => state.modelsReducer.models);
  const cards = useSelector((state: IAppState) => state.entitiesReducer.cards);
  const pagingOptions = useSelector(
    (state: IAppState) => state.entitiesReducer.pagingOptions
  );


  const sortingOptions = useSelector(
    (state: IAppState) => state.entitiesReducer.sortingOptions
  );
  const [isOpen, setOpen] = useState(false);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const updateCallback = (close: boolean, item?: DictionaryObject | null) => {

    if (model) {
      if (close)
        setOpen(false)
      dispatch(getEntities2(model.dbname, pagingOptions, sortingOptions));
    }
  };

  const transformCallback = (currentItem: DictionaryObject) => {
    if (model) {
      dispatch(saveCards(currentItem, model, models, cards));
      dispatch(selectItem(_.clone(currentItem)));
    }
  };

  const onClose = () => {
    
    console.log('diff objects', diff(props.formValues, props.initialFormValues));

    if (props.isDirty) {
      dispatch(setConfirm({
        message: t("UI.CloseMessage"),
        isOpen: true,
        onConfirm: () => {
          setOpen(false);
          if (model) {
            dispatch(clearTryValues(Array.from(tryValue.keys())));
            dispatch(clearRelatedCards());
          }
        }
      }))
    }
    else {
      setOpen(false);
      if (model) {
        dispatch(clearTryValues(Array.from(tryValue.keys())));
        dispatch(clearRelatedCards());
      }
    }
  };

  if (loading) {
    return <span>Loading...</span>;
  }


  return (
    <div className={`list-layout`}>
      <MainListAnt
        selectItem={(item: Dictionary) => dispatch(selectItem(_.clone(item)))}
        selectedItem={selectedItem}
        openForm={setOpen}
        pagingOptions={pagingOptions}
        footer={(selectPrev: () => void, selectNext: () => void) => (
          <ListFooter
            openForm={setOpen}
            updatePagingOps={(pagingOptions: IPagingOptions) => {
              dispatch(updatePaging(pagingOptions));
            }}
            canExport={true}
            createNew={props.createNew}
            selectPrev={selectPrev}
            selectNext={selectNext}
          />
        )}
        model={activeModel}
      />
      <GenericForms
        isOpen={isOpen}
        selectedItem={selectedItem}
        type={ModelType.Entity}
        modelName={activeModel}
        dbname={dbname}
        updateCallback={updateCallback}
        transformCallback={transformCallback}
        onClose={onClose}
      />
    </div>
  );
};


const mapStateToProps = (state: IAppState) => {
  return {
    formValues: getFormValues(state.modelsReducer.activeModel + state.modelsReducer.activeForm + "__FORM")(state),
    initialFormValues: getFormInitialValues(state.modelsReducer.activeModel + state.modelsReducer.activeForm + "__FORM")(state),
    isDirty: isDirty(state.modelsReducer.activeModel + state.modelsReducer.activeForm + "__FORM")(state)
  }
};
export default connect(
  mapStateToProps
)(ListLayout);