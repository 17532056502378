import { Button, Classes, Intent } from "@blueprintjs/core";
import { Col, Row } from "antd";
import classNames from "classnames";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IConstraint } from "../../../../../../models/constraint";
import { CopyItem, ICopyItem } from "../../../../../../models/copy-item";
import { IEmployee } from "../../../../../../models/employee";
import { ModelType } from "../../../../../../models/enums/model-type";
import {
  Dictionary,
  DictionaryObject
} from "../../../../../../models/types/dictionary";
import { IViewField } from "../../../../../../models/view-field";
import { IViewModel } from "../../../../../../models/view-model";
import {
  copy,
  updateEntity
} from "../../../../../../redux/actions/entitiesActions";
import { showError } from "../../../../../../redux/actions/errorsActions";
import {
  refreshItem
} from "../../../../../../redux/actions/newEntitiesActions";
import { getReferenceQuery } from "../../../../../../redux/actions/referencesActions";
import { IAppState } from "../../../../../../redux/states/state";
import BaseTable from "../../../../lists/base-table/BaseTable";
import { defaultPagingOptions } from "../../../../lists/default-paging";
import FieldPopupForm from "../../../controls/fields-popup-form/FieldsPopupForm";
import SimpleSelect from "../../../controls/simple-select/SimpleSelect";
import PrintButtons from "../../../print-buttons/PrintButtons";
import DataSheet from "../data-sheet/DataSheet";
import TextEditor from "../data-sheet/text-editor/TextEditor";
import "./InnerTableDetails.scss";
interface Props extends WithTranslation {
  selectedItem: any;
  model: IViewModel;
  parentModel: IViewModel;
  item: Dictionary;
  type: ModelType;
  f: IViewField;
  formName: string;
  listeners: Map<string, any>;
  models: Map<string, IViewModel>;
  currentEmployee: IEmployee | null;
  closeWindow: () => void;
  copyItem: ICopyItem | null;
  copy: CallableFunction;
  updateEntity: CallableFunction;
  refreshItem: CallableFunction;
  showError: CallableFunction;
}
interface State {
  reference: DictionaryObject[];
  initialData: any[][];
  newData: {
    Analysis: any,
    Result: string,
    Norma: string,
    User: any
  },
  analysisItems: any[],
  data: any;
  columns: IViewField[];
  selections: boolean[];
  confirmIsOpen: boolean;
  printText: string;
}

class InnerTableDetails extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    let temp = this.props.selectedItem.AnalysisDetail.map((row: {
      Analysis: any,
      Result: string,
      Norma: string,
      User: any
    }, index: number) => this.convertToTableRow(row, index))
      .sort((a: any, b: any) => {
        if (a[0].value.ParamOrder > b[0].value.ParamOrder) return 1;
        if (a[0].value.ParamOrder < b[0].value.ParamOrder) return -1;
        return 0;
      });

    this.state = {
      reference: [],
      columns: [],
      newData: {
        Analysis: null,
        Result: "",
        Norma: "",
        User: props.currentEmployee
      },
      analysisItems: [],
      initialData: temp,
      data: this.clone(temp),
      selections: Array(temp.length).fill(false, 0, temp.length),
      confirmIsOpen: false,
      printText: ""
    };
  }

  componentDidMount() {
    const { models, f } = this.props;
    const viewModel = models.get(f.model);
    if (!viewModel) {
      return <span />;
    }
    this.setState({ columns: viewModel.fields });
    getReferenceQuery("AnalysisParams", "").then(
      (result) => {
        if (result) {
          this.setState({ analysisItems: result.filter((el: any) => el.ParamName) });
        }
      }
    )
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevState.reference !== this.state.reference ||
      prevState.reference.length !== this.state.reference.length
    ) {
      this.formateTable();
    }
  }

  convertToServerRow = (row: any[]) => {
    return { Analysis: row[0].value, Result: row[1].value, Norma: row[2].value, User: row[3].value };
  }

  convertToTableRow = (row: {
    Analysis: any,
    Result: string,
    Norma: string,
    User: any
  }, index: number) => {

    return [
      {
        value: row.Analysis,
        hint: "Valid",
        readOnly: true,
        key: `${index}-0`
      },
      {
        value: row.Result,
        hint: "Not valid",
        dataEditor: TextEditor,
        key: `${index}-1`
      },
      {
        value: row.Norma,
        hint: "Not valid",
        dataEditor: TextEditor,
        key: `${index}-2`
      },
      {
        value: row.User,
        hint: "Valid",
        readOnly: true,
        key: `${index}-3`
      }
    ];
  }

  clone(data: any[][]) {
    return data.map((row) => row.map((cell) => ({ ...cell })));
  }

  formateTable() {
    const { reference } = this.state;
    const { currentEmployee } = this.props;
    const data: any[][] = [];
    reference.forEach((ref: DictionaryObject) => {
      data.push([
        { value: ref, readOnly: true },
        { value: "", dataEditor: TextEditor },
        { value: "", dataEditor: TextEditor },
        { value: currentEmployee, readOnly: true },
      ]);
    });
    this.setState({
      data: data.map((a, i) =>
        a.map((cell, j) => Object.assign(cell, { key: `${i}-${j}` }))
      ),
    });
  }

  clearReport = () => {
    this.setState({ reference: [] });
  };

  copyReport = () => {
    this.props.copy(
      new CopyItem(this.props.model, this.clone(this.state.data))
    );
  };

  pasteReport = () => {
    let data = this.props.copyItem?.getItem() as Array<any>;
    this.setState({
      data,
      selections: Array(data.length).fill(false, 0, data.length),
    });
  };

  cancel() {
    this.setState({ data: this.state.initialData });
    this.props.closeWindow();
  }

  cancelChanges = () => {
    const data = this.clone(this.state.initialData);
    this.setState({
      data,
      selections: Array(data.length).fill(false, 0, data.length),
    });
  };

  removeSelectedRow = () => {
    const { data, selections } = this.state;
    const newData = data.filter((el: any, index: number) => !selections[index]);
    this.setState({
      data: newData,
      selections: Array(newData.length).fill(false, 0, newData.length),
    });
  };

  submit = () => {
    let data = { ...this.props.selectedItem };
    data.AnalysisDetail = this.state.data.map((row: any[]) => this.convertToServerRow(row));
    this.props.updateEntity(this.props.parentModel?.name, data, [], this.props.selectedItem, () => this.props.refreshItem(this.props.parentModel.name, data.id));
    this.props.closeWindow();
  }

  onChangeData = (data: any) => {
    this.setState({ data });
  };

  createNew() {
    const { models, f, parentModel } = this.props;
    const referenceModel = models.get(f.model);
    if (!referenceModel || !parentModel) {
      return;
    }

    const fieldRef = referenceModel.fields.find(
      (vf: IViewField) => vf.name === "Analysis"
    );
    const fieldType = parentModel.fields.find(
      (vf: IViewField) => vf.model === "DrugsTypeList"
    );

    if (!fieldRef || !fieldType) {
      return;
    }

    const { selectedItem } = this.props;
    if (!selectedItem || !selectedItem[fieldType.name]) {
      this.setState({ reference: [] });
      return;
    }
    const constraint: IConstraint = {
      type: "Eq",
      field: "DrugType.id",
      value: selectedItem[fieldType.name]["id"],
    };

    getReferenceQuery(fieldRef.model, "", [constraint]).then((response) => {
      this.setState({ reference: response || [] });
    });
  }


  renderView = (item: any): string => {
    if (!item) {
      return "";
    }
    return item.ParamName;
  };

  public render() { 
    const { t, f, parentModel, type, selectedItem } = this.props;
    const { reference, columns, data, selections } = this.state;
    return (
      <>
        <div
          className={classNames(
            "header-actions__container",
            Classes.DIALOG_HEADER
          )}
        >
          <div className={classNames("header-actions__left")}>
            <div><Button intent={Intent.NONE} onClick={() => this.clearReport()}>
              {t("UI.ReportClear")}
            </Button></div>
            <FieldPopupForm
              Save={() => {
                if (this.state.newData.Analysis) {
                  this.setState({
                    data: [...this.state.data, this.convertToTableRow(this.state.newData, this.state.data.length)],
                    newData: {
                      Analysis: null,
                      Result: "",
                      Norma: "",
                      User: this.props.currentEmployee
                    }
                  })
                }
              }}
              content={
                <SimpleSelect

                  renderView={this.renderView}
                  items={this.state.analysisItems}

                  onChange={(item) => { this.setState({ newData: { ...this.state.newData, Analysis: item } }) }}
                  component={
                    <div>
                      <span>{t("Analysis")}:</span>
                      <Button
                        small
                        rightIcon="caret-down"
                        text={this.state.newData.Analysis ? this.renderView(this.state.newData.Analysis) : t("UI.NoSelection")}
                      /></div>
                  } />
              }
            />
          </div>
          <div
            className={classNames(
              "header-actions__right",
              "header-actions--right"
            )}
          >
            <Button intent={Intent.PRIMARY} onClick={() => this.copyReport()}>
              {t("UI.Copy")}
            </Button>
            <Button
              intent={Intent.PRIMARY}
              onClick={() => this.pasteReport()}
              disabled={!this.props.copyItem}
            >
              {t("UI.Paste")}
            </Button>
          </div>
        </div>
        <div className="dialog__body">
          <div className="dialog__content">
            <DataSheet
              columns={columns}
              data={data}
              model={f.model}
              reference={reference}
              onChange={this.onChangeData}
              onSelected={(selections: boolean[]) =>
                this.setState({ selections: selections })
              }
              selections={selections}
            />
            <Col>
              <Row className="dialog__title">{t("PrintHistory")}</Row>
              <BaseTable
                viewPath={'list'}
                loading={false}
                allowSelect={false}
                data={selectedItem.PrintHistory ? selectedItem.PrintHistory : []}
                type={ModelType.Entity}
                model={"PrintHistory"}
                selectedItem={selectedItem}
                openForm={() => null}
                selectItem={(item: any) => { }}
                pagingOptions={defaultPagingOptions}
                footer={() => ""}
              /></Col>
          </div>
        </div>
        <div
          className={classNames(
            "footer-actions__container",
            Classes.DIALOG_FOOTER
          )}
        >
          <div
            className={classNames(
              "footer-actions__left",
              Classes.DIALOG_FOOTER_ACTIONS
            )}
          >
            <Button
              intent={Intent.NONE}
              text={t("UI.CreateNew")}
              onClick={() => this.createNew()}
            />
            <Button intent={Intent.NONE} text={t("UI.Search")} />
            <PrintButtons
              tab={parentModel.tabs.find(t => t.title === "General") || {
                title: "",
                grid: "auto-grid",
                printForms: [],
                icon: "add",
                condition: "true",
                fields: []
              }}
              selectedItem={this.props.selectedItem}
              model={parentModel}
              type={type}
              analysis={true}
            />
            <Button
              intent={Intent.NONE}
              icon="remove"
              text={t("UI.Cancel")}
              onClick={() => this.cancelChanges()}
            />
            <Button
              intent={Intent.NONE}
              icon="trash"
              text={t("UI.ClearDelete")}
              onClick={() => this.removeSelectedRow()}
            />
            <Button disabled text={this.props.selectedItem.PrintCount} />
          </div>
          <div
            className={classNames(
              "footer-actions__right",
              Classes.DIALOG_FOOTER_ACTIONS
            )}
          >
            <Button intent={Intent.PRIMARY} onClick={() => this.submit()}>
              {t("UI.Save")}
            </Button>
            <Button intent={Intent.NONE} onClick={() => this.cancel()}>
              {t("UI.Close")}
            </Button>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  models: state.modelsReducer.models,
  currentEmployee: state.authorizationReducer.currentEmployee,
  copyItem: state.entitiesReducer.copyItem
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    copy: bindActionCreators(copy, dispatch),
    updateEntity: bindActionCreators(updateEntity, dispatch),
    refreshItem: bindActionCreators(refreshItem, dispatch),
    showError: bindActionCreators(showError, dispatch),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(InnerTableDetails)
);
