import {
  Button,
  FormGroup,
  Icon,
  InputGroup,
  Intent,
  Label,
  Tag,
} from "@blueprintjs/core";
import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { autofill, Field } from "redux-form";
import { WrappedFieldProps } from "redux-form/lib/Field";
import { checkDisabledConstraints } from "../../../../../helpers/check-disabled-constraints";
import { checkShowConstraints } from "../../../../../helpers/check-show-constraints";
import { Views } from "../../../../../models/enums/views";
import { IFormField } from "../../../../../models/fields/form-field";
import { DictionaryObject } from "../../../../../models/types/dictionary";
import { IViewField } from "../../../../../models/view-field";
import "./TextInputField.scss";

export const renderTextFieldClear = ({
  input,
  meta: { touched, invalid, error },
  placeholder,
  disabled,
  field,
  selectedItem,
  formName,
}: WrappedFieldProps & {
  placeholder: string;
  disabled: boolean;
  field: IViewField;
  selectedItem: any;
  formName: string;
}) => {
  const clearText = () => {
    if (input.value) {
      input.onChange("");
    }
  };

  const renderTextInputs = (field: IViewField) => {
    return (
      <>
        {field.textInputs &&
          field.textInputs.map((el: string) => (
            <Button
              onClick={() => input.onChange(input.value + el)}
              intent={Intent.NONE}
            >
              {el}
            </Button>
          ))}
      </>
    );
  };

  return (
    <div className="row-input">
      <InputGroup
        {...input}
        className="any-text"
        disabled={disabled}
        leftElement={field.prefix ? tag(field.prefix) : undefined}
        rightElement={
          <>
            {input.value && !disabled && (<Icon
              className="clear-btn"
              icon="small-cross"
              onClick={() => clearText()}
            />)}
            {renderTextInputs(field)}
            {field.postfix ? tag(field.postfix) : undefined}
          </>
        }
      />
      {/*{touched && ((error && <div>{error}</div>))}*/}
    </div>
  );
};

const tag = (value: string) => {
  return <Tag minimal={true}>{value}</Tag>;
};

export const TextInputField = (props: IFormField) => {
  const { t } = useTranslation();
  const { f, fieldName, view, showLabel } = props;
  const selectedItem: DictionaryObject | null = props.selectedItem as DictionaryObject;
  const dispatch = useDispatch();

  const TextInputFilterView = () => {
    return (
      <Row>
        <FormGroup className="form-group-text">
          <Label htmlFor={fieldName}>{t(f.name)}</Label>
          <div className="any-text__container">
            <Field
              name={fieldName}
              type="text"
              component={renderTextFieldClear}
              field={f}
              onChange={(value: any) =>
                props.onChange && props.onChange(value)
              }
            />
          </div>
        </FormGroup>
      </Row>
    );
  };

  const TextInputFormView = () => {
    const { listeners } = props;
    const show = checkShowConstraints(f, listeners);
    const disabled = checkDisabledConstraints(f, listeners);
    if (show) {
      return (
        <Row>
          {showLabel && (
            <Col span={8}>
              <Label htmlFor={fieldName}>
                <span>{t(f.name)}</span>
                <span className="required">{f.required ? "*" : ""}</span>
              </Label>
            </Col>
          )}
          <Col span={16}>
            <div className="any-text__container">
              <Field
                name={fieldName}
                disabled={
                  f.readonly || (f.writable === false) || f.name.toLowerCase() === "id" || disabled
                }
                type="text"
                field={f}
                formName={props.formName}
                selectedItem={props.selectedItem}
                onChange={(e: any) => {
                  props.onChange
                    && props.onChange(
                      e && e.target
                        ? e.target.value
                        : e &&
                          (typeof e === "string" || typeof e === "number")
                          ? e
                          : null
                    )
                }}
                component={renderTextFieldClear}
              />
            </div>
          </Col>
        </Row>
      );
    } else {
      dispatch(
        autofill(
          "OutgoingDocument__FORM",
          f.name,
          selectedItem ? selectedItem[f.name] : undefined
        )
      );
      dispatch(
        autofill(
          "IncomingDocument__FORM",
          f.name,
          selectedItem ? selectedItem[f.name] : undefined
        )
      );
      return <span />;
    }
  };

  switch (view) {
    case Views.FILTER:
      return TextInputFilterView();
    case Views.FORM:
      return TextInputFormView();
    case Views.REFERENCE:
      return <span>Text</span>;
    default:
      return <span>Text</span>;
  }
};
export default TextInputField;
