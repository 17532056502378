import React from "react";
import "./NumberField.scss";
import { FormGroup, Icon, Label, NumericInput } from "@blueprintjs/core";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { IViewField } from "../../../../../models/view-field";
import { Views } from "../../../../../models/enums/views";
import { autofill, Field, formValueSelector } from "redux-form";
import { WrappedFieldProps } from "redux-form/lib/Field";
import { useDispatch, useSelector } from "react-redux";
import { checkShowConstraints } from "../../../../../helpers/check-show-constraints";
import { checkDisabledConstraints } from "../../../../../helpers/check-disabled-constraints";
import ResultViewField from "../result-view-field/ResultViewField";
import { renderEmptyField } from "../../controls/empty-btn/EmptyBtn";
import { IFormField } from "../../../../../models/fields/form-field";
import { DictionaryObject } from "../../../../../models/types/dictionary";
import useRelationHook from "../../../../../hooks/relationHook";
import Generate from "../../controls/generate/Generate";
/* eslint-disable no-eval */

const renderField = ({
  input,
  meta: { touched, invalid, error },
  placeholder,
  disabled,
  field,
  selectedItem,
  formName,
  filter,
  data,
  relationData
}: WrappedFieldProps & {
  placeholder: string;
  disabled: boolean;
  field: IViewField;
  selectedItem: any;
  formName: string;
  filter: boolean;
  data?: any;
  relationData?: any;
}) => {

  const clearText = () => {
    if (input.value) {
      input.onChange("");
    }
  };
  /*В onChange происходит проверка на возможность изменения самостоятельно. */
  return (
    <div className="row-input">
      <NumericInput
        className="any-text"
        disabled={disabled}
        large={false}
        fill
        buttonPosition={"none"}
        allowNumericCharactersOnly
        value={field.isResultView && field.resultFunction && data ? eval(field.resultFunction)(data) : (input.value || "")}
        onValueChange={(e: any) => input.onChange(!field.relation || field.relation?.changable || !field.relation.func ? e : field.relation.func(e, relationData, field.relation.propertyNames))}
        rightElement={
          input.value && !disabled ? (
            <Icon
              className="clear-btn"
              icon="small-cross"
              onClick={() => clearText()}
            />
          ) : (
              <span></span>
            )
        }
      />
      {field.generate && field.sequence && filter && <Generate sequence={field.sequence} onChange={input.onChange} />}
      {field.isResultView && (
        <ResultViewField
          selectedItem={selectedItem}
          field={field}
          formName={formName}
          value={input.value || ""}
        />
      )}
      {/*{touched && ((error && <div>{error}</div>))}*/}
    </div>
  );
};

const parseStringToNumber = (value: string) => {
  return typeof value === "string" && value ? Number(value) : value;
};

export const NumberField = (props: IFormField) => {
  const { f, fieldName, view, showEmpty, hidden, showLabel } = props;
  const { t } = useTranslation();

  const state = useSelector((state: any) => state);
  let selector: any = null;
  const data: any = {};
  if (f.isResultView) {
    if (props.formName) {
      selector = formValueSelector(props.formName);
    }

    const connectedTo = f.connectedTo;

    if (selector && connectedTo && connectedTo.length) {
      connectedTo.forEach((f) => {
        data[f.field] = selector(state, f.field);
      });
      data[f.name] = selector(state, f.name);
    }
  }
  const selectedItem: DictionaryObject | null = props.selectedItem as DictionaryObject;
  const dispatch = useDispatch();
  const NumberFilterView = () => {
    return (
      <Row>
        <FormGroup className="form-group-text">
          <Label htmlFor={fieldName}>{t(f.name)}</Label>
          <div className="any-text__container">
            <Field
              name={fieldName}
              type="text"
              disabled={f.readonly}
              parse={parseStringToNumber}
              component={renderField}
              field={f}
              filter={true}
              onChange={(value: any) =>
                props.onChange && props.onChange(value)
              }
            />
            {!f.noSearchEmpty && showEmpty && (
              <Field
                name={fieldName + "__EMPTY"}
                type="checkbox"
                disabled={f.readonly}
                onClick={() => { }}
                onChange={(e: any) => { }}
                field={f}
                view={view}
                component={renderEmptyField}
              />
            )}
          </div>
        </FormGroup>
      </Row>
    );
  };
  const NumberFormView = () => {
    const { listeners } = props;
    const show = checkShowConstraints(f, listeners);
    const disabled = checkDisabledConstraints(f, listeners);

    const relationData = useRelationHook({
      f: props.f,
      fieldName: props.fieldName,
      formName: props.formName
    });
    if (show) {
      return (
        <Row className={hidden ? "display-none" : ""}>
          {showLabel && (
            <Col span={8}>
              <Label htmlFor={fieldName}>
                <span>{t(f.name)}</span>
                <span className="required">{f.required ? "*" : ""}</span>
              </Label>
            </Col>
          )}
          <Col span={16}>
            <div className="any-text__container">
              <Field
                name={fieldName}
                disabled={
                  f.readonly || (f.writable === false) || f.name.toLowerCase() === "id" || disabled
                }
                type="text"
                field={f}
                formName={props.formName}
                selectedItem={props.selectedItem}
                parse={parseStringToNumber}
                relationData={relationData}
                onChange={(e: any) => {
                  if (props.onChange) {
                    props.onChange(
                      e && e.target
                        ? e.target.value
                        : e && typeof e === "number"
                          ? e
                          : null
                    );
                  }
                }}
                component={renderField}
                data={data}
              />
            </div>
          </Col>
        </Row>
      );
    } else {
      dispatch(
        autofill(
          "OutgoingDocument__FORM",
          f.name,
          selectedItem ? selectedItem[f.name] : undefined
        )
      );
      dispatch(
        autofill(
          "IncomingDocument__FORM",
          f.name,
          selectedItem ? selectedItem[f.name] : undefined
        )
      );
      return <span />;
    }
  };
  switch (view) {
    case Views.FILTER:
      return NumberFilterView();
    case Views.FORM:
      return NumberFormView();
    case Views.REFERENCE:
      return <span>Text</span>;
    default:
      return <span>Text</span>;
  }
};

export default NumberField;
